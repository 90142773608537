import "./style.scss";


export function NotFoundPage() {

    return (
        <>
            <h2
                style={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "50px",
                }}
            >
                404
            </h2>
        </>
    );
}