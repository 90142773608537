export const PASSWORD_FIELD: string = "password";
export const EMAIL_FIELD: string = "email";
export const CLIENT_CODE: string = "clientCode";
export const ACCESS_TOKEN: string = "accessToken";
export const DATE_START: string = "dateStart";
export const DATE_END: string = "dateStart";

//User, supportUser
export const LOGIN_FIELD = "login";
export const CODE_FIELD = "code";
export const GENDER_FIELD = "gender";
export const FIRST_NAME_FIELD = "firstName";
export const LAST_NAME_FIELD = "lastName";
export const PATRONYMIC_FIELD: string = "patronymic";
export const POSITION_FIELD = "position";
export const DATE_OF_BURN = "dateOfBurn";

// IStatementSale
export const FIELD_NN: string = "nn";
export const FIELD_DATE_ORDER: string = "dateOrder";
export const FIELD_STATUS: string = "status";
export const FIELD_BLANK_NUM_SERVICE_NAME: string = "blankNumServiceName";
export const FIELD_ADDITIONAL_INFO: string = "additionalInfo";
export const FIELD_FIO: string = "fio";
export const FIELD_SUM_OPER: string = "sumOper";

//ISignupRequests
export const FIELD_PASSWORD_CONFIRM = "passwordConfirm";
export const FIELD_FIRST_NAME = "firstName";
export const FIELD_LAST_NAME = "lastName";
export const FIELD_POSITION = "position";
export const FIELD_SHORT_COMPANY_NAME = "shortCompanyName";
export const FIELD_LEGAL_ADDRESS = "legalAddress";
export const FIELD_INN = "inn";
export const FIELD_OGRN = "ogrn";
