import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const SwitchIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={style["svg"]}>
            <path
                d="M15 21L15 9"
                stroke="#323232"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={style["path"]}
            />
            <path
                d="M9 15L9 3"
                stroke="#323232"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={style["path"]}
            />
            <path
                d="M12 18L14.913 20.913V20.913C14.961 20.961 15.039 20.961 15.087 20.913V20.913L18 18"
                stroke="#323232"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={style["path"]}
            />
            <path
                d="M6 6L8.91296 3.08704V3.08704C8.96103 3.03897 9.03897 3.03897 9.08704 3.08704V3.08704L12 6"
                stroke="#323232"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={style["path"]}
            />
        </svg>
    )
}