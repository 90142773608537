import React, {
    CSSProperties, Fragment,
    useEffect,
    useRef,
    useState
} from "react";
import './style.scss'
import {Guid} from "../../utils/utils";


interface IMdTable {
    style?: { [selector: string]: CSSProperties }
    columns: Array<IMdTableColumn>;
    itemSource: Array<any>;
    activeItem?: any;
    rowDetailsTemplate?: (data: any) => React.ReactNode;
    onRowSelect?: (data: any) => void;
    onPreviewRowSelect?: (data: any) => boolean;
}

interface IMdTableColumn {
    className?: string,
    header?: string;
    headerTemplate?: () => React.ReactNode;
    cell?: string;
    cellTemplate?: (data: any, rowIndex: number, columnIndex: number) => React.ReactNode;
    width?: number;
    style?: React.CSSProperties;
}


export function MdTable({style = {}, columns, itemSource, activeItem, rowDetailsTemplate, onRowSelect, onPreviewRowSelect}: IMdTable) {
    const root = useRef(null);
    const header = useRef(null);
    const body = useRef(null);

    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [selectedItem, setSelectedItem] = useState<any>(null);

    let scrollBarIsVisible = (body.current != null) && ((body.current as HTMLElement).scrollHeight > (body.current as HTMLElement).clientHeight);


    useEffect(() => {
        const index = (activeItem != null ? itemSource.findIndex(x => x == activeItem) : -1);
        if(selectedIndex != index) {
            setSelectedIndex(index);
        }

        const item = (activeItem != null ? itemSource.find(x => x == activeItem) : null);
        if(selectedItem != item) {
            setSelectedItem(item);
        }
    }, [activeItem]);


    return (
        <table
            ref={root}
            className="md-table"
            style={
                Object.assign({
                    "--selected-row-background": "rgba(62, 92, 184, 0.8)",
                    "--selected-row-highlighted-background": "rgba(62, 92, 184, 0.9)",
                    "--selected-row-font-color": "#FFFFFF",
                }, style["table"])}>
            <thead
                className="document-table"
                style={style["thead"]}
            >
                <tr
                    key={"header-row-" + Guid.newGuid()}
                    style={Object.assign({paddingRight: (scrollBarIsVisible ? "6px": 0)}, style["thead-tr"])}>
                    {
                        columns.map((column, columnIndex) => {
                            return (
                                <th
                                    key={"header-cell-" + Guid.newGuid()}
                                    style={
                                        Object.assign({
                                            width: (column.width != null ? column.width + "px" : "auto"),
                                            minWidth: (column.width != null ? column.width + "px" : "auto"),
                                            flex: (column.width != null ? "0" : "1")
                                        }, style["thead-tr-th"])}
                                >
                                    {
                                        column.headerTemplate != null
                                            ? column.headerTemplate()
                                            : column.header
                                    }
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody
                ref={body}
                style={style["tbody"]}
            >
            {
                itemSource.map((row, rowIndex) => {
                    const details = rowDetailsTemplate!= null ? rowDetailsTemplate(row) : null;
                    return (
                        <React.Fragment key={rowIndex}>
                            <tr className={(rowIndex == selectedIndex ? "selected-row" : "")}
                                style={style["tbody-tr"]}
                                onClick={(e) => {
                                    if(onPreviewRowSelect != null && !onPreviewRowSelect(row)) {
                                        return;
                                    }

                                    setSelectedIndex(rowIndex);
                                    setSelectedItem(row);

                                    if(onRowSelect != null) {
                                        onRowSelect(row)
                                    }
                                }}
                            >
                                {
                                    columns.map((column, columnIndex) => {
                                        let mergedStyles =
                                            Object.assign({
                                                width: (column.width != null ? column.width + "px" : "auto"),
                                                minWidth: (column.width != null ? column.width + "px" : "auto"),
                                                flex: (column.width != null ? "0" : "1")
                                            }, style["tbody-tr-td"]);
                                        return (
                                            <td
                                                key={"body-cell-" + Guid.newGuid()}
                                                className={column.className}
                                                style={Object.assign(mergedStyles, column.style)}
                                            >
                                                {
                                                    column.cellTemplate != null
                                                        ? column.cellTemplate(row, rowIndex, columnIndex)
                                                        : column.cell
                                                }
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                            {
                                details != null &&
                                (
                                    <tr className="row-details"
                                    >
                                        <td colSpan={columns.length}>
                                            {
                                                rowDetailsTemplate != null && rowDetailsTemplate(row)
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </React.Fragment>
                    )
                })
            }
            </tbody>
        </table>
    );
}