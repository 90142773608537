export const VISIBLE_VISIBLE: string = "visible";
export const VISIBLE_COLLAPSE: string = "collapse";
export const VISIBLE_HIDDEN: string = "hidden";

//AccountantDocumentPage
export const EXEL: string = "exel";
export const PDF: string = "pdf";
export const WITH_STAMP = "withStamp";

//color

export const MAIN_BACKGROUND_COLOR = "#EEFFE3";
export const FORBIDDEN_COLOR = "#b00020";
export const GRANTED_COLOR = "#74b100";
export const PRIMARY_COLOR = "#3E5CB8";
export const ON_PRIMARY_COLOR = "#FFFFFF";

//OrderPage
export const SELECT_TICKET = "selectTicket";
export const SELECT_PAXES = "selectPaxes";
export const BOOK_TICKET = "bookTicket";
export const ISSUE_TICKET = "issueTicket";

//OrderPage=>SearchOpt
export const ANY_SEARCH_OPTION = "anyOption";

//orderPage=> BusinessTripModes
export const SIMPLE_TRIP ="simpleTrip";
export const SHIFT_WORK_TRIP="shiftWorkTrip";
export const SPECIAL_REQUIREMENT_TRIP = "specialRequirement"




//OrderPage=>flightOption
export const ECONOMY_SEARCH_OPT = "economyClass";
export const BUSINESS_SEARCH_OPT = "businessClass";
export const FIRST_CLASS_SEARCH_OPT = "firstClass";


// ConditionsListValue
export const NO_REFUNDS= "NoRefunds";
export const UNDEFINED = "Undefined";

//OrderPage => hooks
export const DEPARTURE_CITY = "departureCity";
export const ARRIVED_CITY = "arrivedCity";
export const  DEPARTURE_DATE = "departureDate"
export const ARRIVED_DATE = "arrivedDate"

//OrderPage =>options
export const  MAX_CITIES_MULTI_ROUTE = 6

//SupportPage
export const MODE_GET_ALL = 0
export const MODE_GET_ONE = 1
export const MODE_ADD_NEW_SUPPORT_USER = 2



