import React from "react";
import {useTranslation} from "react-i18next";
import {IAviaTariffInfo, IAviaVariant} from "../../../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {FlightComponent} from "../../../../tripPage/flightComponent";
import {ScheduleIcon} from "../../../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../../../icons/CircleSpinnerIcon";
import {Guid} from "../../../../../../utils/utils";
import "./style.scss";


interface IFavoriteFlightsListComponent {
    flights: IAviaVariant[],
    //selectFlightHandler: (flight: IAviaVariant, tariff: IAviaTariffInfo) => void;
}


export function FavoriteFlightsListComponent(props: IFavoriteFlightsListComponent) {

    const {
        flights,
        //selectFlightHandler,
    } = props;

    const {t} = useTranslation();


    return (
        <div className="trip-page-search-results">
            {
                flights.length > 0 && (
                    <div className="trip-page-search-results-tickets">
                        {
                            flights.map((flight, flightIndex) =>
                                <React.Fragment key={flightIndex}>
                                    <FlightComponent
                                        flightInfo={{id: Guid.newGuid(), flight}}
                                    />
                                </React.Fragment>
                            )
                        }
                    </div>
                )
            }

            {
                flights.length == 0 && (
                    <div className="trip-page-no-results-message">
                        <div className="trip-page-no-results-message-icon">
                            <ScheduleIcon
                                width="50px"
                                height="50px"
                                style={{
                                    "path": {
                                        fill: "rgb(100 100 100)"
                                    }
                                }}
                            />
                        </div>
                        <div className="trip-page-no-results-message-caption">
                            Список пуст
                        </div>
                        <div className="trip-page-no-results-message-content" style={{minWidth: "240px"}}>
                            Добавьте интересующие Вас перелеты в разделе "Результаты поиска".
                        </div>
                    </div>
                )
            }

        </div>
    );
}