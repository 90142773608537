import axios, {CancelToken} from "axios";
import {TripApi} from "../api/TripApi";
import {
  IBookFlightRequestNew,
  IBookTrainRequestNew,
  IFlightSearchResultsRequest,
  ITrainSearchResultsRequest
} from "../api/request/IBookFlightRequest";
import {IOrder, IOrderBookingDetails, IOrderIssueDetails, IOrderTicketPdf} from "../interfaces/IOrderInfo";
import {IPassenger} from "../interfaces/IPassenger";
import {ISearchFlightsResponse} from "../responces/ISearchFlightsResponse";
import {IBookFlightResponse, IBookTrainResponse} from "../responces/IBookFlightResponse";
import {ISearchTrainsResponse} from "../responces/ISearchTrainsResponse";
import {Utils} from "../utils/utils";
import {IStorageKey} from "../interfaces/IStorageKey";
import {store} from "../redux/store";
import {IFlightRoute} from "../pages/cabinetPages/tripPage/multiSearchTicketsModal/searchFlightsComponent";
import { ITrainRoute } from "../pages/cabinetPages/tripPage/multiSearchTicketsModal/searchTrainsComponent";


export function TripService() {

  const tripApi = TripApi();

  /**
   * Method for obtaining flight providers
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<string[]>} list of provider names. in case of errors returns empty array.
   */
  const getFlightProviders = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<string[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getFlightProviders(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining train providers
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<string[]>} list of provider names. in case of errors returns empty array.
   */
  const getTrainProviders = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<string[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getTrainProviders(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining list of paxes
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IPassenger[]>>} list of paxes. in case of errors returns empty array.
   */
  const getPaxes = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IPassenger[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getPaxes(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining flights by requesting parameters
   * @param {IFlightRoute[]} routes - list of flight routes data
   * @param {string[]} providers - list of provider names
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ISearchFlightsResponse|null>} list of flight variants. in case of errors returns null.
   */
  const getFlights = async (routes: IFlightRoute[], providers: string[], clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISearchFlightsResponse | null> => {
    try {
      const state = store.getState();
      const request = {
        routes: routes,
        providers: providers,
        clientCode: clientCode
      };
      const response = await tripApi.getFlights(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for sending search results to TTS
   * @param {IFlightSearchResultsRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean|null>} response with sending status
   */
  const sendFlightsResultToTts = async (request: IFlightSearchResultsRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.sendFlightsResultToTts(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for booking flights
   * @param {IBookFlightRequestNew} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IBookFlightResponse|null>} booking details. in case of errors returns null
   */
  const bookFlights = async (request: IBookFlightRequestNew, ct: CancelToken = axios.CancelToken.source().token): Promise<IBookFlightResponse | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.bookFlights(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for issue flights
   * @param {string} clientCode - active company code
   * @param {string} orderId - order id
   * @param {string[]} pnrs - list of pnrs
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<any | null>} flights issue details. in case of errors returns null.
   */
  const issueFlights = async (clientCode: string, orderId: string, pnrs: string[], ct: CancelToken = axios.CancelToken.source().token): Promise<any | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.issueFlights(clientCode, orderId, pnrs, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }



  /**
   * Method for obtaining trains by requesting parameters
   * @param {IFlightRoute[]} routes - list of flight routes data
   * @param {string[]} providers - list of provider names
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ISearchTrainsResponse|null>} list of flight variants. in case of errors returns null.
   */
  const getTrains = async (routes: ITrainRoute[], providers: string[], clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ISearchTrainsResponse | null> => {
    try {
      const state = store.getState();
      const request = {
        routes: routes,
        providers: providers,
        clientCode: clientCode
      };
      const response = await tripApi.getTrains(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for sending search results to TTS
   * @param {ITrainSearchResultsRequest} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean|null>} response with sending status
   */
  const sendTrainsResultToTts = async (request: ITrainSearchResultsRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.sendTrainsResultToTts(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  };

  /**
   * Method for booking trains
   * @param {IBookTrainRequestNew} request - request data
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IBookTrainResponse|null>} booking details. in case of errors returns null
   */
  const bookTrains = async (request: IBookTrainRequestNew, ct: CancelToken = axios.CancelToken.source().token): Promise<IBookTrainResponse | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.bookTrains(request, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for issue flights
   * @param {string} clientCode - active company code
   * @param {string} orderId - order id
   * @param {string[]} pnrs - list of pnrs
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<any | null>} flights issue details. in case of errors returns null.
   */
  const issueTrains = async (clientCode: string, orderId: string, pnrs: string[], ct: CancelToken = axios.CancelToken.source().token): Promise<any | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.issueTrains(clientCode, orderId, pnrs, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }



  /**
   * Method for obtaining list of orders
   * @param {string} clientCode - active company code
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrder[]>} list of orders. in case of errors returns empty array.
   */
  const getOrderList = async (clientCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrder[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrders(clientCode, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining order details
   * @param {string} clientCode - active company code
   * @param {string} orderNo - order number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrder | null>} order details. in case of errors returns null.
   */
  const getOrder = async (clientCode: string, orderNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrder | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrder(clientCode, orderNo, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for obtaining state of issued order
   * @param {string} clientCode - active company code
   * @param {string} orderNo - order number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<boolean>} state of issued order
   */
  const isOrderIssued = async (clientCode: string, orderNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
    try {
      const state = store.getState();
      const response = await tripApi.isOrderIssued(clientCode, orderNo, state.auth.accessToken, ct);
      return response.data == true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Method for obtaining order booking details
   * @param {string} clientCode - active company code
   * @param {string} orderNo - order number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderBookingDetails[]>} list of order booking details. in case of errors returns empty array.
   */
  const getOrderBookingDetails = async (clientCode: string, orderNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderBookingDetails[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrderBookingDetails(clientCode, orderNo, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining order issue details
   * @param {string} clientCode - active company code
   * @param {string} orderNo - order number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderIssueDetails | null>} order issue details. in case of errors returns null.
   */
  const getOrderIssueDetails = async (clientCode: string, orderNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderIssueDetails | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.getOrderIssueDetails(clientCode, orderNo, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Method for obtaining list of pdf tickets data
   * @param {string} clientCode - active company code
   * @param {string} bsoNo - bso number
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IOrderTicketPdf[]>} list of pdf tickets data. in case of errors returns empty array.
   */
  const getPdfTickets = async (clientCode: string, bsoNo: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IOrderTicketPdf[]> => {
    try {
      const state = store.getState();
      const response = await tripApi.getPdfTickets(clientCode, bsoNo, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining pdf ticket file
   * @param {string} clientCode - active company code
   * @param {IStorageKey} key - storage key
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<Blob | null>} pdf ticket file. in case of errors returns null
   */
  const getPdfTicketFile = async (clientCode: string, key: IStorageKey, ct: CancelToken = axios.CancelToken.source().token): Promise<Blob | null> => {
    try {
      const state = store.getState();
      const response = await tripApi.getPdfTicketFile(clientCode, key, state.auth.accessToken, ct);
      return !Utils.isEmpty(response.data) ? response.data : null;
    } catch (error) {
      return null;
    }
  }


  return {
    getFlightProviders,
    getTrainProviders,
    getPaxes,
    getFlights,
    sendFlightsResultToTts,
    bookFlights,
    getTrains,
    sendTrainsResultToTts,
    bookTrains,
    getOrderList,
    getOrder,
    issueFlights,
    issueTrains,
    isOrderIssued,
    getOrderBookingDetails,
    getOrderIssueDetails,
    getPdfTickets,
    getPdfTicketFile
  };
}
