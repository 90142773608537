import axios, {CancelToken} from "axios";
import {store} from "../redux/store";
import {
    IAddCustomerEmployeeRequest,
    ICompanyUserRoles,
} from "../interfaces/requestsInterfaces/reportRequests";
import {ICustomerEmployee} from "../interfaces/reportInterfaces/customerInterfaces";
import {CompanyApi} from "../api/CompanyApi";
import {Utils} from "../utils/utils";


export function EmployeesService() {

    const companyApi = CompanyApi();


    /**
     * Method for obtaining list of company employees
     * @param {string} companyCode - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICustomerEmployee[]>} list of company employees. in case of errors returns empty array
     */
    const getEmployees = async (companyCode: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICustomerEmployee[]> => {
        try {
            const state = store.getState();
            const response = await companyApi.getEmployees(companyCode, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method for obtaining list of employee roles
     * @param {string} email - user email
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompanyUserRoles[]>} list of employee roles
     */
    const getEmployeeRoles = async (email : string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompanyUserRoles[]> => {
        try {
            const state = store.getState();
            const response = await companyApi.getEmployeeRoles(email, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method for obtaining list of employee roles in company
     * @param {string} companyCode - company code
     * @param {string} email - user email
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompanyUserRoles[]>} list of employee roles in company
     */
    const getEmployeeRolesInCompany = async (companyCode: string, email : string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompanyUserRoles[]> => {
        try {
            const state = store.getState();
            const response = await companyApi.getEmployeeRolesInCompany(companyCode, email, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method for add employee
     * @param {IAddCustomerEmployeeRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICustomerEmployee | null>} employee data. in case of errors returns null
     */
    const addEmployee = async (request: IAddCustomerEmployeeRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ICustomerEmployee | null> => {
        try {
            const state = store.getState();
            const response = await companyApi.addEmployee(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for update employee
     * @param {IAddCustomerEmployeeRequest} request - request data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICustomerEmployee | null>} employee data. in case of errors returns null
     */
    const updateEmployee = async (request: IAddCustomerEmployeeRequest, ct: CancelToken = axios.CancelToken.source().token): Promise<ICustomerEmployee | null> => {
        try {
            const state = store.getState();
            const response = await companyApi.updateEmployee(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    return {
        getEmployees,
        getEmployeeRoles,
        getEmployeeRolesInCompany,
        addEmployee,
        updateEmployee
    };
}