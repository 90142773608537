import React, {useEffect, useState} from "react";
import {IOrder, IOrderInfo} from "../../../interfaces/IOrderInfo";
import {TripService} from "../../../services/TripService";
import {NewTripComponent} from "./newTripComponent";
import {OrdersTableComponent} from "./ordersTableComponent";
import {useAppSelector} from "../../../redux/hooks";
import "./style.scss";
import {useSearchParams} from "react-router-dom";
import {CompanyService} from "../../../services/CompanyService";


export function TripPage() {


    // TODO: check ClientCode from query string
    const [searchParams] = useSearchParams();
    const params = Object.fromEntries(searchParams.entries());
    //const [email, setEmail] = useState(params.email);


    const authState = useAppSelector((state) => state.auth);

    const tripService = TripService();
    const companyService = CompanyService();

    const [tripBuilderModalOpened, setTripBuilderModalOpened] = useState<boolean>(false);

    const [selectedOrder, setSelectedOrder] = useState<IOrder | undefined>(undefined);

    const [orders, setOrders] = useState<IOrder[]>([]);
    const [ordersPending, setOrdersPending] = useState(false);
    const [isOrdersNotFound, setIsOrdersNotFound] = useState(false);

    const onCreateOrderHandler = (order?: IOrder) => {
        setSelectedOrder(order);
        setTripBuilderModalOpened(true);
    }

    const loadOrders = async () => {
        setOrdersPending(true);
        setOrders([]);
        const response = await tripService.getOrderList(authState.currentClientCode);
        if (response.length > 0) {
            setOrders(response);
            setIsOrdersNotFound(false);
        } else {
            setIsOrdersNotFound(true);
        }
        setOrdersPending(false);
    }


    useEffect(() => {
        if(params.clientCode != undefined) {
            companyService.makeActiveCompany(params.clientCode);
        }
    }, [params.clientCode]);


    useEffect(() => {
        if(params.clientCode == undefined || params.clientCode == authState.currentClientCode) {
            loadOrders();
        }
    }, [authState.currentClientCode]);


    return (
        <>
            {
                (params.clientCode == undefined || params.clientCode == authState.currentClientCode) && (
                    <OrdersTableComponent
                        orders={orders}
                        isLoading={ordersPending}
                        isSearchResultNotFound={isOrdersNotFound}
                        createOrderHandler={onCreateOrderHandler}
                        searchOrderHandler={loadOrders}
                    />
                )
            }

            {
                tripBuilderModalOpened && (
                    <NewTripComponent
                        order={selectedOrder}
                        tripBuilderModalOpened={tripBuilderModalOpened}
                        setTripBuilderModalOpened={setTripBuilderModalOpened}
                        refreshOrderListHandler={loadOrders}
                    />
                )
            }

        </>
    );
}
