import {IPax} from "../../../../interfaces/orderInterfaces/IPax";
import {MdList} from "../../../../components/md-list";
import "./style.scss";
import {MdButton} from "../../../../components/md-button";
import {InputTextWithPopoverComponent} from "../../../../components/md-input-text-popover";
import {useEffect, useState} from "react";
import {COMMON_BUTTON_STYLE} from "../../../../constants/componentsContants";
import {ArrowLeftIcon} from "../../../../icons/ArrowLeftIcon";
import {ArrowRightColorIcon} from "../../../../icons/ArrowRightColorIcon";
import {MdInputText} from "../../../../components/md-input-text";

type SelectPaxesProps = {
    allPaxesList?: IPax[];
    selectedPaxesList?: IPax[];


    updateSelectedPaxes: (selectedPaxes: IPax[]) => void;
    getAllPaxes?: () => void;
}

export function SelectPaxesComponent(props: SelectPaxesProps) {

    const [filteredPaxes, setFilteredPaxes] = useState<IPax[]>(props.allPaxesList !== undefined ? props.allPaxesList : []);
    const [inputText, setInputText] = useState("");

    const filterPaxHandler = (input: string) => {
        let paxes = new Array<IPax>();
        setFilteredPaxes([])

        if (input.length === 0 || input.length < inputText.length) {
            console.log(`I am here inputLength: ${input.length} hookLength: ${inputText.length}`)

            setFilteredPaxes(props.allPaxesList!)
            console.log(filteredPaxes.length, "allpaxes:", props.allPaxesList!.length)
            setInputText(input)
            return;
        }
        setInputText(input)
        const regex = new RegExp("(\w*)" + input + "(\w*)", "i",);
        console.log("test regex", regex);
        paxes = filteredPaxes.filter(pax => regex.test(pax.fio));
        setFilteredPaxes(paxes)
    }

    const selectPax = (pax: IPax) => {
       // const selectedPax = filteredPaxes![index];
        console.log(`SelectPax, inputpax: ${pax.fio}, selected paxes length: ${props.selectedPaxesList?.length}`)
        const isHasDuplicate = props.selectedPaxesList!.find(p => p.passportNum === pax.passportNum &&p.fio === pax.fio)

        if (isHasDuplicate) return;
        //setSelectedPaxes([...selectedPaxes, selectedPax]);
        console.log("after duplicatecheck")
        props.updateSelectedPaxes([...props.selectedPaxesList!, pax]);
        //setSelectedPaxes([...selectedPaxes, selectedPax]);
    }

    const removeSelectedPax = (index: number) => {

    }
    console.log("SelectPaxComponentRender");

    useEffect(() => {
        console.log("render component")
        if(props.allPaxesList!==undefined)setFilteredPaxes(props.allPaxesList!)
    }, [props.allPaxesList]);
    // useEffect(() => {
    //
    // }, [props.selectedPaxesList,props.updateSelectedPaxesList]);


    return (

        <div className="select-paxes-component-container">
            <div className="select-paxes-action-container">
                <div style={{alignSelf: "center", padding: "8px 16px"}}><p>Поиск сотрудника:</p></div>
                <div className="common-input" style={{alignSelf: "center", padding: "8px 16px"}}>
                    <input
                        //className="common-input"
                        value={inputText}
                        onChange={(e) => {
                            filterPaxHandler(e.target.value)
                        }}
                    />

                </div>

                <MdButton style={COMMON_BUTTON_STYLE} children={<p style={{color:"white"}}>&#10227; Обновить  </p>}
                onClick={()=>{if(props.getAllPaxes!==undefined)props.getAllPaxes()}}/>
                {/*<InputTextComponent/>*/}
            </div>

            <div className="select-paxes-container">
                <div className="select-paxes-list">
                    <div className="title"><label>All ({filteredPaxes?.length})</label></div>
                    <dl>
                        {filteredPaxes?.map((pax: IPax, index) => (<dd onClick={() =>
                            selectPax(pax)} key={'select-all-paxes-' + index}>{pax.fio}</dd>))}
                    </dl>
                </div>
                {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}

                {/*<ArrowLeftIcon/>*/}
                <div style={{alignSelf: "center", left: "auto"}}><ArrowRightColorIcon iconSize={'50px'}/></div>

                <div className="select-paxes-list">
                    <div className="title"><label>Selected paxes ({props.selectedPaxesList?.length})</label></div>
                    <dl>
                        {props.selectedPaxesList && props.selectedPaxesList.map((pax: IPax, index) => (
                            <dd key={"selected-paxes-"+index} onClick={() => {
                                props.updateSelectedPaxes(props.selectedPaxesList!.filter((sp, si) => si !== index));
                            }}>{pax.fio}
                                <p className="unselect-button">&#x2715;</p>
                            </dd>))}
                    </dl>
                </div>
                {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}
            </div>

            {<p>{props.selectedPaxesList?.length + " / " + props.allPaxesList?.length}</p>}

        </div>)
}