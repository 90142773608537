import {AXIOS_INSTANCE_GATEWAY, AXIOS_INSTANCE_INTEROP, axiosContext, axiosInstance} from "./AxiosInstance";
import {ISupportUser} from "../interfaces/support/ISupportUser";
import {BEARER} from "../constants/routeConstants/ApiRouteConstants";
import {ISupportUserResponse} from "../interfaces/support/ISupportUserResponse";
import {Guid} from "guid-ts";
import axios from "axios";

export function SupportApi() {

    const webAuthAxiosInstanse = axiosContext.getAxiosInstance();
    const gatewayAxiosInstance = axiosContext.getAxiosInstance(AXIOS_INSTANCE_GATEWAY)


    /**
     * Add or update  support user
     * @return {Promise<void>} .
     * @param request - support user data
     * @param token  - access jwt token
     */
    const addSupportUser = async (request: ISupportUser, token: string): Promise<void> => {
        const result = await gatewayAxiosInstance.put("/gateway/api/v1/webauth/supportUser/signup", {
            code: request?.code,
            login: request?.login,
            email: request?.email,
            gender: request?.gender,
            firstName: request?.firstName,
            lastName: request?.lastName,
            patronymic: request?.patronymic,
            position: request?.position,
            dateOfBurn: request?.dateOfBurn
        }, {
            headers: {
                Auth: 123,
                Authorization: BEARER + token,
            }
        })
        console.log(result)
    }

    const getAllSupportUsers = async (code: string, token: string) => {
        const response = await axiosInstance.get<ISupportUserResponse[]>("/api/v1/webauth/SupportUser/getSupportUsers", {
                params: {
                    code: "Ф0021013"
                },
                headers: {
                    Auth: 123,
                    Authorization: BEARER + token,
                }
            }
        )
        return response.data;
    }

    const getSupportUser = async (email: string, code: string, token: string) => {
        const response = await axiosInstance.get<ISupportUserResponse>("/api/v1/webauth/SupportUser/getSupportUser", {
            params: {
                code: code,
                email: email
            },
            headers: {
                Auth: 123,
                Authorization: BEARER + token,
            }
        })
        return response.data;
    }

    const getTtsUser = async (email: string, token: string) => {

        // const responce =await gatewayAxiosInstance
        //     .get<ISupportUserResponse>("/gateway/api/v1/interop/docs/getsupportuser",{
        //         params:{
        //           email:email
        //         },
        //         headers:{
        //             Auth: 123
        //         }
        //     })

        // const instance = axiosContext.getAxiosInstance(AXIOS_INSTANCE_INTEROP);
        // instance.interceptors.response.use(function (response) {
        //     // Do something before request is sent
        //     console.log("I Am interceptors")
        //     return response;
        // }, function (error) {
        //     error.response.
        //
        //     console.log("I Am interceptors error ", error)
        //     return Promise.resolve(undefined);
        // });


        //!Need to update everything
       // const instance = axiosContext.getAxiosInstance();
        const instance = gatewayAxiosInstance;

        instance.interceptors.response.use(
            response  => response,
            error => {
                if(error.response.status !== 200){
                    console.log("BadRequest")
                }
                return Promise.resolve(error.response);
            });



        //"/gateway/api/v1/webauth/supportUser/signup"
        const response = await instance.get<ISupportUserResponse>("/gateway/api/v1/interop/docs/getsupportuser", {
            params: {
                email: email
            },
            headers: {
                Auth: 123,
                Authorization: BEARER + token,
            }
        })
        // return response.data;


        // const response = await axios.get<ISupportUserResponse>
        // ("http://localhost:49301/api/v1/docs/getsupportuser", {
        //     params: {
        //         email: email
        //     },
        //     headers: {
        //         Auth: 123
        //     }
        // })
        if (response.status === 200) {
            console.log("Responce status 200")
            return response.data;

        }
        if(response.status === 400) {
            console.log('responceStatus 400')
            return null;
        }
        console.log("responce status :",response.status)
        //if (response.status === 400) return undefined;
        return undefined;
    }

    const deleteSupportUser = async (id: Guid, token: string) => {

        await axiosInstance.delete("/api/v1/webauth/SupportUser/deleteSupportUser", {
            params: {
                id: id
            },
            headers: {
                Auth: 123,
                Authorization: BEARER + token,
            }
        })
    }

    return {
        addSupportUser,
        getAllSupportUsers,
        getSupportUser,
        deleteSupportUser,
        getTtsUser
    }

}




