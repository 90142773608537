import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const AttachmentIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 511.988 511.988"
            style={style["svg"]}>
            <path
                d="M459.1,37.48c-49.999-49.973-131.045-49.973-181.018,0L21.594,295.355c-8.309,8.354-8.272,21.861,0.081,30.17
			c8.354,8.309,21.861,8.272,30.17-0.081L308.293,67.609c33.271-33.271,87.308-33.271,120.641,0.045
			c33.328,33.328,33.328,87.359,0,120.67L164.912,453.755c-20.787,20.772-54.563,20.772-75.396-0.046
			c-20.826-20.826-20.826-54.593,0.006-75.425l218.731-218.731c0.007-0.007,0.013-0.015,0.02-0.022
			c8.332-8.309,21.816-8.303,30.139,0.02c8.33,8.33,8.33,21.831,0,30.161l-105.58,105.602c-8.33,8.332-8.329,21.84,0.003,30.17
			c8.332,8.33,21.84,8.329,30.17-0.003l105.579-105.6c24.991-24.991,24.991-65.507-0.002-90.499
			c-24.993-24.993-65.508-24.993-90.501,0c-0.014,0.014-0.026,0.03-0.04,0.044L59.352,348.115
			c-37.494,37.494-37.494,98.276,0,135.77c37.498,37.471,98.272,37.471,135.764,0.006L459.14,218.458
			C509.096,168.528,509.096,87.476,459.1,37.48z"
                style={style["path"]}
            />
        </svg>
    )
}