//LeftNavBarItems
export const LEFT_MENU_COMPANY_ACCOUNT: string = "";
export const LEFT_MENU_MY_PROFILE: string = "Profile";
export const LEFT_MENU_ORDERS: string = "Orders";
export const LEFT_MENU_REPORTS: string = "Reports";
export const LEFT_MENU_CONTACTS: string = "Contacts";
export const LEFT_MENU_REGISTERS: string = "Registers";
export const LEFT_MENU_ACC_DOCUMENTS: string = "Documents";
export const LEFT_MENU_EMPLOYEES: string = "Employees";
export const LEFT_MENU_STATEMENTS: string = "Statements";

//CommonHeader
export const HEADER_MENU_SIGN_IN: string = "Sign-in";
export const HEADER_MENU_SIGN_UP: string = "Sign-up";
export const HEADER_MENU_HOME: string = "Home";
export const HEADER_MENU_CABINET: string = "Cabinet";
export const HEADER_MENU_CONTACTS: string = "contacts";

//statisticPage
export const COMMON_PAGE = "CommonPage";
export const USER_ACCOUNT = "UserAccaunt";
export const STATEMENT_SALES = "StatementSales";
export const STATEMENT_INCOMES = "CostomerIncomes";
export const CUSTOMER_FLIGHTS = "CustomerFlights";
export const CUSTOMER_BUH_DOCS = "CustometBuhDocs";
export const CUSTOMER_AIR_COMPANIES = "CustomerAirCompanies";
export const CUSTOMER_TICKET_ORDERS = "CustomerTicketOrders";
export const CUSTOMER_ADDITIONAL_SERVICES = "CustomerAdditionalServices";

//redirect constant
export const REDIRECT_TO_CABINET_HOME_PAGE = "/cabinet";
export const REDIRECT_TO_COMMON_HOME_PAGE = "/lk";
