import React from "react";
import {useTranslation} from "react-i18next";
import {ITrainVariant} from "../../../../../interfaces/ITrainVariant";
import {TrainIcon3} from "../../../../../icons/TrainIcon3";
import "./style.scss";


export interface ITrainTicketComponent {
    ticket: ITrainVariant,
}


export function TrainTicketComponent({ ticket }: ITrainTicketComponent) {
    const {t} = useTranslation();

    //const dateDepartment = new Date(ticket.dateDep.replace('Z', ''));
    const townDepartment = ticket.cityFrom; //.replace(/ *\([^)]*\) */g, "");

    //const dateArrival = new Date(ticket.dateArr.replace('Z', ''));
    const townArrival = ticket.cityTo; //.replace(/ *\([^)]*\) */g, "");

    return (
        <div className="train-ticket-component">

            <div className="train-ticket-component-header">
                <div className="depart-info">
                    <div className="city-code">
                        {
                            ticket.stationFrom
                        }
                    </div>
                </div>
                <div className="train-info">
                    <div className="air-company-name">
                        {
                            ticket.travelTime
                        }
                    </div>
                </div>
                <div className="arrive-info">
                    <div className="city-code">
                        {
                            ticket.stationTo
                        }
                    </div>
                </div>
            </div>

            <div className="train-ticket-component-content">
                <div className="depart-info">
                    <div className="city-name">
                        {
                            townDepartment
                                .toLowerCase()
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                                .split('-')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join('-')
                        }
                    </div>
                    <div className="datetime">
                        {
                            <>
                                <span>
                                    {
                                        ticket.departureShortOn
                                    }
                                </span>
                                <span>
                                    {", "}
                                    {
                                        ticket.departureAt.split(' ').reverse().join(' ').replace('.', '')
                                    }
                                </span>
                            </>
                        }
                    </div>
                </div>
                <div className="train-info">
                    <div className="icon">
                        <span style={{opacity: 0.25}}></span>
                        <span style={{opacity: 0.5}}></span>
                        <span></span>
                        <div style={{margin: "-2px 2px 0 2px"}}>
                            <TrainIcon3
                                width="18px"
                                height="18px"
                                style={{
                                    "path": {
                                        fill: "#475AEF"
                                    }
                                }}
                            />
                        </div>
                        <span></span>
                        <span style={{opacity: 0.5}}></span>
                        <span style={{opacity: 0.25}}></span>
                    </div>
                    <div className="number">
                        {
                            ticket.trainNum
                        }
                    </div>
                </div>
                <div className="arrive-info">
                    <div className="city-name">
                        {
                            townArrival
                                .toLowerCase()
                                .split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')
                                .split('-')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join('-')
                        }
                    </div>
                    <div className="datetime">
                        {
                            <>
                                <span>
                                    {
                                        ticket.arriveAtn
                                    }
                                </span>
                                <span>
                                    {", "}
                                    {
                                        ticket.arriveShortOn.split(' ').reverse().join(' ').replace('.', '')
                                    }
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}
