import {CSSProperties} from "react";
export const DEFAULT_ICON_SIZE = "1.5em";

export const CHILDREN_BUTTON_STYLE:CSSProperties = {
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    fontStyle:"italic",
    color:"white",
    fontSize:"0.9rem"
}

export const COMMON_BUTTON_STYLE: { [selector: string]: CSSProperties } = {
    "button": {
        borderWidth: 0,
        backgroundColor: "#3E5CB8",
        color: "#FFFFFF",
        borderRadius: "6px",
        padding: "6px 15px",
        height: "35px",
    },
    "ink": {
        backgroundColor: "rgba(255,255,255,.5)"
    }
};

export const DANGER_BUTTON_STYLE: { [selector: string]: CSSProperties } = {
    "button": {
        borderWidth: 0,
        backgroundColor: "#D21404",
        color: "#FFFFFF",
        borderRadius: "6px",
        padding: "6px 15px",
        height: "35px",
    },
    "ink": {
        backgroundColor: "rgba(0,0,0,0)"
    }
};

export const COMMON_BUTTON_DISABLE_STYLE: { [selector: string]: CSSProperties } = {
    "button": {
        borderWidth: 0,
        backgroundColor: "lighten(#3E5CB8,10)",
        //color: "#112A46",
        color: "#FFFFFF",
        borderRadius: "6px",
        height: "35px",
        padding: "5px 15px"
    },
    "ink": {
        backgroundColor: "rgba(255,255,255,.5)"
    }
};

export const OPPOSITE_COMMON_BUTTON_STYLE: { [selector: string]: CSSProperties } = {
    "button": {
        borderWidth: 0,
        backgroundColor: "darkorange",
        //color: "#5f071c",
        color: "#FFFFFF",
        borderRadius: "6px",
        height: "35px",
        padding: "5px 15px"
    },
    "ink": {
        backgroundColor: "rgba(255,255,255,.5)"
    }
};
