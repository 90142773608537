import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const ArrowLeftIcon2 = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            style={style["svg"]}>
            <path
                d="M15 7L10 12L15 17"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={style["path"]}
            />
        </svg>
    )
}