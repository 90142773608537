import React, {useEffect, useState} from "react";
import {IOrder, IOrderInfo} from "../../../interfaces/IOrderInfo";
import {TripService} from "../../../services/TripService";
import {useAppSelector} from "../../../redux/hooks";
import "./style.scss";
import {SearchFlightsComponent} from "./searchComponents/searchFlightsComponent";
import {IAviaTariffInfo, IAviaVariant} from "../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {ICompany} from "../../../interfaces/companyInfoInterfaces/ICompany";
import {AirplaneIcon} from "../../../icons/AirplaneIcon";
import {TrainIcon} from "../../../icons/TrainIcon";
import {TrainIcon2} from "../../../icons/TrainIcon2";
import {HotelIcon2} from "../../../icons/HotelIcon2";
import {Railcar, SearchTrainsComponent} from "./searchComponents/searchTrainsComponent";
import {ITrainTariffInfo, ITrainVariant} from "../../../interfaces/ITrainVariant";


export function TripPage2() {

    const authState = useAppSelector((state) => state.auth);
    const companyState = useAppSelector((state) => state.companyInfo);

    const tripService = TripService();

    const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>( companyState as any); // TODO: process companyState as ICompany

    const [activeTab, setActiveTab] = useState<number>(0);

    const selectFlight = (flight: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => {

    }

    const selectTrain = (train: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any) => {

    }

    useEffect(() => {
        setSelectedCompany(companyState as any);
    }, [companyState]);


    return (
        <>
            <div className="trip-page-container">

                <div className="trip-page-tabs-container">
                    <div className="trip-page-tabs-header">
                        <button className={(activeTab == 0 ? "--selected" : "")}
                            onClick={() => setActiveTab(0)}>
                            <span>
                                <AirplaneIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "svg": {
                                            transform: "rotate(-45deg)"
                                        }
                                    }}
                                />
                            </span>
                            <span>Авиа</span>
                        </button>
                        <button className={(activeTab == 1 ? "--selected" : "")}
                            onClick={() => setActiveTab(1)}>
                            <span>
                                <TrainIcon2
                                    width="16px"
                                    height="16px"
                                />
                            </span>
                            <span>Ж/д</span>
                        </button>
                        <button>
                            <span>
                                <HotelIcon2
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "svg": {
                                            marginBottom: "2px"
                                        }
                                    }}
                                />
                            </span>
                            <span>Отели</span>
                        </button>
                    </div>
                    <div className="trip-page-tab">
                        {
                            (() => {
                                switch (activeTab) {
                                    case 0:
                                        return (
                                            <SearchFlightsComponent
                                                selectFlightHandler={selectFlight}
                                                selectedCompany={selectedCompany}
                                            />
                                        );
                                    case 1:
                                        return (
                                            <SearchTrainsComponent
                                                selectTrainHandler={selectTrain}
                                                selectedCompany={selectedCompany}
                                            />
                                        );
                                    default:
                                        return ""
                                }
                            })()

                        }
                    </div>
                </div>

            </div>
        </>
    );
}
