import i18n from "i18next";
import {initReactI18next} from "react-i18next";

//translation files
import translationEng from "./locales/en/translation.json";
import landingEng from "./locales/en/landingPage.json";
import translationRus from "./locales/ru/translation.json";
import landingPageRus from "./locales/ru/landingPage.json";
import i18next from "i18next";
import {useAppSelector} from "./redux/hooks";
import {SITE_LANGUAGE} from "./constants/ServiceCostants";

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init


const resources = {
    en: {
        translation: translationEng,
        landing: landingEng,
    },
    ru: {
        translation: translationRus,
        landing: landingPageRus,
    },
};


let locale = (sessionStorage.getItem(SITE_LANGUAGE)) ?? "ru"

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options


    //work
    .init(
        {

            resources,
            lng: (sessionStorage.getItem(SITE_LANGUAGE)) ?? "ru",
            ns: ["landing", "translation"],

            fallbackLng: ["ru", "en"],
            debug: true,

            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        }
        // (ett, t) => {
        //   i18n.addResources("ru", "landing", "");
        // }
    );

export default i18n;

