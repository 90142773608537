import axios, {AxiosResponse, CancelToken} from "axios";
import {
    BEARER,
    ROUTE_BOOK_FLIGHTS,
    ROUTE_BOOK_TRAINS,
    ROUTE_GET_FLIGHT_PROVIDERS,
    ROUTE_GET_FLIGHTS,
    ROUTE_GET_ORDER_BOOKING_DETAILS,
    ROUTE_GET_ORDER_BY_ID,
    ROUTE_GET_ORDER_ISSUE_DETAILS,
    ROUTE_GET_ORDER_PDF_TICKET,
    ROUTE_GET_ORDER_PDF_TICKET_NAMES,
    ROUTE_GET_ORDERS,
    ROUTE_GET_PAXES,
    ROUTE_GET_TRAIN_PROVIDERS,
    ROUTE_GET_TRAINS,
    ROUTE_IS_ORDER_ISSUED,
    ROUTE_ISSUE_FLIGHTS,
    ROUTE_ISSUE_TRAINS, ROUTE_SEND_FLIGHTS_RESULT_TO_TTS,
    ROUTE_SEND_TRAINS_RESULT_TO_TTS,
} from "../constants/routeConstants/ApiRouteConstants";
import {ISearchFlightsRequest} from "./request/ISearchFlightsRequest";
import {ISearchTrainsRequest} from "./request/ISearchTrainsRequest";
import {
    IBookFlightRequestNew,
    IBookTrainRequestNew,
    IFlightSearchResultsRequest,
    ITrainSearchResultsRequest
} from "./request/IBookFlightRequest";
import {IPassenger} from "../interfaces/IPassenger";
import {ISearchFlightsResponse} from "../responces/ISearchFlightsResponse";
import {ISearchTrainsResponse} from "../responces/ISearchTrainsResponse";
import {IBookFlightResponse, IBookTrainResponse} from "../responces/IBookFlightResponse";
import {IOrder, IOrderBookingDetails, IOrderIssueDetails, IOrderTicketPdf} from "../interfaces/IOrderInfo";
import {IStorageKey} from "../interfaces/IStorageKey";
import {BaggageAllowance} from "../pages/cabinetPages/tripPage2/searchComponents/searchFlightsComponent";
import {
    ExchangeAllowance,
    FlightClass,
    RefundAllowance,
    TimeOfDay
} from "../pages/cabinetPages/tripPage/multiSearchTicketsModal/searchFlightsComponent";
import {
    CoupeType,
    Railcar,
    SeatType
} from "../pages/cabinetPages/tripPage/multiSearchTicketsModal/searchTrainsComponent";


export function TripApi() {

    /**
     * GET method for obtaining flight providers
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string[], any>>} response with list of provider names
     */
    const getFlightProviders = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string[], any>> => {
        return await axios.get<string[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_FLIGHT_PROVIDERS,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining train providers
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<string[], any>>} response list of provider names
     */
    const getTrainProviders = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<string[], any>> => {
        return await axios.get<string[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_TRAIN_PROVIDERS,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining list of paxes
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IPassenger[], any>>} response with list of paxes
     */
    const getPaxes = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IPassenger[], any>> => {
        return await axios.get<Array<IPassenger>>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_PAXES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for obtaining flights by requesting parameters
     * @param {ISearchFlightsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISearchFlightsResponse, any>>} response with list of flight variants
     */
    const getFlights = async (request: ISearchFlightsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISearchFlightsResponse, any>> => {
        return await axios.post<ISearchFlightsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_FLIGHTS,
            {
                parameters: request.routes.map(x => {
                    return {
                        paxCount: x.passengers,
                        rtGroupId: x.id,
                        departDate: x.departDate,
                        exactTime: (x.departDate != undefined && x.departTime != undefined ? x.departDate + "T" + x.departTime : undefined),
                        selectedTime: (x.departTimeOfDay != undefined ? [TimeOfDay.Morning, TimeOfDay.Afternoon, TimeOfDay.Evening].indexOf(x.departTimeOfDay) : undefined),
                        fromCity: {
                            code: x.origin.code,
                            name: x.origin.city_name ?? x.origin.name
                        },
                        toCity: {
                            code: x.direction.code,
                            name: x.direction.city_name ?? x.direction.name
                        },
                        isBaggageRequired: (x.baggage == BaggageAllowance.WithBaggage),
                        hasReturn: (x.refund == RefundAllowance.WithRefund),
                        hasExchange: (x.exchange == ExchangeAllowance.WithExchange),
                        isBusynessOnly: (x.class == FlightClass.Business),
                        notes: null
                    }
                }),
                providers: request.providers,
                clientCode: request.clientCode
            },
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for sending search results to TTS
     * @param {IFlightSearchResultsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean, any>>} response with sending status
     */
    const sendFlightsResultToTts = async (request: IFlightSearchResultsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean, any>> => {
        return await axios.post<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_SEND_FLIGHTS_RESULT_TO_TTS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for booking flights
     * @param {IBookFlightRequestNew} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IBookFlightResponse, any>>} response with booking details
     */
    const bookFlights = async (request: IBookFlightRequestNew, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IBookFlightResponse, any>> => {
        return await axios.post<IBookFlightResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_BOOK_FLIGHTS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for issue flights
     * @param {string} clientCode - active company code
     * @param {string} orderId - order id
     * @param {string[]} pnrs - list of pnrs
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with flights issue details
     */
    const issueFlights = async (clientCode: string, orderId: string, pnrs: string[], accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axios.post<any>( // TODO: specify Response model
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ISSUE_FLIGHTS,
            pnrs,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode,
                    orderId: orderId
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for obtaining trains by requesting parameters
     * @param {ISearchTrainsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ISearchTrainsResponse, any>>} response with list of train variants
     */
    const getTrains = async (request: ISearchTrainsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ISearchTrainsResponse, any>> => {
        return await axios.post<ISearchTrainsResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_TRAINS,
            {
                parameters: request.routes.map(x => {
                    return {
                        rtGroupId: x.id,
                        departDate: x.departDate,
                        exactTime: (x.departDate != undefined && x.departTime != undefined ? x.departDate + "T" + x.departTime : undefined),
                        selectedTime: (x.departTimeOfDay != undefined ? [TimeOfDay.Morning, TimeOfDay.Afternoon, TimeOfDay.Evening].indexOf(x.departTimeOfDay) : undefined),
                        fromCity: {
                            code: x.origin.express,
                            name: x.origin.name
                        },
                        toCity: {
                            code: x.direction.express,
                            name: x.direction.name
                        },
                        paxCount: x.passengers,

                        isCoupe:       (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Coupe) === Railcar.Coupe) ? undefined : false,
                        isCouchette:   (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Couchette) === Railcar.Couchette) ? undefined : false,
                        isSleepingCar: (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Sleeping) === Railcar.Sleeping) ? undefined : false,
                        isSittingCar:  (x.railcar === Railcar.None || x.railcar === Railcar.All || (x.railcar & Railcar.Seat) === Railcar.Seat) ? undefined : false,

                        isManCoupe:    (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Man) === CoupeType.Man) ? undefined : false,
                        isWomanCoupe:  (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Woman) === CoupeType.Woman) ? undefined : false,
                        isFamilyCoupe: (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Family) === CoupeType.Family) ? undefined : false,
                        isMixedCoupe:  (x.coupe === CoupeType.None || x.coupe === CoupeType.All || (x.coupe & CoupeType.Mixed) === CoupeType.Mixed) ? undefined : false,

                        isWindowSeat: (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.ByTheWindow) === SeatType.ByTheWindow) ? undefined : false,
                        isBottom:     (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.Bottom) === SeatType.Bottom) ? undefined : false,
                        isTop:        (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.Top) === SeatType.Top) ? undefined : false,
                        isSideBottom: (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.SideBottom) === SeatType.SideBottom) ? undefined : false,
                        isSideTop:    (x.seat === SeatType.None || x.seat === SeatType.All || (x.seat & SeatType.SideTop) === SeatType.SideTop) ? undefined : false,

                        notes: null
                    }
                }),
                providers: request.providers,
                clientCode: request.clientCode
            },
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for sending search results to TTS
     * @param {ITrainSearchResultsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean, any>>} response with sending status
     */
    const sendTrainsResultToTts = async (request: ITrainSearchResultsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean, any>> => {
        return await axios.post<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_SEND_TRAINS_RESULT_TO_TTS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for booking trains
     * @param {IBookTrainRequestNew} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IBookTrainResponse, any>>} response with booking details
     */
    const bookTrains = async (request: IBookTrainRequestNew, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IBookTrainResponse, any>> => {
        return await axios.post<IBookTrainResponse>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_BOOK_TRAINS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                cancelToken: ct
            },
        );
    };

    /**
     * POST method for issue trains
     * @param {string} clientCode - active company code
     * @param {string} orderId - order id
     * @param {string[]} pnrs - list of pnrs
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with trains issue details
     */
    const issueTrains = async (clientCode: string, orderId: string, pnrs: string[], accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        return await axios.post<any>( // TODO: specify Response model
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ISSUE_TRAINS,
            pnrs,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode,
                    orderId: orderId
                },
                cancelToken: ct
            }
        );
    };


    /**
     * GET method for obtaining list of orders
     * @param {string} clientCode - active company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrder[], any>>} response with list of orders
     */
    const getOrders = async (clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrder[], any>> => {
        return await axios.get<IOrder[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDERS,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    code: clientCode // TODO: check this param on backend
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining order details
     * @param {string} clientCode - active company code
     * @param {string} orderNo - order number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrder, any>>} response with order details
     */
    const getOrder = async (clientCode: string, orderNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrder, any>> => {
        return await axios.get<IOrder>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_BY_ID,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    orderNo: orderNo
                },
                cancelToken: ct
            }
        );
    };



    /**
     * GET method for obtaining state of issued order
     * @param {string} clientCode - active company code
     * @param {string} orderNo - order number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean, any>>} response with state of issued order
     */
    const isOrderIssued = async (clientCode: string, orderNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean, any>> => {
        return await axios.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IS_ORDER_ISSUED,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    orderNo: orderNo,
                }
            }
        );
    };

    /**
     * GET method for obtaining order booking details
     * @param {string} clientCode - active company code
     * @param {string} orderNo - order number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderBookingDetails[], any>>} response with list of order booking details
     */
    const getOrderBookingDetails = async (clientCode: string, orderNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderBookingDetails[], any>> => {
        return await axios.get<IOrderBookingDetails[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_BOOKING_DETAILS,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    orderNo: orderNo
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining order issue details
     * @param {string} clientCode - active company code
     * @param {string} orderNo - order number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderIssueDetails, any>>} response with order issue details
     */
    const getOrderIssueDetails = async (clientCode: string, orderNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderIssueDetails, any>> => {
        return await axios.get<IOrderIssueDetails>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_ISSUE_DETAILS,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    orderNo: orderNo
                }
            }
        );
    };

    /**
     * GET method for obtaining list of pdf tickets data
     * @param {string} clientCode - active company code
     * @param {string} bsoNo - bso number
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IOrderTicketPdf[], any>>} response with list of pdf tickets data
     */
    const getPdfTickets = async (clientCode: string, bsoNo: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IOrderTicketPdf[], any>> => {
        return await axios.get<IOrderTicketPdf[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_PDF_TICKET_NAMES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    clientCode: clientCode, // TODO: check this param on backend
                    bsoNo: bsoNo
                }
            }
        );
    };

    /**
     * POST method for obtaining pdf ticket file
     * @param {string} clientCode - active company code
     * @param {IStorageKey} key - storage key
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<Blob, any>>} response with pdf ticket file
     */
    const getPdfTicketFile = async (clientCode: string, key: IStorageKey, accessToken: string, ct: CancelToken): Promise<AxiosResponse<Blob, any>> => {
        return await axios.post<Blob>( // TODO: specify Response model
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ORDER_PDF_TICKET,
            key,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                },
                responseType: "blob",
                params: {
                    clientCode: clientCode // TODO: check this param on backend
                },
                cancelToken: ct
            }
        );
    };


    return {
        getFlightProviders,
        getTrainProviders,
        getFlights,
        sendFlightsResultToTts,
        getTrains,
        sendTrainsResultToTts,
        getPaxes,
        bookFlights,
        issueFlights,
        bookTrains,
        issueTrains,
        getOrders,
        getOrder,
        isOrderIssued,
        getOrderBookingDetails,
        getOrderIssueDetails,
        getPdfTickets,
        getPdfTicketFile
    };
}