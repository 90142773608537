import React, {createContext, useEffect, useState} from "react";

export const SELECTION_COMPANY_MODAL_ID = "1889ed76-14fa-459d-a827-547037374ed9";
export const SEARCH_TICKETS_MODAL_ID = "065b803a-4f7b-45cd-a57b-5821387b1072";
export const SEARCH_PASSENGERS_MODAL_ID = "55600b61-fd9e-4b5f-ae68-7a071d56d7d1";
export const NEW_TRIP_MODAL_ID = "ea52666b-62b1-424c-bb27-8e6fc0833010";
export const ORDER_DETAILS_MODAL_ID = "e16abf9e-f4c8-46b6-b97c-9d8e93aac2a8";
export const BOOK_FLIGHT_MODAL_ID = "2f8c897c-9f1d-49b3-8723-469d60387500";
export const BOOK_TRAIN_MODAL_ID = "45969ec0-82a6-4e14-9c82-3663748d38cc";

interface IModalContext {
  isOpen: (id: string) => boolean;
  open: (id: string) => void;
  close: (id: string) => void;
  // onOpen?: (id: string) => void;
  // onClose?: (id: string) => void;
}

export const ModalContext = createContext<IModalContext>({
  isOpen: (id: string) => {
  },
  open: (id: string) => {
  },
  close: (id: string) => {
  },
  // onOpen: (id: string) => {
  //
  // },
  // onClose: (id: string) => {
  //
  // },
} as IModalContext);

export const ModalState = ({ children }: { children: React.ReactNode }) => {
  const [opened, setOpened] = useState<string[]>([]);

  const isOpen = (id: string) => {
    return opened.includes(id);
  }

  const open = (id: string) => {
    setOpened(prev => [...prev.filter(x => x !== id), id]);
  }

  const close = (id: string) => {
    setOpened(prev => prev.filter(x => x !== id));
  }


  const onKeyDown = (event: KeyboardEvent) => {
    if (event.repeat) return;
    if (event.key === "Escape") {
      if(opened.length > 0) {
        close(opened[opened.length - 1]);
      }
    }
  };


  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [onKeyDown]);


  return (
    <ModalContext.Provider value={{ isOpen, open, close }}>
      {children}
    </ModalContext.Provider>
  );
};