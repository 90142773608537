import React, {
    useEffect,
    useRef,
} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {IChatUser, IChatRoom} from "../index";
import {MdScrollViewer} from "../../md-scroll-viewer";
import {MdButton} from "../../md-button";
import {EditIcon} from "../../../icons/EditIcon";
import {BellIcon} from "../../../icons/BellIcon";
import {CrossIcon} from "../../../icons/CrossIcon";
import {MagnifierIcon} from "../../../icons/MagnifierIcon";
import {ChatIcon2} from "../../../icons/ChatIcon2";
import {CircleSpinnerIcon} from "../../../icons/CircleSpinnerIcon";
import {Utils} from "../../../utils/utils";
import './../style.scss'


interface IRoomListComponent {
    //userData: IChatUser | null;
    rooms: IChatRoom[];
    roomCreating: boolean;
    createRoomHandler?: (e: React.MouseEvent) => void;
    selectRoomHandler: (roomId: string) => void;
    closeHandler?: (e: React.MouseEvent) => void;
}

export function RoomListComponent(props: IRoomListComponent) {

    const {
        //userData,
        rooms,
        roomCreating,
        createRoomHandler,
        selectRoomHandler,
        closeHandler
    } = props;

    const authState = useAppSelector(state => state.auth);

    const root = useRef(null);


    useEffect(() => {
    }, []);


    return (
        <div ref={root}
             className="chat-list-layout">

            <div className="chat-list-layout-header">

                <div className="chat-list-layout-header-title-bar">

                    <span className="chat-description"> Чаты </span>

                    <MdButton
                        tooltip="Создать чат"
                        style={{
                            "button": {
                                justifyContent: "center",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            }
                        }}
                        disabled={roomCreating}
                        onClick={createRoomHandler}
                    >
                        {
                            roomCreating
                            ? (
                                <CircleSpinnerIcon
                                    width="14px"
                                    height="14px"
                                    style={{
                                        "circle": {
                                            stroke: "#000000"
                                        }
                                    }}
                                />
                            )
                            : (
                                    <EditIcon
                                        width="14px"
                                        height="14px"
                                        style={{
                                            "path": {
                                                fill: "#989898"
                                            }
                                        }}
                                    />
                            )
                        }
                    </MdButton>

                    <div className="notification-container --indicated">
                        <MdButton
                            tooltip="Уведомления"
                            icon={
                                <BellIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            stroke: "#989898"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    justifyContent: "center",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    width: "30px",
                                    height: "30px",
                                    padding: 0
                                },
                                "icon": {
                                    marginTop: "2px",
                                    marginLeft: 0,
                                    marginRight: 0,
                                },
                                "ink": {
                                    backgroundColor: "rgba(230, 230, 230, 0.5)"
                                },
                            }}
                        />
                    </div>

                    <MdButton
                        tooltip="Закрыть"
                        icon={
                            <CrossIcon
                                width="18px"
                                height="18px"
                                style={{
                                    "path": {
                                        fill: "#989898"
                                    }
                                }}
                            />
                        }
                        style={{
                            "button": {
                                justifyContent: "center",
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                color: "rgb(0 0 0)",
                                borderRadius: "6px",
                                width: "30px",
                                height: "30px",
                                padding: 0
                            },
                            "icon": {
                                marginLeft: 0,
                                marginRight: 0,
                            },
                            "ink": {
                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                            },
                            "text": {
                                display: "none"
                            }
                        }}
                        onClick={closeHandler}
                    />

                </div>

                {/*<div className="favorites-chats">*/}
                {/*    <div className="favorite-chat">*/}
                {/*        <button>*/}
                {/*            <img*/}
                {/*                src="/img/chat-icon-20.png"*/}
                {/*                style={{*/}
                {/*                    minHeight: "100%",*/}
                {/*                    minWidth: "100%",*/}
                {/*                    filter: "hue-rotate(247deg)"*/}
                {/*                }}/>*/}
                {/*        </button>*/}
                {/*        <span>Поддержка</span>*/}
                {/*    </div>*/}
                {/*    <div className="favorite-chat">*/}
                {/*        <button>*/}
                {/*            <img*/}
                {/*                src="/img/chat-icon-21.png"*/}
                {/*                style={{*/}
                {/*                    minHeight: "100%",*/}
                {/*                    minWidth: "100%",*/}
                {/*                    filter: "hue-rotate(241deg)"*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </button>*/}
                {/*        <span>Обращения</span>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="chat-list-layout-header-tools-bar">

                    <div className="chat-list-search-bar">
                        <MagnifierIcon
                            width="16px"
                            height="16px"
                            style={{
                                "path": {
                                    fill: "#CCCCCC"
                                }
                            }}
                        />
                        <input type="text" placeholder="поиск"/>
                    </div>

                </div>

            </div>

            <div className="chat-list-layout-content">

                <MdScrollViewer>
                    {
                        rooms == null || rooms.length == 0
                            ? (
                                <div className="no-items-message">

                                    <div className="no-items-message-icon">
                                        <ChatIcon2 width="40px" height="40px" style={{
                                            "path": {
                                                fill: "rgb(100 100 100)"
                                            }
                                        }}/>
                                    </div>

                                    <div className="no-items-message-caption">Нет чатов для отображения</div>
                                    <div className="no-items-message-content">Нажмите на кнопку ниже чтобы создать новый
                                        чат
                                    </div>

                                    <div className="no-items-message-button">
                                        {
                                            roomCreating
                                                ? (
                                                    <CircleSpinnerIcon
                                                        width="30px"
                                                        height="30px"
                                                        style={{
                                                            "svg": {
                                                                margin: "2px"
                                                            },
                                                            "circle": {
                                                                stroke: "#F9952A"
                                                            }
                                                        }}
                                                    />
                                                )
                                                : (
                                                    <MdButton
                                                        style={{
                                                            "button": {
                                                                borderWidth: 0,
                                                                backgroundColor: "rgb(233 233 233)",
                                                                color: "rgb(0 0 0)",
                                                                borderRadius: "6px",
                                                                height: "35px",
                                                                padding: "5px 15px"
                                                            },
                                                            "ink": {
                                                                backgroundColor: "rgba(255,255,255,.5)"
                                                            }
                                                        }}
                                                        onClick={createRoomHandler}
                                                    >
                                                        Создать чат
                                                    </MdButton>
                                                )
                                        }
                                    </div>

                                </div>
                            )
                            : (
                                rooms.map((room, roomIndex) => {
                                    return (
                                        <React.Fragment key={roomIndex}>
                                            <RoomListItemComponent
                                                //userData={userData}
                                                room={room}
                                                onSelect={() => selectRoomHandler(room.id)}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            )
                    }
                </MdScrollViewer>
            </div>
        </div>
    )
}


interface IRoomListItemComponent {
    icon?: any;
    //userData: IChatUser | null;
    room: IChatRoom;
    onSelect: (roomId: string) => void;
}

export function RoomListItemComponent(props: IRoomListItemComponent) {

    const {icon, /*userData,*/ room, onSelect} = props

    const authState = useAppSelector(state => state.auth);

    const root = useRef(null);

    const timeZoneOffset = new Date().getTimezoneOffset() / 60;

    const messages = room.messages;

    const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;

    const unreadMessages = messages.filter(x => x.userId != authState.userId && !x.read);


    const formatDate = (timeStamp: number) => {
        let dt = new Date(timeStamp);
        let date = dt.getDate();
        let timeDiff = timeStamp - Date.now();
        let diffDays = new Date().getDate() - date;
        let diffMonths = new Date().getMonth() - dt.getMonth();
        let diffYears = new Date().getFullYear() - dt.getFullYear();

        if(diffYears === 0 && diffDays === 0 && diffMonths === 0) {
            return dt.getHours().toString().padStart(2, "0") + ":" + dt.getMinutes().toString().padStart(2, "0")
        }
        else if(diffYears === 0 && diffDays === 1) {
            return "Вчера";
        }
        else if(diffYears > 0) {
            return (
                + dt.getDate().toString().padStart(2, "0")
                + " "
                + dt.getMonth().toString().padStart(2, "0")
                + " "
                + dt.getFullYear().toString()
            );
        }
        else {
            return (
                dt.toLocaleString('default', {month: 'short'}).replace(".", "")
                + " "
                + dt.getDate().toString().padStart(2, "0")
            );
        }
    }



    useEffect(() => {
    }, []);


    return (
        <MdButton
            style={{
                "button": {
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent"
                }
            }}
            onClick={() => onSelect(room.id)}>

            <div ref={root}
                 className="chat-list-item">

                <div className="chat-list-item-avatar">
                    {
                        room.icon != null
                        ? (
                            <img alt="" src={room.icon}/>
                        )
                        : (
                            <div className="icon" style={{ backgroundColor: (Utils.stringToHslColor(room.name))}}>
                                {
                                    room.name.split(' ').map((w) => w[0].toUpperCase()).join("")
                                }
                            </div>
                        )
                    }
                </div>

                <div className="chat-list-item-details">

                    <div className="chat-title">{room.name}</div>

                    <div className="chat-last-message">
                        {
                            messages[messages.length - 1]?.content ??
                            <span className="no-messages-text">Сообщений нет</span>
                        }
                        {/*{*/}
                        {/*    printingUsers.length > 0*/}
                        {/*    ? <span style={{fontStyle: "italic"}}>{printingUsers.map(user => user.fullName + " печатает...")}</span>*/}
                        {/*    : lastMessage.content*/}
                        {/*}*/}
                    </div>

                </div>

                <div className="chat-list-item-status">
                    {
                        lastMessage != null
                            && (
                                <div className="chat-last-message-time">
                                    {
                                        formatDate(new Date(lastMessage.createdAt).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
                                    }
                                </div>
                            )
                    }
                    {
                        unreadMessages.length > 0 && (
                            <div className="chat-unread-messages">{unreadMessages.length}</div>
                        )
                    }
                </div>

            </div>

        </MdButton>
    )
}