import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const SearchIcon2 = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            style={style["svg"]}>

            <path
                d="M384,256c-70.579,0-128,57.421-128,128s57.421,128,128,128s128-57.421,128-128S454.579,256,384,256z M384,494.933
			       c-61.167,0-110.933-49.766-110.933-110.933S322.833,273.067,384,273.067S494.933,322.833,494.933,384S445.167,494.933,384,494.933z"
                style={style["path"]}
            />

            <path
                d="M264.533,477.867H25.6c-3.866,0-8.533-3.806-8.533-8.533v-25.6c0-4.71-3.823-8.533-8.533-8.533S0,439.023,0,443.733v25.6
			       c0,13.636,11.964,25.6,25.6,25.6h238.933c4.71,0,8.533-3.823,8.533-8.533S269.244,477.867,264.533,477.867z"
                style={style["path"]}
            />

            <path
                d="M469.333,119.467V76.8c0-14.114-11.486-25.6-25.6-25.6H261.692c-1.835,0-3.652-0.606-5.12-1.707L197.402,5.12
			       C192.998,1.818,187.546,0,182.042,0H51.2C37.086,0,25.6,11.486,25.6,25.6v93.867c-14.114,0-25.6,11.486-25.6,25.6v230.4
			       C0,380.177,3.823,384,8.533,384s8.533-3.823,8.533-8.533v-230.4c0-4.702,3.831-8.533,8.533-8.533h443.733
			       c4.702,0,8.533,3.831,8.533,8.533v119.467c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V145.067
			       C494.933,130.953,483.448,119.467,469.333,119.467z M409.6,119.467H85.333v-8.533c0-4.702,3.831-8.533,8.533-8.533h307.2
			       c4.702,0,8.533,3.831,8.533,8.533V119.467z M452.267,119.467h-25.6v-8.533c0-14.114-11.486-25.6-25.6-25.6h-307.2
			       c-14.114,0-25.6,11.486-25.6,25.6v8.533h-25.6V25.6c0-4.702,3.831-8.533,8.533-8.533h130.842c1.835,0,3.652,0.606,5.12,1.707
			       l59.17,44.373c4.403,3.302,9.856,5.12,15.36,5.12h182.042c4.702,0,8.533,3.831,8.533,8.533V119.467z"
                style={style["path"]}
            />

            <path
                d="M102.4,42.667H68.267c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533H102.4c4.71,0,8.533-3.823,8.533-8.533
			       S107.11,42.667,102.4,42.667z"
                style={style["path"]}
            />

            <path
                d="M179.2,42.667h-8.533c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h8.533c4.71,0,8.533-3.823,8.533-8.533
			       S183.91,42.667,179.2,42.667z"
                style={style["path"]}
            />

            <circle
                cx="136.533"
                cy="51.2"
                r="8.533"
            />

            <circle
                cx="8.533"
                cy="409.6"
                r="8.533"
            />

            <path
                d="M441.233,437.7l-44.962-44.962c8.252-9.097,13.329-21.12,13.329-34.338c0-28.237-22.963-51.2-51.2-51.2
			       c-28.237,0-51.2,22.963-51.2,51.2c0,28.237,22.963,51.2,51.2,51.2c8.832,0,17.143-2.244,24.405-6.195l46.362,46.362
			       c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5C444.57,446.43,444.57,441.037,441.233,437.7z M358.4,392.533
			       c-18.825,0-34.133-15.309-34.133-34.133s15.309-34.133,34.133-34.133s34.133,15.309,34.133,34.133S377.225,392.533,358.4,392.533z"
                style={style["path"]}
            />

            <path
                d="M206.558,228.736c9.276-7.834,15.309-19.405,15.309-32.469c0-23.526-19.14-42.667-42.667-42.667
			       s-42.667,19.14-42.667,42.667c0,13.065,6.033,24.636,15.309,32.469c-11.358,12.134-32.375,39.211-32.375,86.997
			       c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-46.473,22.588-70.221,30.976-78.635
			       c3.738,1.075,7.612,1.835,11.691,1.835s7.953-0.759,11.691-1.835c8.388,8.414,30.976,32.162,30.976,78.635
			       c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533C238.933,267.947,217.916,240.87,206.558,228.736z M179.2,221.867
			       c-14.114,0-25.6-11.486-25.6-25.6s11.486-25.6,25.6-25.6s25.6,11.486,25.6,25.6S193.314,221.867,179.2,221.867z"
                style={style["path"]}
            />

            <path
                d="M315.733,196.267c0-18.825-15.309-34.133-34.133-34.133s-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
			       c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533
			       c0-7.68,0.043-13.278,5.683-19.166c3.575,1.28,7.373,2.099,11.383,2.099s7.808-0.819,11.383-2.099
			       c5.641,5.888,5.683,11.486,5.683,19.166c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533
			       c0-8.405-0.102-18.679-8.567-28.826C312.431,212.634,315.733,204.868,315.733,196.267z M281.6,213.333
			       c-9.412,0-17.067-7.654-17.067-17.067c0-9.412,7.654-17.067,17.067-17.067c9.412,0,17.067,7.654,17.067,17.067
			       C298.667,205.679,291.012,213.333,281.6,213.333z"
                style={style["path"]}
            />

            <path
                d="M110.933,196.267c0-18.825-15.309-34.133-34.133-34.133s-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
                   c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533
			       c0-7.68,0.043-13.278,5.683-19.166c3.575,1.28,7.373,2.099,11.383,2.099s7.808-0.819,11.383-2.099
			       c5.641,5.888,5.683,11.486,5.683,19.166c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533
			       c0-8.405-0.102-18.679-8.568-28.826C107.631,212.634,110.933,204.868,110.933,196.267z M76.8,213.333
			       c-9.412,0-17.067-7.654-17.067-17.067c0-9.412,7.654-17.067,17.067-17.067c9.412,0,17.067,7.654,17.067,17.067
			       C93.867,205.679,86.212,213.333,76.8,213.333z"
                style={style["path"]}
            />

        </svg>
    )
}