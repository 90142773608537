import {MoneyLimitIcon} from "../../../../icons/MoneyLimitIcon";
import {useTranslation} from "react-i18next";
import {DocumentIcon} from "../../../../icons/DocumentIcon";
import {CheckPaymentIcon} from "../../../../icons/CheckPaymentIcon";
import {ColorCardComponent} from "../../../commonComponents/colorCardComponent";

type CompanyAccInfoProps = {
    agreementNo: string;
    agreementFrom: string;
    agreementTill: string;
    conpanyLimit: number;
    //installmentConditions: string;
    companyDuty: number;
    deptType: string;
    deptSum: number;
};

export function CompanyAccInfoComponent(accountInfo: CompanyAccInfoProps) {
    const {t} = useTranslation();
    return (
        <>
            <div
                className="filters-container"
                //style={{backgroundColor: MAIN_BACKGROUND_COLOR }}
            >
                <div className="filters-caption "> Общая информация</div>
                <div className="filters-form">
                    <ColorCardComponent id={"blue"} icon={<DocumentIcon iconSize="40px"/>} title={"Номер договора"}
                                        data={[["", accountInfo.agreementNo]]}/>
                    <ColorCardComponent id={"lightBlue"} icon={<DocumentIcon iconSize="40px"/>}
                                        title={"Срок действия котракта"}

                                        data={[["От: " + accountInfo.agreementFrom, " / До" + accountInfo.agreementTill]]}/>
                    <ColorCardComponent id={"lightgreen"} icon={<MoneyLimitIcon iconSize="40px"/>}
                                        title={t("accInfoPage.companyLimit")}
                                        data={[[accountInfo.conpanyLimit.toString(), " Rub"]]}/>

                    <ColorCardComponent id={"green"} icon={<CheckPaymentIcon iconSize="40px"/>}
                                        title={t("accInfoPage.accountStatus")}
                                        data={[[accountInfo.deptType + ": ", accountInfo.deptSum?.toString() + " Rub"]]}/>

                </div>
            </div>
        </>
    );
}
