import {
  KeyAttributesOptions,
  MatchSorterOptions,
  matchSorter,
} from "match-sorter";
import { SORT_BY_ASC, SORT_BY_DESC } from "../constants/ServiceCostants";
import { ISortOrder } from "../interfaces/componentInterfaces/ISortOrder";
import { IStatementIncome } from "../interfaces/reportInterfaces/IStatementIncome";

interface ICustomCollection<T> {
  array: T[];
}

export function DataFilterService() {
  function filterAndSortArray<T>(
    array: T[],
    filterWord: string | undefined,
    skipfilterPropNames: string[] | undefined,
    sortParams: ISortOrder[] | undefined
  ): T[] {
    let keyOption = new Array<KeyAttributesOptions<T>>();

    if (array.length > 0) {
      keyOption = getFilterOptions(array[0], skipfilterPropNames);
    }

    if (
      filterWord !== undefined &&
      array.length > 0 &&
      skipfilterPropNames !== undefined
    ) {
      array = matchSorter(array, filterWord, { keys: keyOption });
    }
    return array;
  }

  // internal functions
  function getFilterOptions<T>(item: T, skipPropNames: string[] | undefined) {
    let keyOption = new Array<KeyAttributesOptions<T>>();

    const keys = Object.getOwnPropertyNames(item);
    if (skipPropNames !== undefined) {
      keys.forEach((item: string, index: number): void => {
        if (!skipPropNames.some((elem) => elem === item)) {
          keyOption.push({
            threshold: matchSorter.rankings.CONTAINS,
            key: item,
          });
        }
      });
    } else {
      keys.forEach((item: string, index: number): void => {
        keyOption.push({
          threshold: matchSorter.rankings.CONTAINS,
          key: item,
        });
      });
    }

    function getSortOptions<T>() {
      let sortOptions = new Array<KeyAttributesOptions<T>>();
      let matchSorterOptions = new Array<MatchSorterOptions<T>>();
      matchSorterOptions.push({
        threshold: matchSorter.rankings.NO_MATCH,
        baseSort: (a, b) => {
          return a.item < b.item ? 1 : -1;
        },
      });
    }

    return keyOption;
  }

  return { filterAndSortArray, getFilterOptions };
}
