import {TicketType} from "../../../../enums/TicketType";
import "./style.scss";
import {HeartIcon} from "../../../../icons/HeartIcon";
import {PlaneHorizontalIcon} from "../../../../icons/PlaneHorizontalIcon";
import {TrainHorizontalIcon} from "../../../../icons/TrainHorizontalIcon";
import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {AviaInfoComponent} from "../aviaInfoComponent";
import {PlaneIcon} from "../../../../icons/PlaneIcon";
import {useEffect, useState} from "react";
import {TrainInfoComponent} from "../trainInfoComponent";
import {useTranslation} from "react-i18next";
import {PlaneLandIcon} from "../../../../icons/PlaneLandIcon";
import {ITrainVariant} from "../../../../interfaces/ITrainVariant";
import {HotelIcon} from "../../../../icons/HotelIcon";
import {UpIcon} from "../../../../icons/UpIcon";
import {DownIcon} from "../../../../icons/DownIcon";

type TravelVariantProps = {
    //index: number;
    isFavorite?: boolean;
    addToFavorite?: (trip:ITravelVariant) => void;
    removeFromFavorite?: (trip: ITravelVariant) => void;
    isBookingMode?: boolean;
    travelVariant: ITravelVariant;
    selectedTariff?: number;
    selectTariff?: (index: number) => void;
    openBookWindow?: (id: number) => void;
}

export function TravelVariantComponent({
                                           //index,
                                           travelVariant,
                                           openBookWindow,
                                           isBookingMode,
                                           selectTariff,
                                           selectedTariff, isFavorite, addToFavorite, removeFromFavorite
                                       }: TravelVariantProps) {
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(isBookingMode);
    //const [isFavoriteTrip,setIsFavoriteTrip] = useState(isFavorite??false)
    const {t} = useTranslation();

    const favoriteHandler = () => {
        if (!isFavorite && addToFavorite !== undefined) {
            addToFavorite(travelVariant);
        } else if (removeFromFavorite !== undefined) {
            removeFromFavorite(travelVariant)
        }
    }
    const addToFavoriteTest = (trip:ITravelVariant) =>{addToFavoriteTest(trip)}

    useEffect(() => {

    }, [showAdditionalInfo, isFavorite]);

    return (
        <>
            <div className="travel-variant-container">

                {/*main block info*/}
                <div className="travel-variant-main-sub-container">
                    <div className="company-name-sub-container">
                        { travelVariant && travelVariant.travelCompanyOrVehicle?.map((company, index) => <div key={index}>{company}</div>)}
                    </div>

                    {/*main info bock*/}

                    <div className=" travel-main-info">

                        <div className="travel-main-direction-info">

                            {/*  block "FROM */}
                            <div className="travel-time-and-city">
                                <div
                                    className="d-flex flex-row time mx-2 align-self-center"> {travelVariant && travelVariant.departureTime}</div>
                                <div
                                    className="d-flex flex-row time mx-2 align-self-center">
                                    {travelVariant.departureDate}
                                    {/*{travelVariant.departureDate?.getDay()+"/"+travelVariant.departureDate?.getMonth()+"/"+travelVariant.departureDate?.getFullYear()}*/}
                                </div>
                                <div
                                    className="d-flex flex-rowair-port-code mx-2">&nbsp;{travelVariant && travelVariant.departurePlaceCode}</div>
                            </div>

                            {/*  block "Direction" */}
                            <div className="travel-direction-block">
                                <div className="travel-direction-block-info">
                                    <div typeof='travel-time'>
                                        {travelVariant.travelTime}
                                    </div>
                                    <div typeof="horizontal-line">
                                        <svg height="10px" width="100%" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 0 L300 0 Z "
                                                  style={{fill: "none", stroke: "black", strokeWidth: 3}}/>
                                        </svg>
                                    </div>
                                    <div>
                                        <small>
                                            <p className=" align-self-start">{travelVariant.transfersCount <= 0 ? "Прямой" : "Пересадки: " + travelVariant.transfersCount}</p>{" "}
                                        </small>
                                    </div>
                                </div>

                                <div className="travel-direction-block-icon">
                                    {travelVariant.ticketType === TicketType.AviaTicket ?
                                        <PlaneHorizontalIcon iconSize="45px"/> :
                                        <TrainHorizontalIcon iconSize={"45px"}/>}
                                </div>
                            </div>

                            {/*  block "TO */}
                            <div className="travel-time-and-city">
                                <div className="row time"> {travelVariant.arriveTime}</div>
                                <div className="row time">
                                    {travelVariant.arrivedDate}
                                    {/*{travelVariant.arrivedDate?.getDate()+"/"+travelVariant.arrivedDate?.getMonth()+"/"+travelVariant.arrivedDate?.getFullYear()}*/}
                                </div>
                                <div className="row">{travelVariant.arrivePlaceCode}</div>
                            </div>

                        </div>
                        <div className="travel-direction-block-cities">
                            <div>{travelVariant.ticketType === TicketType.AviaTicket ?
                                <PlaneIcon/> : <><HotelIcon iconSize={"15px"}/><TrainHorizontalIcon/></>
                            } {travelVariant.departureCity}</div>
                            <div>&nbsp;-&nbsp;</div>
                            <div> {travelVariant.arriveCity} {travelVariant.ticketType === TicketType.AviaTicket ?
                                <PlaneLandIcon/> : <><TrainHorizontalIcon/><HotelIcon iconSize={"15px"}/></>
                            }</div>
                        </div>
                    </div>

                    <div className="travel-price-info">
                        <div typeof="icon" onClick={() => {
                            favoriteHandler()
                        }}><HeartIcon isFill={isFavorite}/></div>
                        {travelVariant.minPrice === travelVariant.maxPrice ?
                            <div typeof="price">Min: {travelVariant.minPrice} rub</div> : <>
                                <div typeof="price">Min: {travelVariant.minPrice} rub</div>
                                <div typeof="price"> Max: {travelVariant.maxPrice} rub
                                </div>
                            </>
                        }
                    </div>

                    <div
                        className={travelVariant.ticketType === TicketType.AviaTicket ? "additional-info-avia" : "additional-info-train"}
                        typeof={travelVariant.ticketType === TicketType.AviaTicket ? "additional-info-avia" : "additional-info-train"}
                    >
                        <div style={{color: "black"}}>{travelVariant.ticketType === TicketType.AviaTicket ?
                            <PlaneIcon/> : <TrainHorizontalIcon/>}
                        </div>
                        <div>
                            {`${t("orderPage.tariffCount")}: ${travelVariant.tariffCount}`}
                            {`${isFavorite}`}
                        </div>


                        <div className="push-last-child-to-right "
                             onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                            {!isBookingMode &&
                                <button typeof="bookButton" style={{marginRight: "50px"}} onClick={() => {
                                    openBookWindow && openBookWindow(travelVariant.id)
                                }}>Book or Issue
                                </button>}

                            {/*<div nClick={() => setShowAdditionalInfo(!showAdditionalInfo)}><CollapseIcon/></div>*/}
                            <div style={{alignSelf: "center"}}
                                 onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>{showAdditionalInfo ?
                                <DownIcon/> : <UpIcon/>}</div>
                            {/*<CollapseIcon/>*/}
                        </div>
                    </div>

                </div>
                <div
                    className={showAdditionalInfo ? " expanded " : " collapsed"}>
                    {travelVariant.ticketType === TicketType.AviaTicket && showAdditionalInfo &&
                        <AviaInfoComponent isCollapsed={false}
                                           aviaVariant={travelVariant.travelVariant as IAviaVariant}
                                           setActiveTariff={selectTariff}
                                           isBookingMode={isBookingMode}
                                           selectedTariff={selectedTariff}
                        />}

                    {travelVariant.ticketType === TicketType.TrainTicket && showAdditionalInfo &&
                        <TrainInfoComponent isCollapsed={false}
                                            trainVariant={travelVariant.travelVariant as ITrainVariant}
                                            setActiveTariff={selectTariff}
                                            isBookingMode={isBookingMode}
                                            selectedTariff={selectedTariff}

                        />}
                </div>
            </div>


        </>
    )
}