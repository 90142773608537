import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const MinusIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={style["svg"]}>

            <path
                d="M6 12L18 12"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={style["path"]}
            />

        </svg>
    )
}