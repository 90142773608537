import React, {CSSProperties, useEffect, useRef, useState} from "react";
import './style.scss'


interface IMdSliderProps {
    style?: { [selector: string]: CSSProperties }
    header?: React.ReactNode;
    slides?: Array<IMdSlideProps>;
    footer?: React.ReactNode;
    activeSlide?: number,
    onChange?: (event: any) => void;
    paginationAlignment?: 'top' | 'bottom';
}

interface IMdSlideProps {
    content: React.ReactNode;
    style?: React.CSSProperties;
}


export function MdSlider({ style = {}, header, slides = [], footer, activeSlide = 0, paginationAlignment = 'top' }: IMdSliderProps) {
    const root = useRef(null);

    const [selectedIndex, setSelectedIndex] = useState(0);


    const paginationClickHandler = (event: React.MouseEvent, index: number) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedIndex(index)
    }


    useEffect(() => {
        if(selectedIndex != activeSlide) {
            setSelectedIndex(Math.max(0, Math.min(slides?.length - 1 ?? 0, activeSlide)));
        }
    }, [activeSlide]);


    useEffect(() => { }, []);


    return (
        <div
            ref={root}
            className='md-slider'
            style={style["root"]}
        >
            {
                paginationAlignment == 'top' && (
                    <div className='md-slider-pagination'
                        style={style["pagination"]}>
                        {
                            slides?.map((child, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={('dot' + ((index == selectedIndex) ? ' --active' : '') + (slides?.length <= 1 ? " --hidden" : ""))}
                                        onClick={(e) => paginationClickHandler(e, index)}
                                    >
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }

            <div className='md-slider-header'>
                {
                    header
                }
            </div>

            <div className='md-slider-content'>
                <ul className='md-slider-screens screens animate'>
                    {
                        slides?.map((child, index) => {
                            return (
                                <li
                                    key={index}
                                    className={('screen' + ((index == selectedIndex) ? ' --active' : ''))}>
                                    {
                                        // <MdScrollViewer>
                                        //     {child.content}
                                        // </MdScrollViewer>
                                        child.content
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
                {/*<button className='md-slider-prev-screen-button prev-screen' onClick={() => setSelectedIndex(prev => Math.max(0, prev - 1))}>*/}
                {/*    <i className='icon-angle-left'></i>*/}
                {/*</button>*/}
                {/*<button className='md-slider-next-screen-button next-screen' onClick={() => setSelectedIndex(prev => Math.min(slides?.length-1 ?? 0, prev + 1))}>*/}
                {/*    <i className='icon-angle-right'></i>*/}
                {/*</button>*/}
            </div>

            {
                paginationAlignment == 'bottom' && (
                    <div className='md-slider-pagination'
                        style={style["pagination"]}>
                        {
                            slides?.map((child, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={('dot' + ((index == selectedIndex) ? ' --active' : '') + (slides?.length <= 1 ? " --hidden" : ""))}
                                        onClick={(e) => paginationClickHandler(e, index)}
                                    >
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }

            <div className='md-slider-footer walkthrough-footer'>
                {
                    footer
                }
            </div>
        </div>
    );
}