import {IPax} from "../../../../interfaces/orderInterfaces/IPax";
import {ArrowRightColorIcon} from "../../../../icons/ArrowRightColorIcon";
import {useState} from "react";
import "./style.scss";
import {CustomCheckboxButton} from "../../../commonComponents/checkBoxComponents/customCheckBoxButton";
import {PlaneIcon} from "../../../../icons/PlaneIcon";
import {SHIFT_WORK_TRIP, SIMPLE_TRIP, SPECIAL_REQUIREMENT_TRIP} from "../../../../constants/PageConstants";
import {MdAccordion} from "../../../../components/md-accordion";
import {useAppSelector} from "../../../../redux/hooks";
import {SearchDirectionComponent} from "../SearchComponents/SearchDirectionComponent.tsx";
import {useTranslation} from "react-i18next";
import {SearchHotelComponent} from "../SearchComponents/searchHotelComponent";

export function BusinessTripComponent() {

    const {t} = useTranslation();
    const orderState = useAppSelector(state => state.order)
    const [selectedPaxes, setSelectedPaxes] = useState<IPax[]>([]);
    const [filteredPaxes, setFilteredPaxes] = useState<IPax[]>(orderState.paxes)
    const [selectedPax, setSelectedPax] = useState<IPax>()

    const [hotelCity, setHotelCity] = useState("")
    const [checkInHotelDate, setCheckInHotelDate] = useState("")
    const [checkOutHotelDate, setCheckOutHotelDate] = useState("")

    const [currentMode, setCurrentMode] = useState("simpleTrip")

    const tripModeHandler = (value: boolean, modeName: string) => {
        switch (modeName) {
            case SIMPLE_TRIP: {
                setCurrentMode(SIMPLE_TRIP)
                break;
            }
            case SHIFT_WORK_TRIP: {
                setCurrentMode(SHIFT_WORK_TRIP)
                break;
            }
            case SPECIAL_REQUIREMENT_TRIP: {
                setCurrentMode(SPECIAL_REQUIREMENT_TRIP)
                break;
            }
        }
    }

    const unselectPaxHandler = (pax: IPax) => {
        if (selectedPax?.fio === pax.fio && selectedPax.passportNum === pax.passportNum) {
            setSelectedPax(undefined)
        }
        setSelectedPaxes(selectedPaxes.filter(item => item.fio !== pax.fio && item.passportNum !== pax.passportNum))
    }

    const selectPaxHandler = (pax: IPax) => {

        setSelectedPaxes([...selectedPaxes, pax]);
        setSelectedPax(pax);
    }


    return (
        <div className="business-trip-container">

            {/*<SelectPaxesComponent updateSelectedPaxesList={(selected:IPax[])=>{}}/>*/}

            {/*Select Paxes*/}

            <div className="business-trip-row-item">

                <CustomCheckboxButton isChecked={currentMode === SIMPLE_TRIP} onChange={(value: boolean) => {
                    setCurrentMode(SIMPLE_TRIP)
                }} label={"Простая командировка"} icon={<PlaneIcon/>}/>
                <CustomCheckboxButton isChecked={currentMode === SHIFT_WORK_TRIP} onChange={(value: boolean) => {
                    setCurrentMode(SHIFT_WORK_TRIP)
                }}
                                      label={"Вахта"} icon={<PlaneIcon/>}/>
                <CustomCheckboxButton isChecked={currentMode === SPECIAL_REQUIREMENT_TRIP}
                                      onChange={(value: boolean) => {
                                          setCurrentMode(SPECIAL_REQUIREMENT_TRIP)
                                      }} label={"С особыми требованиями"}/>
            </div>
            <div className="business-trip-rotate-arrow">
                <div><ArrowRightColorIcon iconSize="40px"/></div>
            </div>


            <p>Выбор пассажиров:</p>
            {/*<div className="business-trip-row-item">*/}
            <div className="">
                <div><ArrowRightColorIcon/></div>

                <MdAccordion mode="single-open"  sections={[{
                    isOpen: true, header: <div style={{paddingLeft:"15px", display:"inline-block"}} ><p
                        style={{fontSize: "1.2rem", paddingLeft: "15px"}}>Выбраные
                        сотрудники({selectedPaxes.length}): </p></div>,
                    content: <div className="business-trip-row-container">
                        {/* All paxes*/}
                        <div className="business-trip-all-paxes-list">
                            <div className="title"><label>All ({orderState.paxes.length})</label>
                            </div>
                            <dl>
                                {filteredPaxes && filteredPaxes.map((pax: IPax, index) => (<dd onClick={() =>
                                    selectPaxHandler(pax)}
                                                                                               key={'select-all-paxes-' + index}>{pax.fio}</dd>))}
                            </dl>
                        </div>
                        {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}

                        {/*<ArrowLeftIcon/>*/}
                        <div className="business-trip-arrow-icon"><ArrowRightColorIcon iconSize={'50px'}/></div>

                        {/* Selected paxes*/}
                        <div className="business-trip-all-paxes-list">
                            <div className="title"><label>Selected paxes </label></div>
                            <dl>
                                {selectedPaxes && selectedPaxes.map((pax: IPax, index) => (
                                    <dd key={"selected-paxes-" + index}
                                        className={(selectedPax?.fio === pax.fio && selectedPax.passportNum === pax.passportNum) ? " selected-list-item" : ""}>
                                        <span onClick={() => setSelectedPax(pax)}>{pax.fio}</span>
                                        <p className="unselect-button" onClick={() => {
                                            unselectPaxHandler(pax)
                                        }}>&#x2715;</p>
                                    </dd>))}
                            </dl>
                        </div>

                        {/*<ArrowLeftIcon/>*/}
                        <div className="business-trip-arrow-icon"><ArrowRightColorIcon iconSize={'50px'}/></div>

                        {/* Selected One pax*/}
                        <div className="business-trip-all-paxes-list">
                            <div className="title">
                                <label> {selectedPax === undefined ? "Selected user info:" : selectedPax.fio} </label>
                            </div>
                            <dl>
                                <dd><p>FIO:</p>{selectedPax?.fio}</dd>
                                <dd><p>Latin Name:</p>{selectedPax?.first}</dd>
                                <dd><p>Latin Family:</p>{selectedPax?.last}</dd>
                                <dd><p>Latin Patronymic:</p>{selectedPax?.middle}</dd>
                                <dd><p>Passport series:</p>{selectedPax?.passportInfo.series}</dd>
                                <dd><p>Passport Number:</p>{selectedPax?.passportInfo.number}</dd>
                                <dd><p>Passport Expiry:</p>{selectedPax?.passportInfo.expiry}</dd>
                                <dd><p>Passport dob:</p>{selectedPax?.passportInfo.dob}</dd>
                            </dl>
                        </div>
                    </div>
                }]}
                />

                Выбор маршрута:
                <div className="business-trip-row-item"><SearchDirectionComponent directionIndex={0}
                                                                                  hasBackwardDirection={true}
                                                                                  isOneWayMode={true}/></div>
                Поиск Отеля:
                <SearchHotelComponent/>
                {/*<div className="business-trip-row-item">*/}
                {/*    <InputTextWithPopoverComponent value={""} placeholder={""} popoverItems={[]}*/}
                {/*                                   setValue={(value: string) => {*/}
                {/*                                       console.log(value)*/}
                {/*                                   }} runItemsSearch={() => {*/}
                {/*        console.log("")*/}
                {/*    }} clearPopoverItemsResult={() => {*/}
                {/*    }}/>*/}
                {/*    <InputDateComponent setDateHandler={setCheckInHotelDate} dateLabel={"check-in"}/>*/}
                {/*    <InputDateComponent setDateHandler={setCheckOutHotelDate} dateLabel={"check-out"}/>*/}
                {/*    /!*<InputTextWithPopoverComponent value={""} placeholder={""} popoverItems={[]} setValue={(value:string)=>{console.log(value)}} runItemsSearch={} clearPopoverItemsResult={}/>*!/*/}
                {/*    /!* <InputTextWithPopoverComponent value={""} placeholder={""} popoverItems={[]} setValue={(value:string)=>{console.log(value)}} runItemsSearch={} clearPopoverItemsResult={}/>*!/*/}
                {/*</div>*/}

                Summary:
                <div className="business-trip-row-item">
                    ToDo: Summary component with transfers
                </div>

                Book or issue component:
                <div className="business-trip-row-item">
                    ToDo: Book or issue component
                </div>


                {/*<div className="business-trip-all-paxes-list">*/}
                {/*    <div className="title"><label>All ({selectedPaxes && <>filteredPaxes.length</>})</label></div>*/}
                {/*    <dl>*/}
                {/*        {selectedPaxes && selectedPaxes.map((pax: IPax, index) => (<dd onClick={() =>*/}
                {/*            setSelectedPax(pax)} key={'select-all-paxes-' + index}>{pax.fio}</dd>))}*/}
                {/*    </dl>*/}
                {/*</div>*/}
                {/*/!*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*!/*/}

                {/*/!*<ArrowLeftIcon/>*!/*/}
                {/*<div style={{alignSelf: "center", left: "auto"}}><ArrowRightColorIcon iconSize={'50px'}/></div>*/}

                {/*<div className="business-trip-all-paxes-list">*/}
                {/*    <div className="title"><label>Selected paxes </label></div>*/}
                {/*    <dl>*/}
                {/*        /!*{props.selectedPaxesList && props.selectedPaxesList.map((pax: IPax, index) => (*!/*/}
                {/*        /!*    <dd key={"selected-paxes-" + index} onClick={() => {*!/*/}
                {/*        /!*        props.updateSelectedPaxesList(props.selectedPaxesList!.filter((sp, si) => si !== index));*!/*/}
                {/*        /!*    }}>{pax.fio}*!/*/}
                {/*        /!*        <p className="unselect-button">&#x2715;</p>*!/*/}
                {/*        /!*    </dd>))}*!/*/}
                {/*    </dl>*/}
                {/*</div>*/}

                {/*/!*<ArrowLeftIcon/>*!/*/}
                {/*<div style={{alignSelf: "center", left: "auto"}}><ArrowRightColorIcon iconSize={'50px'}/></div>*/}

                {/*<div className="business-trip-all-paxes-list">*/}
                {/*    <div className="title"><label>Selected user info </label></div>*/}
                {/*    <dl>*/}
                {/*        /!*{props.selectedPaxesList && props.selectedPaxesList.map((pax: IPax, index) => (*!/*/}
                {/*        /!*    <dd key={"selected-paxes-" + index} onClick={() => {*!/*/}
                {/*        /!*        props.updateSelectedPaxesList(props.selectedPaxesList!.filter((sp, si) => si !== index));*!/*/}
                {/*        /!*    }}>{pax.fio}*!/*/}
                {/*        /!*        <p className="unselect-button">&#x2715;</p>*!/*/}
                {/*        /!*    </dd>))}*!/*/}
                {/*    </dl>*/}
                {/*</div>*/}
                {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}
            </div>
            <p>asdf</p>

        </div>)
}