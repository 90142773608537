import {SearchResultComponent} from "./searchResultComponent";
import React, {useContext, useState} from "react";
import {SearchComponent} from "./SearchComponents";
import "./style.scss";
import {FilterResultComponent} from "./filterResultComponent";
import {OrderService} from "../../../services/OrderServices";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
    setFavoriteVariantIds,
    setFavoriteVariants,
    setFilteredVariants,
    setFilterOptions,
    setTravelVariants,
} from "../../../redux/orderSlice";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {Modal} from "../../commonComponents/modal/Modal/Modal";
import {BookComponent} from "./bookComponent";
import {LoadingComponent} from "../../commonComponents/loadingComponent";
import {BusinessTripComponent} from "./businessTripComponent";
import {TravelVariantComponent} from "./travelVariantComponent";
import {CustomCheckboxButton} from "../../commonComponents/checkBoxComponents/customCheckBoxButton";
import {MdButton} from "../../../components/md-button";
import {COMMON_BUTTON_STYLE} from "../../../constants/componentsContants";
import {IPax} from "../../../interfaces/orderInterfaces/IPax";
import {SelectPaxFullComponent} from "./selectPaxFullComponent";
import {OrderMode} from "../../../enums/OrderMode";
import {ITripOrder} from "../../../interfaces/orderInterfaces/ITripOrder";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";

export function OrderCabinetPage() {
    // redux
    const dispatch = useAppDispatch();
    const orderState = useAppSelector(state => state.order);

    //services
    const {searchTravelVariants, getFiltersData, getAllPaxes, createTripOrder} = OrderService();
    const {isOpen, open, close} = useContext(ModalContext)

    //hooks
    const [isResultMode, setIsResultMode] = useState(false)
    const [bookingVariantId, setBookingVariantId] = useState(-1)
    const [tripOrders, setTripOrders] = useState<ITripOrder[]>();

    //new functionality
    const [orderMode, setOrderMode] = useState(OrderMode.SearchMode);

    //selected paxes for bookAll
    const [selectedPaxes, setSelectedPaxes] = useState<IPax[]>([]);
    const [errorMsg, setErrorMsg] = useState("")

    //hook visible parts of page
    const [isVisibleBookAllAsChain, setIsVisibleBookAllAsChain] = useState(false);


    // direction handlers
    //!Moved to search component remove after testing
    // const addDirectionsToMultiRoad = () => {
    //
    //     let lastRoad = orderState.orderedMultiRoad[orderState.orderedMultiRoad.length - 1]
    //
    //     let direction: IDirectionRequest = {
    //         departDate: "",
    //         townFrom: lastRoad.townTo ?? "",
    //         townTo: "",
    //         clientCode: "",
    //         notes: ""
    //     }
    //     dispatch(setOrderedMultiRoute([...orderState.orderedMultiRoad, direction]))
    // }

    //!Moved to search component remove after testing
    // const removeLastDirectionOffMultiRoad = () => {
    //     const dirs = [...orderState.orderedMultiRoad];
    //     dispatch(setOrderedMultiRoute(dirs.slice(0, dirs.length - 1)))
    // }

    //!Moved to search component remove after testing
    // const updateMultiRoadDirection = (index: number, newDirection: IDirectionRequest) => {
    //
    //     let directions = orderState.orderedMultiRoad.slice();
    //     directions[index] = {
    //         ...directions[index],
    //         townFrom: newDirection.townFrom,
    //         townTo: newDirection.townTo,
    //         departDate: newDirection.departDate
    //     }
    //     dispatch(setOrderedMultiRoute([...directions]))
    // }

    const bookHandler = (id: number) => {
        setBookingVariantId(id);
        open("bookWindow");
    }

    const searchHandler = async (
        isOneWay: boolean,
        needSearchAvia: boolean,
        needSearchTrain: boolean,
    ) => {

        setOrderMode(OrderMode.Searching);
        const directions = isOneWay ? orderState.orderedRoundRoute : orderState.orderedMultiRoad;
        //ToDo Uncommit after test falcon search
        const res = await searchTravelVariants(directions, needSearchAvia, needSearchTrain);

        //?Redux for falcon search
        dispatch(setTravelVariants(res));
        dispatch(setFilteredVariants(res));
        const filterOptions = getFiltersData(res);
        dispatch(setFilterOptions(filterOptions))
        setOrderMode(OrderMode.SearchResult);
    };

    const modeHandler = (value: number) => {
        setErrorMsg("");
        if ((value === OrderMode.BookAll || value === OrderMode.ChainBooking) && orderState.favoriteVariants.length === 0) {
            alert('Please add at least one trip option to your favorites.')
            return;
        }
        if (value === OrderMode.BookAll || OrderMode.ChainBooking) {
            setTripOrders(orderState.favoriteVariants.map((fv, index) => createTripOrder(fv, index)))
        }
        setOrderMode(value);
    }

    const selectTariffHandler = (tripId: number, tariffIndex: number) => {
        setErrorMsg("");
        if (tripOrders === undefined) return;

        let updatedTripOrders = [...tripOrders]
        const indexTrip = tripOrders.findIndex(trip => trip.id === tripId);
        updatedTripOrders[indexTrip].selectedTariff = tariffIndex;
        setTripOrders(updatedTripOrders);

    }

    const bookAllhandler = () => {
        setErrorMsg("");
        //tripOrders check
        tripOrders?.forEach(trip=>{
            if(trip.selectedTariff<0){
                setErrorMsg("Please select tarif in the each travel variant.")
                return;
            }
        })

        if (orderMode === OrderMode.BookAll) {
            tripOrders?.forEach(trip => trip.paxes = selectedPaxes)
        }
    }

    return (
        <div className="order-document-container">

            {isOpen("bookWindow") && orderState.filteredVariants.find(tv => tv.id === bookingVariantId) &&
                <Modal onClose={() => close('bookWindow')} title={""}> <BookComponent
                    id={bookingVariantId}
                    travelVariant={orderState.filteredVariants.find(tv => tv.id === bookingVariantId)!}
                    paxes={orderState.paxes}
                /></Modal>}

            {orderMode === OrderMode.BusinessTripMode && <BusinessTripComponent/>}

            {orderMode === OrderMode.SearchResult && orderState.favoriteVariants &&
                <div className="order-page-favorite-container">
                    <div className="order-page-favorite-items">

                        <div
                            className="d-flex flex-column gap-2 mt-2 z-0">{orderState.favoriteVariants.map((fv, index) => {
                                return fv !== undefined ?
                                    <div className="order-page-favorite-item"><TravelVariantComponent //index={index}
                                        travelVariant={fv}
                                        isFavorite={true}
                                        isBookingMode={false}
                                        openBookWindow={bookHandler}
                                        removeFromFavorite={() => {
                                            dispatch(setFavoriteVariants(orderState.favoriteVariants.filter(trip => trip.id !== fv.id)));
                                            dispatch(setFavoriteVariantIds(orderState.favoriteVariantIds.filter(id => id !== fv.id)))
                                        }
                                        }
                                    />
                                    </div> : <></>
                            }
                        )}</div>
                    </div>
                </div>
            }

            <div className="order-page-action-row-">


                <div className="align-self-center"><p>Mode:</p></div>

                <CustomCheckboxButton isChecked={orderMode === OrderMode.SearchMode}
                                      onChange={() => modeHandler(OrderMode.SearchMode)}
                                      label={`MODE: Search`}/>
                <CustomCheckboxButton isChecked={orderMode === OrderMode.SearchResult}
                                      onChange={() => modeHandler(OrderMode.SearchResult)}
                                      label={"MODE: Search result"}/>
                <CustomCheckboxButton isChecked={orderMode === OrderMode.BookAll}
                                      onChange={() => modeHandler(OrderMode.BookAll)}
                                      label={`MODE: Book all`}/>
                {/*<CustomCheckboxButton isChecked={orderMode === OrderMode.ChainBooking}*/}
                {/*                      onChange={() => modeHandler(OrderMode.ChainBooking)}*/}
                {/*                      label={`MODE: Book all as chain`}/>*/}

                {orderMode === OrderMode.SearchResult &&
                    <CustomCheckboxButton isChecked={orderState.favoriteVariants?.length > 0}
                                          onChange={(val: boolean) => console.log()}
                                          label={`Show favorite(${orderState.favoriteVariants.length})`}/>}


                {(orderMode === OrderMode.BookAll || orderMode === OrderMode.ChainBooking) &&
                    <MdButton children={<p style={{color: "white"}}>Update passengers</p>}
                              style={COMMON_BUTTON_STYLE} onClick={() => {
                        getAllPaxes("Ф0013808")
                    }}/>}
                {/*<MdButton children={<p style={{color: "white"}}>Consecutive bookings for each trip </p>}*/}
                {/*          style={COMMON_BUTTON_STYLE}/>*/}
            </div>


            {orderMode === OrderMode.BookAll &&
                <>
                    <SelectPaxFullComponent allPaxes={orderState.paxes} selectedPax={[]}
                                            updateSelectedPaxes={setSelectedPaxes}/>
                    <div className="order-page-favorite-container">
                        <div className="order-page-favorite-items">

                            {tripOrders && <div
                                className="d-flex flex-column gap-2 mt-2 z-0">{tripOrders.map((to, index) => {
                                    return to !== undefined &&
                                        <div className="order-page-favorite-item"><TravelVariantComponent //index={index}
                                            travelVariant={to.travelVariant}
                                            isFavorite={true}
                                            //selectTariff={0}
                                            isBookingMode={true}
                                            openBookWindow={bookHandler}
                                            selectedTariff={to.selectedTariff}
                                            selectTariff={(index: number) => selectTariffHandler(to.id, index)}
                                            removeFromFavorite={() => {
                                                dispatch(setFavoriteVariants(orderState.favoriteVariants.filter(trip => trip.id !== to.travelVariant.id)));
                                                dispatch(setFavoriteVariantIds(orderState.favoriteVariantIds.filter(id => id !== to.travelVariant.id)))
                                            }
                                            }
                                        />
                                            <p>{to.selectedTariff}</p>
                                        </div>
                                }
                            )}

                            </div>}
                        </div>
                    </div>
                    <div className="select-pax-row-item">
                        <div typeof="error"><ErrorMessage error={errorMsg}/></div>
                        <div typeof="book-all-action">
                            <MdButton style={COMMON_BUTTON_STYLE} children={<p>Book</p>} onClick={()=>bookAllhandler()}/>
                        </div>
                    </div>
                </>}

            {(orderMode === OrderMode.SearchMode ||orderMode === OrderMode.Searching) &&
                <SearchComponent
                    errors={[]}
                    runSearch={searchHandler}
                    directions={orderState.orderedMultiRoad}
                    //addDirection={addDirectionsToMultiRoad}
                    //removeLastDirection={removeLastDirectionOffMultiRoad}
                    //updateDirection={updateMultiRoadDirection}
                    //isSearching={isSearching}
                    isResultMode={isResultMode}
                    //setIsResultMode={setIsResultMode}
                    hasPreviouslyResult={orderState.travelVariants.length > 0}
                    goToPreviouslyResult={() => {
                        if (orderState.travelVariants.length > 0) setIsResultMode(true)
                    }}
                />}

            {orderMode === OrderMode.Searching && <div style={{alignSelf:"center"}}><LoadingComponent message={"ПОИСК БИЛЕТОВ..."}/></div>}

            {orderMode === OrderMode.SearchResult && <div className="d-flex flex-row gap-2">
                <div className="order-document-filter-container">
                    <FilterResultComponent isDefaultState={false}
                                           travelVariants={orderState.travelVariants}
                                           filteredTravelVariants={orderState.filteredVariants}
                                           directions={orderState.isOneWay ? orderState.orderedMultiRoad : orderState.orderedMultiRoad}
                                           filterOptions={orderState.filterOptions}
                    />
                </div>
                <div className="overflow-auto"
                    // style={{height:"500px"}}
                >
                    <div className="overflow-auto d-block">
                    </div>
                    <SearchResultComponent selectedVariants={[]}
                                           filteredVariants={orderState.filteredVariants}
                                           openBookDialog={bookHandler}
                                           favoriteVariants={orderState.favoriteVariantIds}

                    />
                </div>
            </div>}

            {/*{isBookAllFavorite && <p>Mode BookAll</p>}*/}
            {isVisibleBookAllAsChain && <p>Mode BookAll As Chain</p>}

            {/*</div>}*/}


        </div>
    );
}
