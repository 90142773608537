import {useEffect, useState} from "react";
import {DateService} from "../../../services/DateService";
import {SearchIcon} from "../../../icons/SearchIcon";
import {useTranslation} from "react-i18next";
import {MdButton} from "../../../components/md-button";
import {MdInputDate} from "../../../components/md-input-date";
import {CHILDREN_BUTTON_STYLE, COMMON_BUTTON_STYLE} from "../../../constants/componentsContants";
import {ErrorMessage} from "../ErrorComponents/errorMessage/ErrorMessage";

interface SearchByDateProps {
    startDate?: Date;
    endDate?: Date;
    //string for datePicker
    runSearch: (startDate: string, endDate: string) => void;
}

export function SearchByDateComponent(props: SearchByDateProps) {
    //services
    const {t} = useTranslation();
    //const {dateToStringForDatePicker} = DateService();
    //hooks
    const [startDate, setStartDate] = useState(props.startDate === undefined ? "" : props.startDate.toISOString());
    const [endDate, setEndDate] = useState(props.endDate === undefined ? new Date().toISOString() : props.endDate.toISOString());
    const [errorMsg, setErrorMsg] = useState<string>("");

    const clickHandler = () => {

       // setError(null);
        if (startDate === "" || endDate === "") {
            setErrorMsg(t("errorMsg.inputDateError"))
        }else {
            props.runSearch(startDate, endDate);
        }
    };

    useEffect(() => {
        console.log(`UseEffect searchDateHAndler`);
        setErrorMsg("");

    }, [endDate, startDate]);

    return (
        <>
            <div>&nbsp;{t("commonText.from") + ":"}&nbsp; <MdInputDate inputDateISO={startDate}
                                                                       setDateHandler={setStartDate}/></div>
            <div> &nbsp;{t("commonText.to") + ":"}&nbsp; <MdInputDate inputDateISO={endDate}
                                                                      setDateHandler={setEndDate}/></div>
            <MdButton style={COMMON_BUTTON_STYLE} onClick={clickHandler}
                      children={<div style={CHILDREN_BUTTON_STYLE}>
                          <SearchIcon iconSize={'20px'}/> {t("commonText.search")}
                      </div>}/>
            {errorMsg && <ErrorMessage error={errorMsg}/>}


            {/*<div className="filters-form">*/}

            {/*  <div>*/}
            {/*<span className=" input-group" style={{backgroundColor: "white"}}>*/}
            {/*  <label className="sr-only  align-self-lg-center">*/}
            {/*    &nbsp;{t("commonText.from") + ":"}&nbsp;*/}
            {/*  </label>*/}
            {/*  <div className="form-group">*/}
            {/*  <input*/}
            {/*      className="form-control"*/}
            {/*      type="date" //value="option1"*/}
            {/*      //placeholder="Clients name"*/}
            {/*      value={startDate}*/}
            {/*      onChange={(e) => setStartDate(e.target.value)}*/}
            {/*  />*/}
            {/*    </div>*/}
            {/*</span>*/}

            {/*  </div>*/}
            {/*  <div>*/}
            {/*<span className=" input-group" style={{backgroundColor: "white"}}>*/}
            {/*  <label className="sr-only  align-self-lg-center">*/}
            {/*    &nbsp;{t("commonText.to") + ":"}&nbsp;*/}
            {/*  </label>*/}
            {/*  <div className="form-group">*/}
            {/*  <input*/}
            {/*      className="form-control"*/}
            {/*      type="date" //value="option1"*/}
            {/*      //placeholder="Clients name"*/}
            {/*      value={endDate}*/}
            {/*      onChange={(e) => setEndDate(e.target.value)}*/}
            {/*  />*/}
            {/*    </div>*/}
            {/*</span>*/}
            {/*  </div>*/}
            {/*  <div className=" me-1 z-0">*/}
            {/*  <span className="input-group">*/}
            {/*    <MdButton*/}
            {/*        style={{*/}
            {/*          "button": {*/}
            {/*            borderWidth: 0,*/}
            {/*            backgroundColor: "#3E5CB8",*/}
            {/*            color: "#FFFFFF",*/}
            {/*            borderRadius: "6px",*/}
            {/*            height: "35px",*/}
            {/*            padding: "5px 15px"*/}
            {/*          },*/}
            {/*          "ink": {*/}
            {/*            backgroundColor: "rgba(255,255,255,.5)"*/}
            {/*          }*/}
            {/*        }}*/}
            {/*        onClick={clickHandler}*/}
            {/*    >*/}
            {/*        {t("documentPage.search")}*/}
            {/*    </MdButton>*/}
            {/*  </span>*/}
            {/*  </div>*/}

            {/*  /!*  <span className=" input-group-text p-0 border-white  ">*!/*/}
            {/*  /!*  <button className="btn btn-outline-secondary " type="button" onClick={clickHandler}>*!/*/}
            {/*  /!*    <SearchIcon/> {t("commonText.search")}*!/*/}
            {/*  /!*  </button>*!/*/}
            {/*  /!*</span>*!/*/}

            {/*</div>*/}
        </>

    );
}
