import React, {
    useEffect,
    useRef,
    useState
} from "react";
import {useAppSelector} from "../../redux/hooks";
import {BASE_URL, BEARER, ROUTE_CHAT_HUB} from "../../constants/routeConstants/ApiRouteConstants";
import {axiosInstance} from "../../api/AxiosInstance";
import {HubConnection} from "@microsoft/signalr";
import {SignalRService} from "../../services/SignalRService";
import {RoomListComponent} from "./roomList";
import {RoomComponent} from "./room";
import {CircleSpinnerIcon} from "../../icons/CircleSpinnerIcon";
import './style.scss'


enum LayoutType {
    List,
    Room,
}


export interface IChatUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    Device: string;
}

export interface IChatRoom {
    id: string;
    icon?: string;
    name: string;
    description: string;
    createdAt: string;
    users: IChatUser[];
    messages: IChatMessage[];
}

export interface IChatMessage {
    id: string,
    roomId: string;
    userId: string;
    content: string;
    createdAt: string;
    read: boolean;
}


interface IChatComponent{
    closeHandler?: (e: React.MouseEvent) => void;
}


export function ChatComponent(props: IChatComponent) {

    const {
        closeHandler
    } = props;

    const authState = useAppSelector(state => state.auth);

    const root = useRef(null);


    const [token, setToken] = useState<string>(authState.accessToken);

    const [signalRService] = useState<SignalRService>(new SignalRService());

    const [connection, setConnection] = useState<HubConnection | null>(null);
    const [reconnection, setReconnection] = useState<boolean>(false);

    const [activeLayout, setActiveLayout] = useState<LayoutType>(LayoutType.List);

    const [rooms, setRooms] = useState<IChatRoom[]>([]);
    const [activeRoomId, setActiveRoomId] = useState<string>("");
    const [activeRoom, setActiveRoom] = useState<IChatRoom | undefined>(undefined);

    const [roomCreating, setRoomCreating] = useState<boolean>(false);

    const [supportConnecting, setSupportConnecting] = useState<boolean>(false);

    const [loadMessagesMap, setLoadMessagesMap] = useState<Map<string, boolean>>(new Map());
    const [messagesMap, setMessagesMap] = useState<Map<string, IChatMessage[]>>(new Map());



    const createChatConnection = async () => {
        const conn = await signalRService.createConnection(
            BASE_URL + ROUTE_CHAT_HUB,
            {
                accessTokenFactory: () => authState.accessToken,
                withCredentials: true
            }
        );
        setConnection(conn);
    }

    const startConnection = async () => {
        const connected = await signalRService.startConnection();
        if(connected) {
            bindSignalREvents();
            await receiveRoomsHandler();
        }
    }

    const stopConnection = async () => {
        const result = await signalRService.stopConnection();
        if(result) {
            unbindSignalREvents();
        }
    }


    const bindSignalREvents = () => {
        console.warn('[SignalR] bind events...');

        signalRService.addEventListener('userConnected', signalRUserConnected);

        signalRService.addEventListener('userDisconnected', signalRUserDisconnected);

        signalRService.addEventListener('messageSent', signalRMessageSent);

        signalRService.addEventListener('userJoined', signalRUserJoined);

        signalRService.addEventListener('userLeft', signalRUserLeft);
    }

    const unbindSignalREvents = () => {
        console.warn('[SignalR] unbind events...');

        signalRService.removeEventListener('userConnected', signalRUserConnected);

        signalRService.removeEventListener('userDisconnected', signalRUserDisconnected);

        signalRService.removeEventListener('messageSent', signalRMessageSent);

        signalRService.removeEventListener('userJoined', signalRUserJoined);

        signalRService.removeEventListener('userLeft', signalRUserLeft);
    }



    // api requests
    const apiGetAllRooms = async () => {
        console.log('[Request] /api/v1/webauth/room/getUserChatRooms');
        try {
            const result = await axiosInstance.get<IChatRoom[]>(
                "/api/v1/webauth/room/getUserChatRooms", {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true
                }
            );
            console.warn('[Response] /api/v1/webauth/room/getUserChatRooms', result);
            return result.data.length > 0 ? result.data : [];
        } catch (ex) {
            console.error('[Response] /api/v1/webauth/room/getUserChatRooms', ex);
        }
        return [];
    };

    const apiCreateRoom = async () => {
        console.log('[Request] /api/v1/webauth/room/create');
        try {
            const result = await axiosInstance.post<IChatRoom>(
                "/api/v1/webauth/room/createRoom",
                {
                    name: "Новый чат",
                    description: "Описание чата"
                },
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true,
                },
            );
            console.warn('[Response] /api/v1/webauth/room/create', result);
            return result.data;
        } catch (ex) {
            console.error('[Response] /api/v1/webauth/room/create', ex);
        }
        return null;
    }

    const apiGetRoomById = async (roomId: string) => {
        console.log('[Request] /api/v1/webauth/room/getRoom?roomId=' + roomId);
        try {
            const result = await axiosInstance.get<IChatRoom>(
                "/api/v1/webauth/room/getRoom", {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    params: {
                        roomId: roomId,
                    },
                    withCredentials: true
                }
            );
            console.warn('[Response] /api/v1/webauth/room/getRoom?roomId=' + roomId, result);
            return result.data;
        } catch (ex) {
            console.error('[Response] /api/v1/webauth/room/getRoom?roomId=' + roomId, ex);
        }
        return null;
    };

    const apiGetRoomMessages = async (roomId: string) => {
        console.log('[Request] /api/v1/webauth/message/getMessagesByRoomId?roomId=' + roomId);
        try {
            const result = await axiosInstance.get<IChatMessage[]>(
                "/api/v1/webauth/message/getMessagesByRoomId",
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    params: {
                        roomId: roomId
                    },
                    withCredentials: true,
                },
            );
            console.warn('[Response] /api/v1/webauth/message/getMessagesByRoomId?roomId=' + roomId, result);
            return result.data.length > 0 ? result.data : [];
        } catch (ex) {
            console.error('[Response] /api/v1/webauth/message/getMessagesByRoomId?roomId=' + roomId, ex);
        }
        return [];
    };

    const apiSendMessage = async (roomId: string, message: string) => {
        console.log('[Request] api/message/send', {roomId: roomId, content: message});
        try {
            const result = await axiosInstance.post(
                "/api/v1/webauth/message/send",
                {
                    roomId: roomId,
                    content: message,
                },
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                    },
                    withCredentials: true,
                },
            );
            console.warn('[Response] /api/v1/webauth/message/send', result);
            return result.data;
        } catch (ex) {
            console.error('[Response] /api/v1/webauth/message/send', ex);
        }
        return null;
    }

    const apiConnectSupportUser = async (roomId: string) => {
        console.log('[Request] api/room/connectionSupportInRoom', {roomId: roomId});
        try {
            const result = await axiosInstance.put(
                "/api/v1/webauth/room/connectionSupportInRoom",
                roomId,
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                },
            );
            console.warn('[Response] /api/v1/webauth/room/connectionSupportInRoom', result);
            return result.data;
        } catch (ex) {
            console.error('[Response] /api/v1/webauth/room/connectionSupportInRoom', ex);
        }
        return null;
    }

    const apiDisconnectSupportUser = async (roomId: string) => {
        console.log('[Request] api/room/disconnectionSupportFromRoom', {roomId: roomId});
        try {
            const result = await axiosInstance.put(
                "/api/v1/webauth/room/disconnectionSupportFromRoom",
                {
                    roomId: roomId,
                    supportId: authState.userId
                },
                {
                    headers: {
                        Authorization: BEARER + authState.accessToken,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                },
            );
            console.warn('[Response] /api/v1/webauth/room/disconnectionSupportFromRoom', result);
            return result.data;
        } catch (ex) {
            console.error('[Response] /api/v1/webauth/room/disconnectionSupportFromRoom', ex);
        }
        return null;
    }



    // callbacks from signalR
    const signalRUserConnected = async (user: IChatUser) => {
        console.warn('[SignalR message] userConnected', user);
    };

    const signalRUserDisconnected = async (user: IChatUser) => {
        console.warn('[SignalR message] userDisconnected', user);
        setMessagesMap(map => new Map());
        setRooms([]);
    };

    const signalRUserJoined = async (roomId: string) => {
        console.warn('[SignalR message] userJoined', roomId);
        await updateRoomHandler(roomId);
    };

    const signalRUserLeft = async (roomId: string) => {
        console.warn('[SignalR message] userLeft', roomId);
        await updateRoomHandler(roomId);
    }

    const signalRMessageSent = async (roomId: string, message: IChatMessage) => {
        console.warn('[SignalR message] messageSent', roomId, message);
        if (message != null) {
            setMessagesMap(map => new Map(map.set(roomId, [...(map.get(roomId) ?? []), message])));
        }
        await updateRoomHandler(roomId);
    };



    // handlers
    const receiveRoomsHandler = async () => {
        // setRoomsReceiving(true);
        const rooms = await apiGetAllRooms();
        setRooms(rooms);
        // setRoomsReceiving(false);
    }

    const createRoomHandler = async () => {
        setRoomCreating(true);
        const room = await apiCreateRoom();
        if (room != null) {
            setRooms(prev => [...prev.filter(x => x.id != room.id), room]);
            await selectRoomHandler(room.id);
        }
        setRoomCreating(false);
    }

    const updateRoomHandler = async (roomId: string) => {
        // setRoomUpdating(true);
        const room = await apiGetRoomById(roomId);
        if(room != null) {
            setRooms(prev => [...(prev.length > 0 ? prev.map(r => r.id == room.id ? room : r) : [room])]);
        }
        // setRoomUpdating(false);
    }

    const selectRoomHandler = async (roomId: string) => {
        if (roomId) {
            setActiveRoomId(roomId);
            setActiveLayout(LayoutType.Room);

            setLoadMessagesMap(map => new Map(map.set(roomId, true)));
            setMessagesMap(map => new Map());

            const messages = await apiGetRoomMessages(roomId);

            setMessagesMap(map => new Map(map.set(roomId, messages)));
            setLoadMessagesMap(map => new Map(map.set(roomId, false)));
        }
    }

    const connectSupportUserHandler = async (roomId: string) => {
        setSupportConnecting(true);
        await apiConnectSupportUser(roomId);
        setSupportConnecting(false);
    }

    const disconnectSupportUserHandler = async (roomId: string) => {
        await apiDisconnectSupportUser(roomId);
    }

    const sendMessageHandler = async (chatId: string, message: string) => {
        // TODO: add message to list and set pending state, then remove it in signalRMessageSent callback
        await apiSendMessage(chatId, message.trimEnd());
    }

    const backToChatListHandler = () => {
        setActiveLayout(0);
    }

    // const onUserStartPrintingHandler = (roomId: string) => {
    //     if (!activeRoom?.printingUsers.some(x => x.email == authState.email)) {
    //         try {
    //             console.warn(authState.email, connection?.connectionId);
    //             connection?.invoke("StartPrinting", chatId, authState.email, connection.connectionId);
    //         } catch (ex) {
    //             console.error(ex);
    //         }
    //     }
    // }

    // const onUserEndPrintingHandler = (roomId: string) => {
    //     if (activeRoom?.printingUsers.some(x => x.email == authState.email)) {
    //         try {
    //             connection?.invoke("EndPrinting", chatId, authState.email, connection.connectionId);
    //         } catch (ex) {
    //             console.error(ex);
    //         }
    //     }
    // }



    useEffect(() => {
        createChatConnection();
    }, []);

    useEffect(() => {
        if(connection) {
            startConnection();
        }
        return () => {
            stopConnection();
        }
    }, [connection]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (authState.isValidUser) {
                // get token expiration date
                let tokenExpirationDate = new Date(authState.expiredAt * 1000);
                // get token lifetime
                let tokenLifetime = ((tokenExpirationDate.getTime() - new Date().getTime()));
                // set tome before token update
                let timeBeforeUpdate = tokenLifetime - 60 * 1000;
                // set reconnect 5 seconds before token refresh
                if (timeBeforeUpdate < 5000) {
                    setReconnection(true);
                } else {
                    setReconnection(false);
                }
            }
        }, 2500);
        // clear interval
        return () => clearInterval(timer);
    });

    useEffect(() => {
        if(authState.isTokenBeingRefreshed) {
            stopConnection();
        }
    }, [authState.isTokenBeingRefreshed]);


    useEffect(() => {
        if(token != authState.accessToken) {
            setToken(authState.accessToken);
            startConnection();
        }
    }, [authState.accessToken]);

    useEffect(() => {
        setActiveRoom(rooms.find(x => x.id == activeRoomId));
    }, [rooms, activeRoomId]);


    return (
        <div ref={root}
             className="chat">

            <div className="chat-slider-container">
                <div className="chat-slider-slide" data-type={activeLayout == LayoutType.List ? "active" : "previous"}>
                    <RoomListComponent
                        rooms={rooms}
                        roomCreating={roomCreating}
                        createRoomHandler={createRoomHandler}
                        selectRoomHandler={selectRoomHandler}
                        closeHandler={closeHandler}
                    />
                </div>
                <div className="chat-slider-slide" data-type={activeLayout == LayoutType.Room ? "active" : ""}>
                    <RoomComponent
                        room={activeRoom}
                        messages={(messagesMap.get(activeRoomId) ?? [])}
                        messagesLoading={(loadMessagesMap.get(activeRoomId) ?? false)}
                        supportConnecting={supportConnecting}
                        //onUserStartPrintingHandler={onUserStartPrintingHandler}
                        //onUserEndPrintingHandler={onUserEndPrintingHandler}
                        backToChatListHandler={backToChatListHandler}
                        connectSupportUserHandler={connectSupportUserHandler}
                        disconnectSupportUserHandler={disconnectSupportUserHandler}
                        closeHandler={closeHandler}
                        sendMessageHandler={sendMessageHandler}
                    />
                </div>
            </div>

            {
                reconnection && (
                    <div className="reconnecting-layer">
                        <div className="reconnecting-layer-message">
                            <CircleSpinnerIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "svg": {
                                        margin: "2px"
                                    },
                                    "circle": {
                                        stroke: "#999999"
                                    }
                                }}
                            />
                            <span>Подключение..</span>
                        </div>
                    </div>
                )
            }

        </div>
    )
}