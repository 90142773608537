import React from "react";
import ReactDOM from "react-dom/client";
import "./App.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { CookiesProvider } from "react-cookie";
import { ModalState } from "./pages/commonComponents/modal/ModalContext";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ModalState>
        <React.StrictMode>
          <CookiesProvider defaultSetOptions={{ path: "/" }}>
          {/* PersistGate delays the rendering of UI until the persisted state has been retrrieved and saved to redux */}
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
          </CookiesProvider>
        </React.StrictMode>
      </ModalState>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
