import React, {useContext, useEffect, useRef, useState} from "react";
import { useAppSelector} from "../../../redux/hooks";
import {UserRoles} from "../../../enums/UserRole";
import {ICustomerEmployee} from "../../../interfaces/reportInterfaces/customerInterfaces";
import {
  IAddCustomerEmployeeRequest,
  ICompanyRole,
  ICompanyUserRoles
} from "../../../interfaces/requestsInterfaces/reportRequests";
import {AuthService} from "../../../services/AuthService";
import {EmployeesService} from "../../../services/EmployeesService";
import {EmployeeEditComponent} from "./employeeEditComponent";
import {EmployeeTableComponent} from "./employeesTableComponent";
import {MdModal} from "../../../components/md-modal";
import {EmployeeAddComponent} from "./employeeAddComponent";
import {ModalContext} from "../../commonComponents/modal/ModalContext";
import {CompanySelectionModal} from "../../commonComponents/companySelectionModal";
import {Guid} from "../../../utils/utils";
import {ICompany} from "../../../interfaces/companyInfoInterfaces/ICompany";
import {useTranslation} from "react-i18next";


export function EmployeesPage() {

  const {t} = useTranslation()

  const authState = useAppSelector((state) => state.auth);

  const employeesService = EmployeesService();

  const authService = AuthService();

  const {open, close} = useContext(ModalContext);

  const [addEmployeeModalId] = useState(Guid.newGuid());
  const [editEmployeeModalId] = useState(Guid.newGuid());

  const [employeeList, setEmployeeList] = useState<ICustomerEmployee[]>();
  const [employeeListPending, setEmployeeListPending] = useState(false);
  const [isSearchResultNotFound, setIsSearchResultNotFound] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<ICustomerEmployee[]>([]);

  const [addEmployeePending, setAddEmployeePending] = useState(false);
  const [addEmployeeError, setAddEmployeeError] = useState(false);

  const [employeeToEdit, setEmployeeToEdit] = useState<ICustomerEmployee>();
  const [editEmployeePending, setEditEmployeePending] = useState(false);
  const [editEmployeeError, setEditEmployeeError] = useState(false);

  const [companySelectionModalOpened, setCompanySelectionModalOpened] = useState<boolean>(false);

  const [companyRoles, setCompanyRoles] = useState<ICompanyUserRoles[]>([]);



  const loadEmployees = async () => {
    setEmployeeListPending(true);
    setEmployeeList([]);
    setSelectedEmployees([]);
    const response = await employeesService.getEmployees(authState.currentClientCode);
    if (response.length > 0) {
      setEmployeeList(response);
      setIsSearchResultNotFound(false);
    } else {
      setIsSearchResultNotFound(true);
    }
    setEmployeeListPending(false);
  }


  const openEmployeeAddModal = () => {
    open(addEmployeeModalId);
  }

  const onAddModalOpenedHandler = async () => {
    setAddEmployeeError(false);
  };

  const addEmployeeHandler = async (
      firstName: string,
      lastName: string,
      patronymic: string,
      position: string,
      roles: ICompanyRole[],
      dateOfBurn: string,
      email: string,
      gender: boolean
  ) => {
    setAddEmployeeError(false);
    setAddEmployeePending(true);
    const request = {
      firstName: firstName,
      lastName: lastName,
      patronymic: patronymic,
      position: position,
      dateOfBurn: dateOfBurn,
      email: email,
      gender: gender,
      companiesRoles: roles,
    } as IAddCustomerEmployeeRequest;
    const result = await employeesService.addEmployee(request);
    if(result != null) {
      setAddEmployeePending(false);
      closeEmployeeAddModal();
    }
    else {
      setAddEmployeePending(false);
      setAddEmployeeError(true);
      return;
    }
    await loadEmployees();
  };

  const onAddModalClosedHandler = async () => {
    setAddEmployeeError(false);
  };

  const closeEmployeeAddModal = () => {
    close(addEmployeeModalId);
  }



  const requestEditEmployee = async (employee: ICustomerEmployee) => {
    setCompanyRoles([]);

    setEmployeeToEdit(employee);

    let roles = await employeesService.getEmployeeRoles(employee.email);

    setCompanyRoles(roles);

    openEmployeeEditModal();
  }

  const openEmployeeEditModal = () => {
    open(editEmployeeModalId);
  }

  const onEditModalOpenedHandler = async () => {
    setEditEmployeeError(false);
  };

  const editEmployeeHandler = async (
      firstName: string,
      lastName: string,
      patronymic: string,
      position: string,
      roles: ICompanyRole[],
      dateOfBurn: string,
      email: string,
      gender: boolean
  ) => {
    setEditEmployeeError(false);
    setEditEmployeePending(true);
    const request = {
      firstName: firstName,
      lastName: lastName,
      patronymic: patronymic,
      position: position,
      dateOfBurn: dateOfBurn,
      email: email,
      gender: gender,
      companiesRoles: roles,
    } as IAddCustomerEmployeeRequest;
    const result = await employeesService.updateEmployee(request);
    if(result != null) {
      setEditEmployeePending(false);
      closeEmployeeEditModal();
    }
    else {
      setEditEmployeePending(false);
      setEditEmployeeError(true);
      return;
    }
    await loadEmployees();
  };

  const onEditModalClosedHandler = async () => {
    setEditEmployeeError(false);
    setEmployeeToEdit(undefined);
    setCompanyRoles([]);
  };

  const closeEmployeeEditModal = () => {
    close(editEmployeeModalId);
  }



  const openCompanySelectionModal = () => {
    setCompanySelectionModalOpened(true);
  }

  const addCompanyHandler = (company: ICompany) => {
    if(!companyRoles.some(x => x.clientCode == company.code || x.clientCode == company.code)) {
      setCompanyRoles(prev => [
          ...prev.filter(x => (x.clientCode != company.code)),
          {
            clientCode: company.code ?? company.code,
            companyFullName: company.fullName,
            roles: [UserRoles.AnonymousUser]
          }
        ]
      );
    }
  }

  const removeCompanyHandler = (companyCode: string) => {
    if(companyRoles.some(x => x.clientCode == companyCode)) {
      setCompanyRoles(prev => prev.filter(x => x.clientCode != companyCode));
    }
  }



  const addCompanyRoleHandler = (companyCode: string, companyName: string, roleName: string) => {
    setCompanyRoles(prev => [
      ...prev.map(company => {
        return {
          clientCode: company.clientCode,
          companyFullName: company.companyFullName,
          roles: (company.clientCode == companyCode ? [...company.roles.filter(role => role != roleName), roleName] : company.roles)
        }
      })
    ]);

  }

  const removeCompanyRoleHandler = (companyCode: string, companyName: string, roleName: string) => {
    setCompanyRoles(prev => [
      ...prev.map(company => {
        return {
          clientCode: company.clientCode,
          companyFullName: company.companyFullName,
          roles: (company.clientCode == companyCode ? company.roles.filter(role => role != roleName) : company.roles)
        }
      })
    ]);
  }


  useEffect(() => {
    loadEmployees();
  }, []);

  useEffect(() => {
    loadEmployees();
  }, [authState.currentClientCode]);

  useEffect(() => { }, []);


  return (
      <>
        {/*<EmployeeSearchComponent*/}
        {/*    code={code}*/}
        {/*    setCode={setCode}*/}
        {/*    searchHandler={searchEmployeesHandler}*/}
        {/*/>*/}

        <EmployeeTableComponent
            employees={employeeList}
            isLoading={employeeListPending}
            isSearchResultNotFound={isSearchResultNotFound}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            // selectEmployee={selectEmployee}
            // unselectEmployee={unselectEmployee}
            // selectAllEmployees={selectAllEmployees}
            // unselectAllEmployees={unselectAllEmployees}
            loadEmployees={loadEmployees}
            openEmployeeAddModal={openEmployeeAddModal}
            requestEditEmployee={requestEditEmployee}
        />

        <MdModal
            id={addEmployeeModalId}
            title={t("employeePage.newEmployee")}
            openHandler={onAddModalOpenedHandler}
            closeHandler={onAddModalClosedHandler}>
            <EmployeeAddComponent
                pending={addEmployeePending}
                error={addEmployeeError}
                //companies={companies}
                companyRoles={companyRoles}
                addCompanyRoleHandler={addCompanyRoleHandler}
                removeCompanyHandler={removeCompanyHandler}
                removeCompanyRoleHandler={removeCompanyRoleHandler}
                openCompanySelectionModal={openCompanySelectionModal}
                addHandler={addEmployeeHandler}
            />
        </MdModal>

        {
          employeeToEdit != null && (
                <MdModal
                    id={editEmployeeModalId}
                    title={t("employeePage.editEmployee")}
                    openHandler={onEditModalOpenedHandler}
                    closeHandler={onEditModalClosedHandler}>
                  <EmployeeEditComponent
                      employee={employeeToEdit}
                      pending={editEmployeePending}
                      error={editEmployeeError}
                      //companies={companies}
                      companyRoles={companyRoles}
                      addCompanyRoleHandler={addCompanyRoleHandler}
                      removeCompanyHandler={removeCompanyHandler}
                      removeCompanyRoleHandler={removeCompanyRoleHandler}
                      openCompanySelectionModal={openCompanySelectionModal}
                      submitHandler={editEmployeeHandler}
                  />
                </MdModal>
            )
        }

        <CompanySelectionModal
            opened={companySelectionModalOpened}
            setOpened={setCompanySelectionModalOpened}
            submitHandler={addCompanyHandler}
        />

      </>
  );
}
