import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../constants/componentsContants";

export function SeatIcon({iconSize}:IIconProps) {
    iconSize = iconSize??DEFAULT_ICON_SIZE;
    return (<>



        <svg width={iconSize} height={iconSize}
             viewBox="0 0 64 64"
             aria-hidden="true" role="img"
             className="iconify iconify--emojione"
             preserveAspectRatio="xMidYMid meet"
        >

            <path d="M54.5 32c.2 2.2-1.3 4-3.4 4H12.9c-2.1 0-3.6-1.8-3.4-4l2.1-22.2c.2-2.2 2.1-4 4.2-4h32.4c2.1 0 4 1.8 4.2 4L54.5 32" fill="#b4d7ee">

            </path>

            <g fill="#3e4347">

                <path d="M8.9 30.5h6v8h-6z">

                </path>

                <path d="M49.1 30.5h6v8h-6z">

                </path>

                <path d="M14.6 50.9h6V61h-6z">

                </path>

                <path d="M43.4 50.9h6V61h-6z">

                </path>

            </g>

            <path d="M41.4 25c-.3 2.6-2.4 4.8-4.5 4.8h-9.8c-2.2 0-4.2-2.1-4.5-4.8l-1.8-14.6c-.3-2.6 1.2-4.8 3.3-4.8h15.7c2.2 0 3.7 2.2 3.3 4.8L41.4 25" fill="#428bc1">

            </path>

            <g fill="#94989b">

                <path d="M23 58.9c-.2-.5-.8-1-1.3-1h-8c-.6 0-1.1.4-1.3 1L10.9 63c-.2.5.1 1 .7 1h12c.6 0 .9-.4.7-1L23 58.9">

                </path>

                <path d="M53.1 63l-1.4-4.1c-.2-.5-.8-1-1.3-1h-8c-.6 0-1.1.4-1.3 1L39.7 63c-.2.5.1 1 .7 1h12c.5 0 .8-.4.7-1">

                </path>

            </g>

            <g fill="#29abe2">

                <path d="M46.2 6c0 2.2-1.7 4-3.8 4H21.5c-2.1 0-3.8-1.8-3.8-4V4c0-2.2 1.7-4 3.8-4h20.9c2.1 0 3.8 1.8 3.8 4v2">

                </path>

                <path d="M15.5 29.2l-.3-2.1c-.2-1.1-1.1-2-2.1-2h-2.7c-1 0-1.9.9-2.1 2L8 29.2c-.2 1.1.5 2 1.5 2H14c1 0 1.7-.9 1.5-2">

                </path>

                <path d="M55.8 29.2l-.3-2.1c-.2-1.1-1.1-2-2.1-2h-2.7c-1 0-1.9.9-2.1 2l-.3 2.1c-.2 1.1.5 2 1.5 2h4.5c1 0 1.6-.9 1.5-2">

                </path>

                <path d="M51.5 36h-39c-3.1 0-5 3-4.4 6.7l.4 2.6c.6 3.7 3.7 6.7 6.7 6.7h33.4c3.1 0 6.1-3 6.7-6.7l.5-2.6c.7-3.6-1.3-6.7-4.3-6.7">

                </path>

            </g>

        </svg>
    </>)
}