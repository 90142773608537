import Chart from "react-google-charts";
import { StatisticChartComponent } from "../statisticChartComponet/StatisticChartComponent";
import { IClientStructure } from "../../../../interfaces/companyInfoInterfaces/IClientStructure";
import {
  AVIA_SERVICE_RESPONSE,
  HOTEL_SERVICE_RESPONSE,
  TRAIN_SERVICE_RESPONSE,
  TRANSFERL_SERVICE_RESPONSE,
} from "../../../../constants/ServiceCostants";
import {useTranslation} from "react-i18next";

type AllStatisticProps = {
  clientSales?: IClientStructure[];
  clientExchnge?: IClientStructure[];
  clientRefound?: IClientStructure[];
};
export function AllStatisticComponent(props: AllStatisticProps) {
  const { t } = useTranslation();
  const wight = "100%";
  const height = "250px";
  //
  return (
    <>
      <div className="row justify-content-around gx-0 "
          // style={{ backgroundColor: "#EEFFE3" }}
      >
        <div className="col-3">
          <StatisticChartComponent
            chartTitle={`${t("statisticPage.sales")}`}
            dataTitle={"zxcv"}
            moneyCode={"RUB"}
            aviaValue={
              props.clientSales?.find((item) => item.serviceKind === AVIA_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
            trainValue={
              props.clientSales?.find((item) => item.serviceKind === TRAIN_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
            hotelValue={
              props.clientSales?.find((item) => item.serviceKind === HOTEL_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
            transferValue={
              props.clientSales?.find((item) => item.serviceKind === TRANSFERL_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
          />
        </div>
        <div className="col-3">
          {" "}
          <StatisticChartComponent
            chartTitle={`${t("statisticPage.exchange")}`}
            dataTitle={"somesu=ing"}
            moneyCode={"RUB"}
            aviaValue={
              props.clientExchnge?.find((item) => item.serviceKind === AVIA_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
            trainValue={
              props.clientExchnge?.find((item) => item.serviceKind === TRAIN_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
            hotelValue={
              props.clientExchnge?.find((item) => item.serviceKind === HOTEL_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
            transferValue={
              props.clientExchnge?.find((item) => item.serviceKind === TRANSFERL_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            }
          />
        </div>
        <div className="col-3">
          {" "}
          <StatisticChartComponent
            chartTitle={`${t("statisticPage.refund")}`}
            dataTitle={"somesu=ing"}
            moneyCode={"RUB"}
            aviaValue={Math.abs(
              props.clientRefound?.find((item) => item.serviceKind === AVIA_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            )}
            trainValue={Math.abs(
              props.clientRefound?.find((item) => item.serviceKind === TRAIN_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            )}
            hotelValue={Math.abs(
              props.clientRefound?.find((item) => item.serviceKind === HOTEL_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            )}
            transferValue={Math.abs(
              props.clientRefound?.find((item) => item.serviceKind === TRANSFERL_SERVICE_RESPONSE)
                ?.totalSum ?? 0
            )}
          />
        </div>
      </div>
    </>
  );
}
