import {IReadyToDownloadDoc} from "../../../../interfaces/reportInterfaces/accountantPageInterfaces";
import {useTranslation} from "react-i18next";
import {MdButton} from "../../../../components/md-button";
import {PdfIcon2} from "../../../../icons/PdfIcon2";
import "./style.scss";
import {IAccountantDocument} from "../../../../interfaces/reportInterfaces/customerInterfaces";


type DocumentFilesProps = {
    activBuhDoc?: IAccountantDocument;
    availableDocs?: IReadyToDownloadDoc[];
    downloadPDFDocumentHandler: (link: string) => void;
};


export function DocumentFilesComponent(props: DocumentFilesProps) {

    const {t} = useTranslation();

    if (props.availableDocs != null && props.availableDocs.length > 0) {
        return (
            <div className="document-pdf-list">
                {
                    props.availableDocs.map((doc, i) => {
                        let fileName = doc.docName.substr(0, doc.docName.lastIndexOf('.')); //path.parse(doc.docName).name;
                        let fileExt = doc.docName.substr(doc.docName.lastIndexOf('.') + 1); //path.parse(doc.docName).ext;

                        let trimmedName = fileName.length > 20 ? fileName.slice(0, 20 - 1) + "..." : fileName;
                        return (
                            <MdButton
                                key={i}
                                icon={
                                    <PdfIcon2 width="12px" height="12px"/>
                                }
                                style={{
                                    "button": {
                                        borderWidth: 0,
                                        backgroundColor: "rgb(255 255 255)",
                                        color: "#000000",
                                        borderRadius: "6px",
                                        maxWidth: "300px",
                                        height: "auto",
                                        padding: "5px",
                                        boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.06), 0px 1px 10px 0px rgba(0, 0, 0, 0.04)"
                                    },
                                    "icon": {
                                        marginLeft: "5px"
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(240,240,240,.5)"
                                    }
                                }}
                                onClick={() => props.downloadPDFDocumentHandler(doc.docLink)}
                            >
                                {
                                    (trimmedName + "." + fileExt)
                                }
                            </MdButton>
                        )
                    })
                }
            </div>
        );
    } else {
        return (
            <div className="document-pdf-list">
                {t("commonText.noData")}
            </div>
        );
    }

}
