import {IDirectionRequest} from "../../../../../interfaces/requestsInterfaces/IDirectionRequest";
import {MdButton} from "../../../../../components/md-button";

type CompactSearchStatusProps = {
    setIsSearchMode?:(value:boolean)=>void
    directions: IDirectionRequest[]
}

export function CompactSearchStatusComponent({directions,setIsSearchMode}: CompactSearchStatusProps) {
    return (
        <div className="order-search-filter-row">
            {directions.map((dir,index) => (
                <div key={"compact-search-result+"+index} className="order-route-result-container">
                    {index !== 0 ? <div className="order-status-direction-arrow ">&#x2192;</div>:<></>}
                    <div className="order-status-direction" >
                        <div typeof="route">{dir.townFrom}</div>
                        <div typeof="route"> {dir.townTo}</div>
                        <div typeof="date">{new Date(dir.departDate).toDateString()}</div>
                    </div>
                    {/*<div className="order-status-direction-arrow">&#x2192;</div>*/}
                </div>
            ))}
            <div className="order-status-direction-button">
            <MdButton
                style={{
                    "button": {
                        borderWidth: 0,
                        backgroundColor: "#3E5CB8",
                        color: "#FFFFFF",
                        borderRadius: "20px",
                        height: "35px",
                        padding: "5px 15px"
                    },
                    "ink": {
                        backgroundColor: "rgba(255,255,255,.5)"
                    }
                }}
                children={"Новый маршрут"}
                onClick={() => {
                }}
            />
            </div>

        </div>)
}