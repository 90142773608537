import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../redux/hooks";
import axios, {CancelTokenSource} from "axios";
import {IAviaTariffInfo, IAviaVariant} from "../../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {DateService} from "../../../../../services/DateService";
import {TripService} from "../../../../../services/TripService";
import {MdSelect} from "../../../../../components/md-select";
import {MdButton} from "../../../../../components/md-button";
import {SwitchIcon} from "../../../../../icons/SwitchIcon";
import {RefsService} from "../../../../../services/RefsService";
import {ICompany} from "../../../../../interfaces/companyInfoInterfaces/ICompany";
import {IOrder} from "../../../../../interfaces/IOrderInfo";
import {MdMenu} from "../../../../../components/md-menu";
import {SearchFlightsResultsComponent} from "./searchFlightsResultsComponent";
import {MdToggleButton} from "../../../../../components/md-toggle-button";
import {MinusIcon} from "../../../../../icons/MinusIcon";
import {PlusIcon} from "../../../../../icons/PlusIcon";
import {SunriseIcon} from "../../../../../icons/SunriseIcon";
import {SunIcon} from "../../../../../icons/SunIcon";
import {MoonIcon} from "../../../../../icons/MoonIcon";
import {Guid, Utils} from "../../../../../utils/utils";
import "./style.scss";


export enum TimeOfDay {
    Morning   = 0,
    Afternoon = 1,
    Evening   = 2
}

export enum FlightClass {
    None     = 0,      // 0
    Economy  = 1 << 0, // 1
    Business = 1 << 1, // 2
    All = Economy | Business  // 3
}

export enum BaggageAllowance {
    None           = 0,      // 0
    WithoutBaggage    = 1 << 0, // 1
    WithBaggage = 1 << 1, // 2
    All = WithoutBaggage | WithBaggage  // 3
}

export enum RefundAllowance {
    None          = 0,      // 0
    WithoutRefund    = 1 << 0, // 1
    WithRefund = 1 << 1, // 2
    All = WithoutRefund | WithRefund  // 3
}

export enum ExchangeAllowance {
    None          = 0,      // 0
    WithoutExchange    = 1 << 0, // 1
    WithExchange = 1 << 1, // 2
    All = WithoutExchange | WithExchange  // 3
}


export interface IFlightRoute {
    id: string;
    isRoundTrip: boolean;
    passengers: number;
    class: FlightClass;
    baggage: BaggageAllowance;
    refund: RefundAllowance;
    exchange: ExchangeAllowance;
    origin: IAutoCompleteItem;
    direction: IAutoCompleteItem;
    departDate?: string;
    departTimeOfDay?: TimeOfDay;
    departTime?: string;
    returnDate?: string;
    returnTimeOfDay?: TimeOfDay;
    returnTime?: string;
    notes: string;
}


interface IAutoCompleteItem {
    name: string;
    country_name: string;
    country_code: string;
    city_name: string;
    main_airport_name: string;
    code: string;
}


interface ISearchFlightsComponent {
    order?: IOrder;
    selectFlightHandler: (flight: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => void;
    selectedCompany: ICompany | undefined;
}


export function SearchFlightsComponent(props: ISearchFlightsComponent) {

    const {
        order,
        selectFlightHandler,
        selectedCompany
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();

    const tripService = TripService();

    const dateService = DateService();


    const clearTimerRef = useRef();

    const days = [
        t("bookingPage.sundayShort"),
        t("bookingPage.mondayShort"),
        t("bookingPage.tuesdayShort"),
        t("bookingPage.wednesdayShort"),
        t("bookingPage.thursdayShort"),
        t("bookingPage.fridayShort"),
        t("bookingPage.saturdayShort"),
    ];

    const months = [
        t("bookingPage.januaryShort"),
        t("bookingPage.februaryShort"),
        t("bookingPage.marchShort"),
        t("bookingPage.aprilShort"),
        t("bookingPage.mayShort"),
        t("bookingPage.juneShort"),
        t("bookingPage.julyShort"),
        t("bookingPage.augustShort"),
        t("bookingPage.septemberShort"),
        t("bookingPage.octoberShort"),
        t("bookingPage.novemberShort"),
        t("bookingPage.decemberShort"),
    ];

    const minPassengersCount = 1;
    const maxPassengersCount = 6;


    const [flightRoute, setFlightRoute] =
        useState<IFlightRoute>(
            {
                id: Guid.newGuid(),
                isRoundTrip: false,
                passengers: minPassengersCount,
                class: FlightClass.None,
                baggage: BaggageAllowance.None,
                refund: RefundAllowance.None,
                exchange: ExchangeAllowance.None,
                origin: {
                    name: "",
                    country_code: "",
                    country_name: "",
                    city_name: "",
                    main_airport_name: "",
                    code: "",
                },
                direction: {
                    name: "",
                    country_code: "",
                    country_name: "",
                    city_name: "",
                    main_airport_name: "",
                    code: "",
                },
                notes: ""
            }
        );

    const [originFocused, setOriginFocused] = useState<boolean>(false);
    const [directionFocused, setDirectionFocused] = useState<boolean>(false);
    const [departDateFocused, setDepartDateFocused] = useState<boolean>(false);
    const [returnDateFocused, setReturnDateFocused] = useState<boolean>(false);

    const [autoComplete, setAutoComplete] = useState<IAutoCompleteItem[]>([]);

    const [providers, setProviders] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

    const [activeSlide, setActiveSlide] = useState<number>(0);

    const [errors, setErrors] = useState<string[]>([]);

    const [flights, setFlight] = useState<IAviaVariant[]>([]);
    const [searchPending, setSearchPending] = useState(false);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [searchFlightsCT, setSearchFlightsCT] = useState<CancelTokenSource|undefined>(undefined);

    const [searchResponse, setSearchResponse] = useState<any>(null);

    const [bookingFlight, setBookingFlight] = useState<{ variant: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any } | undefined>(undefined);
    const [bookFlightsModalOpened, setBookFlightsModalOpened] = useState<boolean>(false);



    const setIsRoundTrip = (isRoundTrip: boolean) => {
        setFlightRoute(route =>
            ({
                ...route,
                isRoundTrip: isRoundTrip,
                returnDate: (isRoundTrip ? route.returnDate: undefined),
                returnTime: (isRoundTrip ? route.returnTime: undefined),
            })
        );
    }

    const setPassengers = (passengers: number) => {
        setFlightRoute(route =>
            ({
                ...route,
                passengers: passengers
            })
        );
    }

    const setClass = (flightClass: FlightClass) => {
        setFlightRoute(route =>
            ({
                ...route,
                class: flightClass
            })
        );
    }

    const setBaggage = (baggage: BaggageAllowance) => {
        setFlightRoute(route =>
            ({
                ...route,
                baggage: baggage
            })
        );
    }

    const setRefund = (refund: RefundAllowance) => {
        setFlightRoute(route =>
            ({
                ...route,
                refund: refund
            })
        );
    }

    const setExchange = (exchange: ExchangeAllowance) => {
        setFlightRoute(route =>
            ({
                ...route,
                exchange: exchange
            })
        );
    }

    const setOrigin = (origin: IAutoCompleteItem) => {
        setFlightRoute(route =>
            ({
                ...route,
                origin: origin
            })
        );
    }

    const switchCities = () => {
        setFlightRoute(route =>
            ({
                ...route,
                origin: route.direction,
                direction: route.origin
            })
        );
    }

    const setDirection = (direction: IAutoCompleteItem) => {
        setFlightRoute(route =>
            ({
                ...route,
                direction: direction
            })
        );
    }

    const setDepartDate = (date: string | undefined) => {
        setFlightRoute(route =>
            ({
                ...route,
                departDate: date,
                returnDate: (route.returnDate != undefined && date != undefined && new Date(route.returnDate) < new Date(date) ? undefined : route.returnDate)
            })
        );
    }

    const setDepartTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setFlightRoute(route =>
            ({
                ...route,
                departTimeOfDay: timeOfDay
            })
        );
    }

    const setDepartTime = (time: string | undefined) => {
        setFlightRoute(route =>
            ({
                ...route,
                departTime: time
            })
        );
    }

    const setReturnDate = (date: string | undefined) => {
        setFlightRoute(route =>
            ({
                ...route,
                returnDate: date
            })
        );
    }

    const setReturnTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setFlightRoute(route =>
            ({
                ...route,
                returnTimeOfDay: timeOfDay
            })
        );
    }

    const setReturnTime = (time: string | undefined) => {
        setFlightRoute(route =>
            ({
                ...route,
                returnTime: time
            })
        );
    }


    const delay = (fn: Function, ms: number) => {
        clearTimeout(clearTimerRef.current);
        clearTimerRef.current = setTimeout(fn, ms) as any;
    }


    const originFocusHandler = async () => {
        setOriginFocused(true);
        setAutoComplete([]);
        const originText = flightRoute.origin.name;
        if(Utils.isEmpty(originText)) {
            return;
        }
        //delay(async () => {
            const autoCompleteItems = await refsService.getAviaCities(originText, "ru");
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }
    const originBlurHandler = async () => {
        setOriginFocused(false);
    }
    const originTextChangeHandler = async (text: string) => {
        //delay(async () => {
            if(Utils.isEmpty(text)) {
                setAutoComplete([]);
                return;
            }
            const autoCompleteItems = await refsService.getAviaCities(text, "ru");
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }

    const directionFocusHandler = async () => {
        setDirectionFocused(true);
        setAutoComplete([]);
        const directionText = flightRoute.direction.name;
        if(Utils.isEmpty(directionText)) {
            return;
        }
        //delay(async () => {
            const autoCompleteItems = await refsService.getAviaCities(directionText, "ru");
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }
    const directionBlurHandler = async () => {
        setDirectionFocused(false);
    }
    const directionTextChangeHandler = async (text: string) => {
        //delay(async () => {
            if(Utils.isEmpty(text)) {
                setAutoComplete([]);
                return;
            }
            const autoCompleteItems = await refsService.getAviaCities(text, "ru");
            if(autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            }
            else {
                setAutoComplete([]);
            }
        //}, 500);
    }

    const departDateFocusHandler = async () => {
        setDepartDateFocused(true);
    }
    const departDateBlurHandler = async () => {
        setDepartDateFocused(false);
    }

    const returnDateFocusHandler = async () => {
        setReturnDateFocused(true);
    }
    const returnDateBlurHandler = async () => {
        setReturnDateFocused(false);
    }


    const getProviders = async () => {
        //setProvidersReceivePending(true);
        //setIsProvidersNotFound(false);
        setProviders([]);
        const response = await tripService.getFlightProviders(selectedCompany?.code ?? "");
        const providers = response ?? [];
        if (providers.length > 0) {
            setProviders(providers);
        } else {
            //setIsProvidersNotFound(true);
        }
        //setProvidersReceivePending(false);
    };


    const searchFlights = async () => {
        setSearchPending(true);
        setSearchNotFound(false);
        setFlight([]);

        if (searchFlightsCT) {
            searchFlightsCT.cancel();
        }

        let cts = axios.CancelToken.source();
        setSearchFlightsCT(cts);

        const response = await tripService.getFlights(
            (
                flightRoute.isRoundTrip
                    ?
                        [
                            Object.assign({}, ({
                                ...flightRoute,
                            })),
                            Object.assign({}, ({
                                ...flightRoute,
                                origin: flightRoute.direction,
                                direction: flightRoute.origin,
                                departDate: flightRoute.returnDate,
                                departTimeOfDay: flightRoute.returnTimeOfDay,
                                departTime: flightRoute.returnTime
                            }))
                        ]
                    :
                        [
                            Object.assign({}, ({
                                ...flightRoute,
                            }))
                        ]
            ),
            selectedProviders,
            selectedCompany?.code ?? "",
            cts.token
        );

        setSearchFlightsCT(undefined);

        setSearchResponse(response);

        const variants = response?.variants ?? [];

        if (variants.length > 0) {
            setFlight(variants.sort(function(a,b){
                return new Date(a.rejsInfo[0].dateDep).getTime() - new Date(b.rejsInfo[0].dateDep).getTime();
            }));
        } else {
            setSearchNotFound(true);
        }

        setSearchPending(false);
    };
    const cancelSearchFlights = () => {
        if (searchFlightsCT) {
            searchFlightsCT.cancel();
        }
    }


    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchFlights();
        }
    }


    const selectFlight = (variant: IAviaVariant, tariff: IAviaTariffInfo) => {
        selectFlightHandler(variant, tariff, searchResponse);
        //setBookingFlight({ variant, tariff, searchResponse });
        //setBookFlightsModalOpened(true);
    }


    const sendSearchResultToTts = async () => {
        const response = await tripService.sendFlightsResultToTts(
            {
                flightSearchResponse: searchResponse,
                orderId: order?.orderNo.toString() ?? "",
                clientCode: selectedCompany?.code ?? "",
            }
        );
    }


    useEffect(() => {
        getProviders();
    }, [selectedCompany]);


    useEffect(() => {
        const errorMessages = [];

        const wrongReturnDate =
            (flightRoute.returnDate != null && flightRoute.departDate != null && new Date(flightRoute.returnDate).getTime() < new Date(flightRoute.departDate).getTime())
            || (flightRoute.returnDate != null && flightRoute.departDate != null && new Date(flightRoute.returnDate + "T" + flightRoute.returnTime ?? "00:00:00").getTime() < new Date(flightRoute.departDate + "T" + flightRoute.departTime ?? "00:00:00").getTime());

        if(wrongReturnDate != null) {
            errorMessages.push(t("errorMsg.inputDateError"));
        }

        setErrors(errorMessages);
    }, [flightRoute]);


    return (
        <React.Fragment>
            <div className="trip-page-search-form">

                <div className="trip-page-search-form-header">

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    flightRoute.isRoundTrip
                                        ? t("bookingPage.roundTrip")
                                        : t("bookingPage.oneWayTrip")
                                }

                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: (!flightRoute.isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: (!flightRoute.isRoundTrip ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: (!flightRoute.isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={!flightRoute.isRoundTrip}
                                onClick={() => {
                                    if (flightRoute.isRoundTrip) {
                                        setIsRoundTrip(false);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.oneWayTrip")
                                }
                            </MdToggleButton>

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: (flightRoute.isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: (flightRoute.isRoundTrip ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: (flightRoute.isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={flightRoute.isRoundTrip}
                                onClick={() => {
                                    if (!flightRoute.isRoundTrip) {
                                        setIsRoundTrip(true);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.roundTrip")
                                }
                            </MdToggleButton>

                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {t("bookingPage.passengers")}: {flightRoute.passengers.toString().padStart(2, "0")}
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="passengers-input-control">
                            <MdButton
                                icon={
                                    <MinusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (flightRoute.passengers > minPassengersCount) {
                                        setPassengers(flightRoute.passengers - 1);
                                    }
                                }}
                            />

                            <input
                                type="number"
                                min={minPassengersCount}
                                max={maxPassengersCount}
                                value={flightRoute.passengers}
                                readOnly={true}
                            />

                            <MdButton
                                icon={
                                    <PlusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (flightRoute.passengers < maxPassengersCount) {
                                        setPassengers(flightRoute.passengers + 1);
                                    }
                                }}
                            />
                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    (() => {
                                        switch (flightRoute.class) {
                                            case FlightClass.Economy:
                                                return t("bookingPage.economyClass");
                                            case FlightClass.Business:
                                                return t("bookingPage.businessClass");
                                            case FlightClass.None:
                                            case FlightClass.All:
                                            default:
                                                return t("bookingPage.anyClass")
                                        }
                                    })()
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.class & FlightClass.Economy) == FlightClass.Economy ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.class & FlightClass.Economy) == FlightClass.Economy ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.class & FlightClass.Economy) == FlightClass.Economy ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.class & FlightClass.Economy) == FlightClass.Economy}
                                onClick={() => {
                                    if ((flightRoute.class & FlightClass.Economy) != FlightClass.Economy) {
                                        // add economy flag if does not exist
                                        setClass(flightRoute.class |= FlightClass.Economy);
                                    }
                                    else {
                                        // remove economy flag if already exist
                                        setClass(flightRoute.class &= ~FlightClass.Economy);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.economyClass")
                                }
                            </MdToggleButton>

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.class & FlightClass.Business) == FlightClass.Business ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.class & FlightClass.Business) == FlightClass.Business ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.class & FlightClass.Business) == FlightClass.Business ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.class & FlightClass.Business) == FlightClass.Business}
                                onClick={() => {
                                    if ((flightRoute.class & FlightClass.Business) != FlightClass.Business) {
                                        // add economy flag if does not exist
                                        setClass(flightRoute.class |= FlightClass.Business);
                                    }
                                    else {
                                        // remove economy flag if already exist
                                        setClass(flightRoute.class &= ~FlightClass.Business);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.businessClass")
                                }
                            </MdToggleButton>

                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    (() => {
                                        switch (flightRoute.baggage) {
                                            case BaggageAllowance.WithoutBaggage:
                                                return t("bookingPage.withoutBaggage");
                                            case BaggageAllowance.WithBaggage:
                                                return t("bookingPage.withBaggage");
                                            case BaggageAllowance.None:
                                            case BaggageAllowance.All:
                                            default:
                                                return t("bookingPage.anyBaggage")
                                        }
                                    })()
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.baggage & BaggageAllowance.WithoutBaggage) == BaggageAllowance.WithoutBaggage ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.baggage & BaggageAllowance.WithoutBaggage) == BaggageAllowance.WithoutBaggage ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.baggage & BaggageAllowance.WithoutBaggage) == BaggageAllowance.WithoutBaggage ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.baggage & BaggageAllowance.WithoutBaggage) == BaggageAllowance.WithoutBaggage}
                                onClick={() => {
                                    if ((flightRoute.baggage & BaggageAllowance.WithoutBaggage) != BaggageAllowance.WithoutBaggage) {
                                        // add without baggage flag if does not exist
                                        setBaggage(flightRoute.baggage |= BaggageAllowance.WithoutBaggage);
                                    }
                                    else {
                                        // remove without baggage flag if already exist
                                        setBaggage(flightRoute.baggage &= ~BaggageAllowance.WithoutBaggage);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.withoutBaggage")
                                }
                            </MdToggleButton>

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.baggage & BaggageAllowance.WithBaggage) == BaggageAllowance.WithBaggage ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.baggage & BaggageAllowance.WithBaggage) == BaggageAllowance.WithBaggage ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.baggage & BaggageAllowance.WithBaggage) == BaggageAllowance.WithBaggage ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.baggage & BaggageAllowance.WithBaggage) == BaggageAllowance.WithBaggage}
                                onClick={() => {
                                    if ((flightRoute.baggage & BaggageAllowance.WithBaggage) != BaggageAllowance.WithBaggage) {
                                        // add with baggage flag if does not exist
                                        setBaggage(flightRoute.baggage |= BaggageAllowance.WithBaggage);
                                    }
                                    else {
                                        // remove with baggage flag if already exist
                                        setBaggage(flightRoute.baggage &= ~BaggageAllowance.WithBaggage);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.withBaggage")
                                }
                            </MdToggleButton>

                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    (() => {
                                        switch (flightRoute.refund) {
                                            case RefundAllowance.WithoutRefund:
                                                return t("bookingPage.withoutRefund");
                                            case RefundAllowance.WithRefund:
                                                return t("bookingPage.withRefund");
                                            case RefundAllowance.None:
                                            case RefundAllowance.All:
                                            default:
                                                return t("bookingPage.anyRefund")
                                        }
                                    })()
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.refund & RefundAllowance.WithoutRefund) == RefundAllowance.WithoutRefund ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.refund & RefundAllowance.WithoutRefund) == RefundAllowance.WithoutRefund ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.refund & RefundAllowance.WithoutRefund) == RefundAllowance.WithoutRefund ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.refund & RefundAllowance.WithoutRefund) == RefundAllowance.WithoutRefund}
                                onClick={() => {
                                    if ((flightRoute.refund & RefundAllowance.WithoutRefund) != RefundAllowance.WithoutRefund) {
                                        // add without refund flag if does not exist
                                        setRefund(flightRoute.refund |= RefundAllowance.WithoutRefund);
                                    }
                                    else {
                                        // remove without refund flag if already exist
                                        setRefund(flightRoute.refund &= ~RefundAllowance.WithoutRefund);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.withoutRefund")
                                }
                            </MdToggleButton>

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.refund & RefundAllowance.WithRefund) == RefundAllowance.WithRefund ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.refund & RefundAllowance.WithRefund) == RefundAllowance.WithRefund ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.refund & RefundAllowance.WithRefund) == RefundAllowance.WithRefund ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.refund & RefundAllowance.WithRefund) == RefundAllowance.WithRefund}
                                onClick={() => {
                                    if ((flightRoute.refund & RefundAllowance.WithRefund) != RefundAllowance.WithRefund) {
                                        // add with refund flag if does not exist
                                        setRefund(flightRoute.refund |= RefundAllowance.WithRefund);
                                    }
                                    else {
                                        // remove with refund flag if already exist
                                        setRefund(flightRoute.refund &= ~RefundAllowance.WithRefund);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.withRefund")
                                }
                            </MdToggleButton>

                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    (() => {
                                        switch (flightRoute.exchange) {
                                            case ExchangeAllowance.WithoutExchange:
                                                return t("bookingPage.withoutExchange");
                                            case ExchangeAllowance.WithExchange:
                                                return t("bookingPage.withExchange");
                                            case ExchangeAllowance.None:
                                            case ExchangeAllowance.All:
                                            default:
                                                return t("bookingPage.anyExchange")
                                        }
                                    })()
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.exchange & ExchangeAllowance.WithoutExchange) == ExchangeAllowance.WithoutExchange ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.exchange & ExchangeAllowance.WithoutExchange) == ExchangeAllowance.WithoutExchange ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.exchange & ExchangeAllowance.WithoutExchange) == ExchangeAllowance.WithoutExchange ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.exchange & ExchangeAllowance.WithoutExchange) == ExchangeAllowance.WithoutExchange}
                                onClick={() => {
                                    if ((flightRoute.exchange & ExchangeAllowance.WithoutExchange) != ExchangeAllowance.WithoutExchange) {
                                        // add without exchange flag if does not exist
                                        setExchange(flightRoute.exchange |= ExchangeAllowance.WithoutExchange);
                                    }
                                    else {
                                        // remove without exchange flag if already exist
                                        setExchange(flightRoute.exchange &= ~ExchangeAllowance.WithoutExchange);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.withoutExchange")
                                }
                            </MdToggleButton>

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: ((flightRoute.exchange & ExchangeAllowance.WithExchange) == ExchangeAllowance.WithExchange ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: ((flightRoute.exchange & ExchangeAllowance.WithExchange) == ExchangeAllowance.WithExchange ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: ((flightRoute.exchange & ExchangeAllowance.WithExchange) == ExchangeAllowance.WithExchange ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={(flightRoute.exchange & ExchangeAllowance.WithExchange) == ExchangeAllowance.WithExchange}
                                onClick={() => {
                                    if ((flightRoute.exchange & ExchangeAllowance.WithExchange) != ExchangeAllowance.WithExchange) {
                                        // add with exchange flag if does not exist
                                        setExchange(flightRoute.exchange |= ExchangeAllowance.WithExchange);
                                    }
                                    else {
                                        // remove with exchange flag if already exist
                                        setExchange(flightRoute.exchange &= ~ExchangeAllowance.WithExchange);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.withExchange")
                                }
                            </MdToggleButton>

                        </div>
                    </MdMenu>

                </div>

                <div className="trip-page-search-form-content">

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((originFocused || (flightRoute.origin.city_name ?? flightRoute.origin.name).length > 0 ? "--displaced" : ""))}>
                            {t("commonText.from")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={(flightRoute.origin.city_name ?? flightRoute.origin.name)}
                            setText={(value) => setOrigin(autoComplete.find(x => x.city_name == value || x.name == value) ?? {
                                name: "",
                                country_code: "",
                                country_name: "",
                                city_name: value,
                                main_airport_name: "",
                                code: ""
                            })}
                            selectedItem={flightRoute.origin}
                            setSelectedItem={(item) => setOrigin(item)}
                            onFocus={() => originFocusHandler()}
                            onBlur={() => originBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.city_name ?? item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <span className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                )
                                            }
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.city_name ?? item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <div className="trip-page-search-form-city-item-flag">
                                                            <span
                                                                className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                    </div>
                                                )
                                            }
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => originTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>


                    <div className="trip-page-search-form-switch-cities-control">
                        <MdButton
                            style={{
                                "button": {
                                    position: 'absolute',
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%) rotate(-90deg)",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "30px",
                                    padding: "5px",
                                    zIndex: 1
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            tabIndex={-1}
                            disabled={searchPending}
                            onClick={() => switchCities()}
                        >
                            <SwitchIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        stroke: "rgb(100, 100, 100)",
                                        fill: "rgb(100, 100, 100)"
                                    }
                                }}
                            />
                        </MdButton>
                    </div>


                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((directionFocused || (flightRoute.direction.city_name ?? flightRoute.direction.name).length > 0 ? "--displaced" : ""))}>
                            {t("commonText.to")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={(flightRoute.direction.city_name ?? flightRoute.direction.name)}
                            setText={(value) => setDirection(autoComplete.find(x => x.city_name == value || x.name == value) ?? {
                                name: "",
                                country_code: "",
                                country_name: "",
                                city_name: value,
                                main_airport_name: "",
                                code: ""
                            })}
                            selectedItem={flightRoute.direction}
                            setSelectedItem={(item) => setDirection(item)}
                            onFocus={() => directionFocusHandler()}
                            onBlur={() => directionBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.city_name ?? item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <span className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                )
                                            }
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.main_airport_name ?? item.name), item.country_name].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.city_name ?? item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.code}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {
                                                item.country_code.length > 0 && (
                                                    <div className="trip-page-search-form-city-item-flag">
                                                            <span
                                                                className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>
                                                    </div>
                                                )
                                            }
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => directionTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>


                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                         style={{
                             marginLeft: "6px",
                             minWidth: "280px",
                             backgroundColor: ((flightRoute.returnDate != undefined && flightRoute.departDate != undefined && new Date(flightRoute.returnDate) < new Date(flightRoute.departDate)) ? "rgba(255, 0, 0, 0.2)" : "transparent")
                        }}>
                        <label className={(departDateFocused || flightRoute.departDate != null ? "--displaced" : "")}>
                            {t("bookingPage.departDate")}
                        </label>
                        <input
                            className={(departDateFocused ? "--focused" : "")}
                            type="date"
                            min={dateService.dateToStringForDatePicker(new Date()) + "T00:00:00"}
                            value={flightRoute.departDate ?? ""}
                            onFocus={() => departDateFocusHandler()}
                            onBlur={() => departDateBlurHandler()}
                            onChange={(e) => setDepartDate(e.target.value)}
                            disabled={searchPending}
                        />
                        {
                            (flightRoute.departDate != null && flightRoute.departTimeOfDay == null) && (
                                <input
                                    className={(departDateFocused ? "--focused" : "")}
                                    type="time"
                                    value={flightRoute.departTime ?? ""}
                                    onFocus={() => departDateFocusHandler()}
                                    onBlur={() => departDateBlurHandler()}
                                    onChange={(e) => setDepartTime(e.target.value)}
                                    disabled={searchPending}
                                />
                            )
                        }

                        {
                            flightRoute.departDate != null && (
                                <div className={("trip-page-search-form-datetime-item" + (departDateFocused ? " --hidden" : ""))}>
                                    <div className="trip-page-search-form-datetime-item-date">
                                        {
                                            days[new Date(flightRoute.departDate).getDay()]
                                            + ", " + new Date(flightRoute.departDate).getDate().toString().padStart(2, "0") + " "
                                            + months[new Date(flightRoute.departDate).getMonth()]
                                        }
                                    </div>
                                    <div className="trip-page-search-form-datetime-item-time">
                                        {
                                            flightRoute.departTimeOfDay == null
                                                ? (
                                                    <span style={{marginLeft: "15px"}}>
                                                        {
                                                            flightRoute.departTime != null ? flightRoute.departTime.toString() : "00:00"
                                                        }
                                                    </span>
                                                )
                                                : (
                                                    (() => {
                                                        switch (flightRoute.departTimeOfDay){
                                                            case TimeOfDay.Morning:
                                                                return (
                                                                    <>
                                                                        <SunriseIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            04:00 - 12:00
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Afternoon:
                                                                return (
                                                                    <>
                                                                        <SunIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            10:00 - 16:00
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Evening:
                                                                return (
                                                                    <>
                                                                        <MoonIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            16:00 - 23:00
                                                                        </span>
                                                                    </>
                                                                )
                                                            default:
                                                                return ""
                                                        }
                                                    })()
                                                )
                                        }
                                    </div>
                                </div>
                            )
                        }

                        <MdMenu
                            behaviour={'none'}
                            header={(
                                <div className="flight-departure-time-type">
                                    {
                                        flightRoute.departTimeOfDay == null
                                            ? (
                                                t("bookingPage.approximateTime")
                                            )
                                            : (
                                                (() => {
                                                    switch (flightRoute.departTimeOfDay){
                                                        case TimeOfDay.Morning:
                                                            return t("bookingPage.morning")
                                                        case TimeOfDay.Afternoon:
                                                            return t("bookingPage.afternoon")
                                                        case TimeOfDay.Evening:
                                                            return t("bookingPage.evening")
                                                        default:
                                                            return ""
                                                    }
                                                })()
                                            )
                                    }
                                </div>
                            )}
                            disabled={searchPending}
                            style={{
                                "root": {
                                    position: 'absolute',
                                    bottom: "2px",
                                    right: "3px",
                                }
                            }}
                        >
                            <div className="flight-departure-time-type-variants">

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "4px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "11px",
                                        color: (flightRoute.departTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (flightRoute.departTimeOfDay == TimeOfDay.Morning ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (flightRoute.departTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={flightRoute.departTimeOfDay == TimeOfDay.Morning}
                                    onClick={() => {
                                        if (flightRoute.departTimeOfDay != TimeOfDay.Morning) {
                                            setDepartTimeOfDay(TimeOfDay.Morning);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.morning")
                                    }
                                </MdToggleButton>

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "4px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "11px",
                                        color: (flightRoute.departTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (flightRoute.departTimeOfDay == TimeOfDay.Afternoon ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (flightRoute.departTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={flightRoute.departTimeOfDay == TimeOfDay.Afternoon}
                                    onClick={() => {
                                        if (flightRoute.departTimeOfDay != TimeOfDay.Afternoon) {
                                            setDepartTimeOfDay(TimeOfDay.Afternoon);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.afternoon")
                                    }
                                </MdToggleButton>

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "4px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "11px",
                                        color: (flightRoute.departTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (flightRoute.departTimeOfDay == TimeOfDay.Evening ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (flightRoute.departTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={flightRoute.departTimeOfDay == TimeOfDay.Evening}
                                    onClick={() => {
                                        if (flightRoute.departTimeOfDay != TimeOfDay.Evening) {
                                            setDepartTimeOfDay(TimeOfDay.Evening);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.evening")
                                    }
                                </MdToggleButton>

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "4px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "11px",
                                        color: (flightRoute.departTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (flightRoute.departTimeOfDay == undefined ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (flightRoute.departTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={flightRoute.departTimeOfDay == undefined}
                                    onClick={() => {
                                        if (flightRoute.departTimeOfDay != undefined) {
                                            setDepartTimeOfDay(undefined);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.approximateTime")
                                    }
                                </MdToggleButton>

                            </div>
                        </MdMenu>
                    </div>

                    {
                        flightRoute.isRoundTrip && (
                            <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                                 style={{
                                     marginLeft: "6px",
                                     minWidth: "280px",
                                     backgroundColor: ((flightRoute.returnDate != undefined && flightRoute.departDate != undefined && new Date(flightRoute.returnDate) < new Date(flightRoute.departDate)) ? "rgba(255, 0, 0, 0.2)" : "transparent")
                                }}>
                                <label className={(returnDateFocused || flightRoute.returnDate != null ? "--displaced" : "")}>
                                    {t("bookingPage.returnDate")}
                                </label>
                                <input
                                    className={(returnDateFocused ? "--focused" : "")}
                                    type="date"
                                    min={dateService.dateToStringForDatePicker(new Date()) + "T00:00:00"}
                                    value={flightRoute.returnDate ?? ""}
                                    onFocus={() => returnDateFocusHandler()}
                                    onBlur={() => returnDateBlurHandler()}
                                    onChange={(e) => setReturnDate(e.target.value)}
                                    disabled={searchPending}
                                />
                                {
                                    (flightRoute.returnDate != null && flightRoute.returnTimeOfDay == null) && (
                                        <input
                                            className={(returnDateFocused ? "--focused" : "")}
                                            type="time"
                                            value={flightRoute.returnTime ?? ""}
                                            onFocus={() => returnDateFocusHandler()}
                                            onBlur={() => returnDateBlurHandler()}
                                            onChange={(e) => setReturnTime(e.target.value)}
                                            disabled={searchPending}
                                        />
                                    )
                                }

                                {
                                    flightRoute.returnDate != null && (
                                        <div className={("trip-page-search-form-datetime-item" + (returnDateFocused ? " --hidden" : ""))}>
                                            <div className="trip-page-search-form-datetime-item-date">
                                                {
                                                    days[new Date(flightRoute.returnDate).getDay()]
                                                    + ", " + new Date(flightRoute.returnDate).getDate().toString().padStart(2, "0") + " "
                                                    + months[new Date(flightRoute.returnDate).getMonth()]
                                                }
                                            </div>
                                            <div className="trip-page-search-form-datetime-item-time">
                                                {
                                                    flightRoute.returnTimeOfDay == null
                                                        ? (
                                                            <span style={{marginLeft: "15px"}}>
                                                        {
                                                            flightRoute.returnTime != null ? flightRoute.returnTime.toString() : "00:00"
                                                        }
                                                    </span>
                                                        )
                                                        : (
                                                            (() => {
                                                                switch (flightRoute.returnTimeOfDay) {
                                                                    case TimeOfDay.Morning:
                                                                        return (
                                                                            <>
                                                                                <SunriseIcon
                                                                                    width="16px"
                                                                                    height="16px"
                                                                                />
                                                                                <span>
                                                                            04:00 - 12:00
                                                                        </span>
                                                                            </>
                                                                        )
                                                                    case TimeOfDay.Afternoon:
                                                                        return (
                                                                            <>
                                                                                <SunIcon
                                                                                    width="16px"
                                                                                    height="16px"
                                                                                />
                                                                                <span>
                                                                            10:00 - 16:00
                                                                        </span>
                                                                            </>
                                                                        )
                                                                    case TimeOfDay.Evening:
                                                                        return (
                                                                            <>
                                                                                <MoonIcon
                                                                                    width="16px"
                                                                                    height="16px"
                                                                                />
                                                                                <span>
                                                                            16:00 - 23:00
                                                                        </span>
                                                                            </>
                                                                        )
                                                                    default:
                                                                        return ""
                                                                }
                                                            })()
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                                <MdMenu
                                    behaviour={'none'}
                                    header={(
                                        <div className="flight-return-time-type">
                                            {
                                                flightRoute.returnTimeOfDay == null
                                                    ? (
                                                        t("bookingPage.approximateTime")
                                                    )
                                                    : (
                                                        (() => {
                                                            switch (flightRoute.returnTimeOfDay) {
                                                                case TimeOfDay.Morning:
                                                                    return t("bookingPage.morning")
                                                                case TimeOfDay.Afternoon:
                                                                    return t("bookingPage.afternoon")
                                                                case TimeOfDay.Evening:
                                                                    return t("bookingPage.evening")
                                                                default:
                                                                    return ""
                                                            }
                                                        })()
                                                    )
                                            }
                                        </div>
                                    )}
                                    disabled={searchPending}
                                    style={{
                                        "root": {
                                            position: 'absolute',
                                            bottom: "2px",
                                            right: "3px",
                                        }
                                    }}
                                >
                                    <div className="flight-return-time-type-variants">

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "11px",
                                                color: (flightRoute.returnTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (flightRoute.returnTimeOfDay == TimeOfDay.Morning ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (flightRoute.returnTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={flightRoute.returnTimeOfDay == TimeOfDay.Morning}
                                            onClick={() => {
                                                if (flightRoute.returnTimeOfDay != TimeOfDay.Morning) {
                                                    setReturnTimeOfDay(TimeOfDay.Morning);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.morning")
                                            }
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "11px",
                                                color: (flightRoute.returnTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (flightRoute.returnTimeOfDay == TimeOfDay.Afternoon ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (flightRoute.returnTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={flightRoute.returnTimeOfDay == TimeOfDay.Afternoon}
                                            onClick={() => {
                                                if (flightRoute.returnTimeOfDay != TimeOfDay.Afternoon) {
                                                    setReturnTimeOfDay(TimeOfDay.Afternoon);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.afternoon")
                                            }
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "11px",
                                                color: (flightRoute.returnTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (flightRoute.returnTimeOfDay == TimeOfDay.Evening ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (flightRoute.returnTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={flightRoute.returnTimeOfDay == TimeOfDay.Evening}
                                            onClick={() => {
                                                if (flightRoute.returnTimeOfDay != TimeOfDay.Evening) {
                                                    setReturnTimeOfDay(TimeOfDay.Evening);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.evening")
                                            }
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "4px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "11px",
                                                color: (flightRoute.returnTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (flightRoute.returnTimeOfDay == undefined ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (flightRoute.returnTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={flightRoute.returnTimeOfDay == undefined}
                                            onClick={() => {
                                                if (flightRoute.returnTimeOfDay != undefined) {
                                                    setReturnTimeOfDay(undefined);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.approximateTime")
                                            }
                                        </MdToggleButton>

                                    </div>
                                </MdMenu>
                            </div>
                        )
                    }

                </div>

                <MdButton
                    type="button"
                    style={{
                        "button": {
                            position: "absolute",
                            bottom: "-20px",
                            right: "30px",
                            borderWidth: 0,
                            backgroundColor: (searchPending ? "#B83E50" : "#3E5CB8"),
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            minWidth: "160px",
                            height: "40px",
                            padding: "5px 15px",
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "capitalize",
                            alignItems: "center",
                            justifyContent: "center"
                        },
                        "text": {
                            width: "auto",
                            flex: 0,
                            color: "#FFFFFF"
                        },
                        "ink": {
                            backgroundColor: "rgba(255,255,255,.5)"
                        }
                    }}
                    //disabled={flightRoute.departDate == null}
                    onClick={() => {
                        if (!searchPending) {
                            searchFlights()
                        } else {
                            cancelSearchFlights();
                        }
                    }}
                >
                    {
                        searchPending
                            ? (
                                t("bookingPage.stop")
                            )
                            : (
                                t("bookingPage.searchFlights")
                            )
                    }
                </MdButton>

            </div>

            <div className="trip-page-search-tools">
                {
                    authState.isSupportUser && (
                        <>
                            <div className="trip-page-search-providers">
                                <span className="provider-list-label">{t("bookingPage.providers")}:</span>
                                {
                                    providers.map((provider, providerIndex) => {
                                        const isSelected = selectedProviders.includes(provider);
                                        return (
                                            <React.Fragment key={"provider" + Guid.newGuid()}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "2px 8px",
                                                        borderRadius: "4px",
                                                        minWidth: "28px",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "10px",
                                                        color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={isSelected}
                                                    onClick={() => {
                                                        if (isSelected) {
                                                            setSelectedProviders(prev => [...prev.filter(x => x != provider)]);
                                                        } else {
                                                            setSelectedProviders(prev => [...prev, provider]);
                                                        }
                                                    }}
                                                >
                                                    {
                                                        provider
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>

                            {
                                (order != null && flights.length > 0) && (
                                    <MdButton
                                        type="button"
                                        style={{
                                            "button": {
                                                marginTop: "7px",
                                                border: "1px dashed rgb(175, 175, 175)",
                                                backgroundColor: "transparent",
                                                color: "rgb(0, 0, 0)",
                                                borderRadius: "14px",
                                                padding: "5px 15px",
                                                fontWeight: 500,
                                                fontSize: "10px",
                                                textTransform: "capitalize",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "30px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        //disabled={trainRoute.departDate == null}
                                        onClick={() => {
                                            sendSearchResultToTts()
                                        }}
                                    >
                                        Сохранить ответ
                                    </MdButton>
                                )
                            }

                        </>
                    )
                }
            </div>

            <SearchFlightsResultsComponent
                searchPending={searchPending}
                searchNotFound={searchNotFound}
                flights={flights}
                selectFlightHandler={selectFlight}
            />

        </React.Fragment>
    );
}