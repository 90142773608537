import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const BellIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={style["svg"]}>
            <path
                d="M18.9,11.2s0-8.7-6.9-8.7-6.9,8.7-6.9,8.7v3.9L2.5,17.5h19l-2.6-2.4Z"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                style={style["path"]}
            />

            <path
                d="M14.5,20.5s-.5,1-2.5,1-2.5-1-2.5-1"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                style={style["path"]}
            />
        </svg>
    )
}