import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../constants/componentsContants";

export function SandClockIcon({iconSize}: IIconProps) {
    if (iconSize === undefined) {
        iconSize = DEFAULT_ICON_SIZE;
    }
    return (<>
        <svg height={iconSize}
             width={iconSize}
             version="1.1"
             viewBox="0 0 512 512"
        >
<g>
	<path style={{fill:"#D2AA82"}} d="M328.163,40H128c-8.8,0-16-7.2-16-16l0,0c0-8.8,7.2-16,16-16h256.001c8.8,0,16,7.2,16,16l0,0
		c0,8.8-7.2,16-16,16h-23.998H328.163z"/>
    <path style={{fill:"#D2AA82"}} d="M328.163,472H127.999c-8.8,0-16,7.2-16,16l0,0c0,8.8,7.2,16,16,16H384c8.8,0,16-7.2,16-16l0,0
		c0-8.8-7.2-16-16-16h-23.998H328.163z"/>
</g>
            <path style={{fill:"#E4EAF6"}} d="M327.164,40H128.166v46c0,8.8,4.112,21.91,9.137,29.134l97.992,140.865l-97.992,140.865
	c-5.025,7.224-9.137,20.335-9.137,29.134v46h200.998h31h24v-46c0-8.8-4.112-21.91-9.137-29.134l-97.993-140.865l97.992-140.865
	c5.025-7.224,9.137-20.334,9.137-29.134V40h-24H327.164z"/>
            <g>
	<path style={{fill:"#E1BE91;"}} d="M256.163,282.033l92.571,133.071c1.425,2.207,3.338,8.307,3.428,10.932v13.96H160.164v-13.961
		c0.091-2.625,2.004-8.728,3.429-10.933L256.163,282.033"/>
                <polygon style={{fill:"#E1BE91"}} points="218.624,175.998 256.163,229.962 293.704,175.998 	"/>
</g>
            <path d="M392.162,465.431v-39.436c0-10.379-4.642-25.184-10.569-33.703l-94.815-136.296l94.815-136.296
	c5.927-8.52,10.569-23.324,10.569-33.703V46.57c9.228-3.347,15.839-12.2,15.839-22.57c0-13.234-10.766-24-24-24H128
	c-13.234,0-24.001,10.766-24.001,24c0,10.491,6.769,19.425,16.165,22.68v39.32c0,10.379,4.643,25.183,10.57,33.703l94.815,136.296
	l-94.814,136.296c-5.927,8.52-10.57,23.324-10.57,33.703v39.325c-9.397,3.255-16.166,12.189-16.166,22.68
	c0,13.233,10.767,24,24.001,24h256.001c13.234,0,24-10.767,24-24C408,477.63,401.39,468.778,392.162,465.431z M384,496H128
	c-4.412,0-8.001-3.589-8.001-8c0-4.411,3.589-8,8.001-8h200.163c0.033,0,0.064-0.005,0.097-0.005h0.903c4.419,0,8-3.582,8-8
	c0-4.418-3.581-8-8-8H136.165v-38c0-7.187,3.6-18.666,7.705-24.566l97.992-140.865c1.91-2.746,1.91-6.391,0-9.137L143.87,110.564
	c-4.105-5.899-7.705-17.378-7.705-24.565V48h191.998c4.419,0,8-3.582,8-8s-3.581-8-8-8h-0.99c-0.003,0-0.007,0-0.01,0H128.165
	c-0.003,0-0.006,0-0.01,0H128c-4.412,0-8.001-3.589-8.001-8s3.589-8,8.001-8h256.001c4.411,0,8,3.589,8,8
	c0,4.408-3.584,7.995-7.99,8h-23.849c-0.003,0-0.006,0-0.01,0h-0.15c-4.418,0-8,3.582-8,8s3.582,8,8,8h16.159v38
	c0,7.188-3.6,18.666-7.704,24.566L270.464,251.43c-1.91,2.746-1.91,6.391,0,9.137l97.993,140.865
	c4.104,5.899,7.704,17.378,7.704,24.565v38h-16c-0.033,0-0.064,0.004-0.097,0.005h-0.063c-4.419,0-8,3.581-8,8c0,4.418,3.581,8,8,8
	h23.998c4.411,0,8,3.589,8,8C392,492.411,388.411,496,384,496z M360.162,439.996v-13.96c0-0.092-0.001-0.184-0.005-0.275
	c-0.139-4.035-2.512-11.603-4.702-14.996c-0.05-0.078-0.102-0.154-0.154-0.229L262.73,277.465c-1.496-2.15-3.949-3.432-6.567-3.432
	c-2.619,0-5.072,1.282-6.567,3.432l-92.57,133.07c-0.052,0.075-0.103,0.15-0.152,0.228c-2.191,3.391-4.565,10.96-4.705,14.998
	c-0.003,0.092-0.005,0.184-0.005,0.276v13.959c0,4.418,3.582,8,8,8h191.998C356.582,447.996,360.162,444.414,360.162,439.996z
	 M344.163,431.996H168.164v-5.725c0.141-1.479,1.415-5.538,2.12-6.778l85.879-123.452l85.869,123.438
	c0.729,1.294,2.001,5.352,2.13,6.774V431.996z M256.163,237.962c2.619,0,5.072-1.282,6.567-3.432l37.54-53.963
	c1.701-2.445,1.902-5.633,0.523-8.273c-1.379-2.64-4.111-4.295-7.09-4.295h-75.08c-2.979,0-5.711,1.655-7.09,4.295
	c-1.379,2.64-1.178,5.828,0.523,8.273l37.54,53.963C251.092,236.681,253.545,237.962,256.163,237.962z M278.393,183.998
	l-22.23,31.956l-22.23-31.956H278.393z"/>
</svg>
    </>)
}