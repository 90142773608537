import "./style.scss";
import {CSSProperties} from "react";

type InputTextProps = {
    title?: string;
    value: string;
    placeholder?: string;
    label?: string;

    runActionByEnter?:()=>void;
    setValue: (value: string) => void;
    style?: CSSProperties;
};
export function MdInputText (props:InputTextProps) {



    return (
        <div className="input-text-container">
            {props.label && <div className="input-text-component-label" >{props.label}</div>}
            <div className="input-text-custom-item">
                <label></label>
                <input
                    type="text"
                    style={props.style}
                    className=" common-input "
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={(e) => {props.setValue(e.target.value)}}
                    onKeyDown={(e)=>{ if(e.key==="Enter" && props.runActionByEnter!==undefined) props.runActionByEnter() }}
                />
                {props.value !== "" ? <div className="clear-input" onClick={() => {
                    props.setValue("")
                   // setInputText("");
                }}>&#x2715;</div> : <div className="clear-input">&nbsp;&nbsp;&nbsp;</div>}
            </div>
        </div>
    )
}