import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../constants/componentsContants";

export function UpIcon({iconSize}: IIconProps) {
    if (iconSize === undefined) {
        iconSize = DEFAULT_ICON_SIZE;
    }
    return (
        <svg fill="currentColor" width={iconSize} height={iconSize} viewBox="0 0 32 32" version="1.1"
             >
            <title>up</title>
            <path d="M11.25 15.688l-7.656 7.656-3.594-3.688 11.063-11.094 11.344 11.344-3.5 3.5z"></path>
        </svg>
    )
}