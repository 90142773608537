import {AxiosResponse, CancelToken} from "axios";
import {
    BEARER,
    ROUTE_GET_EDM_UPLOADS,
    ROUTE_GET_ACCOUNTANT_DOCS,
    ROUTE_GENERATE_EDM_REPORTS,
} from "../constants/routeConstants/ApiRouteConstants";
import {axiosInstance} from "./AxiosInstance";
import {
    IAccountantDocument,
} from "../interfaces/reportInterfaces/customerInterfaces";
import {
    IGetAccountantDocumentsRequest,
} from '../interfaces/requestsInterfaces/reportRequests';
import {ISendDocsToEdm} from "../Requests/ISendDocsToEdm";


export function DocumentsApi() {

    /**
     * GET method for obtaining accountant documents
     * @param {IGetAccountantDocumentsRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IAccountantDocument[]>>} response with accountant documents
     */
    const getAccountantDocuments = async (request: IGetAccountantDocumentsRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IAccountantDocument[]>> => {
        return await axiosInstance.get<IAccountantDocument[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ACCOUNTANT_DOCS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: request.clientCode,
                    dateStart: request.dateStart,
                    dateEnd: request.dateEnd,
                    gkCode: request.gkCode,
                    name: request.name,
                    docNo: request.docNo,
                    route: request.route,
                    ticketNum: ""
                },
                cancelToken: ct
            }
        );
    };

    /**
     * POST method for generate accountant documents
     * @param {string} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any>>} response with result of generating accountant documents
     */
    const generateDocuments = async (request: ISendDocsToEdm, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any>> => {
        return await axiosInstance.post(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GENERATE_EDM_REPORTS,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                },
                cancelToken: ct
            }
        );
    }

    /**
     * POST method for obtaining accountant documents uploads
     * @param {string} clientCode - company code
     * @param {string[]} docNums - document numbers
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any>>} response with accountant documents uploads
     */
    const getDocumentUploads = async (clientCode: string, docNums: string[], accessToken: string, ct: CancelToken): Promise<AxiosResponse<any>> => {
        return await axiosInstance.post(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_EDM_UPLOADS,
            docNums,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                },
                params: {
                    clientCode: clientCode,
                },
                cancelToken: ct
            }
        );
    }

    /**
     * GET method for obtaining document file
     * @param {string} keyOrLink - document key or link data
     * @param {boolean} asLink - flag defining the download type (as link or as key)
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<any, any>>} response with document file
     */
    const getDocumentFile = async (keyOrLink: string, asLink: boolean, clientCode: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<any, any>> => {
        if (asLink) {
            return await axiosInstance.get(keyOrLink,
                {
                    headers: {
                        Authorization: BEARER + accessToken,
                        "Accept": "application/json",
                        "Content-type": "application/json",
                        responseType: 'blob'
                    },
                    cancelToken: ct
                }
            );
        } else {
            return await axiosInstance.post("/api/Edm/download",
                keyOrLink,
                {
                    headers: {
                        Authorization: BEARER + accessToken,
                        "Accept": "application/json",
                        "Content-type": "application/json",
                        responseType: 'blob'
                    },
                    params: {
                        clientCode: clientCode
                    },
                    cancelToken: ct
                }
            );
        }
    }


    return {
        getAccountantDocuments,
        generateDocuments,
        getDocumentUploads,
        getDocumentFile
    };
}