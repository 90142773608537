import { ICustomerAdditionalService } from "../../../../interfaces/reportInterfaces/customerInterfaces";
import { DataConvertService } from "../../../../services/DataConvertService";
import "../../../../scss/tables.scss";
import {useTranslation} from "react-i18next";

type CustomerAdditionalServiceProps = {
  customerAddServices: ICustomerAdditionalService[];
};

export function CustomerAdditionalServiceComponent({
  customerAddServices,
}: CustomerAdditionalServiceProps) {
  const { useMoneyRubFormat } = DataConvertService();
  const { t } = useTranslation();
  return (
    <>
      <table className="table common-document-stripped-table table-bordered">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{`${t("statisticPage.serviceType")}`}</th>
            <th scope="col">{`${t("statisticPage.destination")}`}</th>
            <th scope="col">{`${t("statisticPage.totalCharge")}`}</th>
            <th scope="col">{`${t("statisticPage.totalCount")}`}</th>
          </tr>
        </thead>
        <tbody>
          { customerAddServices && customerAddServices.map((item, i) => {
            return (
              <tr key={"customerAdditionalService_"+i}>
                <td>{i}</td>
                <td>{item.destination}</td>
                <td>{item.destination}</td>
                <td>{useMoneyRubFormat.format(item.totalCharge)}</td>
                <td>{item.totalCount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
