import React, {CSSProperties, SetStateAction, useEffect, useRef, useState} from "react";
import {ArrowsUpDownIcon} from "../../icons/ArrowsUpDownIcon";
import {Utils} from "../../utils/utils";
import './style.scss'


interface IMdSelectProps {
    style?: { [selector: string]: CSSProperties };
    isEditable?: boolean;
    text?: string;
    setText?: (text: string) => void;// React.Dispatch<SetStateAction<string>>;
    itemSource: Array<any>;
    displayMemberPath?: string;
    itemTemplate?: (data: any) => React.ReactNode;
    selectedItemTemplate?: (data: any) => React.ReactNode;
    selectedItem?: any;
    setSelectedItem?: React.Dispatch<SetStateAction<any>>;
    disabled?: boolean;
    onTextChange?: (value: any) => void;
    onChange?: (value: any) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export function MdSelect(props: IMdSelectProps) {
    const root = useRef<HTMLDivElement>(null);


    const {
        setText,
        itemSource,
        selectedItem,
        setSelectedItem,
        itemTemplate,
        selectedItemTemplate,
        displayMemberPath,
        onTextChange,
        onChange,
        onFocus,
        disabled,
        onBlur,
    } = props;

    const style = props.style ?? {};
    const isEditable = props.isEditable ?? false;
    const text = props.text ?? "";


    const [isFocused, setIsFocused] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    const focusHandler = (event: React.FocusEvent) => {
        if(!isFocused) {
            setIsFocused(true);
            setIsOpened(true);
            if (onFocus != null) {
                onFocus();
            }
        }
        event.target.classList.add('--focused');
    }

    const blurHandler = (event: React.FocusEvent) => {
        const enteringParent = root.current?.contains(event.relatedTarget);
        if(!enteringParent) {
            setIsFocused(false);
            setIsOpened(false);
            if (onBlur != null) {
                onBlur();
            }
        }
        event.target.classList.remove('--focused');
    }

    const toggleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked && !isOpened) {
            setIsOpened(true);
        }
        else if(!event.target.checked && isOpened) {
            setIsOpened(false);
        }
    }

    const textChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!isOpened) {
            setIsOpened(true);
        }
        if(setText != null) {
            setText(event.target.value);
        }
        if(onTextChange != null) {
            onTextChange(event.target.value);
        }
    }

    const itemClickHandler = (event: React.MouseEvent, item: any) => {
        let value = (displayMemberPath != null ? item[displayMemberPath] : item);

        if(isEditable && setText != null) {
            setText(value);
        }

        if(setSelectedItem != null) {
            setSelectedItem(item);
        }

        if(onChange != null) {
            onChange(value);
        }

        setIsOpened(false);

        if(document.activeElement instanceof HTMLInputElement) {
            document.activeElement.blur();
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if(!isFocused) {
            return;
        }

        if(event.code == 'ArrowDown') {
            let focusableItems = Array.from(root.current?.querySelectorAll('.--focusable-item') ?? []);
            let focusedItemIndex = index;
            let nextIndex = focusedItemIndex + 1;
            let  nextItem = focusableItems.length - 1 >= nextIndex ? focusableItems[nextIndex] : null;
            if(nextItem != null && nextItem instanceof HTMLInputElement) {
                nextItem.focus();
            }
            event.preventDefault();
        }
        else if (event.code == 'ArrowUp') {
            let focusableItems = Array.from(root.current?.querySelectorAll('.--focusable-item') ?? []);
            let focusedItemIndex = index;
            let prevIndex = focusedItemIndex - 1;
            let prevItem = focusableItems.length - 1 >= prevIndex ? focusableItems[prevIndex] : null;
            if(prevItem != null && prevItem instanceof HTMLInputElement) {
                prevItem.focus();
            }
            event.preventDefault();
        }
        else if((event.code == 'Enter' || 'NumpadEnter') && index > 0) {
            let item = props.itemSource[index - 1];

            let value = (displayMemberPath != null ? item[displayMemberPath] : item);

            if(isEditable && setText != null) {
                setText(value);
            }

            if(setSelectedItem != null) {
                setSelectedItem(item);
            }

            if(onChange != null) {
                onChange(value);
            }

            setIsOpened(false);
        }
    }


    useEffect(() => { }, [isFocused, isOpened]);


    return (
        <div ref={root}
            className={(
                "md-select"
                + (disabled ? " --disabled" : "")
                + (isOpened ? " --opened" : "")
            )}
            style={style["root"]}>
            {
                isEditable
                ? (
                        <>
                            <input className="md-select-text-field --focusable-item"
                                   style={style["text-field"]}
                                   type="text"
                                   value={text}
                                   onFocus={focusHandler}
                                   onBlur={blurHandler}
                                   onChange={textChangeHandler}
                                   onKeyDown={(e) => handleKeyDown(e, 0)}
                            />
                            {
                                !isFocused && selectedItem != null && (
                                    <div className="selected-value" style={{ position: 'absolute', top: 0, left: 0, display: 'flex', alignItems: 'center', pointerEvents: 'none', width: '100%', height: '100%'}}>
                                        {
                                            (() => {
                                                if (selectedItemTemplate != null) {
                                                    return selectedItemTemplate(selectedItem)
                                                } else if (displayMemberPath != null) {
                                                    return selectedItem[displayMemberPath]
                                                } else {
                                                    return selectedItem
                                                }
                                            })()
                                        }
                                    </div>
                                )
                            }
                        </>
                    )
                    : (
                        <>
                            <input className="options-view-button"
                                   type="checkbox"
                                   checked={isOpened}
                                   onBlur={blurHandler}
                                   onChange={toggleHandler}
                            />
                            <div className="toggle-button"
                                 style={style["toggle-button"]}>
                                <div className="selected-value">
                                    {
                                        selectedItem != null
                                            ? (
                                                (() => {
                                                    if (itemTemplate != null) {
                                                        return itemTemplate(selectedItem)
                                                    } else if (displayMemberPath != null) {
                                                        return selectedItem[displayMemberPath]
                                                } else {
                                                    return selectedItem
                                                }
                                            })()
                                        )
                                        : (
                                            "Выберите значение"
                                        )
                                }
                            </div>
                            <div className="toggle-button-icon"
                                style={style["toggle-button-icon"]}>
                                <ArrowsUpDownIcon

                                />
                            </div>
                        </div>
                    </>
                )
            }

            <div className={"options" + (isOpened ? " --opened" : "") + (itemSource.length == 0 ? " --empty" : "")}>
                {
                    itemSource.map((item, itemIndex) => {
                        return (
                            <div key={itemIndex}
                                 className={"option" + (
                             selectedItem != null
                                 ? (Utils.stringHash(JSON.stringify(selectedItem)) == Utils.stringHash(JSON.stringify(item))
                                     ? " --selected"
                                     : "")
                                 : "")
                         }
                         onMouseDown={(e) => e.preventDefault()}
                         onClick={(e) => itemClickHandler(e, item)}
                            >
                                <input type="checkbox"
                                    className="--focusable-item"
                                       onFocus={focusHandler}
                                       onBlur={blurHandler}
                                    onKeyDown={(e) => handleKeyDown(e, itemIndex + 1)}
                                />
                                <span className="label">
                                    {
                                        (() => {
                                            if (itemTemplate != null) {
                                                return itemTemplate(item)
                                            } else if (displayMemberPath != null) {
                                                return  item[displayMemberPath]
                                            } else {
                                                return item.toString()
                                            }
                                        })()
                                    }
                                </span>
                            </div>
                    )
                    })
                }
            </div>
        </div>
    )
}