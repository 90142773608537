import React, {
    useRef,
    useState,
    CSSProperties, useLayoutEffect, useEffect
} from "react";
import './style.scss'


interface IMdScrollViewer {
    children?: any;
    needsScrollBottom?: boolean,
    setNeedsScrollBottom?: React.Dispatch<React.SetStateAction<boolean>>,
    style?: { [selector: string]: CSSProperties }
}


export function MdScrollViewer({needsScrollBottom = false, setNeedsScrollBottom = () => {}, children = null, style = {}}: IMdScrollViewer) {
    const root = useRef(null);
    const scrollContainer = useRef(null);
    const content = useRef(null);

    const [shadowedTop, setShadowedTop] = useState<boolean>(false);
    const [shadowedBottom, setShadowedBottom] = useState<boolean>(false);


    const onScroll = () => {
        if(scrollContainer.current != null) {
            const element = scrollContainer.current as HTMLElement;
            setShadowedTop(element.scrollTop > 0)
            setShadowedBottom(element.scrollHeight > element.offsetHeight && element.scrollTop != (element.scrollHeight - element.offsetHeight));
        }
    }

    useEffect(() => {
        if(needsScrollBottom == false) {
            return;
        }
        if(scrollContainer.current != null) {
            (scrollContainer.current as HTMLElement).scrollTop = (scrollContainer.current as HTMLElement).scrollHeight;
            setNeedsScrollBottom(false);
        }
    }, [needsScrollBottom]);


    useEffect(() => {
        if (!content.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if(scrollContainer.current != null) {
                const element = scrollContainer.current as HTMLElement;
                setShadowedTop(element.scrollTop > 0);
            }

            if(scrollContainer.current != null) {
                const element = scrollContainer.current as HTMLElement;
                setShadowedBottom(element.scrollHeight > element.offsetHeight && element.scrollTop != (element.scrollHeight - element.offsetHeight));
            }
        });
        resizeObserver.observe(content.current);
        return () => resizeObserver.disconnect();
    }, []);


    return (
        <div
            ref={root}
            className={"md-scroll-viewer" + (shadowedTop ? " --shadowed-top" : "") + (shadowedBottom ? " --shadowed-bottom" : "")}
            style={style["root"]}
        >
            <div className="top-shadow"></div>
            <div ref={scrollContainer} className="scrollable-content" style={style["content"]} onScroll={onScroll}>
                <div ref={content}>
                    {
                        children
                    }
                </div>
            </div>
            <div className="bottom-shadow"></div>
        </div>
    );
}