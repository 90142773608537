import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    ModalContext,
    SEARCH_TICKETS_MODAL_ID,
} from "../../../commonComponents/modal/ModalContext";
import {IAviaTariffInfo, IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {ITrainTariffInfo, ITrainVariant} from "../../../../interfaces/ITrainVariant";
import {IOrder} from "../../../../interfaces/IOrderInfo";
import {MdModal} from "../../../../components/md-modal";
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompany";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {TrainIcon2} from "../../../../icons/TrainIcon2";
import {HotelIcon2} from "../../../../icons/HotelIcon2";
import { SearchTrainsComponent } from "./searchTrainsComponent";
import {SearchFlightsComponent} from "./searchFlightsComponent";
import "./style.scss"


interface IMultiSearchTicketsModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    order?: IOrder;
    selectedCompany: ICompany | undefined,
    submitHandler: (flight: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => void;
    submitHandler2: (train: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any) => void;
}


export function MultiSearchTicketsModal(props: IMultiSearchTicketsModal) {

    const {
        opened,
        setOpened,
        order,
        selectedCompany,
        submitHandler,
        submitHandler2
    } = props;

    const {t} = useTranslation();

    const {isOpen, open, close} = useContext(ModalContext);

    //const authState = useAppSelector((state) => state.auth);

    const [activeTab, setActiveTab] = useState(0);


    const onModalOpen = () => {
        if(!opened) {
            setOpened(true);
        }
        resetForm();
    }

    const onModalClose = () => {
        if(opened) {
            setOpened(false);
        }
        resetForm();
    }


    const selectFlight = (flight: IAviaVariant, tariff: IAviaTariffInfo, searchResponse: any) => {
        submitHandler(flight, tariff, searchResponse);
        setOpened(false);
    }


    const selectTrain = (train: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any) => {
        submitHandler2(train, tariff, searchResponse);
        setOpened(false);
    }


    const resetForm = () => { }

    useEffect(() => {
        if(isOpen(SEARCH_TICKETS_MODAL_ID) && !opened) {
            close(SEARCH_TICKETS_MODAL_ID);
        }
        else if(!isOpen(SEARCH_TICKETS_MODAL_ID) && opened) {
            open(SEARCH_TICKETS_MODAL_ID);
        }
    }, [opened]);


    useEffect(() => { }, []);


    return (
        <MdModal
            id={SEARCH_TICKETS_MODAL_ID}
            title={
                (t("bookingPage.ticketSearch") + (order != null ? " для заказа №" + order.orderNo : ""))
            }
            openHandler={onModalOpen}
            closeHandler={onModalClose}
            closeOnClickOutside={false}>

            <MdScrollViewer
                style={{
                    "root": {
                        width: "100%",
                        height: "100%",
                        minWidth: "120ppx",
                        padding: "40px",
                        maxHeight: "85vh",
                        backgroundColor: "rgba(220, 220, 220, 0.1)"
                    }
                }}
            >

                <div className="trip-page-container">

                    <div className="trip-page-tabs-container">
                        <div className="trip-page-tabs-header">
                            <button className={(activeTab == 0 ? "--selected" : "")}
                                    onClick={() => setActiveTab(0)}>
                            <span>
                                <AirplaneIcon
                                    width="20px"
                                    height="20px"
                                    style={{
                                        "svg": {
                                            transform: "rotate(-45deg)"
                                        }
                                    }}
                                />
                            </span>
                                <span>Авиа</span>
                            </button>
                            <button className={(activeTab == 1 ? "--selected" : "")}
                                    onClick={() => setActiveTab(1)}>
                            <span>
                                <TrainIcon2
                                    width="16px"
                                    height="16px"
                                />
                            </span>
                                <span>Ж/д</span>
                            </button>
                            <button>
                            <span>
                                <HotelIcon2
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "svg": {
                                            marginBottom: "2px"
                                        }
                                    }}
                                />
                            </span>
                                <span>Отели</span>
                            </button>
                        </div>
                        <div className="trip-page-tab" style={{ width: "90vw", maxWidth: "1200px" }}>
                            {
                                (() => {
                                    switch (activeTab) {
                                        case 0:
                                            return (
                                                <SearchFlightsComponent
                                                    order={order}
                                                    selectFlightHandler={selectFlight}
                                                    selectedCompany={selectedCompany}
                                                />
                                            );
                                        case 1:
                                            return (
                                                <SearchTrainsComponent
                                                    order={order}
                                                    selectTrainHandler={selectTrain}
                                                    selectedCompany={selectedCompany}
                                                />
                                            );
                                        default:
                                            return ""
                                    }
                                })()

                            }
                        </div>
                    </div>

                </div>

            </MdScrollViewer>

            {/*<MdTabs*/}
            {/*    activeIndex={activeTab}*/}
            {/*    onTabChange={(index) => setActiveTab(index)}*/}
            {/*    tabs={[*/}
            {/*        {*/}
            {/*            header: (*/}
            {/*                <span className="tab-header">*/}
            {/*                    Авиа*/}
            {/*                </span>*/}
            {/*            ),*/}
            {/*            content: (*/}
            {/*                <MultiSearchFlightsComponent*/}
            {/*                    selectedCompany={selectedCompany}*/}
            {/*                    selectFlightHandler={selectFlight}*/}
            {/*                />*/}
            {/*            )*/}
            {/*        },*/}
            {/*        {*/}
            {/*            header: (*/}
            {/*                <span className="tab-header">*/}
            {/*                    ЖД*/}
            {/*                </span>*/}
            {/*            ),*/}
            {/*            content: (*/}
            {/*                <MultiSearchTrainsComponent*/}
            {/*                    selectedCompany={selectedCompany}*/}
            {/*                    selectTrainHandler={selectTrain}*/}
            {/*                />*/}
            {/*            )*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*    style={{*/}
            {/*        "root": {*/}
            {/*            minHeight: "600px",*/}
            {/*            minWidth: "400px",*/}
            {/*            padding: "0px 20px 20px 20px"*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}

        </MdModal>
    );
}