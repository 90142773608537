import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const TrainIcon3 = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 32 32"
            style={style["svg"]}>
            <path
                d="M2,21h26.7c1.1,0,2.1-0.5,2.7-1.4c0.6-0.9,0.8-1.9,0.4-2.9c-1.4-4-5.2-6.7-9.6-6.7H2c-0.6,0-1,0.4-1,1v9
		           C1,20.6,1.4,21,2,21z M15,15v-3h4v3H15z M13,15H9v-3h4V15z M28.6,15H21v-3h1.3C24.8,12,27.1,13.1,28.6,15z M7,12v3H3v-3H7z"
                  style={style["path"]}
            />
            <path
                d="M31,23H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h30c0.6,0,1-0.4,1-1S31.6,23,31,23z"
                style={style["path"]}
            />
        </svg>
    )
}