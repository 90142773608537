import {useState, useEffect} from "react";
import "./style.scss";
import {IError} from "../../../../interfaces/dataInterfaces/IError";
import {IDirectionRequest} from "../../../../interfaces/requestsInterfaces/IDirectionRequest";

import {useTranslation} from "react-i18next";
import {SearchDirectionComponent} from "./SearchDirectionComponent.tsx";
import {CompactSearchStatusComponent} from "./CompactSearchStatusComponent";
import {CustomCheckboxButton} from "../../../commonComponents/checkBoxComponents/customCheckBoxButton";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {setOrderedMultiRoute} from "../../../../redux/orderSlice";

type SearchProps = {
    runSearch: (
        isOneWay: boolean,
        needSearchAvia: boolean,
        needSearchTrain: boolean,
    ) => {};

    //addDirection: () => void;
    //removeLastDirection: () => void;
    //updateDirection: (index: number, newDirection: IDirectionRequest) => void;

    directions: IDirectionRequest[]
    errors: IError[];

    hasPreviouslyResult: boolean;
    goToPreviouslyResult: () => void;

    isResultMode: boolean;
    //isSearching: boolean;
};

export function SearchComponent(props: SearchProps) {

    const {t} = useTranslation();
    const orderState = useAppSelector(state => state.order)
    const dispatch = useAppDispatch();

    //hooks
    //  default state true
    const [isOneWay, setIsOneWay] = useState(false);

    //filters
    const [needSearchAvia, setNeedSearchAvia] = useState(true);
    const [needSearchTrain, setNeedSearchTrain] = useState(false);
    const [needSearchHotel, setNeedSearchHotel] = useState(false);


    //ToDo need to implement
    const [showFlightNotes, setShowFlightNotes] = useState(false);
    const [showTrainNotes, setShowTrainNotes] = useState(false);
    const [trainNotes, setTrainNotes] = useState<string[]>([]);
    const [flightNotes, setFlightNotes] = useState<string[]>([]);

    const searchHandler = async () => {
        props.runSearch(isOneWay, needSearchAvia, needSearchTrain);
    };


    const addDirectionsToMultiRoad = () => {

        let lastRoad = orderState.orderedMultiRoad[orderState.orderedMultiRoad.length - 1]

        let direction: IDirectionRequest = {
            departDate: "",
            townFrom: lastRoad.townTo ?? "",
            townTo: "",
            clientCode: "",
            notes: ""
        }
        dispatch(setOrderedMultiRoute([...orderState.orderedMultiRoad, direction]))
    }

    const updateMultiRoadDirection = (index: number, newDirection: IDirectionRequest) => {

        let directions = orderState.orderedMultiRoad.slice();
        directions[index] = {
            ...directions[index],
            townFrom: newDirection.townFrom,
            townTo: newDirection.townTo,
            departDate: newDirection.departDate
        }
        dispatch(setOrderedMultiRoute([...directions]))
    }

    const removeLastDirectionOffMultiRoad = () => {
        const dirs = [...orderState.orderedMultiRoad];
        dispatch(setOrderedMultiRoute(dirs.slice(0, dirs.length - 1)))
    }

    useEffect(() => {

    }, [props]);

    return (
        <>
            <div className="order-search-container">
                {props.isResultMode && <CompactSearchStatusComponent directions={props.directions}/>}
                {!props.isResultMode &&
                    <>
                        <div className="order-search-filter-row">

                            <CustomCheckboxButton isChecked={needSearchAvia} onChange={setNeedSearchAvia}
                                                  label={t("orderPage.searchAvia")}/>
                            <CustomCheckboxButton isChecked={needSearchTrain} onChange={setNeedSearchTrain}
                                                  label={t("orderPage.searchTrain")}/>
                            <CustomCheckboxButton isChecked={needSearchHotel} onChange={setNeedSearchHotel}
                                                  label={t("orderPage.searchHotel")}/>
                            <CustomCheckboxButton isChecked={isOneWay} onChange={(value?: boolean) => {
                                setIsOneWay(true)
                            }} label={"One way/Round route"}/>
                            <CustomCheckboxButton isChecked={!isOneWay} onChange={(value?: boolean) => {
                                setIsOneWay(false)
                            }} label={"Multi-city route"}/>

                            <div style={{marginLeft: "auto"}}></div>
                            <CustomCheckboxButton isChecked={props.hasPreviouslyResult}
                                                  onChange={() => {
                                                      props.goToPreviouslyResult()
                                                  }}
                                                  label={props.hasPreviouslyResult ? "Show last search result" : "No last Search result"}/>
                        </div>

                        {/*<FlightOptionsSearchComponent flightNotes={[]} updateNotes={(vaues: Array<string>) => {*/}
                        {/*}}/>*/}
                        {/*<TrainOptionSearchComponent trainNotes={[]} updateNotes={(vaues: Array<string>) => {*/}
                        {/*}}/>*/}

                        <p>Directions: {props.directions?.length}</p>
                        {isOneWay && <div className="order-search-filter-row">
                            <SearchDirectionComponent
                                directionIndex={0}
                                hasBackwardDirection={false}
                                isOneWayMode={isOneWay}
                                isLastDirection={true}
                                roundDirections={orderState.orderedRoundRoute}/>
                        </div>}

                        {!isOneWay && orderState.orderedMultiRoad?.map((direction, index) => {
                            return (
                                <div className="order-search-filter-row" key={index}><SearchDirectionComponent
                                    directionIndex={index}
                                    hasBackwardDirection={true}
                                    isOneWayMode={isOneWay}
                                    runSearch={props.directions.length - 1 === index ? searchHandler : undefined}
                                    isLastDirection={props.directions.length - 1 === index}
                                    addEmptyDirection={addDirectionsToMultiRoad}
                                    directionOffMultiRoute={direction}
                                    updateDirections={updateMultiRoadDirection}
                                    removeDirection={removeLastDirectionOffMultiRoad}/>
                                </div>)
                        })}
                    </>
                }
            </div>
        </>
    );
}
