import axios from "axios";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStatementIncome} from '../interfaces/reportInterfaces/IStatementIncome';
import {IStatementSale} from '../interfaces/reportInterfaces/IStatementSale';
import {ReportsApi} from "../api/ReportsApi";
import {
    IClientStructureRequest,
    ICommonReportRequest,
    IGetAccountantDocumentsRequest,
} from "../interfaces/requestsInterfaces/reportRequests";
import {IRootState} from "./store";
import {
    ICustomerAdditionalService,
    ICustomerAirCompany,
    ICustomerFlight,
    ICustomerTicketOrder,
    ICustomerOpeningMonthlyBalance,
    IAccountantDocument,
    ICustomerRegister,
    IDocumentFilters,
    IFlightFilters,
    ITrainFilters
} from '../interfaces/reportInterfaces/customerInterfaces';
import {DocumentsApi} from "../api/DocumentsApi";
import {IClientStructure} from "../interfaces/companyInfoInterfaces/IClientStructure";
import {IReadyToDownloadDoc} from '../interfaces/reportInterfaces/accountantPageInterfaces';
import {IStatementOpenBalance} from "../interfaces/reportInterfaces/IStatementOpenBalance";
import {IStatementCloseBalance} from "../interfaces/reportInterfaces/IStatementCloseBalance";
import {Utils} from "../utils/utils";


interface ReportState {
    startReportDate: string;
    endReportDate: string;
    statementSales: IStatementSale[];
    statementIncomes: IStatementIncome[];
    statementOpenBalance: number;
    statementCloseBalance: number;

    flightFilters: IFlightFilters;
    trainFilters: ITrainFilters;
    customerFlights: ICustomerFlight[];
    customerTicketOrders: ICustomerTicketOrder[];
    customerAirCompanies: ICustomerAirCompany[];
    customerAdditionalServices: ICustomerAdditionalService[];
    customerOpeningMonthlyBalance: ICustomerOpeningMonthlyBalance[];

    reportClientStructureSales: IClientStructure[];
    reportClientStructureExchange: IClientStructure[];
    reportClientStructureRefound: IClientStructure[];

    //buhDocs Page
    documentFilters: IDocumentFilters;
    customerBuhDocs: IAccountantDocument[];
    availableEdmBuhDocs: IReadyToDownloadDoc[],
    sentToEdmBuhDocs: IAccountantDocument[];
    downloadingBuhDocs:string[]

    //Registers Page
    customerRegisterNames: string[];
    customerRegisters: ICustomerRegister[];
}


const initialState: ReportState = {
    startReportDate: "",
    endReportDate: "",

    statementSales: [],
    statementIncomes: [],
    statementCloseBalance: 0,
    statementOpenBalance: 0,

    flightFilters: {
        departDate: undefined,
        fromCity: undefined,
        toCity: undefined,
    },
    trainFilters: {
        departDate: undefined,
        fromCity: undefined,
        toCity: undefined,
    },

    customerFlights: [],
    customerTicketOrders: [],
    customerAirCompanies: [],
    customerAdditionalServices: [],
    customerOpeningMonthlyBalance: [],

    //
    reportClientStructureSales: [],
    reportClientStructureExchange: [],
    reportClientStructureRefound: [],

    //documentsPage
    documentFilters: {
        startDate: undefined,
        endDate: undefined,
        docNumber: undefined,
        direction: undefined,
        clientName: undefined,
        company: undefined,
    },
    customerBuhDocs: [],
    availableEdmBuhDocs: [],
    sentToEdmBuhDocs: [],
    downloadingBuhDocs:[],

    customerRegisterNames: [],
    customerRegisters: [],
};


const reportsApi = ReportsApi();
const documentsApi = DocumentsApi();


export const addAvailableEdmBuhDocsThunk = createAsyncThunk(
    "reports/addAvailableEdmBuhDocsThunk", async (docs: IReadyToDownloadDoc[], thunkAPI) => {
        return docs;
    }
);

export const getStatementSalesThunk = createAsyncThunk(
    "reports/getStatementSales", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getStatementSales(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
);

export const getStatementIncomesThunk = createAsyncThunk(
    "reports/getStatementIncomes", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getStatementIncomes(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
);

export const getStatementOpenBalanceThunk = createAsyncThunk(
    "reports/getStatementOpenBalance", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getStatementOpenBalance(request, state.auth.accessToken, axios.CancelToken.source().token);
            return !Utils.isEmpty(response.data) ? response.data : {} as IStatementOpenBalance;
        }
        catch (ex: any) {
            return {} as IStatementOpenBalance;
        }
    }
);

export const getStatementCloseBalanceThunk = createAsyncThunk(
    "reports/getStatementCloseBalance", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getStatementCloseBalance(request, state.auth.accessToken, axios.CancelToken.source().token);
            return !Utils.isEmpty(response.data) ? response.data : {} as IStatementCloseBalance;
        }
        catch (ex: any) {
            return {} as IStatementCloseBalance;
        }
    }
);

export const getCustomerFlightsThunk = createAsyncThunk(
    "reports/getCustomerFlights", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getCustomerFlights(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    });

export const getCustomerAirCompaniesThunk = createAsyncThunk(
    "reports/getCustomerAirCompanies", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getCustomerAirCompanies(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    });

export const getCustomerTicketOrdersThunk = createAsyncThunk(
    "reports/getCustomerTicketOrders", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getCustomerTicketOrders(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    });

export const getCustomerAdditionalServicesThunk = createAsyncThunk(
    "reports/getCustomerAdditionalServices", async (request: ICommonReportRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getCustomerAdditionalServices(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    });

export const getReportClientStructureSalesThunk = createAsyncThunk("reports/getReportClientStructureSales",
    async (request: IClientStructureRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientSalesStructure(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
)

export const getReportClientStructureExchangeThunk = createAsyncThunk("reports/getReportClientStructureExchange",
    async (request: IClientStructureRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientSalesStructure(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
)

export const getReportClientStructureRefoundThunk = createAsyncThunk("reports/getReportClientStructureRefound",
    async (request: IClientStructureRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await reportsApi.getClientSalesStructure(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }
)

export const getCustomerBuhDocsThunk = createAsyncThunk("reports/getCustomerBuhDocs",
    async (request: IGetAccountantDocumentsRequest, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IRootState;
            const response = await documentsApi.getAccountantDocuments(request, state.auth.accessToken, axios.CancelToken.source().token);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    })


export const reportsSlice = createSlice({
    name: "reports", initialState,
    reducers: {
        setStatementSales: (state, action: PayloadAction<IStatementSale[]>) => {
            state.statementSales = action.payload;
        },
        setStatementIncome: (state, action: PayloadAction<IStatementIncome[]>) => {
            state.statementIncomes = action.payload;
        },
        setStatementOpenBalance: (state, action: PayloadAction<number>) => {
            state.statementOpenBalance = action.payload;
        },
        setStatementCloseBalance: (state, action: PayloadAction<number>) => {
            state.statementCloseBalance = action.payload;
        },

        setFlightFilters: (state, action: PayloadAction<IFlightFilters>) => {
            state.flightFilters = action.payload;
        },
        setTrainFilters: (state, action: PayloadAction<ITrainFilters>) => {
            state.trainFilters = action.payload;
        },
        setCustomerFlights: (state, action: PayloadAction<ICustomerFlight[]>) => {
            state.customerFlights = action.payload;
        },
        setCustomerTicketOrders: (state, action: PayloadAction<ICustomerTicketOrder[]>) => {
            state.customerTicketOrders = action.payload;
        },

        setCustomerAirCompanies: (state, action: PayloadAction<ICustomerAirCompany[]>) => {
            state.customerAirCompanies = action.payload;
        },

        setCustomerAdditionalServices: (state, action: PayloadAction<ICustomerAdditionalService[]>) => {
            state.customerAdditionalServices = action.payload;
        },

        setCustomerOpeningMonthlyBalance: (state, action: PayloadAction<ICustomerOpeningMonthlyBalance[]>) => {
            state.customerOpeningMonthlyBalance = action.payload;
        },

        setReportClientStructureSales: (state, action: PayloadAction<IClientStructure[]>) => {
            state.reportClientStructureSales = action.payload;
        },

        setReportClientStructureExchange: (state, action: PayloadAction<IClientStructure[]>) => {
            state.reportClientStructureExchange = action.payload;
        },

        setReportClientStructureRefound: (state, action: PayloadAction<IClientStructure[]>) => {
            state.reportClientStructureRefound = action.payload;
        },

        // Accountant docs
        setDocumentFilters: (state, action: PayloadAction<IDocumentFilters>) => {
            state.documentFilters = action.payload;
        },
        setCustomerBuhDocs: (state, action: PayloadAction<IAccountantDocument[]>) => {
            state.customerBuhDocs = action.payload;
        },
        setAvailableEdmBuhDocs: (state, action: PayloadAction<IReadyToDownloadDoc[]>) => {
            state.availableEdmBuhDocs = action.payload;
        },
        setSentToEdmBuhDocs: (state, action: PayloadAction<IAccountantDocument[]>) => {
            state.sentToEdmBuhDocs = action.payload;
        },
        setDownloadingBuhDocs:(state, action:PayloadAction<string[]>)=>{
            state.downloadingBuhDocs = action.payload;
        },
        setStartReportDate: (state, action: PayloadAction<string>) => {
            state.startReportDate = action.payload;
        },
        setEndReportDate: (state, action: PayloadAction<string>) => {
            state.endReportDate = action.payload;
        },
        resetReports: (state) => {
            state.customerBuhDocs = [];
            state.availableEdmBuhDocs = [];
            state.sentToEdmBuhDocs = [];

        },

        resetDocumentFilters: (state) => {
            state.documentFilters.startDate = undefined;
            state.documentFilters.endDate = undefined;
            state.documentFilters.docNumber = undefined;
            state.documentFilters.direction = undefined;
            state.documentFilters.clientName = undefined;
            state.documentFilters.company = undefined;
        },

        setCustomerRegisterNames: (state, action: PayloadAction<string[]>) => {
            state.customerRegisterNames = action.payload;
        },
        setCustomerRegisters: (state, action: PayloadAction<ICustomerRegister[]>) => {
            state.customerRegisters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addAvailableEdmBuhDocsThunk.fulfilled, (state, action: PayloadAction<IReadyToDownloadDoc[]>) => {
                state.availableEdmBuhDocs = [...state.availableEdmBuhDocs, ...action.payload]
            })
            .addCase(getStatementSalesThunk.fulfilled, (state, action: PayloadAction<IStatementSale[]>) => {
                state.statementSales = action.payload
            })
            .addCase(getStatementIncomesThunk.fulfilled, (state, action: PayloadAction<IStatementIncome[]>) => {
                state.statementIncomes = action.payload
            })
            .addCase(getStatementOpenBalanceThunk.fulfilled, (state, action: PayloadAction<IStatementOpenBalance>) => {
                state.statementOpenBalance = action.payload.openBalance;
            })
            .addCase(getStatementCloseBalanceThunk.fulfilled, (state, action: PayloadAction<IStatementCloseBalance>) => {
                state.statementCloseBalance = action.payload.closeBalance;
            })
            .addCase(getCustomerFlightsThunk.fulfilled, (state, action: PayloadAction<ICustomerFlight[]>) => {
                state.customerFlights = action.payload
            })
            .addCase(getCustomerAirCompaniesThunk.fulfilled, (state, action: PayloadAction<ICustomerAirCompany[]>) => {
                state.customerAirCompanies = action.payload
            })
            .addCase(getCustomerTicketOrdersThunk.fulfilled, (state, action: PayloadAction<ICustomerTicketOrder[]>) => {
                state.customerTicketOrders = action.payload
            })
            .addCase(getCustomerAdditionalServicesThunk.fulfilled, (state, action: PayloadAction<ICustomerAdditionalService[]>) => {
                state.customerAdditionalServices = action.payload
            })
            .addCase(
                getReportClientStructureSalesThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                    state.reportClientStructureSales = action.payload
                })
            .addCase(
                getReportClientStructureExchangeThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                    state.reportClientStructureExchange = action.payload
                })
            .addCase(
                getReportClientStructureRefoundThunk.fulfilled, (state, action: PayloadAction<IClientStructure[]>) => {
                    state.reportClientStructureRefound = action.payload
                })
            .addCase(
                getCustomerBuhDocsThunk.fulfilled, (state, action: PayloadAction<IAccountantDocument[]>) => {
                    state.customerBuhDocs = action.payload
                }
            )
    }
})


export const {
    setDocumentFilters,
    setCustomerBuhDocs,
    setAvailableEdmBuhDocs,
    setSentToEdmBuhDocs,
    setStartReportDate,
    resetReports,
    resetDocumentFilters,
    setDownloadingBuhDocs
} = reportsSlice.actions;


export const reportsState = (state: IRootState): ReportState => {
    return {

        startReportDate: state.reports.startReportDate,
        endReportDate: state.reports.endReportDate,

        statementSales: state.reports.statementSales,
        statementIncomes: state.reports.statementIncomes,
        statementOpenBalance: state.reports.statementOpenBalance,
        statementCloseBalance: state.reports.statementCloseBalance,

        flightFilters: state.reports.flightFilters,
        trainFilters: state.reports.trainFilters,
        customerFlights: state.reports.customerFlights,
        customerTicketOrders: state.reports.customerTicketOrders,
        customerAirCompanies: state.reports.customerAirCompanies,
        customerAdditionalServices: state.reports.customerAdditionalServices,
        customerOpeningMonthlyBalance: state.reports.customerOpeningMonthlyBalance,

        reportClientStructureExchange: state.reports.reportClientStructureExchange,
        reportClientStructureRefound: state.reports.reportClientStructureRefound,
        reportClientStructureSales: state.reports.reportClientStructureSales,

        documentFilters: state.reports.documentFilters,
        customerBuhDocs: state.reports.customerBuhDocs,
        availableEdmBuhDocs: state.reports.availableEdmBuhDocs,
        sentToEdmBuhDocs: state.reports.sentToEdmBuhDocs,
        downloadingBuhDocs:state.reports.downloadingBuhDocs,

        customerRegisterNames: state.reports.customerRegisterNames,
        customerRegisters: state.reports.customerRegisters,
    }
}

export default reportsSlice.reducer;
