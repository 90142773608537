import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IReadyToDownloadDoc} from "../../../../interfaces/reportInterfaces/accountantPageInterfaces";
import {DocumentFilesComponent} from "../documentFilesComponent";
import {MdButton} from "../../../../components/md-button";
import {MdTable} from "../../../../components/md-table";
import {CheckMarkIcon} from "../../../../icons/CheckMarkIcon";
import {WaitIcon} from "../../../../icons/WaitIcon";
import {MdCheckbox} from "../../../../components/md-checkbox";
import {PaperPlaneIcon} from "../../../../icons/PaperPlaneIcon";
import "./style.scss";
import "../../../../scss/tables.scss";
import {IAccountantDocument} from "../../../../interfaces/reportInterfaces/customerInterfaces";
import {useAppSelector} from "../../../../redux/hooks";
import {reportsState} from "../../../../redux/reportsSlice";


interface DocumentTableProps {
    //docs?: IAccountantDocument[];
    selectDocument: (doc: IAccountantDocument) => void;
    unselectDocument: (doc: IAccountantDocument) => void;
    selectAllDocuments: () => void;
    unselectAllDocuments: () => void;
    selectedDocs?: IAccountantDocument[];
    sentDocs: IAccountantDocument[];
    setActiveDocumentHandler: (doc: IAccountantDocument) => void;
    downloadableDocs: IReadyToDownloadDoc[];
   // downloadingDocs: string[];
    activeDoc?: IAccountantDocument;
    downloadPDFDocumentHandler: (link: string) => void;
    generateDocumentsHandler: (docNumber: string) => void;
    isLoading: boolean;
    isSearchResultNotFound: boolean;
    openDocumentSendModal: () => void;
}


export function DocumentTableComponent({
                                           //docs,
                                           selectedDocs,
                                           unselectDocument,
                                           selectAllDocuments,
                                           unselectAllDocuments,
                                           selectDocument,
                                           setActiveDocumentHandler,
                                           downloadableDocs,
                                           //downloadingDocs,
                                           activeDoc,
                                           downloadPDFDocumentHandler,
                                           generateDocumentsHandler,
                                           isLoading,
                                           isSearchResultNotFound,
                                           openDocumentSendModal,
                                       }: DocumentTableProps) {

    const {t} = useTranslation();

    //let tableData = docs;
    const reportsState =  useAppSelector(state=>state.reports);

    const itemsPerPage = 10;

    const isTableLoading = isLoading;
    const isEmptySearchResult = isSearchResultNotFound;


    //const [docList, setDocList] = useState<IAccountantDocument[]>(documentsState.customerBuhDocs??[]);
    const [expandedDocuments, setExpandedDocuments] = useState<string[]>([]);
    const [activePage, setActivePage] = useState(1);


    const isActiveDocument = (docNumber: string) => {
        return (activeDoc !== undefined && activeDoc.docNo === docNumber);
    };

    const getEDMStatus = (docNumber: string) => {
        let IsDownloadable = downloadableDocs?.some(x => x.docNumber == docNumber);
        let IsDownloading = reportsState.downloadingBuhDocs?.includes(docNumber);
        if (IsDownloadable) {
            return 1;
        } else if (IsDownloading) {
            return 0;
        }
        return -1;
    };

    const toggleDocumentDetails = (doc: IAccountantDocument) => {
        let docNumber = doc.docNo.trim();
        let index = expandedDocuments.indexOf(docNumber);
        if (index === -1) {
            setExpandedDocuments([...expandedDocuments, docNumber]);
        } else {
            setExpandedDocuments(expandedDocuments.filter(x => x != docNumber));
        }
    };

    // const onPageChange = (pageNumber: number) => {
    //     let skip = Math.max(0, (pageNumber - 1) * itemsPerPage);
    //     setActivePage(pageNumber);
    //     if (tableData != null) {
    //         setDocList(tableData.slice(skip, skip + itemsPerPage));
    //     }
    // }

    // useEffect(() => {
    //     if (tableData != null) {
    //         //let skip = Math.max(0, (activePage - 1) * itemsPerPage);
    //         //setDocList(tableData.slice(skip, skip + itemsPerPage));
    //         setDocList(tableData);
    //     } else {
    //         setDocList([]);
    //     }
    // }, [selectedDocs, docs, expandedDocuments, downloadableDocs, activePage]);


    return (
        <>
            <div className="document-results">
                <div className="document-results-header">
                    <div className="document-results-caption">{t("documentPage.documents")}
                        <span style={{display: (isTableLoading ? "none" : "block")}}>{reportsState.customerBuhDocs?.length}</span>
                        <span className="document-results-preloader"
                              style={{display: (isTableLoading ? "flex" : "none")}}>
                            <div className="spinner-border document-results-preloader-icon"></div>
                            <span className="document-results-preloader-text">{t("commonText.searching")}</span>
                        </span>
                    </div>
                    <div className="document-results-tools">

                        <MdButton
                            icon={
                                <PaperPlaneIcon
                                    width="16px"
                                    height="16px"
                                    style={{
                                        "path": {
                                            fill: "rgb(100 100 100)"
                                        }
                                    }}
                                />
                            }
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            onClick={() => openDocumentSendModal()}
                            disabled={(isTableLoading || selectedDocs == null || selectedDocs.length === 0)}
                        >
                            {`${t("documentPage.send")}`}
                        </MdButton>
                    </div>
                </div>

                {
                    !isTableLoading && !isEmptySearchResult && reportsState.customerBuhDocs!==undefined && reportsState.customerBuhDocs.length > 0 && (
                        <MdTable
                            columns={[
                                {
                                    width: 45,
                                    headerTemplate() {
                                        return (
                                            <MdCheckbox
                                                type="filled"
                                                checked={(selectedDocs?.length === reportsState.customerBuhDocs.length && reportsState.customerBuhDocs.length > 0)}
                                                style={{
                                                    "input": {
                                                        width: "16px",
                                                        height: "16px",
                                                    },
                                                }}
                                                onChecked={selectAllDocuments}
                                                onUnchecked={unselectAllDocuments}
                                            />
                                        )
                                    },
                                    cellTemplate(data: IAccountantDocument) {
                                        return (
                                            <MdCheckbox
                                                type="filled"
                                                checked={
                                                    selectedDocs?.some((d) => d.docNo === data.docNo)
                                                }
                                                style={{
                                                    "input": {
                                                        "--border-active": (isActiveDocument(data.docNo) ? "#FFFFFF" : "rgba(62, 92, 184, 0.8)"),
                                                        "--stroke": (isActiveDocument(data.docNo) ? "rgba(62, 92, 184, 0.8)" : "#FFFFFF"),
                                                        width: "16px",
                                                        height: "16px",
                                                    },
                                                }}
                                                onChanged={(checked: boolean) => {
                                                    if (checked) {
                                                        selectDocument(data);
                                                    } else {
                                                        unselectDocument(data);
                                                    }
                                                }
                                                }
                                            />
                                        )
                                    }
                                },
                                {
                                    width: 100,
                                    header: t("documentPage.date"),
                                    cellTemplate(data: IAccountantDocument) {
                                        return <div>{data.docDate}</div>
                                    },
                                },
                                {
                                    width: 100,
                                    header: "№",
                                    cellTemplate(data: IAccountantDocument) {
                                        return <div>{data.docNo}</div>
                                    }
                                },
                                {
                                    header: t("documentPage.client"),
                                    cellTemplate(data) {
                                        return <div>{data.customer}</div>
                                    },
                                    style: {
                                        justifyContent: "start"
                                    }
                                },
                                // {
                                //     header: t("documentPage.payer"),
                                //     cellTemplate(data: IAccountantDocument) {
                                //         return <div>{data.payer}</div>
                                //     }
                                // },
                                {
                                    width: 100,
                                    header: t("documentPage.var"),
                                    cellTemplate(data: IAccountantDocument) {
                                        return <div>{data.vatAmount}</div>
                                    }
                                },
                                {
                                    width: 100,
                                    header: t("documentPage.amount"),
                                    cellTemplate(data: IAccountantDocument) {
                                        return <div>{data.amount}</div>
                                    }
                                },
                                {
                                    width: 110,
                                    header: t("documentPage.files"),
                                    cellTemplate(data: IAccountantDocument) {
                                        const edmStatus = getEDMStatus(data.docNo.trim());
                                        return (
                                            edmStatus == 1
                                                ? (
                                                    <div className="doc-state-indicator --success"
                                                         style={{width: "100px"}}>
                                                        <div style={{display: "flex", gap: "5px"}}>
                                                                <span>
                                                                    <CheckMarkIcon
                                                                        width="10px"
                                                                        height="10px"
                                                                        style={{
                                                                            "path": {
                                                                                stroke: "#74B100"
                                                                            }
                                                                        }}
                                                                    />
                                                                </span>
                                                            <span>{t("commonText.loaded")}</span>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    edmStatus === 0
                                                        ? (
                                                            <div className="doc-state-indicator --progress"
                                                                 style={{width: "100px"}}>
                                                                <div style={{display: "flex", gap: "5px"}}>
                                                                        <span>
                                                                            <WaitIcon
                                                                                width="10px"
                                                                                height="10px"
                                                                                style={{
                                                                                    "path": {
                                                                                        fill: "rgb(100, 100, 100)"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    {t("commonText.expectation")}
                                                                </div>
                                                            </div>
                                                        )
                                                        : (
                                                            <button
                                                                className="download-documents-button"
                                                                onClick={() => generateDocumentsHandler(data.docNo.trim())}
                                                            >
                                                                {t("commonText.load")}
                                                            </button>
                                                        )
                                                )
                                        )
                                    }
                                },
                                {
                                    header: "",
                                    width: 45,
                                    cellTemplate(data: IAccountantDocument) {
                                        const edmStatus = getEDMStatus(data.docNo.trim());
                                        let isRowExpanded = expandedDocuments.includes(data.docNo.trim());
                                        return (
                                            edmStatus == 1
                                            && (
                                                <button
                                                    className={"row-details-toggle-button " + (isRowExpanded ? "--expanded" : "")}
                                                    onClick={(e) => {
                                                        toggleDocumentDetails(data)
                                                    }}>
                                                </button>
                                            )
                                        )
                                    }
                                },
                            ]}
                            itemSource={reportsState.customerBuhDocs}
                            rowDetailsTemplate={(data: IAccountantDocument) => {
                                if (!expandedDocuments.includes(data.docNo.trim())) {
                                    return;
                                }
                                return (
                                    <DocumentFilesComponent
                                        availableDocs={
                                            downloadableDocs &&
                                            downloadableDocs.filter(
                                                (x) => x.docNumber === data.docNo.trim()
                                            )
                                        }
                                        activBuhDoc={data}
                                        downloadPDFDocumentHandler={downloadPDFDocumentHandler}
                                    />
                                )
                            }}
                            onRowSelect={(data) => setActiveDocumentHandler(data)}
                            style={{
                                "tbody": {
                                    maxHeight: "calc(100vh - 400px)"
                                }
                            }}
                        />
                    )
                }

                {
                    isTableLoading && (
                        <div className="table-skeleton-preloader">
                            {
                                Array.from(Array(itemsPerPage + 1).keys()).map((item, index) => {
                                    return (
                                        <div key={index} className="table-skeleton-item"></div>
                                    )
                                })
                            }
                        </div>
                    )
                }

                {
                    isEmptySearchResult && (
                        <div className="empty-search-result-message">
                            Документы не найдены, попробуйте изменить параметры поиска и повторите.
                        </div>
                    )
                }

            </div>

            {/*<div className="pagination-container" style={{float: "right"}}>*/}
            {/*    <MdPagination*/}
            {/*        activePage={activePage}*/}
            {/*        itemsCount={(tableData != null ? tableData.length : 0)}*/}
            {/*        itemsPerPage={itemsPerPage}*/}
            {/*        onChange={(page) => onPageChange(page)}*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    );
}
