import { IIconProps } from "../interfaces/componentInterfaces/IIconProps";
import { DEFAULT_ICON_SIZE } from "../constants/componentsContants";

export function FilterIcon({ iconSize }: IIconProps) {
  if (iconSize === undefined) {
    iconSize = DEFAULT_ICON_SIZE;
  }
  return (
    <>
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="miter"
      >
        <polygon points="22 4.5 14 14 14 22 10 22 10 14 2 4.5 2 2 22 2 22 4.5"></polygon>
      </svg>
    </>
  );
}
