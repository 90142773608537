import {Validator} from "fluentvalidation-ts";
import {useTranslation} from "react-i18next";


export class EmailValidator extends Validator<{email:string}>{

    constructor() {
        super();
        this.ruleFor("email")
            .notEmpty()
            .emailAddress()
            //.withMessage(t("asdf"))
            .withMessage("Invalid Email");
    }

}