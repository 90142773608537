import Chart from "react-google-charts";

interface DataTupleProps {
  data: [date: Date, value: number];
}

interface CommonCalendarProps {
  dataAray: DataTupleProps[];
}

export function CommonCalendarComponent() {
  const data = [
    [
      { type: "date", id: "Date" },
      { type: "number", id: "Won/Loss" },
    ],
    [new Date(2024, 2, 4), 10],
    [new Date(2024, 2, 5), 3],
    [new Date(2024, 2, 7), -1],
    [new Date(2024, 2, 8), 2],
    [new Date(2024, 2, 12), -1],
    [new Date(2024, 2, 13), 1],
    [new Date(2024, 2, 15), 1],
    [new Date(2024, 2, 16), -4],
    [new Date(2024, 1, 4), 10],
    [new Date(2024, 1, 5), 3],
    [new Date(2024, 1, 7), -1],
    [new Date(2024, 1, 8), 2],
    [new Date(2024, 1, 12), -1],
    [new Date(2024, 1, 13), 1],
    [new Date(2024, 1, 15), 1],
    [new Date(2024, 1, 16), -4],
  ];

  const options = {
    title: "Sales Avia",
    calendar: { cellSize: 20, daysOfWeek: "ВПВСЧПС" },
  };

  return (
    <>
      <Chart
        chartType="Calendar"
        width="100%"
        height="250px"
        data={data}
        options={options}
      />
    </>
  );
}
