import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../constants/componentsContants";

export function ArrowRightColorIcon({iconSize}:IIconProps) {
	if (iconSize === undefined) {
		iconSize = DEFAULT_ICON_SIZE;
	}
    return (<>
        <svg version="1.1"
             id="Layer_2"
            //xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px"
             y="0px"
             viewBox="0 0 512 512"
             enableBackground="new 0 0 512 512"
			 height={iconSize}
			 width={iconSize}
            //xml:space="preserve"
        >
            <g>
                <g>
                    <polygon fill="#6E83B7" points="502,256 302,106 302,186 146,186 146,326 302,326 302,406 		"/>
                </g>
                <g>
                    <rect x="78" y="186" fill="#6E83B7" width="40" height="140"/>
                </g>
                <g>
                    <rect x="10" y="186" fill="#6E83B7" width="40" height="140"/>
                </g>
            </g>
        </svg>
    </>)
}