import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const ArrowsUpDownIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 1024 1024"
            style={style["svg"]}>
            <path
                d="M620.6 562.3l36.2 36.2L512 743.3 367.2 598.5l36.2-36.2L512 670.9l108.6-108.6zM512 353.1l108.6 108.6 36.2-36.2L512 280.7 367.2 425.5l36.2 36.2L512 353.1z"
                style={style["path"]}
            />
        </svg>
    )
}