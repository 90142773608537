import { Dispatch, SetStateAction, useEffect, useState } from "react"

function getSessionStorageOrDefault<T>(key: string, defaultItem: T): T {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
        return defaultItem;
    }

    let result: T = JSON.parse(stored);
    return result;
}

export function useSessionStorage<T>(key: string, defaultItem: T): [T, Dispatch<SetStateAction<T>>] {
    const [value, setValue] = useState<T>(getSessionStorageOrDefault(key, defaultItem))

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue]
}

