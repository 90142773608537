import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const CompanyIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={style["svg"]}
        >
            <path
                d="M11,3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3V22h9ZM9,18H4V16H9Zm0-4H4V12H9Zm0-4H4V8H9ZM9,6H4V4H9ZM21,8H14a1,1,0,0,0-1,1V22h9V9A1,1,0,0,0,21,8ZM17,20H15V18h2Zm0-4H15V14h2Zm0-4H15V10h2Zm3,8H18V18h2Zm0-4H18V14h2Zm0-4H18V10h2Z"
                style={style["path"]}/>
        </svg>
    )
}