import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IOrder, OrderStatus} from "../../../../interfaces/IOrderInfo";
import {TripService} from "../../../../services/TripService";
import {MdButton} from "../../../../components/md-button";
import {MdTable} from "../../../../components/md-table";
import {OrderModal} from "../orderComponent";
import {ScheduleIcon} from "../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import "./style.scss";
import "../../../../scss/tables.scss";


interface IOrdersTableComponent {
    orders: IOrder[];
    isLoading: boolean,
    isSearchResultNotFound: boolean,
    createOrderHandler: (order?: IOrder) => void;
    searchOrderHandler: () => void;
}


export function OrdersTableComponent({
        orders,
        isLoading,
        isSearchResultNotFound,
        createOrderHandler,
        searchOrderHandler
    }: IOrdersTableComponent) {

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const tripService = TripService();

    let tableData = orders;

    const itemsPerPage = 10;

    const isTableLoading = isLoading;
    const isEmptySearchResult = isSearchResultNotFound;


    const [orderList, setOrderList] = useState<IOrder[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const [selectedOrderNumber, setSelectedOrderNumber] = useState<string>("");

    const [orderDetailsModalOpened, setOrderDetailsModalOpened] = useState<boolean>(false);


    const openOrderDetailsHandler = async (order: IOrder) => {
        setSelectedOrderNumber(order.orderNo);
        setOrderDetailsModalOpened(true);
    }


    useEffect(() => {
        if (tableData != null) {
            //let skip = Math.max(0, (activePage - 1) * itemsPerPage);
            //setOrderList(tableData.slice(skip, skip + itemsPerPage));
            setOrderList(tableData);
        } else {
            setOrderList([]);
        }
    }, [orders, /*selectedOrders, */activePage]);


    return (
        <>

            <div className="orders-results">
                <div className="orders-results-header">
                    <div className="orders-results-caption">
                        {`${t("bookingPage.orders")}`}
                        <span style={{display: (isTableLoading ? "none" : "block")}}>{tableData?.length}</span>
                        <span className="orders-results-preloader"
                            style={{display: (isTableLoading ? "flex" : "none")}}>
                            <div className="spinner-border orders-results-preloader-icon"></div>
                            <span className="orders-results-preloader-text">
                                {`${t("bookingPage.searching")}`}
                            </span>
                        </span>
                    </div>
                    <div className="orders-results-tools">
                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            disabled={isTableLoading}
                            onClick={() => createOrderHandler()}
                        >
                            {`${t("bookingPage.create")}`}
                        </MdButton>
                    </div>
                </div>

                {
                    (!isTableLoading && !isEmptySearchResult && orders.length > 0) && (
                        <MdTable
                            columns={[
                                {
                                    width: 200,
                                    header: t("bookingPage.number"),
                                    cellTemplate(data: IOrder) {
                                        return <div>{data.orderNo}</div>
                                    }
                                },
                                {
                                    width: 200,
                                    header: t("bookingPage.created"),
                                    cellTemplate(data: IOrder) {
                                        return <div>{new Date(new Date(data.createdAt).getTime() - new Date().getTimezoneOffset() * 60000).toLocaleString()}</div>
                                    }
                                },
                                // {
                                //     width: 200,
                                //     header: t("bookingPage.title"),
                                //     cellTemplate(data: IOrder) {
                                //         return <div>{data.orderTitle}</div>
                                //     }
                                // },
                                // {
                                //     width: 200,
                                //     header: t("bookingPage.comment"),
                                //     cellTemplate(data: IOrder) {
                                //         return <div>{data.comment}</div>
                                //     }
                                // },
                                // {
                                //     width: 200,
                                //     header: t("bookingPage.closed"),
                                //     cellTemplate(data) {
                                //         return <div>
                                //             {
                                //                 <div className="doc-state-indicator">
                                //                     <div style={{display: "flex", gap: "5px"}}>
                                //                                     <span>
                                //                                         <CrossIcon
                                //                                             width="10px"
                                //                                             height="10px"
                                //                                             style={{
                                //                                                 "path": {
                                //                                                     stroke: "#999999"
                                //                                                 }
                                //                                             }}
                                //                                         />
                                //                                     </span>
                                //                     </div>
                                //                 </div>
                                //             }
                                //         </div>
                                //     }
                                // },
                                {
                                    header:"",
                                    cellTemplate(data: IOrder) {
                                        return (
                                            <div style={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }}>
                                                {
                                                    data.status === OrderStatus.PreOrder && (
                                                        <MdButton
                                                            style={{
                                                                "button": {
                                                                    borderWidth: 0,
                                                                    backgroundColor: "rgb(233 233 233)",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "6px",
                                                                    height: "35px",
                                                                    padding: "5px 15px"
                                                                },
                                                                "icon": {
                                                                    marginRight: "5px",
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                                }
                                                            }}
                                                            onClick={() => createOrderHandler(data)}
                                                        >
                                                            Продолжить
                                                        </MdButton>
                                                    )
                                                }

                                                {
                                                    data.status !== OrderStatus.PreOrder && (
                                                        <MdButton
                                                            style={{
                                                                "button": {
                                                                    borderWidth: 0,
                                                                    backgroundColor: "rgb(233 233 233)",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "6px",
                                                                    height: "35px",
                                                                    padding: "5px 15px"
                                                                },
                                                                "icon": {
                                                                    marginRight: "5px",
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                                }
                                                            }}
                                                            onClick={() => openOrderDetailsHandler(data)}
                                                        >
                                                            {t("bookingPage.details")}
                                                        </MdButton>
                                                    )
                                                }

                                            </div>
                                        )
                                    }
                                },
                            ]}
                            itemSource={orderList}
                            onPreviewRowSelect={() => false}
                            //onRowSelect={(data: IOrder) => openOrderDetailsHandler(data)}
                            style={{
                                "tbody": {
                                    width: "100%",
                                    maxHeight: "calc(100vh - 400px)"
                                }
                            }}
                        />
                    )
                }

                {/*{*/}
                {/*    isTableLoading && (*/}
                {/*        <div className="table-skeleton-preloader">*/}
                {/*            {*/}
                {/*                Array.from(Array(itemsPerPage + 1).keys()).map((item, index) => {*/}
                {/*                    return (*/}
                {/*                        <div key={index} className="table-skeleton-item"></div>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

                {(isEmptySearchResult) && (
                    <div className="no-orders-message" style={{ margin: "40px 0"}}>
                        <div className="no-orders-message-icon">
                            <ScheduleIcon width="60px" height="60px" style={{
                                "path": {
                                    fill: "rgb(100 100 100)"
                                }
                            }}/>
                        </div>
                        <div className="no-orders-message-caption">{t("bookingPage.noOrders")}</div>
                        <div className="no-orders-message-content">{t("bookingPage.createOrSearch")}
                        </div>
                        <div className="no-orders-message-button">
                            {
                                !isTableLoading
                                ? (
                                        <MdButton
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(233 233 233)",
                                                    color: "rgb(0 0 0)",
                                                    borderRadius: "6px",
                                                    minWidth: "92px",
                                                    height: "35px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            disabled={isTableLoading}
                                            onClick={() => searchOrderHandler()}
                                        >
                                            {t("bookingPage.repeat")}
                                        </MdButton>
                                    )
                                    : (
                                        <CircleSpinnerIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "circle": {
                                                    stroke: "rgb(50 50 50)"
                                                }
                                            }}
                                        />
                                    )
                            }
                        </div>
                    </div>
                )}

            </div>

            {/*<div className="pagination-container" style={{float: "right"}}>*/}
            {/*    <MdPagination*/}
            {/*        activePage={activePage}*/}
            {/*        itemsCount={(tableData != null ? tableData.length : 0)}*/}
            {/*        itemsPerPage={itemsPerPage}*/}
            {/*        onChange={(page) => onPageChange(page)}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<OrderDetailsModal*/}
            {/*    opened={orderDetailsModalOpened}*/}
            {/*    setOpened={setOrderDetailsModalOpened}*/}
            {/*    orderInfo={selectedOrder}*/}
            {/*    //submitHandler={bookOrder}*/}
            {/*/>*/}


            <OrderModal
                opened={orderDetailsModalOpened}
                setOpened={setOrderDetailsModalOpened}
                orderNo={(selectedOrderNumber)}
            />

        </>
    );
}
