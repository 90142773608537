export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

export class Utils {
    static stringHash(str: string, seed = 0) {
        let hash = seed;
        if ((str ?? "").length === 0) return hash;
        for (let i = 0; i < str.length; i++) {
            let chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }
        return hash;
    }
    static stringToHslColor(str: string, saturation: number = 30, lightness: number = 60) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let h = hash % 360;
        return 'hsl('+h+', '+saturation+'%, '+lightness+'%)';
    }
    static isEmpty(value: any) {
        return (value == null || value.length === 0);
    }
    static mod(a: number, n: number) {
        return ((a % n) + n) % n;
    }
}