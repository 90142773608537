import {useTranslation} from "react-i18next";

export function AccessDeniedPage () {
    const {t} =useTranslation();

    return(
        <>
            <h2
                style={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "50px",
                }}
            >
                {t("accessDeniedPage.accessDenied")}
            </h2>
            <h3  style={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "50px",
            }}> {t("accessDeniedPage.makeActionMsg")}</h3>
        </>
    )
}