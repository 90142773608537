import "./style.scss";
import {ICheckBox} from "../../../../interfaces/componentInterfaces/ICheckBox";


export function CustomCheckBox ({title, isChecked, onChange,icon, style={}}:ICheckBox) {
    return(<>

       {/*!Need update later*/}
        {/*<div className="custom-checkbox-container">*/}
        {/*    <label className="align-self-center">*/}
        {/*        <input  className="custom-checkbox" type="checkbox"*/}
        {/*        checked={isChecked}*/}
        {/*        onChange={(e) =>*/}
        {/*            onChange(!isChecked)}/>*/}
        {/*    </label>*/}
        {/*    <label>{title}</label>*/}

        {/*</div>*/}
        <div className="checkbox-wrapper-29">
            <label className="checkbox" style={style["label"]}>
                <input type="checkbox" className="checkbox__input"
                checked={isChecked}
                onChange={() =>
                    onChange(!isChecked)}/>
                <span className="checkbox__label"></span>
                {title}{icon && <> {icon}</>}
            </label>
        </div>
    </>)
}