import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {
    ModalContext,
    SEARCH_PASSENGERS_MODAL_ID,
} from "../../../commonComponents/modal/ModalContext";
import {ICompany} from "../../../../interfaces/companyInfoInterfaces/ICompany";
import {IPassenger} from "../../../../interfaces/IPassenger";
import {IOrder} from "../../../../interfaces/IOrderInfo";
import {TripService} from "../../../../services/TripService";
import {MdButton} from "../../../../components/md-button";
import {MdModal} from "../../../../components/md-modal";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdCheckbox} from "../../../../components/md-checkbox";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {SearchIcon2} from "../../../../icons/SearchIcon2";
import {MagnifierIcon} from "../../../../icons/MagnifierIcon";
import {CalendarIcon} from "../../../../icons/CalendarIcon";
import {Guid} from "../../../../utils/utils";
import "./style.scss"


interface IPassengersSelectionModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    order?: IOrder,
    company: ICompany | undefined,
    tripPassengers: { passenger: IPassenger, passport: any }[],
    submitHandler: (passengers: { passenger: IPassenger, passport: any }[]) => void;
}


export function PassengersSelectionModal(props: IPassengersSelectionModal) {

    const {
        opened,
        setOpened,
        order,
        company,
        tripPassengers,
        submitHandler
    } = props;

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const authState = useAppSelector((state) => state.auth);

    //const companyState = useAppSelector((state) => state.companyInfo);

    const tripService = TripService();

    const {isOpen, open, close} = useContext(ModalContext);


    //const [company, setCompany] = useState<ICompanyInfo>(companyState as any);
    const [passengersSearchText, setPassengersSearchText] = useState("");

    const [passengers, setPassengers] = useState<{ passenger: IPassenger, passport: any }[]>([]);
    const [searchPending, setSearchPending] = useState(false);
    const [isSearchNotFound, setIsSearchNotFound] = useState(false);

    const [selectedPassengers, setSelectedPassengers] = useState<{ passenger: IPassenger, passport: any }[]>([]);


    const filteredPassengers = passengers.filter(x => x.passenger.fio.toUpperCase().includes(passengersSearchText.toUpperCase()));


    const searchPassengers = async () => {
        setSearchPending(true);
        setIsSearchNotFound(false);
        setPassengers([]);
        setSelectedPassengers([]);

        const response = await tripService.getPaxes(company?.code ?? "");

        if (response.length > 0) {
            const passengersList = response.sort((a, b) => (a.fio > b.fio) ? 1 : ((b.fio > a.fio) ? -1 : 0));
            setPassengers(passengersList.map(x => {
                return {
                    passenger: x,
                    passport: x.passportInfo
                }
            }));
            setSelectedPassengers(tripPassengers.filter(x => passengersList.some(r => r.paxCode == x.passenger.paxCode)));
        } else {
            setIsSearchNotFound(true);
        }

        setSearchPending(false);
    };


    const onModalOpen = async () => {
        resetForm();

        if (!opened) {
            setOpened(true);
        }

        await searchPassengers();
    }

    const onModalClose = () => {
        if (opened) {
            setOpened(false);
        }
        resetForm();
    }


    //const onCompanyChanged = async (company: ICompanyInfo) => {
    //    setCompany(company);
    //}


    const onPassengerSelect = (passengerInfo: { passenger: IPassenger, passport: any }) => {
        setSelectedPassengers(prevState => [...prevState.filter(x => x.passenger.paxCode != passengerInfo.passenger.paxCode), passengerInfo]);
    }

    const onPassengerUnselect = (passengerInfo: { passenger: IPassenger, passport: any }) => {
        setSelectedPassengers(prevState => prevState.filter(x => x.passenger.paxCode != passengerInfo.passenger.paxCode));
    }


    const onSubmit = () => {
        submitHandler(selectedPassengers);
        setOpened(false);
        resetForm();
    }


    const resetForm = () => {
        setSearchPending(false);
        setIsSearchNotFound(false);
        setPassengers([]);
        setSelectedPassengers([]);
    }


    // useEffect(() => {
    //     searchPassengers();
    // }, [company]);


    useEffect(() => {
        if (isOpen(SEARCH_PASSENGERS_MODAL_ID) && !opened) {
            close(SEARCH_PASSENGERS_MODAL_ID);
        } else if (!isOpen(SEARCH_PASSENGERS_MODAL_ID) && opened) {
            open(SEARCH_PASSENGERS_MODAL_ID);
        }
    }, [opened]);


    useEffect(() => {
    }, []);


    return (
        <MdModal
            id={SEARCH_PASSENGERS_MODAL_ID}
            title={
                (t("bookingPage.choosingPax") + (order != null ? " для заказа №" + order.orderNo : ""))
            }
            openHandler={onModalOpen}
            closeHandler={onModalClose}>

            <div className="passengers-selection-dialog"
                 style={{maxHeight: (passengers.length > 0 ? "770px" : "350px")}}>

                <div className="ticket-selection-dialog__results">

                    {
                        (!searchPending && !isSearchNotFound && passengers.length > 0) && (
                            <div className="passengers-selection-dialog__header">

                                <div className="chat-list-search-bar">
                                    <MagnifierIcon
                                        width="16px"
                                        height="16px"
                                        style={{
                                            "path": {
                                                fill: "#CCCCCC"
                                            }
                                        }}
                                    />
                                    <input
                                        type="text"
                                        placeholder={t("commonText.search")}
                                        value={passengersSearchText}
                                        onChange={(e) => {
                                            setPassengersSearchText(e.target.value);
                                        }}
                                    />
                                </div>

                                {/*<div className="passengers-selection-dialog__header-left-side">*/}
                                {/*    <MdMenu*/}
                                {/*        header={company.fullName}*/}
                                {/*        icon={*/}
                                {/*            <CompanyIcon*/}
                                {/*                width={"12px"}*/}
                                {/*                height={"12px"}*/}
                                {/*                style={{*/}
                                {/*                    "path": {*/}
                                {/*                        fill: "#777E90"*/}
                                {/*                    }*/}
                                {/*                }*/}
                                {/*                }*/}
                                {/*            />*/}
                                {/*        }*/}
                                {/*        onSelect={(company: ICompanyInfo) => onCompanyChanged(company)}*/}
                                {/*    >*/}
                                {/*        {*/}
                                {/*            authState.gkCompanies.map((company, index) => {*/}
                                {/*                return (*/}
                                {/*                    <MdMenuItem key={index} value={company}*/}
                                {/*                                title={company.fullName}>*/}
                                {/*                        {company.fullName}*/}
                                {/*                    </MdMenuItem>*/}
                                {/*                )*/}
                                {/*            })*/}
                                {/*        }*/}
                                {/*    </MdMenu>*/}
                                {/*</div>*/}

                                {/*<div className="passengers-selection-dialog__header-right-side">*/}


                                {/*</div>*/}

                            </div>
                        )
                    }


                    <div className="passengers-selection-dialog__content">

                        {
                            searchPending && (
                                <div className="loading-results-message">
                                    <CircleSpinnerIcon
                                        width="20px"
                                        height="20px"
                                        style={{
                                            "circle": {
                                                stroke: "#A0A1A6"
                                            }
                                        }}
                                    />
                                    {t("bookingPage.searchingPax")}
                                </div>
                            )
                        }

                        {
                            (!searchPending && !isSearchNotFound && passengers.length > 0) && (
                                <MdScrollViewer>
                                    <div className="passenger-list">
                                        {
                                            filteredPassengers.length > 0
                                            ? (
                                                    filteredPassengers.map((passengerInfo, passengerInfoIndex) => {
                                                        const isSelected = selectedPassengers.some(x => x.passenger.paxCode == passengerInfo.passenger.paxCode);
                                                        return (
                                                            <button
                                                                key={"passenger" + Guid.newGuid()}
                                                                className={"passenger-item-button" + (isSelected ? " --active" : "")}
                                                                onClick={() => {
                                                                    if (isSelected) {
                                                                        onPassengerUnselect(passengerInfo);
                                                                    } else {
                                                                        onPassengerSelect(passengerInfo);
                                                                    }
                                                                }}>
                                                                <div className="passenger-item-button-checkbox">
                                                                    <MdCheckbox
                                                                        type="filled"
                                                                        checked={isSelected}
                                                                        style={{
                                                                            "input": {
                                                                                width: "16px",
                                                                                height: "16px",
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="passenger-item-button-details">
                                                                    <div className="passenger-item-button-details-row">
                                                                        <span className="passenger-fio">
                                                                            {
                                                                                passengerInfo.passenger.fio
                                                                                    .toLowerCase()
                                                                                    .split(' ')
                                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                    .join(' ')
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="passenger-item-button-details-row">
                                                                        <CalendarIcon
                                                                            width="10px"
                                                                            height="10px"
                                                                            style={{
                                                                                "svg": {
                                                                                    marginTop: "2px"
                                                                                },
                                                                                "path": {
                                                                                    fill: "#8E8E90"
                                                                                }
                                                                            }}
                                                                        />
                                                                        <span className="passenger-dob">{passengerInfo.passenger.passportInfo.dob.split('T')[0]}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="passenger-item-button-icon">

                                                                </div>
                                                            </button>
                                                        )
                                                    })
                                            )
                                            : (
                                                <div className="passenger-list-filter-error">
                                                    {t("bookingPage.failPaxSearch")}
                                                </div>
                                            )

                                        }
                                    </div>
                                </MdScrollViewer>
                            )
                        }

                        {
                            isSearchNotFound && (
                                <div className="no-passengers-message">
                                    <div className="no-passengers-message-icon">
                                        <SearchIcon2
                                            width="36px"
                                            height="36px"
                                            style={{
                                                "path": {
                                                    fill: "rgb(100 100 100)"
                                                }
                                            }}/>
                                    </div>
                                    <div className="no-passengers-message-caption">{t("bookingPage.noPax")}</div>
                                    <div className="no-passengers-message-content">{t("bookingPage.repeatSearch")}
                                    </div>
                                    <div className="no-passengers-message-button">
                                        <MdButton
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(233 233 233)",
                                                    color: "rgb(0 0 0)",
                                                    borderRadius: "6px",
                                                    height: "30px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            onClick={() => searchPassengers()}
                                        >
                                            {t("bookingPage.repeat")}
                                        </MdButton>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    {
                        (!searchPending && !isSearchNotFound && passengers.length > 0) && (
                            <div className="passengers-selection-dialog__footer">
                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            borderWidth: 0,
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                            borderRadius: "6px",
                                            height: "35px",
                                            padding: "5px 15px"
                                        },
                                        "ink": {
                                            backgroundColor: "rgba(255,255,255,.5)"
                                        }
                                    }}
                                    disabled={(selectedPassengers.length == 0)}
                                    onClick={() => onSubmit()}
                                >
                                    {t("bookingPage.saveSelection")}
                                </MdButton>
                            </div>
                        )
                    }

                </div>

            </div>

        </MdModal>
    );
}