import "./style.scss";

interface StatisticSimpleCardProps {
  type?: "blue"|"lightBlue"|"green"|"lightGreen"
  icon?: JSX.Element;
  title: string;
  footer: string;
  data: [string, string][];
}

export function StatisticSimpleCardComponent(props: StatisticSimpleCardProps) {

  return (
    <>
      <span>
        <div id ={props.type} className={"statistic-simple-card"}>
          <div className="title"
               style={{
                 color: "black"
               }}
          >
            {props.icon} {props.title}
          </div>
          <div className="card-body p-1">
            <ul className="list-group list-group-flush  pb-0">
              {props.data.map((item, index) => {
                return (
                    <li
                        className="list-group-item pb-0"
                        id={props.title + "_" + index.toString()}
                        key={props.title + index}
                    >
                      <p className="p-0">
                        <strong>{item[0] + ": "}</strong>
                        {item[1]}
                      </p>
                    </li>
                );
              })}
            </ul>
          </div>
        </div>
      </span>
    </>
  );
}
