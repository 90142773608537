export const customerBuhDocsTestData = {
  descr: "ok",
  media: [
    {
      accountingDocNumber: " 1317634",
      date: "2023-01-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "1538.49",
      amount: "17374.11",
      quantity: "3",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1321660",
      date: "2023-01-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "1633.49",
      amount: "18419.11",
      quantity: "3",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1321661",
      date: "2023-01-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "24377.08",
      quantity: "3",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1326192",
      date: "2023-02-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "61.67",
      amount: "13929.11",
      quantity: "2",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1326193",
      date: "2023-02-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "31277.08",
      quantity: "5",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1332352",
      date: "2023-02-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "-5583.63",
      amount: "-61680.00",
      quantity: "8",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1332759",
      date: "2023-02-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "5645.29",
      amount: "62438.22",
      quantity: "5",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1335840",
      date: "2023-03-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "61.67",
      amount: "24409.11",
      quantity: "2",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1339273",
      date: "2023-03-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "1652.58",
      amount: "18629.11",
      quantity: "3",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1339274",
      date: "2023-03-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "15877.08",
      quantity: "3",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1342825",
      date: "2023-03-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "19577.08",
      quantity: "2",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1348148",
      date: "2023-04-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "61.67",
      amount: "13709.11",
      quantity: "2",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1348149",
      date: "2023-04-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "14777.08",
      quantity: "3",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1374394",
      date: "2023-06-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "-6443.64",
      amount: "-70170.00",
      quantity: "8",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1374645",
      date: "2023-06-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "21615.45",
      amount: "239190.55",
      quantity: "14",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1378359",
      date: "2023-06-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "61.66",
      amount: "12026.24",
      quantity: "4",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1378360",
      date: "2023-06-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "47354.16",
      quantity: "7",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1395006",
      date: "2023-07-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "-29720.00",
      quantity: "4",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1395205",
      date: "2023-07-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "123.34",
      amount: "105978.22",
      quantity: "4",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1395206",
      date: "2023-07-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "22050.00",
      quantity: "2",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1412271",
      date: "2023-09-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "5388.94",
      amount: "59908.22",
      quantity: "6",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1412272",
      date: "2023-09-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "75331.24",
      quantity: "8",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1415202",
      date: "2023-09-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "6720.30",
      amount: "74263.22",
      quantity: "5",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1415203",
      date: "2023-09-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "23877.08",
      quantity: "4",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1420483",
      date: "2023-09-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "61.66",
      amount: "19628.22",
      quantity: "4",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1420484",
      date: "2023-09-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "11720.00",
      quantity: "2",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1425518",
      date: "2023-10-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "5236.06",
      amount: "72667.33",
      quantity: "7",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1425519",
      date: "2023-10-01",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "33000.00",
      quantity: "3",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1435153",
      date: "2023-10-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "-14800.00",
      quantity: "4",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1435341",
      date: "2023-10-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "4417.01",
      amount: "81733.73",
      quantity: "11",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1444318",
      date: "2023-11-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "-3518.18",
      amount: "-36204.00",
      quantity: "5",
      consolidated: "0",
    },
    {
      accountingDocNumber: " 1446684",
      date: "2023-11-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "30.83",
      amount: "32088.38",
      quantity: "3",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1446685",
      date: "2023-11-10",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "3700.00",
      quantity: "1",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1451237",
      date: "2023-11-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "61.66",
      amount: "22094.22",
      quantity: "4",
      consolidated: "1",
    },
    {
      accountingDocNumber: " 1451238",
      date: "2023-11-20",
      customer: '"ЛУКОЙЛ-Инжиниринг" г. Тюмень 2020',
      payer: 'Ф "ЛУКОЙЛ ТЮМЕНЬ"',
      vatAmount: "0.00",
      amount: "26166.66",
      quantity: "5",
      consolidated: "1",
    },
  ],
};
