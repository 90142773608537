import {IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import "./style.scss";
import "../../../../scss/extends.scss";
import {DateService} from "../../../../services/DateService";
import {PlaneHorizontalIcon} from "../../../../icons/PlaneHorizontalIcon";
import {DataConvertService} from "../../../../services/DataConvertService";
import {BaggageIcon} from "../../../../icons/BaggageIcon";
import {useTranslation} from "react-i18next";
import {FORBIDDEN_COLOR, GRANTED_COLOR, NO_REFUNDS, UNDEFINED} from "../../../../constants/PageConstants";
import {ExchangeIcon} from "../../../../icons/ExchangeIcon";
import {RefundIcon} from "../../../../icons/RefundIcon";

type AviaInfoProps = {
    isCollapsed: boolean;
    aviaVariant: IAviaVariant;

    selectedTariff?:number
    setActiveTariff?:(index:number)=>void
    isBookingMode?:boolean
}

export function AviaInfoComponent(props: AviaInfoProps) {
    const {getCustomTime} = DateService();
    const {t} = useTranslation();

    const {useMoneyRubFormat} = DataConvertService();

    const tariffs = props.aviaVariant.rejsInfo[props.aviaVariant.rejsInfo.length - 1].tarifs

    const getConditions = (value?: string): string => {
        switch (value) {
            case NO_REFUNDS:
                return t("orderPage.forbidden");
            case UNDEFINED:
                return t("orderPage.undefined");
        }
        return ""
    }

    return (<>

        <div className="avia-info-container">
            {props.aviaVariant.rejsInfo.length > 1 && <p>{t("orderPage.tickets")}: </p>}
            {props.aviaVariant.rejsInfo.length > 1 && props.aviaVariant.rejsInfo.map((rejs,index) =>
                <div className="avia-ticket-item" key={"avia-ticket-item"+index}>
                    <div className="avia-ticket-item-air-info"
                    >
                        <div><p>{(rejs.airCompanyName.split("-"))[0]}</p></div>
                        <div>{rejs.flightNumber}</div>
                    </div>

                    <div className="avia-ticket-item-date-block">
                        <div>{getCustomTime(new Date(rejs.dateDep))}</div>
                        <div>{(new Date(rejs.dateDep)).toDateString()}</div>
                    </div>
                    <div typeof="direction-block">
                        <div>{rejs.airCodeDep}</div>
                        <div>{rejs.cityDep}</div>
                    </div>
                    <div typeof="svg-line">
                        <svg height="10" width="60px" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0 L60 0 Z "
                                  style={{fill: "none", stroke: "black", strokeWidth: 3}}/>
                        </svg>
                    </div>
                    <div style={{alignSelf: "center"}}><PlaneHorizontalIcon iconSize="35px"/></div>
                    <div typeof="direction-block">
                        <div>{rejs.airCodeArr}</div>
                        <div>{rejs.cityArr}</div>
                    </div>
                    <div className="avia-ticket-item-direction-block">
                        <div>{getCustomTime(new Date(rejs.dateArr))}</div>
                        <div>{(new Date(rejs.dateArr)).toDateString()}</div>
                    </div>

                </div>)}
            <div><p>{t("orderPage.tariffs")}: {tariffs.length}</p></div>
            <div className="avia-tariffs-container">
                {tariffs && tariffs.map((tariff, index) => {
                    return (
                        <div className="avia-tariff-item">
                            <div className="avia-tariff-name">{tariff.name}</div>
                            <div className="avia-tariff-tooltip">
                                <label>{t("orderPage.exchange")}</label>
                                <ExchangeIcon iconSize="35px" activeColor={GRANTED_COLOR}
                                              disableColor={FORBIDDEN_COLOR}/>
                            </div>
                            <div className="avia-tariff-item-info-block">

                                <div>{t("orderPage.before")}: {getConditions(tariff.obmenDo)}</div>
                                <div>{t("orderPage.after")}: {getConditions(tariff.obmenPosle)}</div>
                            </div>
                            <div className="avia-tariff-tooltip">
                                <label>{t("orderPage.refund")}</label>
                                <RefundIcon iconSize="30px" activeColor={GRANTED_COLOR} disableColor={FORBIDDEN_COLOR}
                                            isChecked={true}/>
                            </div>

                            <div className="avia-tariff-item-info-block">
                                {/*<div>Обмен:</div>*/}
                                <div>до:</div>
                                <div>после:</div>
                            </div>
                            <div className="common-vertical-border"></div>
                            <div className="avia-tariff-tooltip">
                                <label> {t("orderPage.baggage")} </label>
                                <BaggageIcon iconSize="35px" activeColor={GRANTED_COLOR} disableColor={FORBIDDEN_COLOR}
                                             isChecked={tariff.bagaj === "Exist"}/>
                            </div>

                            <div className="avia-info-price" typeof="tariff-name">
                                <div> &nbsp;&nbsp; {tariff.bagaj}</div>
                                <div> &nbsp;&nbsp; {tariff.bagajInfo}</div>
                            </div>
                            <div className="common-vertical-border"></div>

                            <div className="avia-tariff-item-price-block">
                                <div>{useMoneyRubFormat.format(tariff.priceWithSborTts)}</div>
                                &nbsp;&nbsp;
                                {props.setActiveTariff !== undefined &&
                                    <div className="avia-info-price">
                                        <input type="radio" id="radioTarrifAvia" style={{cursor: "pointer"}}
                                               checked={index === props.selectedTariff}
                                               onChange={() => {
                                                   props.setActiveTariff!(index)
                                               }}/>
                                    </div>}
                            </div>
                        </div>)
                })}
            </div>
        </div>
    </>)
}
