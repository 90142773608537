import { useState } from "react";
import Chart from "react-google-charts";

interface StatisticChartComponentProps {
  chartTitle: string;
  dataTitle: string;
  moneyCode: string;
  aviaValue: number;
  trainValue: number;
  hotelValue: number;
  transferValue: number;
}
export function StatisticChartComponent(props: StatisticChartComponentProps) {
  const hasDataCheck = () => {
    if (
      props.aviaValue === 0 &&
      props.trainValue === 0 &&
      props.hotelValue === 0 &&
      props.transferValue === 0
    )
      return false;
    return true;
  };
  const [hasData, setHasData] = useState(hasDataCheck());
  const wight = "100%";
  const height = "250px";

  const data = [
    [props.dataTitle, props.moneyCode],
    ["Train", hasData ? props.trainValue : 1],
    ["Transfer", hasData ? props.transferValue : 1],
    ["Hotel", hasData ? props.hotelValue : 1],
    ["Avia", hasData ? props.aviaValue : 1],
  ];

  const options = {
    //forceIFrame: true,
    title: props.chartTitle,
    fontSize: "18",
    pieSliceTextStyle: {
      color: "black",
      //fontName: "",
      fontSize: 12,
    },

    slices: [
      { color: "lightBlue" },
      { color: "#eb5e28" },
      { color: "#9172EC" },
      { color: "lightgreen" },
    ],
    tooltip: { textStyle: { color: "blue" }, showColorCode: true },
    //hide tooltip
    enableInteractivity: false,
    chartArea: { left: 0, top: "50px", width: "75%", height: "75%" },
    is3D: true,
    sliceVisibilityThreshold: 0.01, // 20%
    legend: {
      position: "bottom",
      textStyle: {
        color: "blue",
        fontSize: 14,
        italic: "true",
      },
      maxLines: "2",
    },
    pieSliceText: hasData ? "percentage" : "label",
    pieStartAngle: 45,
    //backgroundColor: "#EEFFE3",
  };

  return (
    <>
      <Chart chartType="PieChart" data={data} options={options} width={wight} height={height} />
    </>
  );
}
