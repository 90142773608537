export enum UserRole {
  None,
  SuperAdmin = "SuperAdmin",
  UserAdmin = "UserAdmin",
  CompanyAdmin = "CompanyAdmin",
  CompanyUser = "CompanyUser",
}


export enum UserRoles {
  SelfBooker = "SelfBooker",
  AnonymousUser = "AnonymousUser",
  AccountantUser = "AccountantUser",
  TtsAdmin = "TtsAdmin",
  CompanyAdmin = "CompanyAdmin"
}