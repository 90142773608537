import "./style.scss";
import {ReactNode} from "react";

type LoadingProps = {
    message?: string;
}

export function LoadingComponent({message}: LoadingProps) {

    message = message ?? "LOADING...";
   const length = message.length<13?message.length:13

    const arr=new Array<number>(length).fill(0,length)

    return (

         <div className="loading-container">
             <div className="loading-header"><p>{message}</p></div>
             <div className="loading-dots">
                 {/*{arr.map(x=>(<><span key={"loading_"+x}></span></>))}*/}
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
                 <span></span>
             </div>
         </div>


        // <div className="loading">
        //     <div>Поиск компаний</div>
        //     <span></span>
        //     <span></span>
        //     <span></span>
        //     <span></span>
        //     <span></span>
        //     <span></span>
        //     <span></span>
        // </div>



        // <div className="loading">
        //     <div className="loading-text">
        //         {message.split('').map((ch, index) => (
        //             <span key={"loading-component-" + index} className="loading-text-words">{ch}</span>))}
        //         {/*<span className="loading-text-words">L</span>*/}
        //         {/*<span className="loading-text-words">O</span>*/}
        //         {/*<span className="loading-text-words">A</span>*/}
        //         {/*<span className="loading-text-words">D</span>*/}
        //         {/*<span className="loading-text-words">I</span>*/}
        //         {/*<span className="loading-text-words">N</span>*/}
        //         {/*<span className="loading-text-words">G</span>*/}
        //     </div>
        // </div>


    )
}