import React, {useEffect, useRef, useState} from "react";
import {useLocation, Link, Outlet} from "react-router-dom";
import {useAppSelector} from "../../redux/hooks";
import {AuthService} from "../../services/AuthService";
import {MdButton} from "../../components/md-button";
import {MdMenu, MdMenuItem} from "../../components/md-menu";
import {UserIconFilled} from "../../icons/UserIconFilled";
import {ICompany} from "../../interfaces/companyInfoInterfaces/ICompany";
import {CompanyIcon} from "../../icons/CompanyIcon";
import {WorldIcon} from "../../icons/WorldIcon";
import {CompanyService} from "../../services/CompanyService";
import {ChatComponent} from "../../components/chat";
import {ChatIcon} from "../../icons/ChatIcon";
import "./../../scss/reset-mobile.scss";
import "./style.scss";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_PRODUCTION,
    ENVIROMENT_STAGING,
} from "../../constants/ServiceCostants";
import {MagnifierIcon} from "../../icons/MagnifierIcon";
import {CircleSpinnerIcon} from "../../icons/CircleSpinnerIcon";
import {MdList} from "../../components/md-list";
//import i18next from "i18next";
import i18n from "../../i18n";
import {useSessionStorage} from "../../services/StorageService";
import {ILocalization} from "../../interfaces/siteOptions/ILocalization";
import {useTranslation  } from "react-i18next";


enum Pages {
    Home,
    Services,
    Tariffs,
    Contacts,
    Cabinet
}

const MainLayout = () => {
    const location = useLocation();
    const {t,i18n} = useTranslation(["landing"]);


    const authState = useAppSelector(state => state.auth);
    const [lang,setlang] = useSessionStorage("siteLanguage",{key: "Ru-ru", name: "Русский",languageCode:"ru"})

    //const [authStatus, setAuthStatus] = useState(authState.isValidUser);

    const companyState = useAppSelector(state => state.companyInfo);

    const authService = AuthService();
    const companyService = CompanyService();

    const {makeActiveCompany} = CompanyService();

    let localizations:ILocalization[] = [
        {key: "Ru-ru", name: "Русский", languageCode: "ru"},
        {key: "En-en", name: "English", languageCode: "en"
        }];

    const getLocalization = ()=>{
        if (lang.languageCode==="ru"){
            return localizations;
        }
        else{
            return [localizations[1],localizations[0]]
        }
    }


    const [localization, setLocalization] = useState<ILocalization>(lang);

    //const [selectedCompany, setSelectedCompany] = useState<ICompany>(companyState as any);
    const searchCompaniesInputRef = useRef(null);
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [searchCompaniesPending, setSearchCompaniesPending] = useState(false);
    const [searchCompaniesError, setSearchCompaniesError] = useState(false);

    //hooks temporary solution for scroll spy
    const [activeLink,setActiveLink] = useState("home")


    const [mobileMenuIsOpened, setMobileMenuIsOpened] = useState(false);

    const [chatIsOpened, setChatIsOpened] = useState(false);

    const [scrollTop, setScrollTop] = useState(0);

    const mobileMenuOpenHandler = (_: React.MouseEvent) => {
        setMobileMenuIsOpened(!mobileMenuIsOpened);
    };

    const mobileMenuCloseHandler = (_: React.MouseEvent) => {
        setMobileMenuIsOpened(false);
    };

    const onLocalizationSelected = async(loc: ILocalization) => {
        console.log(`OnlocalizationSelected ${loc.languageCode}`)
        //const r=await i18next.changeLanguage(loc.languageCode).then(r =>r.name )
        setlang(loc);
        setLocalization(loc)
        const r=await i18n.changeLanguage(loc.languageCode).then(r =>r.name )
    }

    const logoutHandler = async () => {
        await authService.logout();
        //setAuthStatus(false);
        setMobileMenuIsOpened(false);
    };
    console.log(`current language I18:${i18n.language} ,carrentLang Hooh: ${localization.languageCode}, current SessionStorage: ${lang.languageCode} `)
    console.log(` Current PAth:$ter ${window.location.pathname}`)


    const getActivePage = () => {
        //disabled (11/10/2024) For I.Matveev information
       // const segments = location.pathname.split('/');


        if (activeLink === "home") {
            return Pages.Home;
        } else if (activeLink === "services") {
            return Pages.Services;
        } else if (activeLink ==="tariffs") {
            return Pages.Tariffs;
        } else if (activeLink ==="contacts") {
            return Pages.Contacts;
        } else if (activeLink ==="cabinet") {
            return Pages.Cabinet;
        }

        //disabled (11/10/2024) For I.Matveev information
        // if (location.pathname === "/") {
        //     return Pages.Home;
        // } else if (segments.includes("services")) {
        //     return Pages.Services;
        // } else if (segments.includes("tariffs")) {
        //     return Pages.Tariffs;
        // } else if (segments.includes("contacts")) {
        //     return Pages.Contacts;
        // } else if (segments.includes("cabinet")) {
        //     return Pages.Cabinet;
        // }
    }

    const getActivePageName = () => {
        const activePage = getActivePage();

        switch (activePage) {
            case Pages.Home:
                return "Главная";
            case Pages.Services:
                return "Сервисы";
            case Pages.Tariffs:
                return "Тарифы";
            case Pages.Contacts:
                return "Контакты";
            case Pages.Cabinet:
                return "Личный кабинет"
            default:
                return "";
        }
    }


    const onSearchCompaniesKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchCompany();
        }
    }

    const searchCompany = async () => {
        setCompanies([]);
        setSearchCompaniesError(false);
        setSearchCompaniesPending(true);

        const searchText = searchCompaniesInputRef != null && searchCompaniesInputRef.current != null ? (searchCompaniesInputRef.current as HTMLInputElement).value : "";

        const response = await companyService.searchCompaniesByName(searchText);

        if (response != null && response.length > 0) {
            setCompanies(response);
        } else {
            setSearchCompaniesError(true);
        }

        //setSelectedCompany(null);

        // if(searchType == "наименование") {
        //     const response = await companyService.searchCompanyByName(companyName);
        //
        //     if (response != null && response.length > 0) {
        //         setCompanies(response);
        //     } else {
        //         setSearchError(true);
        //     }
        // }
        // else {
        //     const response = await companyService.getCompanyByCode(companyName);
        //
        //     if (response != null) {
        //         setCompanies([response]);
        //     } else {
        //         setSearchError(true);
        //     }
        // }

        setSearchCompaniesPending(false);
    }

    const changeActiveCompany = async (company: ICompany) => {
        await makeActiveCompany(company.code);
        //setSelectedCompany(company);
        setCompanies([]);
        if(searchCompaniesInputRef.current != null) {
            (searchCompaniesInputRef.current as HTMLInputElement).value = "";
        }
        //setSearchCompaniesError(false);
        //setSearchCompaniesPending(false);
    }

    const onChatOpenHandler = (_: React.MouseEvent) => {
        if (!chatIsOpened) {
            setChatIsOpened(true);
        }
    }
    const closeHandler = (event: React.MouseEvent) => {
        event.preventDefault();
        if (chatIsOpened) {
            setChatIsOpened(false);
        }
    }

    const isDevelopmentEnv = () => {
        return !(CURRENT_REACT_ENVIROMENT === ENVIROMENT_STAGING || CURRENT_REACT_ENVIROMENT === ENVIROMENT_PRODUCTION)
    }

    useEffect(() => {
        function onScroll() {
            let currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);
    useEffect(()=>{
        i18n.changeLanguage(lang.languageCode)
    },[i18n.language])

    return (
        <div className="layout-container">

            <header className="page-header" style={{marginBottom: (scrollTop >= 40 ? 60 : 0)}}>
                <div className="header-container">

                    <ul className="navbar-nav ml-auto header-navbar header-navbar-left">
                        <li className="nav-item nav-custom-link">
                            <MdMenu
                                header={localization.name}
                                icon={
                                    <WorldIcon
                                        width={"12px"}
                                        height={"12px"}
                                        style={{
                                            "path": {
                                                fill: "#777E90"
                                            }
                                        }
                                        }
                                    />
                                }
                                style={{
                                    "label": {
                                        fontSize: "10px"
                                    }
                                }}
                                onSelect={onLocalizationSelected}>
                                {
                                    localizations.map((loc, index) => {
                                        return (
                                            <MdMenuItem key={index} value={loc}  >
                                                {loc.name}
                                            </MdMenuItem>
                                        )
                                    })
                                }
                            </MdMenu>
                        </li>
                    </ul>

                    <ul className="navbar-nav ml-auto header-navbar header-navbar-right" data-easing="easeInOutExpo"
                        data-speed="1250"
                        data-offset="65">
                        {
                            !authState.isValidUser
                                ? (
                                    <>
                                        <li className="nav-item nav-custom-link">
                                            <Link className="nav-link" to="/sign-in">{t("headMenu.login")}</Link>
                                        </li>
                                    </>
                                )
                                : (
                                    <>
                                        <li className="nav-item nav-custom-link">
                                            <MdMenu
                                                behaviour={'none'}
                                                header={authState.currentCompany?.fullName}
                                                icon={
                                                    <CompanyIcon
                                                        width={"12px"}
                                                        height={"12px"}
                                                        style={{
                                                            "path": {
                                                                fill: "#777E90"
                                                            }
                                                        }
                                                        }
                                                    />
                                                }
                                                style={{
                                                    "list": {
                                                        width: "300px"
                                                    }
                                                }}
                                            >
                                                {
                                                    authState.isSupportUser
                                                        ? (
                                                            <>
                                                                {
                                                                    <div className="company-selection-container">
                                                                        <div className="company-selection-search-bar">
                                                                            <input ref={searchCompaniesInputRef} type="text"
                                                                                   placeholder={t("headMenu.search")}
                                                                                   onKeyDown={onSearchCompaniesKeyDown}/>
                                                                            <MagnifierIcon
                                                                                width="14px"
                                                                                height="14px"
                                                                                style={{
                                                                                    "svg": {
                                                                                        position: "absolute",
                                                                                        top: "50%",
                                                                                        left: "12px",
                                                                                        transform: "translate(0, -50%)"
                                                                                    },
                                                                                    "path": {
                                                                                        fill: "#CCCCCC"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="company-selection-search-results">
                                                                            {
                                                                                searchCompaniesPending && (
                                                                                    <div className="loading-results-message">
                                                                                        <CircleSpinnerIcon
                                                                                            width="14px"
                                                                                            height="14px"
                                                                                            style={{
                                                                                                "circle": {
                                                                                                    stroke: "#A0A1A6"
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        {t("headMenu.searchCompanies")}
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {
                                                                                 companies.length > 0 && (
                                                                                    <>
                                                                                        <div
                                                                                            className="company-selection-search-results-title">
                                                                                            {t("headMenu.searchResult")}
                                                                                        </div>
                                                                                        <MdList
                                                                                            itemSource={companies}
                                                                                            itemTemplate={(data: ICompany) => {
                                                                                                return (
                                                                                                    <>
                                                                                                         <div
                                                                                                            className="company-selection-item"
                                                                                                            title={data.fullName}>
                                                                                                            <div
                                                                                                                className="company-selection-item-code">
                                                                                                                {t("headMenu.companyCode")}: {data.code}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="company-selection-item-name">
                                                                                                                {data.fullName}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="company-selection-item-code">
                                                                                                                {t("headMenu.agreement")}: {data.agreements && data.agreements[0]?.code}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            }}
                                                                                            style={{
                                                                                                "root": {
                                                                                                    flex: 1,

                                                                                                    borderTop: "1px solid #efefef"
                                                                                                },
                                                                                                "list": {
                                                                                                    padding: "0px 10px"
                                                                                                }
                                                                                            }}
                                                                                            onItemSelect={changeActiveCompany}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                searchCompaniesError && (
                                                                                    <>
                                                                                        <div
                                                                                            className="company-selection-search-no-results-message">
                                                                                            {t("headMenu.companiesNotFound")}
                                                                                        </div>

                                                                                    </>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className="company-selection-container" style={{marginTop: "5px"}}>
                                                                        <div className="company-selection-search-results">
                                                                            {
                                                                                <>
                                                                                    <div className="company-selection-search-results-title">
                                                                                        {t("headMenu.companyGroup")}
                                                                                    </div>
                                                                                    <MdList
                                                                                        itemSource={authState.gkCompanies}
                                                                                        activeItem={authState.currentCompany}
                                                                                        itemTemplate={(data: ICompany) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div
                                                                                                        className="company-selection-item">
                                                                                                        <div
                                                                                                            className="company-selection-item-code">
                                                                                                            {t("headMenu.companyCode")}: {data.code}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="company-selection-item-name">
                                                                                                            {data.fullName}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="company-selection-item-code">
                                                                                                            {t("headMenu.agreement")}: {(data.agreements != null && data.agreements.length > 0 ? data.agreements[0].code : "")}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }}
                                                                                        style={{
                                                                                            "root": {
                                                                                                flex: 1,

                                                                                                borderTop: "1px solid #efefef"
                                                                                            },
                                                                                            "list": {
                                                                                                padding: "0px 10px"
                                                                                            }
                                                                                        }}
                                                                                        onItemSelect={changeActiveCompany}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                        : (
                                                            <>
                                                                {
                                                                    <div className="company-selection-container">
                                                                        <div className="company-selection-search-results">
                                                                            {
                                                                                <>
                                                                                    <div className="company-selection-search-results-title">
                                                                                        {t("headMenu.companyGroup")}
                                                                                    </div>
                                                                                    <MdList
                                                                                        itemSource={authState.gkCompanies}
                                                                                        activeItem={authState.currentCompany}
                                                                                        itemTemplate={(data: ICompany) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div
                                                                                                        className="company-selection-item"
                                                                                                        title={data.fullName}>
                                                                                                        <div
                                                                                                            className="company-selection-item-code">
                                                                                                            {data.inn}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="company-selection-item-name">
                                                                                                            {data.fullName}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }}
                                                                                        style={{
                                                                                            "root": {
                                                                                                flex: 1,

                                                                                                borderTop: "1px solid #efefef"
                                                                                            },
                                                                                            "list": {
                                                                                                padding: "0px 10px"
                                                                                            }
                                                                                        }}
                                                                                        onItemSelect={changeActiveCompany}
                                                                                    />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                }
                                            </MdMenu>
                                        </li>

                                        <li className="nav-item nav-custom-link">
                                            <MdMenu
                                                header={(authState.firstName + " " + authState.lastName)}
                                                icon={
                                                    <UserIconFilled
                                                        width="12px"
                                                        height="12px"
                                                        style={{
                                                            "svg": {
                                                                transform: "translate(0, -1px)"
                                                            },
                                                            "path": {
                                                                fill: "#777E90"
                                                            }
                                                        }}
                                                    />
                                                }
                                            >
                                                <MdButton
                                                    style={{
                                                        "button": {
                                                            width: "100%"
                                                        }
                                                    }}
                                                    onClick={logoutHandler}
                                                >
                                                    {t("headMenu.logout")}
                                                </MdButton>
                                            </MdMenu>
                                        </li>
                                    </>
                                )
                        }
                    </ul>

                </div>
            </header>

            <nav
                className={"page-navbar navbar-expand-lg" + (scrollTop >= 40 ? ' --fixed' : '') + (mobileMenuIsOpened ? " --mobile-menu-opened" : "") + (scrollTop > 0 ? ' --shadowed' : '')}>

                <div className="page-navbar-desktop collapse navbar-collapse">
                    <div className="navbar-container">
                        <div className="navbar-logo-desktop-container">
                            <Link className="navbar-logo-desktop" to="/">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.0008 0 500.0007 415.1737">
                                    <g transform="matrix(0.15451198816299438, 0, 0, 0.15451198816299438, -149.7228546142578, -267.7692565917969)">
                                        <polygon fill="#1A2B63" strokeWidth="7.62"
                                                 points="3641,3156 3641,2461 4205,2461 4205,4420 3641,4420 3641,3695 1735,3695 1735,3173 3631,3173 3641,3173 "/>
                                        <polygon fill="#1A2B63" strokeWidth="7.62"
                                                 points="1534,2997 1534,3692 969,3692 969,1733 1534,1733 1534,2458 3439,2458 3439,2981 1543,2981 1534,2981 "/>
                                        <path fill="white" fillRule="nonzero"
                                              d="M1998 2876l-57 0 0 -248 -100 0 0 -44 257 0 0 44 -100 0 0 248zm283 -69c-7,14 -14,26 -22,35 -7,9 -16,17 -25,23 -8,6 -18,10 -29,13 -10,2 -22,3 -35,3 -5,0 -11,0 -17,0 -7,-1 -12,-2 -18,-3l0 -46c6,2 12,3 17,3 6,1 11,1 15,1 7,0 14,-1 19,-2 6,-2 11,-4 16,-8 5,-3 10,-8 15,-15 4,-6 9,-14 13,-24l-120 -203 63 0 87 149 73 -149 62 0 -114 223zm241 -44c-5,0 -10,0 -15,-1 -5,0 -9,0 -14,0l0 114 -57 0 0 -292 90 0c15,0 28,0 39,2 11,1 22,3 30,5 22,6 38,16 49,29 12,14 17,31 17,51 0,15 -3,27 -9,39 -6,11 -15,20 -27,28 -12,8 -26,14 -44,18 -17,5 -36,7 -59,7zm-29 -46c4,0 8,0 13,1 5,0 10,0 15,0 16,0 28,-1 39,-4 10,-2 18,-5 25,-9 6,-4 11,-9 13,-15 3,-6 5,-13 5,-20 0,-8 -2,-16 -7,-22 -4,-6 -11,-11 -21,-15 -6,-1 -12,-3 -20,-3 -8,-1 -18,-2 -30,-2l-32 0 0 89zm286 -13l143 0 0 -120 57 0 0 292 -57 0 0 -127 -143 0 0 127 -57 0 0 -292 57 0 0 120zm331 127l153 0 0 45 -210 0 0 -292 202 0 0 44 -145 0 0 76 124 0 0 45 -124 0 0 82z"/>
                                        <path fill="white" fillRule="nonzero"
                                              d="M1998 3609l-57 0 0 -247 -100 0 0 -45 257 0 0 45 -100 0 0 247zm230 -113c-5,0 -10,0 -15,0 -5,-1 -10,-1 -14,-1l0 114 -57 0 0 -292 90 0c15,0 28,0 39,2 11,1 21,3 30,5 21,6 37,16 49,29 11,14 17,31 17,52 0,14 -3,26 -9,38 -6,11 -15,21 -27,28 -12,8 -27,14 -44,18 -17,5 -37,7 -59,7zm-29 -46c3,0 7,1 12,1 5,0 11,0 16,0 15,0 28,-1 38,-3 11,-3 19,-6 25,-10 7,-4 11,-9 14,-15 3,-6 4,-13 4,-20 0,-8 -2,-16 -6,-22 -4,-6 -11,-11 -22,-14 -5,-2 -12,-3 -20,-4 -8,-1 -18,-1 -29,-1l-32 0 0 88zm369 85l-117 0 -30 74 -61 0 123 -292 52 0 123 292 -60 0 -30 -74zm-59 -148c-11,30 -21,56 -30,77l-10 26 81 0 -11 -26c-8,-21 -18,-47 -29,-77l-1 0zm250 50l144 0 0 -120 57 0 0 292 -57 0 0 -127 -144 0 0 127 -57 0 0 -292 57 0 0 120zm426 -81c-15,0 -29,3 -42,7 -14,5 -25,12 -34,21 -9,10 -17,21 -22,34 -5,14 -8,29 -8,47 0,17 3,32 7,45 5,13 12,24 21,33 9,8 20,15 33,20 13,4 27,6 44,6 12,0 23,-1 36,-2 12,-2 23,-4 34,-8l0 46c-11,3 -22,5 -35,7 -13,2 -26,2 -39,2 -26,0 -49,-3 -69,-10 -20,-7 -37,-17 -50,-30 -14,-13 -24,-28 -31,-46 -6,-18 -10,-38 -10,-60 0,-22 4,-43 11,-62 7,-19 18,-36 31,-50 14,-14 31,-25 51,-33 20,-8 43,-12 69,-12 13,0 26,1 38,2 11,2 22,4 31,8l0 45c-12,-4 -24,-6 -34,-8 -11,-1 -21,-2 -32,-2z"/>
                                        <path fill="#1A2B63"
                                              d="M1903 4050l0 39 -157 0 0 61c14,-1 27,-2 38,-2 24,0 45,2 63,6 18,3 32,8 44,14 12,7 20,15 26,25 6,9 8,20 8,32 0,16 -5,30 -15,42 -10,13 -26,22 -46,29 -11,4 -24,7 -40,9 -15,2 -33,3 -55,3l-79 0 0 -258 213 0zm-157 218l27 0c14,0 26,-1 38,-2 12,-1 21,-4 30,-7 8,-3 14,-7 19,-13 5,-5 7,-12 7,-20 0,-13 -7,-22 -20,-28 -14,-7 -36,-10 -66,-10 -11,0 -22,1 -35,2l0 78zm446 -218l59 0 0 258 -56 0 0 -119c0,-15 1,-39 2,-72l-1 0c-10,15 -29,39 -55,70l-100 121 -59 0 0 -258 57 0 0 118c0,19 -1,43 -3,72l1 0c10,-14 28,-37 54,-68l101 -122zm126 11c30,-11 62,-16 97,-16 17,0 33,1 46,5 14,3 26,8 35,14 10,6 17,14 22,23 5,9 8,19 8,31 0,6 -1,13 -4,19 -2,5 -5,11 -8,15 -4,5 -9,9 -14,13 -5,3 -11,6 -17,8l0 1c16,4 28,10 37,20 10,9 14,22 14,37 0,11 -2,21 -7,31 -5,10 -13,19 -24,26 -10,8 -24,14 -40,18 -16,4 -36,6 -58,6 -19,0 -36,-1 -52,-5 -15,-3 -29,-7 -41,-12l0 -40c14,6 29,10 43,13 14,3 29,4 46,4 12,0 23,-1 32,-3 9,-2 17,-4 23,-8 7,-4 12,-8 15,-13 4,-6 5,-11 5,-18 0,-5 -1,-9 -3,-13 -2,-4 -5,-8 -9,-11 -4,-4 -10,-6 -17,-8 -8,-2 -17,-3 -27,-3l-53 0 0 -39 46 0c10,0 19,-1 26,-3 7,-2 12,-4 16,-8 4,-3 8,-7 9,-11 2,-5 3,-9 3,-15 0,-11 -5,-19 -14,-25 -10,-6 -25,-9 -46,-9 -14,0 -28,1 -44,4 -15,3 -30,6 -45,11l0 -39zm330 95l142 0 0 -106 56 0 0 258 -56 0 0 -113 -142 0 0 113 -56 0 0 -258 56 0 0 106zm328 112l151 0 0 40 -207 0 0 -258 199 0 0 39 -143 0 0 67 123 0 0 39 -123 0 0 73zm360 -183c-15,0 -29,2 -42,6 -13,4 -24,10 -33,18 -10,9 -17,19 -22,30 -5,12 -8,26 -8,41 0,15 2,29 7,40 5,12 12,21 21,29 9,8 19,14 32,18 13,3 27,5 44,5 11,0 23,0 35,-2 12,-1 24,-3 34,-6l0 40c-11,3 -22,5 -35,6 -12,2 -25,2 -38,2 -26,0 -49,-3 -69,-9 -19,-6 -36,-15 -49,-26 -13,-12 -23,-25 -30,-41 -7,-16 -10,-34 -10,-53 0,-20 3,-38 11,-55 7,-16 17,-31 30,-43 14,-13 31,-22 51,-30 20,-7 43,-10 68,-10 13,0 25,0 37,2 12,1 22,3 31,6l0 40c-12,-3 -23,-5 -34,-7 -10,-1 -21,-1 -31,-1z"/>
                                    </g>
                                </svg>
                            </Link>
                        </div>
                        <ul  className="navbar-nav ml-auto nav-right" data-easing="easeInOutExpo" data-speed="1250"
                            data-offset="65">
                                <li className={(getActivePage() === Pages.Home ? "--active" : "")} onClick={() => {window.location.replace("/#section-1")
                                setActiveLink("home")}}>
                                {/*<Link className="nav-link" to="/">{t("headMenu.home")}</Link>*/}
                                <div typeof="li">{t("headMenu.home")}</div>

                            </li>
                            <li className={(getActivePage() === Pages.Services ? "--active" : "") }
                                onClick={() => {window.location.replace("/#section-2")
                                setActiveLink("services")}}
                            >
                                {/*<Link className="nav-link" to="/services">{t("headMenu.services")}</Link>*/}
                                {/*<div onClick={() => window.location.replace("/#section-2")}>{t("headMenu.services")}</div>*/}
                                <div typeof="li">{t("headMenu.services")}</div>

                            </li>
                            <li className={(getActivePage() === Pages.Tariffs ? "--active" : "")} onClick={() => {window.location.replace("/#section-4")
                                setActiveLink("tariffs")}}>
                                {/*<Link className="nav-link" to="/tariffs">{t("headMenu.tariffs")}</Link>*/}
                                <div typeof="li">{t("headMenu.tariffs")}</div>
                            </li>
                            <li className={(getActivePage() === Pages.Contacts ? "--active" : "")} onClick={() => {window.location.replace("/#main-footer")
                                setActiveLink("contacts")}}>
                                {/*<Link className="nav-link" to="/contacts">{t("headMenu.contacts")}</Link>*/}
                                <div typeof="li">{t("headMenu.contacts")}</div>
                            </li>
                            {
                            authState && authState.isValidUser && (
                                    <li className={(getActivePage() === Pages.Cabinet ? "--active" : "")}>
                                        <Link className="nav-link" to="/cabinet">{t("headMenu.cabinet")}</Link>
                                    </li>
                                )
                            }
                        </ul>
                        <div className="lc-button-container">
                            <MdButton
                                type="submit"
                                style={{
                                    "button": {
                                        height: "38px",
                                        padding: "10px 12px",
                                        fontSize: "12px",
                                        borderRadius: "20px",
                                        backgroundColor: "#3E5CB8",
                                        color: "#FFFFFF",
                                    }
                                }}
                                onClick={(_) => {
                                }}
                            >
                                {t("headMenu.feedback")}
                            </MdButton>
                            {/*<Link className="lc-button" to="/cabinet">Личный кабинет</Link>*/}
                        </div>
                    </div>
                </div>

                <div className="page-navbar-mobile">
                    <div className="page-navbar-mobile-left-side">
                        <Link className="navbar-logo-mobile" to="/" onClick={mobileMenuCloseHandler}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.0008 0 500.0007 415.1737"
                                 className="original-logo">
                                <g transform="matrix(0.15451198816299438, 0, 0, 0.15451198816299438, -149.7228546142578, -267.7692565917969)">
                                    <polygon fill="#1A2B63" strokeWidth="7.62"
                                             points="3641,3156 3641,2461 4205,2461 4205,4420 3641,4420 3641,3695 1735,3695 1735,3173 3631,3173 3641,3173 "/>
                                    <polygon fill="#1A2B63" strokeWidth="7.62"
                                             points="1534,2997 1534,3692 969,3692 969,1733 1534,1733 1534,2458 3439,2458 3439,2981 1543,2981 1534,2981 "/>
                                    <path fill="white" fillRule="nonzero"
                                          d="M1998 2876l-57 0 0 -248 -100 0 0 -44 257 0 0 44 -100 0 0 248zm283 -69c-7,14 -14,26 -22,35 -7,9 -16,17 -25,23 -8,6 -18,10 -29,13 -10,2 -22,3 -35,3 -5,0 -11,0 -17,0 -7,-1 -12,-2 -18,-3l0 -46c6,2 12,3 17,3 6,1 11,1 15,1 7,0 14,-1 19,-2 6,-2 11,-4 16,-8 5,-3 10,-8 15,-15 4,-6 9,-14 13,-24l-120 -203 63 0 87 149 73 -149 62 0 -114 223zm241 -44c-5,0 -10,0 -15,-1 -5,0 -9,0 -14,0l0 114 -57 0 0 -292 90 0c15,0 28,0 39,2 11,1 22,3 30,5 22,6 38,16 49,29 12,14 17,31 17,51 0,15 -3,27 -9,39 -6,11 -15,20 -27,28 -12,8 -26,14 -44,18 -17,5 -36,7 -59,7zm-29 -46c4,0 8,0 13,1 5,0 10,0 15,0 16,0 28,-1 39,-4 10,-2 18,-5 25,-9 6,-4 11,-9 13,-15 3,-6 5,-13 5,-20 0,-8 -2,-16 -7,-22 -4,-6 -11,-11 -21,-15 -6,-1 -12,-3 -20,-3 -8,-1 -18,-2 -30,-2l-32 0 0 89zm286 -13l143 0 0 -120 57 0 0 292 -57 0 0 -127 -143 0 0 127 -57 0 0 -292 57 0 0 120zm331 127l153 0 0 45 -210 0 0 -292 202 0 0 44 -145 0 0 76 124 0 0 45 -124 0 0 82z"/>
                                    <path fill="white" fillRule="nonzero"
                                          d="M1998 3609l-57 0 0 -247 -100 0 0 -45 257 0 0 45 -100 0 0 247zm230 -113c-5,0 -10,0 -15,0 -5,-1 -10,-1 -14,-1l0 114 -57 0 0 -292 90 0c15,0 28,0 39,2 11,1 21,3 30,5 21,6 37,16 49,29 11,14 17,31 17,52 0,14 -3,26 -9,38 -6,11 -15,21 -27,28 -12,8 -27,14 -44,18 -17,5 -37,7 -59,7zm-29 -46c3,0 7,1 12,1 5,0 11,0 16,0 15,0 28,-1 38,-3 11,-3 19,-6 25,-10 7,-4 11,-9 14,-15 3,-6 4,-13 4,-20 0,-8 -2,-16 -6,-22 -4,-6 -11,-11 -22,-14 -5,-2 -12,-3 -20,-4 -8,-1 -18,-1 -29,-1l-32 0 0 88zm369 85l-117 0 -30 74 -61 0 123 -292 52 0 123 292 -60 0 -30 -74zm-59 -148c-11,30 -21,56 -30,77l-10 26 81 0 -11 -26c-8,-21 -18,-47 -29,-77l-1 0zm250 50l144 0 0 -120 57 0 0 292 -57 0 0 -127 -144 0 0 127 -57 0 0 -292 57 0 0 120zm426 -81c-15,0 -29,3 -42,7 -14,5 -25,12 -34,21 -9,10 -17,21 -22,34 -5,14 -8,29 -8,47 0,17 3,32 7,45 5,13 12,24 21,33 9,8 20,15 33,20 13,4 27,6 44,6 12,0 23,-1 36,-2 12,-2 23,-4 34,-8l0 46c-11,3 -22,5 -35,7 -13,2 -26,2 -39,2 -26,0 -49,-3 -69,-10 -20,-7 -37,-17 -50,-30 -14,-13 -24,-28 -31,-46 -6,-18 -10,-38 -10,-60 0,-22 4,-43 11,-62 7,-19 18,-36 31,-50 14,-14 31,-25 51,-33 20,-8 43,-12 69,-12 13,0 26,1 38,2 11,2 22,4 31,8l0 45c-12,-4 -24,-6 -34,-8 -11,-1 -21,-2 -32,-2z"/>
                                    <path fill="#1A2B63"
                                          d="M1903 4050l0 39 -157 0 0 61c14,-1 27,-2 38,-2 24,0 45,2 63,6 18,3 32,8 44,14 12,7 20,15 26,25 6,9 8,20 8,32 0,16 -5,30 -15,42 -10,13 -26,22 -46,29 -11,4 -24,7 -40,9 -15,2 -33,3 -55,3l-79 0 0 -258 213 0zm-157 218l27 0c14,0 26,-1 38,-2 12,-1 21,-4 30,-7 8,-3 14,-7 19,-13 5,-5 7,-12 7,-20 0,-13 -7,-22 -20,-28 -14,-7 -36,-10 -66,-10 -11,0 -22,1 -35,2l0 78zm446 -218l59 0 0 258 -56 0 0 -119c0,-15 1,-39 2,-72l-1 0c-10,15 -29,39 -55,70l-100 121 -59 0 0 -258 57 0 0 118c0,19 -1,43 -3,72l1 0c10,-14 28,-37 54,-68l101 -122zm126 11c30,-11 62,-16 97,-16 17,0 33,1 46,5 14,3 26,8 35,14 10,6 17,14 22,23 5,9 8,19 8,31 0,6 -1,13 -4,19 -2,5 -5,11 -8,15 -4,5 -9,9 -14,13 -5,3 -11,6 -17,8l0 1c16,4 28,10 37,20 10,9 14,22 14,37 0,11 -2,21 -7,31 -5,10 -13,19 -24,26 -10,8 -24,14 -40,18 -16,4 -36,6 -58,6 -19,0 -36,-1 -52,-5 -15,-3 -29,-7 -41,-12l0 -40c14,6 29,10 43,13 14,3 29,4 46,4 12,0 23,-1 32,-3 9,-2 17,-4 23,-8 7,-4 12,-8 15,-13 4,-6 5,-11 5,-18 0,-5 -1,-9 -3,-13 -2,-4 -5,-8 -9,-11 -4,-4 -10,-6 -17,-8 -8,-2 -17,-3 -27,-3l-53 0 0 -39 46 0c10,0 19,-1 26,-3 7,-2 12,-4 16,-8 4,-3 8,-7 9,-11 2,-5 3,-9 3,-15 0,-11 -5,-19 -14,-25 -10,-6 -25,-9 -46,-9 -14,0 -28,1 -44,4 -15,3 -30,6 -45,11l0 -39zm330 95l142 0 0 -106 56 0 0 258 -56 0 0 -113 -142 0 0 113 -56 0 0 -258 56 0 0 106zm328 112l151 0 0 40 -207 0 0 -258 199 0 0 39 -143 0 0 67 123 0 0 39 -123 0 0 73zm360 -183c-15,0 -29,2 -42,6 -13,4 -24,10 -33,18 -10,9 -17,19 -22,30 -5,12 -8,26 -8,41 0,15 2,29 7,40 5,12 12,21 21,29 9,8 19,14 32,18 13,3 27,5 44,5 11,0 23,0 35,-2 12,-1 24,-3 34,-6l0 40c-11,3 -22,5 -35,6 -12,2 -25,2 -38,2 -26,0 -49,-3 -69,-9 -19,-6 -36,-15 -49,-26 -13,-12 -23,-25 -30,-41 -7,-16 -10,-34 -10,-53 0,-20 3,-38 11,-55 7,-16 17,-31 30,-43 14,-13 31,-22 51,-30 20,-7 43,-10 68,-10 13,0 25,0 37,2 12,1 22,3 31,6l0 40c-12,-3 -23,-5 -34,-7 -10,-1 -21,-1 -31,-1z"/>
                                </g>
                            </svg>
                        </Link>
                    </div>
                    <div className="page-navbar-mobile-middle">
                        {getActivePageName()}
                    </div>
                    <div className="page-navbar-mobile-right-side">
                        <button className="mobile-menu-toggle-button" onClick={mobileMenuOpenHandler}>
                            <i>
                                <div className="icon-burger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="icon-close">
                                    <span></span>
                                    <span></span>
                                </div>
                            </i>
                        </button>
                    </div>
                </div>

                <div className={("mobile-menu" + (mobileMenuIsOpened ? " --opened" : ""))}>

                    <div className="mobile-menu-nav-container">

                        <div className="nav-experience">
                            <ol>
                                <li className={("prev-menu-item" + (getActivePage() === Pages.Home ? " --active" : ""))}>
                                    <Link className="nav-link" to="/" onClick={mobileMenuCloseHandler}>{t("headMenu.home")}</Link>
                                </li>
                                <li className={("prev-menu-item" + (getActivePage() === Pages.Services ? " --active" : ""))}>
                                    <Link className="nav-link" to="/services"
                                          onClick={mobileMenuCloseHandler}>{t("headMenu.services")}</Link>
                                </li>
                                <li className={("prev-menu-item" + (getActivePage() === Pages.Tariffs ? " --active" : ""))}>
                                    <Link className="nav-link" to="/tariffs"
                                          onClick={mobileMenuCloseHandler}>{t("headMenu.tariffs")}</Link>
                                </li>
                                <li className={("prev-menu-item" + (getActivePage() === Pages.Contacts ? " --active" : ""))}>
                                    <Link className="nav-link" to="/contacts"
                                          onClick={mobileMenuCloseHandler}>{t("headMenu.contacts")}</Link>
                                </li>
                                {
                                    authState && authState.isValidUser && (
                                        <li className={(getActivePage() === Pages.Cabinet ? "--active" : "")}>
                                            <Link className="nav-link" to="/cabinet"
                                                  onClick={mobileMenuCloseHandler}>{t("headMenu.cabinet")}</Link>
                                        </li>
                                    )
                                }
                            </ol>
                        </div>

                        <div className="nav-main">

                            <ul style={{
                                display: "flex",
                                flexDirection: "column",
                                minHeight: "100%",
                                //justifyContent: (authState.isValidUser ? "start" : "center")
                                justifyContent: "center"
                            }}>
                                {
                                    authState.isValidUser
                                        ? (
                                            <>
                                                {/*<li>*/}
                                                {/*    <Link className="nav-link" to="/cabinet"*/}
                                                {/*          onClick={mobileMenuCloseHandler}>Лицевой счет</Link>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*    <Link className="nav-link" to="/cabinet/myprofile"*/}
                                                {/*          onClick={mobileMenuCloseHandler}>Профиль</Link>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*    <Link className="nav-link" to="/cabinet/orders"*/}
                                                {/*          onClick={mobileMenuCloseHandler}>Заказы</Link>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*    <Link className="nav-link" to="/cabinet/reports"*/}
                                                {/*          onClick={mobileMenuCloseHandler}>Отчеты</Link>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*<Link className="nav-link" to="/cabinet/registers"*/}
                                                {/*          onClick={mobileMenuCloseHandler}>Реестры</Link>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*    <Link className="nav-link" to="/cabinet/documents"*/}
                                                {/*          onClick={mobileMenuCloseHandler}>Документы</Link>*/}
                                                {/*</li>*/}
                                                {/*<li>*/}
                                                {/*    <Link className="nav-link" to="/cabinet/employees"*/}
                                                {/*          onClick={mobileMenuCloseHandler}>Сотрудники</Link>*/}
                                                {/*</li>*/}
                                                <li>
                                                    <button style={{padding: 0}} onClick={logoutHandler}>
                                                        <span style={{marginRight: "5px "}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 width="18px"
                                                                 height="18px"
                                                                 viewBox="0 0 24 24"
                                                                 fill="none"
                                                                 style={{
                                                                     transform: "translate(0, -2px)"
                                                                 }}>
                                                                <path
                                                                    d="M12 15L15 12M15 12L12 9M15 12H4M4 7.24802V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2839 4.21799 18.9076C4 18.4798 4 17.9201 4 16.8V16.75"
                                                                    stroke="#515253"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                            </svg>
                                                        </span>
                                                        <span>{t("headMenu.logout")}</span>
                                                    </button>
                                                </li>
                                            </>
                                        )
                                        : (
                                            <li>
                                                <span style={{marginRight: "5px "}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         width="14px"
                                                         height="14px"
                                                         viewBox="0 0 32 32"
                                                         style={{
                                                             transform: "translate(0, -3px)"
                                                         }}>
                                                        <path fill="#515253"
                                                              d="M26,13h-2V9c0-4.411-3.589-8-8-8S8,4.589,8,9v4H6c-1.1,0-2,0.9-2,2v14c0,1.1,0.9,2,2,2h20  c1.1,0,2-0.9,2-2V15C28,13.9,27.1,13,26,13z M17,21.723V25c0,0.552-0.447,1-1,1s-1-0.448-1-1v-3.277c-0.595-0.346-1-0.984-1-1.723  c0-1.105,0.895-2,2-2s2,0.895,2,2C18,20.738,17.595,21.376,17,21.723z M20,13h-8V9c0-2.206,1.794-4,4-4s4,1.794,4,4V13z"/>
                                                        </svg>
                                                </span>
                                                <Link className="nav-link" to="/sign-in"
                                                      onClick={mobileMenuCloseHandler}>{t("headMenu.login")}</Link>
                                            </li>
                                        )
                                }
                            </ul>

                        </div>

                    </div>
                </div>
            </nav>

            <div className="page-container">
                <Outlet/>
            </div>

            <footer id="main-footer">
                <div className="footer-container">
                    <div className="footer-logo">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.0008 0 500.0007 415.1737">
                            <g transform="matrix(0.15451198816299438, 0, 0, 0.15451198816299438, -149.7228546142578, -267.7692565917969)">
                                <polygon fill="#FFFFFF" strokeWidth="7.62"
                                         points="3641,3156 3641,2461 4205,2461 4205,4420 3641,4420 3641,3695 1735,3695 1735,3173 3631,3173 3641,3173 "/>
                                <polygon fill="#FFFFFF" strokeWidth="7.62"
                                         points="1534,2997 1534,3692 969,3692 969,1733 1534,1733 1534,2458 3439,2458 3439,2981 1543,2981 1534,2981 "/>
                                <path fill="#62656a" fillRule="nonzero"
                                      d="M1998 2876l-57 0 0 -248 -100 0 0 -44 257 0 0 44 -100 0 0 248zm283 -69c-7,14 -14,26 -22,35 -7,9 -16,17 -25,23 -8,6 -18,10 -29,13 -10,2 -22,3 -35,3 -5,0 -11,0 -17,0 -7,-1 -12,-2 -18,-3l0 -46c6,2 12,3 17,3 6,1 11,1 15,1 7,0 14,-1 19,-2 6,-2 11,-4 16,-8 5,-3 10,-8 15,-15 4,-6 9,-14 13,-24l-120 -203 63 0 87 149 73 -149 62 0 -114 223zm241 -44c-5,0 -10,0 -15,-1 -5,0 -9,0 -14,0l0 114 -57 0 0 -292 90 0c15,0 28,0 39,2 11,1 22,3 30,5 22,6 38,16 49,29 12,14 17,31 17,51 0,15 -3,27 -9,39 -6,11 -15,20 -27,28 -12,8 -26,14 -44,18 -17,5 -36,7 -59,7zm-29 -46c4,0 8,0 13,1 5,0 10,0 15,0 16,0 28,-1 39,-4 10,-2 18,-5 25,-9 6,-4 11,-9 13,-15 3,-6 5,-13 5,-20 0,-8 -2,-16 -7,-22 -4,-6 -11,-11 -21,-15 -6,-1 -12,-3 -20,-3 -8,-1 -18,-2 -30,-2l-32 0 0 89zm286 -13l143 0 0 -120 57 0 0 292 -57 0 0 -127 -143 0 0 127 -57 0 0 -292 57 0 0 120zm331 127l153 0 0 45 -210 0 0 -292 202 0 0 44 -145 0 0 76 124 0 0 45 -124 0 0 82z"/>
                                <path fill="#62656a" fillRule="nonzero"
                                      d="M1998 3609l-57 0 0 -247 -100 0 0 -45 257 0 0 45 -100 0 0 247zm230 -113c-5,0 -10,0 -15,0 -5,-1 -10,-1 -14,-1l0 114 -57 0 0 -292 90 0c15,0 28,0 39,2 11,1 21,3 30,5 21,6 37,16 49,29 11,14 17,31 17,52 0,14 -3,26 -9,38 -6,11 -15,21 -27,28 -12,8 -27,14 -44,18 -17,5 -37,7 -59,7zm-29 -46c3,0 7,1 12,1 5,0 11,0 16,0 15,0 28,-1 38,-3 11,-3 19,-6 25,-10 7,-4 11,-9 14,-15 3,-6 4,-13 4,-20 0,-8 -2,-16 -6,-22 -4,-6 -11,-11 -22,-14 -5,-2 -12,-3 -20,-4 -8,-1 -18,-1 -29,-1l-32 0 0 88zm369 85l-117 0 -30 74 -61 0 123 -292 52 0 123 292 -60 0 -30 -74zm-59 -148c-11,30 -21,56 -30,77l-10 26 81 0 -11 -26c-8,-21 -18,-47 -29,-77l-1 0zm250 50l144 0 0 -120 57 0 0 292 -57 0 0 -127 -144 0 0 127 -57 0 0 -292 57 0 0 120zm426 -81c-15,0 -29,3 -42,7 -14,5 -25,12 -34,21 -9,10 -17,21 -22,34 -5,14 -8,29 -8,47 0,17 3,32 7,45 5,13 12,24 21,33 9,8 20,15 33,20 13,4 27,6 44,6 12,0 23,-1 36,-2 12,-2 23,-4 34,-8l0 46c-11,3 -22,5 -35,7 -13,2 -26,2 -39,2 -26,0 -49,-3 -69,-10 -20,-7 -37,-17 -50,-30 -14,-13 -24,-28 -31,-46 -6,-18 -10,-38 -10,-60 0,-22 4,-43 11,-62 7,-19 18,-36 31,-50 14,-14 31,-25 51,-33 20,-8 43,-12 69,-12 13,0 26,1 38,2 11,2 22,4 31,8l0 45c-12,-4 -24,-6 -34,-8 -11,-1 -21,-2 -32,-2z"/>
                                <path fill="#FFFFFF"
                                      d="M1903 4050l0 39 -157 0 0 61c14,-1 27,-2 38,-2 24,0 45,2 63,6 18,3 32,8 44,14 12,7 20,15 26,25 6,9 8,20 8,32 0,16 -5,30 -15,42 -10,13 -26,22 -46,29 -11,4 -24,7 -40,9 -15,2 -33,3 -55,3l-79 0 0 -258 213 0zm-157 218l27 0c14,0 26,-1 38,-2 12,-1 21,-4 30,-7 8,-3 14,-7 19,-13 5,-5 7,-12 7,-20 0,-13 -7,-22 -20,-28 -14,-7 -36,-10 -66,-10 -11,0 -22,1 -35,2l0 78zm446 -218l59 0 0 258 -56 0 0 -119c0,-15 1,-39 2,-72l-1 0c-10,15 -29,39 -55,70l-100 121 -59 0 0 -258 57 0 0 118c0,19 -1,43 -3,72l1 0c10,-14 28,-37 54,-68l101 -122zm126 11c30,-11 62,-16 97,-16 17,0 33,1 46,5 14,3 26,8 35,14 10,6 17,14 22,23 5,9 8,19 8,31 0,6 -1,13 -4,19 -2,5 -5,11 -8,15 -4,5 -9,9 -14,13 -5,3 -11,6 -17,8l0 1c16,4 28,10 37,20 10,9 14,22 14,37 0,11 -2,21 -7,31 -5,10 -13,19 -24,26 -10,8 -24,14 -40,18 -16,4 -36,6 -58,6 -19,0 -36,-1 -52,-5 -15,-3 -29,-7 -41,-12l0 -40c14,6 29,10 43,13 14,3 29,4 46,4 12,0 23,-1 32,-3 9,-2 17,-4 23,-8 7,-4 12,-8 15,-13 4,-6 5,-11 5,-18 0,-5 -1,-9 -3,-13 -2,-4 -5,-8 -9,-11 -4,-4 -10,-6 -17,-8 -8,-2 -17,-3 -27,-3l-53 0 0 -39 46 0c10,0 19,-1 26,-3 7,-2 12,-4 16,-8 4,-3 8,-7 9,-11 2,-5 3,-9 3,-15 0,-11 -5,-19 -14,-25 -10,-6 -25,-9 -46,-9 -14,0 -28,1 -44,4 -15,3 -30,6 -45,11l0 -39zm330 95l142 0 0 -106 56 0 0 258 -56 0 0 -113 -142 0 0 113 -56 0 0 -258 56 0 0 106zm328 112l151 0 0 40 -207 0 0 -258 199 0 0 39 -143 0 0 67 123 0 0 39 -123 0 0 73zm360 -183c-15,0 -29,2 -42,6 -13,4 -24,10 -33,18 -10,9 -17,19 -22,30 -5,12 -8,26 -8,41 0,15 2,29 7,40 5,12 12,21 21,29 9,8 19,14 32,18 13,3 27,5 44,5 11,0 23,0 35,-2 12,-1 24,-3 34,-6l0 40c-11,3 -22,5 -35,6 -12,2 -25,2 -38,2 -26,0 -49,-3 -69,-9 -19,-6 -36,-15 -49,-26 -13,-12 -23,-25 -30,-41 -7,-16 -10,-34 -10,-53 0,-20 3,-38 11,-55 7,-16 17,-31 30,-43 14,-13 31,-22 51,-30 20,-7 43,-10 68,-10 13,0 25,0 37,2 12,1 22,3 31,6l0 40c-12,-3 -23,-5 -34,-7 -10,-1 -21,-1 -31,-1z"/>
                            </g>
                        </svg>
                    </div>
                    <div className="footer-block">
                        <div className="footer-block-title">{t("footer.contacts")}</div>
                        <ul className="footer-block-items">
                            <li style={{
                                paddingBottom: "25px"
                            }}>
                                {t("footer.addressPartOne")} <br/>ул.{t("footer.addressPartTwo")}
                            </li>
                            <li>
                                +7 (4012) 311 - 401
                            </li>
                            <li>
                                <a href="mailto:office@turne-trans.ru" style={{
                                    marginTop: "5px",
                                    color: "rgb(111 146 253)"
                                }}>office@turne-trans.ru</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>

            {
                (authState.isValidUser && isDevelopmentEnv()) && (
                    <div className={"chat-button" + (chatIsOpened ? " expand" : "")}
                         onClick={onChatOpenHandler}>
                        <i className="chat-button-icon">
                            <ChatIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        fill: "#000000"
                                    }
                                }}
                            />
                        </i>
                        <div className={"chat-container" + (chatIsOpened ? " enter" : "")}>
                            <ChatComponent
                                closeHandler={closeHandler}
                            />
                        </div>
                    </div>
                )
            }

        </div>
    )
};

export default MainLayout;
