import React, {
    Children,
    CSSProperties,
    useEffect,
    useRef,
    useState
} from "react";
import './style.scss'


interface IMdMenuProps {
    behaviour?: 'combobox' | 'none';
    icon?: any;
    header?: string | React.ReactNode;
    style?: { [selector: string]: CSSProperties }
    children?: any;
    disabled?: boolean;
    onSelect?: (item: any) => void;
}

interface IMdMenuItemProps {
    title?: string;
    value?: any; // TODO: remove
    children?: any;
    disabled?: boolean;
    onClick?: (event: any) => void;
    style?: CSSProperties;
}


export function MdMenu({ behaviour = 'combobox', icon = null, header = "", style = {}, children = null, disabled = false, onSelect = () => {} }: IMdMenuProps) {
    const root = useRef(null);
    const headerNode = useRef(null);

    const [isOpened, setIsOpened] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (root.current && !(root.current as HTMLElement).contains(event.target as HTMLElement)) {
            setIsOpened(false);
        }
    };

    const onHeaderClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsOpened(!isOpened);
    }

    const onItemClick = (event: React.MouseEvent, child: any) => {
        if(behaviour == 'none') {
            return;
        }
        if (child.type.name === MdMenuItem.name) {
            onSelect(child.props.value);
        }
        setIsOpened(false);
    }


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);


    return (
        <details
            ref={root}
            className={"md-menu" + (disabled ? " --disabled" : "")}
            data-behaviour={behaviour}
            open={isOpened}
            style={Object.assign({zIndex: (isOpened ? 2000 : 1000)}, style["root"])}>
            <summary
                ref={headerNode}
                className="md-menu-header"
                role="button"
                onClick={onHeaderClick}>
                <span className="md-menu-header-icon" style={style["icon"]}>
                    {icon}
                </span>
                <span className="md-menu-header-label" style={style["label"]} title={(typeof header === 'string' ? header : "")}>
                    {header}
                </span>
                <span>
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10px"
                        height="10px"
                        viewBox="0 0 1024 1024">
                        <path
                            d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                            fill="#777E90"
                            style={style["arrow"]}/>
                    </svg>
                </span>
            </summary>
            <div className="md-menu-dropdown-list" style={style["list"]}>
                <ul>
                    {
                        Children.toArray(children).map((child: any, index: number) => {
                            return (
                                <li key={index}
                                    onClick={(e) => onItemClick(e, child)}
                                >
                                    {child}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </details>
    );
}


export function MdMenuItem(props: IMdMenuItemProps) {
    return (
        <div className="md-menu-item" title={props.title}>
            {props.children}
        </div>
    );
}