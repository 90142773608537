import { HotelIcon } from "../../../../icons/HotelIcon";
import { PlaneIcon } from "../../../../icons/PlaneIcon";
import { TrainIcon } from "../../../../icons/TrainIcon";
import { DataConvertService } from "../../../../services/DataConvertService";
import { StatisticSimpleCardComponent } from "../../../commonComponents/commonCardComponents/statisticSimpleCardComponent";
import { useTranslation } from "react-i18next";
import "./style.scss";
import {
    PERIOD_CURRENT_MONTH,
    PERIOD_CURRENT_QUARTER,
    PERIOD_LAST_MONTH,
    PERIOD_LAST_QUARTER,
    PERIOD_LAST_TWELVE_MONTH, PERIOD_SELECTED_BY_USER,
} from "../../../../constants/ServiceCostants";

export interface CompanyFeesInfoProps {
  isLoading: boolean;
  currentPeriod: string;
  currentPeriodHandler: (period: string) => void;

  aviaTicketSale?: number;
  aviaTicketSaleCount?: number;
  aviaTicketExcange?: number;
  aviaTicketExcangeCount?: number;
  aviaTicketReturn?: number;
  aviaTicketReturnCount?: number;

  trainTicketSale?: number;
  trainTicketSaleCount?: number;
  trainTicketExcange?: number;
  trainTicketExcangeCount?: number;
  trainTicketReturn?: number;
  trainTicketReturnCount?: number;

  hotelTicketSale?: number;
  hotelTicketSaleCount?: number;
  hotelTicketExcange?: number;
  hotelTicketExcangeCount?: number;
  hotelTicketReturn?: number;
  hotelTicketReturnCount?: number;
}

export function CompanyFeesInfoComponent(props: CompanyFeesInfoProps) {
  const { t } = useTranslation();
  const { useMoneyRubFormat } = DataConvertService();

  const changePeriodHandler = (period: string) => {
    props.currentPeriodHandler(period);
  };

  const getStatsPeriodNameHandler = (period: string) => {
    switch (props.currentPeriod) {
      case PERIOD_CURRENT_MONTH:
        return t("accInfoPage.currentMonth");
      case PERIOD_LAST_MONTH:
        return t("accInfoPage.lastMonth");
      case PERIOD_CURRENT_QUARTER:
        return t("accInfoPage.currentQuarter");
      case PERIOD_LAST_QUARTER:
        return t("accInfoPage.lastQuarter");
      case PERIOD_LAST_TWELVE_MONTH:
        return t("accInfoPage.lastTwelweMonth");
        case PERIOD_SELECTED_BY_USER:
            return t("accInfoPage.selectedByUser");
      default:
        return t("accInfoPage.lastMonth");
    }
  };

  return (
    <>
      <div className="filters-container"
           //style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}
      >
        <h5 className="m-2">
          {t("accInfoPage.dataFor")+": "}&nbsp;

            <span className="dropdown">
            {"  "}
            <p className=" user-select-none">{getStatsPeriodNameHandler(props.currentPeriod)}</p>
            <ol className="dropdown-content">
              {/* <p className=" user-select-none">{t("accInfoPage.currentMonth")}</p> */}

              {props.currentPeriod !== PERIOD_LAST_MONTH ? (
                <li>
                  <span id="selectItem" onClick={() => changePeriodHandler(PERIOD_LAST_MONTH)}>
                    {t("accInfoPage.lastMonth")}
                  </span>
                </li>
              ) : (
                <></>
              )}

              {props.currentPeriod !== PERIOD_CURRENT_MONTH && (
                <li>
                  <span id="selectItem" onClick={() => changePeriodHandler(PERIOD_CURRENT_MONTH)}>
                    {t("accInfoPage.currentMonth")}
                  </span>
                </li>
              )}

              {props.currentPeriod !== PERIOD_LAST_QUARTER && (
                <li>
                  <span id="selectItem" onClick={() => changePeriodHandler(PERIOD_LAST_QUARTER)}>
                    {t("accInfoPage.lastQuarter")}
                  </span>
                </li>
              )}

              {props.currentPeriod !== PERIOD_CURRENT_QUARTER && (
                <li>
                  <span id="selectItem" onClick={() => changePeriodHandler(PERIOD_CURRENT_QUARTER)}>
                    {t("accInfoPage.currentQuarter")}
                  </span>
                </li>
              )}

              {props.currentPeriod !== PERIOD_LAST_TWELVE_MONTH && (
                <li>
                  <span
                    id="selectItem"
                    onClick={() => changePeriodHandler(PERIOD_LAST_TWELVE_MONTH)}
                  >
                    {t("accInfoPage.lastTwelweMonth")}
                  </span>
                </li>
              )}
                {props.currentPeriod !== PERIOD_SELECTED_BY_USER && (
                    <li>
                  <span
                      id="selectItem"
                      onClick={() => changePeriodHandler(PERIOD_SELECTED_BY_USER)}
                  >
                    {t("accInfoPage.selectedByUser")}
                  </span>
                    </li>
                )}

            </ol>{" "}
          </span>
          &nbsp;
          {props.isLoading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <></>
          )}
        </h5>

        <div className="company-fee-row-container">
          <div className="company-fee-info-block">
            <div typeof="fee-info-title" >{t("accInfoPage.finance")}:</div>
            <div className="company-fee-row-container">
              {/* Avia Card */}
              <StatisticSimpleCardComponent
                icon={<PlaneIcon />}
                type="lightBlue"
                title={t("accInfoPage.avia")}
                footer={""}
                data={[
                  [t("accInfoPage.sale"), useMoneyRubFormat.format(props.aviaTicketSale ?? 0)],
                  [t("accInfoPage.refound"), useMoneyRubFormat.format(props.aviaTicketReturn ?? 0)],
                  [
                    t("accInfoPage.exchange"),
                    useMoneyRubFormat.format(props.aviaTicketExcange ?? 0),
                  ],
                ]}
              />

              <StatisticSimpleCardComponent
                icon={<TrainIcon />}
                type="lightGreen"
                title={t("accInfoPage.train")}
                footer={""}
                data={[
                  [t("accInfoPage.sale"), useMoneyRubFormat.format(props.trainTicketSale ?? 0)],
                  [
                    t("accInfoPage.refound"),
                    useMoneyRubFormat.format(props.trainTicketReturn ?? 0),
                  ],
                  [
                    t("accInfoPage.exchange"),
                    useMoneyRubFormat.format(props.trainTicketExcange ?? 0),
                  ],
                ]}
              />
              <StatisticSimpleCardComponent
                icon={<HotelIcon />}
                type="green"
                title={t("accInfoPage.hotel")}
                footer={""}
                data={[
                  [t("accInfoPage.sale"), useMoneyRubFormat.format(props.hotelTicketSale ?? 0)],
                  [
                    t("accInfoPage.refound"),
                    useMoneyRubFormat.format(props.hotelTicketReturn ?? 0),
                  ],
                  [
                    t("accInfoPage.exchange"),
                    useMoneyRubFormat.format(props.hotelTicketExcange ?? 0),
                  ],
                ]}
              />
            </div>
          </div>

          <div className="d-flex flex-column p-2  ">
            <h6> {t("accInfoPage.operationCount")}:</h6>

            <div className="d-flex flex-row gap-1">
              {/* Avia Card */}
              <StatisticSimpleCardComponent
                icon={<PlaneIcon />}
                type = "lightBlue"
                title={t("accInfoPage.avia")}
                footer={""}
                data={[
                  [
                    t("accInfoPage.sale"),
                    props.aviaTicketSaleCount !== undefined
                      ? props.aviaTicketSaleCount.toString()
                      : "0",
                  ],
                  [
                    t("accInfoPage.refound"),
                    props.aviaTicketReturnCount !== undefined
                      ? props.aviaTicketReturnCount.toString()
                      : "0",
                  ],
                  [
                    t("accInfoPage.exchange"),
                    props.aviaTicketExcangeCount !== undefined
                      ? props.aviaTicketExcangeCount.toString()
                      : "0",
                  ],
                ]}
              />

              <StatisticSimpleCardComponent
                icon={<TrainIcon />}
                type = "lightGreen"
                title={t("accInfoPage.train")}
                footer={""}
                data={[
                  [
                    t("accInfoPage.sale"),
                    props.trainTicketSaleCount !== undefined
                      ? props.trainTicketSaleCount.toString()
                      : "0",
                  ],
                  [
                    t("accInfoPage.refound"),
                    props.trainTicketReturnCount !== undefined
                      ? props.trainTicketReturnCount.toString()
                      : "0",
                  ],
                  [
                    t("accInfoPage.exchange"),
                    props.trainTicketExcangeCount !== undefined
                      ? props.trainTicketExcangeCount.toString()
                      : "0",
                  ],
                ]}
              />
              <StatisticSimpleCardComponent
                icon={<HotelIcon />}
                type="green"
                title={t("accInfoPage.hotel")}
                footer={""}
                data={[
                  [
                    t("accInfoPage.sale"),
                    props.hotelTicketSaleCount !== undefined
                      ? props.hotelTicketSaleCount.toString()
                      : "0",
                  ],
                  [
                    t("accInfoPage.refound"),
                    props.hotelTicketReturnCount !== undefined
                      ? props.hotelTicketReturnCount.toString()
                      : "0",
                  ],
                  [
                    t("accInfoPage.exchange"),
                    props.hotelTicketExcangeCount !== undefined
                      ? props.hotelTicketExcangeCount.toString()
                      : "0",
                  ],
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
