import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "./store";
import {IBookFlightResponse} from "../responces/IBookFlightResponse";

interface BookingState {
  orders: IBookFlightResponse[];
}

const initialState: BookingState = {
  orders: [],
};

export const bookingSlice = createSlice({
  name: "book", initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<IBookFlightResponse[]>) => {
      state.orders = action.payload;
    },
  },
})

export const {
  setOrders,
} = bookingSlice.actions;

export const bookingState = (state: IRootState): BookingState => {
  return {
    orders: state.booking.orders,
  }
}

export default bookingSlice.reducer;
