import { register } from "module";

interface ErrorMessageProps {
  error: string;
  allignMessage?: "right" | "left";
}

export function ErrorInputComponent({ error, allignMessage }: ErrorMessageProps) {
  let bootstrapStyle = "input-group p-1 justify-content-end ";
  if ((allignMessage = "right")) {
    bootstrapStyle = "input-group p-1 ";
  }

  return (
    <>
      <div className={bootstrapStyle}>
        <span
          className="input-group-text w-100 justify-content-end bg-danger "
          id="basic-addon2"
          //style={{ backgroundColor: "red" }}
        >
          {error}
        </span>
      </div>
    </>
  );
}
