import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {IPax} from "../../../../interfaces/orderInterfaces/IPax";
import {PlaneIcon} from "../../../../icons/PlaneIcon";
import {TicketType} from "../../../../enums/TicketType";
import {TrainIcon} from "../../../../icons/TrainIcon";
import "./style.scss";
import {ArrowRightColorIcon} from "../../../../icons/ArrowRightColorIcon";
import {PlaneLandIcon} from "../../../../icons/PlaneLandIcon";
import {IAviaTariffInfo, IAviaVariant} from "../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {useState} from "react";
import {DataConvertService} from "../../../../services/DataConvertService";
import {BaggageIcon} from "../../../../icons/BaggageIcon";
import {ExchangeIcon} from "../../../../icons/ExchangeIcon";
import {SandClockIcon} from "../../../../icons/SandClockIcon";
import {ITrainVariant} from "../../../../interfaces/ITrainVariant";

type BookSummaryProps = {
    selectedVariant: ITravelVariant;
    selectedTariff: number;
    selectedPaxes: IPax[];
}

export function BookSummaryComponent(props: BookSummaryProps) {
    const {useMoneyRubFormat} = DataConvertService()
    const [selectedPax, setSelectedPax] = useState<IPax>(props.selectedPaxes[0])
    //const [error, setError] = useState<IAviaVariant | ITrainVariant>();


    let aviaVariant: IAviaVariant | undefined = undefined;
    let trainVariant: ITrainVariant | undefined = undefined;
    let aviaTariff: IAviaTariffInfo | undefined = undefined;
    let price: number = 0;
    let lastRejsIndex = 0;

    if (props.selectedVariant.ticketType === TicketType.AviaTicket) {
        aviaVariant = props.selectedVariant.travelVariant as IAviaVariant;
        lastRejsIndex = aviaVariant.rejsInfo.length - 1;
        price = props.selectedTariff >= 0 ? aviaVariant.rejsInfo[lastRejsIndex].tarifs[props.selectedTariff].priceWithSborTts : 0;
        aviaTariff = aviaVariant.rejsInfo[lastRejsIndex].tarifs[props.selectedTariff];

    }

    if (props.selectedVariant.ticketType === TicketType.TrainTicket) {
        trainVariant = props.selectedVariant.travelVariant as ITrainVariant;
    }
    console.log("SummaryComponentRender");


    return (
        <div className="summary-container">
            {/*Common Info*/}
            <div className="summary-info-row ">
                {props.selectedVariant.ticketType === TicketType.AviaTicket && <div typeof="icon"><PlaneIcon/></div>}
                {props.selectedVariant.ticketType === TicketType.TrainTicket && <TrainIcon/>}
                {/*<div className=""> <PlaneIcon/>} </div>*/}
                <div className="summary-info-block">{props.selectedVariant.departureDate}</div>
                <div className="border"></div>
                {/*<div className="common-vertical-border"></div>*/}

                <div className="summary-info-block">
                    <div>{props.selectedVariant.departureCity}</div>
                    <div typeof="airport-code">{props.selectedVariant.departurePlaceCode}</div>
                </div>

                <div><ArrowRightColorIcon/></div>

                <div className="summary-info-block">
                    <div>{props.selectedVariant.arriveCity}</div>
                    <div typeof="airport-code">
                        <div>{props.selectedVariant.arrivePlaceCode}</div>
                    </div>
                </div>
                <div className="border"></div>
                {/*{props.selectedVariant.ticketType === TicketType.AviaTicket &&*/}
                {/*    <div typeof="icon"><PlaneLandIcon/></div>}*/}
                <div className="summary-info-block">{props.selectedVariant.arrivedDate}</div>
                {props.selectedVariant.ticketType === TicketType.AviaTicket &&
                    <div typeof="icon"><PlaneLandIcon/></div>}
                <div className="border"></div>
                {aviaVariant && <div className=""> {useMoneyRubFormat.format(price)}</div>}
            </div>
            {/* The first avia info line */}
            {props.selectedVariant.ticketType === TicketType.AviaTicket &&
                <div className="summary-info-row">
                    <div className="summary-info-block">
                        <span> <ExchangeIcon/> Пересадки:{props.selectedVariant.transfersCount}</span></div>
                    <div className="border"></div>
                    <div className="summary-info-block"><span><SandClockIcon
                        iconSize={"25px"}/>{props.selectedVariant.travelTime}</span></div>
                    <div className="border"></div>
                    {aviaTariff && <>
                        <div className="summary-info-block"><span><BaggageIcon iconSize={"25px"}/> &nbsp;
                            {aviaTariff.name}</span></div>
                        <div className="border"></div>
                        <div className="summary-info-block"><span><BaggageIcon iconSize={"25px"}/> &nbsp;
                            Багаж: {aviaTariff.bagaj}</span></div>
                        <div className="border"></div>
                    </>
                    }
                </div>
            }
            {aviaTariff && <></>}

            {props.selectedPaxes &&
                <div className="summary-info-row">
                    {/*<div className="select-paxes-container-test" style={{alignContent: "space-between"}}>*/}
                        <div className="select-paxes-list">
                            <div className="title"><label>Selected Pax ({props.selectedPaxes.length})</label></div>
                            <dl>
                                {props.selectedPaxes.map((pax: IPax, index) => (
                                    <dd className={selectedPax.fio === pax.fio && selectedPax.passportNum ===pax.passportNum ? "active-pax-item" : ""} onClick={() =>
                                        setSelectedPax(pax)}>{pax.fio}</dd>))}
                            </dl>
                        </div>
                        {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}

                        {/*<ArrowLeftIcon/>*/}
                        <div style={{alignSelf: "center", marginLeft: "25px", marginRight: "25px"}}><ArrowRightColorIcon
                            iconSize={'50px'}/>
                        </div>
                        <div className="select-paxes-list">
                            <div className="title"><label>{selectedPax.fio}</label></div>
                            <dl>
                                <dd>ФИО(latin): {selectedPax.first} {selectedPax.middle} {selectedPax.last}</dd>
                                <dd>: {selectedPax.passportNum}</dd>
                                <dd>Gender: {selectedPax.gender}</dd>
                                <dd>Expiry: {selectedPax.passportInfo.expiry}</dd>
                                {/*<dd>Паспорт*/}
                                {/*    (серия/N): {props.selectedPaxes[selectedPax].PassportInfo?.series !== "" ? props.selectedPaxes[selectedPax].PassportInfo.series : " _ "}/{props.selectedPaxes[selectedPax].PassportInfo.number}</dd>*/}
                                <dd>Истекает: {selectedPax.passportInfo?.expiry}</dd>

                                {/*{props.selectedPaxes[selectedPax].BonusCards.length > 0 &&*/}
                                {/*    props.selectedPaxes[selectedPax].BonusCards.map((card, index) => (*/}
                                {/*        <dd>Карта №{index + 1}: {card}</dd>))}*/}


                                {/*<dd>Бонусые карты: {props.selectedPaxes[selectedPax].BonusCards.length}</dd>*/}
                                {/*<dd>ValidPerevoz: {props.selectedPaxes[selectedPax].SBlist.length}</dd>*/}
                            </dl>

                        </div>

                    {/*<div className="select-paxes-list">*/}
                        {/*    <div className="title"><label>Selected paxes ({props.selectedPaxesList!.length})</label></div>*/}
                        {/*    <dl>*/}
                        {/*        {props.selectedPaxesList && props.selectedPaxesList.map((pax: IPax,index) => (<dd onClick={()=>{*/}
                        {/*            props.updateSelectedPaxesList(props.selectedPaxesList!.filter((sp,si)=>si!==index));*/}
                        {/*        }}>{pax.fio} <div className="unselect-button">&#x2715;</div></dd>))}*/}
                        {/*        /!*<dd>АНДРОСОВ ДМИТРИЙ СЕРГЕЕВИЧ <div className="unselect-button">&#x2715;</div></dd>*!/*/}
                        {/*        /!*<dd>БАХТИН АЛЕКСАНДР АЛЕКСАНДРОВИЧ <div className="unselect-button">&#x2715;</div></dd>*!/*/}
                        {/*        /!*<dd>БЕЛЫХ МИХАИЛ ЮРЬЕВИЧ <div className="unselect-button">&#x2715;</div></dd>*!/*/}
                        {/*    </dl>*/}
                        {/*</div>*/}
                        {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}
                    {/*</div>*/}
                </div>
            }


        </div>)
}