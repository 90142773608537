import {doc} from "prettier";
import fill = doc.builders.fill;
import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../constants/componentsContants";

export function TrainHorizontalIcon({iconSize}:IIconProps) {

    iconSize = iconSize??DEFAULT_ICON_SIZE;

    //const styleZero = "fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10";
    // const styleOne = "fill:none;stroke:#000000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;";

    return (<>


        <svg
            fill="currentColor"
            height={iconSize}
            width={iconSize}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <g>
                <g>
                    <path d="M502.488,277.008l-38.184-50.364c-20.688-27.288-53.483-43.578-87.726-43.578h-176.05
			c-0.483-0.479-0.991-0.941-1.538-1.372l-28.832-22.698l27.198-16.884c8.413-5.222,10.998-16.274,5.776-24.686
			c-5.223-8.413-16.276-10.998-24.686-5.776l-48.933,30.377c-4.967,3.083-8.119,8.396-8.444,14.233
			c-0.325,5.838,2.216,11.468,6.81,15.084l14.89,11.722H17.927C8.027,183.066,0,191.093,0,200.993v133.21
			c0,9.901,8.027,17.927,17.927,17.927H465.17c25.821,0,46.83-21.007,46.83-46.83C512,295.148,508.621,285.1,502.488,277.008z
			 M465.17,316.276H35.855v-97.355h340.722c20.387,0,40.003,8.574,53.921,23.243h-31.189c-9.901,0-17.927,8.027-17.927,17.927
			c0,9.901,8.027,17.927,17.927,17.927h53.793c1.619,0,3.182-0.234,4.673-0.637l16.139,21.288c1.438,1.897,2.23,4.252,2.23,6.632
			C476.145,311.353,471.221,316.276,465.17,316.276z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M173.105,242.164h-20.689c-9.901,0-17.927,8.027-17.927,17.927c0,9.901,8.027,17.927,17.927,17.927h20.689
			c9.901,0,17.927-8.027,17.927-17.927C191.033,250.191,183.007,242.164,173.105,242.164z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M253.104,242.164h-20.689c-9.901,0-17.927,8.027-17.927,17.927c0,9.901,8.027,17.927,17.927,17.927h20.689
			c9.901,0,17.927-8.027,17.927-17.927C271.031,250.191,263.005,242.164,253.104,242.164z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M333.103,242.164h-20.689c-9.901,0-17.927,8.027-17.927,17.927c0,9.901,8.027,17.927,17.927,17.927h20.689
			c9.901,0,17.927-8.027,17.927-17.927C351.03,250.191,343.004,242.164,333.103,242.164z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M467.568,367.194H17.952c-9.901,0-17.927,8.027-17.927,17.927c0,9.901,8.027,17.927,17.927,17.927h449.615
			c9.901,0,17.927-8.027,17.927-17.927C485.495,375.221,477.468,367.194,467.568,367.194z"/>
                </g>
            </g>
        </svg>
    </>)
}