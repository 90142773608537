import {useEffect, useRef, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {setIsTokenBeingRefreshed} from "./redux/authSlice";
import {ProtectedRoute} from "./components/protectedRoute";
import MainLayout from "./layout/mainLayout";
import {HomeLayout} from "./layout/homeLayout";
import {CabinetLayout} from "./layout/cabinetLayout";
import {HomePage} from "./pages/landingPages/homePage";
import {SignInPage} from "./pages/landingPages/signInPage";
import {HomeCabinetPage} from "./pages/cabinetPages/homeCabinetPage";
import {MyProfilePage} from "./pages/cabinetPages/myProfilePage";
import {StatisticPage} from "./pages/cabinetPages/statisticPage";
import {OrderCabinetPage} from "./pages/cabinetPages/orderCabinetPage";
import {AccountantDocumentsPage} from "./pages/cabinetPages/documentsPage";
import {EmployeesPage} from "./pages/cabinetPages/employeesPage";
import {ServicesPage} from "./pages/landingPages/servicesPage";
import {TariffsPage} from "./pages/landingPages/tariffsPage";
import {ContactsPage} from "./pages/landingPages/contactsPage";
import {ChangePasswordPage} from "./pages/commonPages/changePasswordPage";
import {RecoveryPasswordPage} from "./pages/commonPages/recoveryPasswordPage";
import {NotFoundPage} from "./pages/errorPages/notFoundPage";
import {AuthService} from "./services/AuthService";
import {Environments} from "./enums/Environments";
import {TripPage} from "./pages/cabinetPages/tripPage";
import {TripPage2} from "./pages/cabinetPages/tripPage2";
import {SupportPage} from "./pages/cabinetPages/supportPage";
import {store} from "./redux/store";
import "./App.scss";
import {TimeUtils} from "./utils/timeUtils";


export function App() {

    const dispatch = useAppDispatch();

    const authService = AuthService();

    const [isFocused, setIsFocused] = useState(true);


    const onFocus = () =>  {
        //console.log('window got focus..')
        setIsFocused(true);
    }
    const onBlur = () => {
        //console.log('window lost focus..')
        setIsFocused(false);
    }


    const refreshAccessToken = async () => {
        // console.log('run refresh token method, check window state..')
        if(!document.hidden && isFocused) {
            // console.log('window is active, get global state..')
            const state = store.getState();
            if (state.auth.isValidUser) {
                // console.log('user is valid..')
                try {
                    // console.log('refreshing token..')
                    // set token to refreshing state
                    dispatch(setIsTokenBeingRefreshed(true));
                    // request token refreshing
                    let refreshTokenResult = await authService.refreshToken();
                    // if token refreshing was successfully complete
                    if (refreshTokenResult) {
                        // console.warn('token has been refresh..')
                        // reset token refreshing state
                        dispatch(setIsTokenBeingRefreshed(false));
                        return true;
                    } else {
                        // console.warn('error occurred while token refreshing')
                        // user logout
                        await authService.logout();
                    }
                } catch (error) {
                    // console.warn('error occurred while token refreshing')
                    // user logout
                    await authService.logout();
                }
                // console.log('remove token refreshing lock..')
                // reset token refreshing state
                dispatch(setIsTokenBeingRefreshed(false));
            }
        }
        return false;
    }

    const checkToken = (force: boolean = false) => {
        // console.log(document.hidden, isFocused);
        if(!force && (document.hidden || !isFocused)) {
            // console.log('window has not focused, prevent token check..')
            return;
        }
        // console.log('get global state..')
        const state = store.getState();
        // console.log('check user..');
        if (state.auth.isValidUser) {
            // console.log('user is valid..');
            // console.log('check token lifetime..');
            // get token expiration date
            let tokenExpirationDate = new Date(state.auth.expiredAt * 1000);
            // get token lifetime
            let tokenLifetime = ((tokenExpirationDate.getTime() - new Date().getTime()));
            // set time before token update
            let timeBeforeUpdate = tokenLifetime - 60 * 1000;
            // TimeUtils.getTimeDetails(timeBeforeUpdate, function (hours, minutes, seconds) {
            //     console.log('time before token update', minutes + ":" + seconds.toFixed(0).padStart(2, '0'));
            // });
            // update token
            if (timeBeforeUpdate < 0) {
                // console.log('token needs update..')
                refreshAccessToken();
            }
            else if(state.auth.isTokenBeingRefreshed) {
                dispatch(setIsTokenBeingRefreshed(false));
            }
        }
    }


    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        }
    });

    useEffect(() => {
        checkToken(true);
        const timer = setInterval(() => {
            checkToken();
        }, 1000);
        return () => clearInterval(timer);
    });


    return (
        <>
            <Routes>
                <Route path="/" element={<MainLayout/>}>
                    <Route path="/" element={<HomeLayout/>}>
                        <Route index element={<HomePage/>}/>
                        <Route path="/services" element={<ServicesPage/>}/>
                        <Route path="/tariffs" element={<TariffsPage/>}/>
                        <Route path="/contacts" element={<ContactsPage/>}/>
                        <Route path="sign-in" element={<SignInPage/>}/>
                        {/*<Route path="sign-up" element={<SignUpPage/>}/>*/}
                        <Route path="changePassword" element={<ChangePasswordPage/>}/>
                        <Route path="recovery" element={<RecoveryPasswordPage/>}/>
                    </Route>
                    <Route path="/cabinet/" element={<ProtectedRoute/>}>
                        <Route path="/cabinet/" element={<CabinetLayout/>}>

                            <Route path="/cabinet/" element={<Navigate to="/cabinet/booking"/>}/>
                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development}/>}>
                                <Route path="/cabinet/account" element={<HomeCabinetPage/>}/>
                            </Route>

                            <Route path="/cabinet/" element={<ProtectedRoute env={Environments.Development}/>}>
                                <Route path="/cabinet/profile" element={<MyProfilePage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development}/>
                                }
                            >
                                <Route path="/cabinet/reports" element={<StatisticPage/>}/>
                            </Route>

                            <Route
                                path="documents"
                                element={
                                    <AccountantDocumentsPage/>
                                }
                            />

                            <Route
                                path="booking"
                                element={
                                    <TripPage/>
                                }
                            />

                            <Route
                                path="booking-v2"
                                element={
                                    <TripPage2 />
                                }
                            />

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development | Environments.Staging}/>
                                }
                            >
                                <Route path="/cabinet/employees" element={<EmployeesPage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute env={Environments.Development}/>
                                }
                            >
                                <Route path="/cabinet/orders" element={<OrderCabinetPage/>}/>
                            </Route>

                            <Route
                                path="/cabinet/"
                                element={
                                    <ProtectedRoute />
                                }
                            >
                                <Route path="/cabinet/support" element={<SupportPage/>}/>
                            </Route>

                        </Route>
                    </Route>
                    <Route path='*' element={<NotFoundPage/>}/>
                    {/*<Route path='/accessDenied' element={<AccessDeniedPage/>}/>*/}
                </Route>
            </Routes>
        </>
    );
}

export default App;