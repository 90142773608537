import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const SunriseIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            style={style["svg"]}>
            <path
                d="M13 5a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0V5z"
                style={style["path"]}
            />
            <path
                d="M9.028 19H3a1 1 0 1 1 0-2h3.803a6 6 0 1 1 10.394 0H21a1 1 0 1 1 0 2H9.028zM12 10a4 4 0 0 0-2.646 7h5.292A4 4 0 0 0 12 10z"
                fillRule="evenodd"
                clipRule="evenodd"
                style={style["path"]}
            />
            <path
                d="M19.071 8.343l-.707.707a1 1 0 1 1-1.414-1.414l.707-.707a1 1 0 1 1 1.414 1.414zM4 15a1 1 0 1 0 0-2H3a1 1 0 1 0 0 2h1zm18-1a1 1 0 0 1-1 1h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1zM5.636 9.05A1 1 0 0 0 7.05 7.636l-.707-.707A1 1 0 1 0 4.93 8.343l.707.707z"
                style={style["path"]}
            />
        </svg>
    )
}