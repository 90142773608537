export enum AttachmentFormat {
    None = 0,
    Pdf  = 1 << 0,  // 1
    Excel = 1 << 1  // 2
}

export enum AttachmentType {
    None = 0,
    Bill = 1 << 0,         // 1
    ClosingDocs = 1 << 1,  // 2
    Attachments = 1 << 2,  // 4
    All = Bill | ClosingDocs | Attachments // 7
}

export interface IDocAttachments {
    [key: string]: AttachmentType
}

export interface ISendDocsToEdm {
    documents: IDocAttachments,
    emails: string[],
    format: AttachmentFormat,
    connectionId: string,
    needEdmUpload: boolean,
}