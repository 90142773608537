import React, {
    useEffect,
    useRef,
    useState,
    CSSProperties
} from "react";
import {MdScrollViewer} from "../md-scroll-viewer";
import {Guid, Utils} from "../../utils/utils";
import './style.scss'


interface IMdList {
    itemSource: Array<any>;
    itemTemplate?: (data: any) => React.ReactNode;
    activeItem?: any;
    onItemSelect?: (data: any) => void;
    style?: { [selector: string]: CSSProperties }
}


export function MdList({style = {}, itemSource, itemTemplate, activeItem, onItemSelect}: IMdList) {
    const root = useRef(null);
    const body = useRef(null);

    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [selectedItem, setSelectedItem] = useState<any>(null);


    useEffect(() => {
        const index = (activeItem != null ? itemSource.findIndex(x => x == activeItem) : -1);
        if (selectedIndex != index) {
            setSelectedIndex(index);
        }

        //const item = (activeItem != null ? itemSource.find(x => x == activeItem) : null);
        const item = activeItem;

        if (selectedItem != item) {
            setSelectedItem(item);
        }
    }, [activeItem]);

    return (
        <div
            ref={root}
            className="md-list"
            style={style["root"]}>
            <MdScrollViewer>
                <ul
                    ref={body}
                    style={style["list"]}
                >
                    {
                        itemSource.map((row, rowIndex) => {

                                const template = itemTemplate!== undefined&& row!==undefined ? itemTemplate(row) : <></>;
                                const isSelected =row===undefined?false: (Utils.stringHash(JSON.stringify(selectedItem)) == Utils.stringHash(JSON.stringify(row)));
                                return (
                                    <li
                                        key={"md-list-item-" + Guid.newGuid()}
                                        className={(isSelected ? "--selected-item" : "")}
                                        onClick={(e) => {
                                            setSelectedIndex(rowIndex);
                                            setSelectedItem(row);
                                            if (onItemSelect != null) {
                                                onItemSelect(row)
                                            }
                                        }}
                                        style={style["list-item"]}
                                    >
                                        {
                                            template != null
                                                ? (
                                                    template
                                                )
                                                : (
                                                    <></>
                                                    // <>{row}</>
                                                )
                                        }
                                    </li>
                                )
                            }
                        )


                    }
                </ul>
            </MdScrollViewer>
        </div>
    );
}