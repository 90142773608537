import './style.scss'
import React, {CSSProperties, useEffect, useState} from "react";

interface IMdToggleButtonProps {
    type?: "reset" | "submit" | "button";
    buttonStyle?: CSSProperties;
    inkStyle?: CSSProperties;
    children?: any;
    checked?: boolean;
    disabled?: boolean;
    onClick?: (event: any) => void;
    onChange?: (state: boolean) => void;
}

export function MdToggleButton(props: IMdToggleButtonProps) {

    const [checked, setChecked] = useState(props.checked == true);

    const onMouseDown = (event: React.MouseEvent) => {

        if (event.button != 0) return;

        let button = event.target as HTMLButtonElement;

        let rect = button.getBoundingClientRect();

        let x = event.clientX - rect.left; //x position within the element.
        let y = event.clientY - rect.top;  //y position within the element.

        let inkNode = button.querySelector('.md-toggle-button-ink') as HTMLElement;

        if (inkNode != null && !inkNode.classList.contains('--ink-active')) {
            inkNode.style.left = (x - Math.max(rect.width, rect.height)) + 'px';
            inkNode.style.top = (y - Math.max(rect.width, rect.height)) + 'px';
            inkNode.style.width = (Math.max(rect.width, rect.height) * 2) + 'px';
            inkNode.style.height = (Math.max(rect.width, rect.height) * 2) + 'px';
            inkNode.classList.add('--ink-active');
            setTimeout(() => {
                inkNode.classList.remove('--ink-active');
            }, 400);
        }
    }

    const onClick = (event: React.MouseEvent) => {
        let state = !checked;
        setChecked(state);
        if(props.onClick) {
            props.onClick(event);
        }
        if (props.onChange) {
            props.onChange(state);
        }
    }

    return (
        <button
            type="button"
            className="md-toggle-button"
            style={props.buttonStyle}
            disabled={props.disabled == true}
            onMouseDown={(e) => onMouseDown(e)}
            onClick={(e) => onClick(e)}>
            <span className="md-toggle-button-content">{props.children}</span>
            <span className="md-toggle-button-ink" style={props.inkStyle}></span>
        </button>
    );
}