import React, {CSSProperties, useEffect, useState} from "react";
import './style.scss'


interface IMdAccordionProps {
    mode?: "multi-open" | "single-open";
    sections: Array<IMdAccordionSectionProps>;
    style?: { [selector: string]: CSSProperties }
}

interface IMdAccordionSectionProps {
    header?: string | React.ReactNode;
    content?: string | React.ReactNode;
    isOpen?: boolean;
    style?: React.CSSProperties;
}


export function MdAccordion({ mode = "multi-open", sections = [], style = {} }: IMdAccordionProps) {

    const [openedSections, setOpenedSections] = useState<number[]>([]);

    const onHeaderClick = (index: number) => {
        if(!openedSections.some(x => x == index)) {
            if(mode == "multi-open") {
                setOpenedSections(prev => [...prev.filter(x => x != index), index]);
            }
            else {
                setOpenedSections(prev => [index]);
            }
        }
        else {
            if(mode == "multi-open") {
                setOpenedSections(prev => [...prev.filter(x => x != index)]);
            }
            else {
                setOpenedSections(prev => []);
            }
        }
    }


    useEffect(() => { }, []);


    return (
        <div className={"md-accordion"}
            style={ style["root"] }>
            {
                sections.map((section, index) => {
                    const isOpen = openedSections.some(x => x == index);
                    return (
                        <div key={index}
                            className="md-accordion-section">
                            <div className="md-accordion-section-header">
                                <button className="md-accordion-section-header-button"
                                    onClick={() => onHeaderClick(index)}
                                >
                                    {section.header}
                                </button>
                            </div>
                            <div className={("md-accordion-section-content" + (isOpen ? " --open" : ""))}>
                                {section.content}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}


// export function MdAccordion({ mode = "multi-open", sections = [], style = {} }: IMdAccordionProps) {
//
//
//     useEffect(() => { }, []);
//
//
//     return (
//         <div className={"md-accordion"} style={ style["root"] }>
//             {
//                 sections.map((section, sectionIndex) => {
//                     return (
//                         <React.Fragment key={sectionIndex}>
//                             <MdAccordionItem
//                                 header={section.header}
//                                 content={section.content}
//                                 isOpen={section.isOpen}
//                                 style={section.style}
//                             />
//                         </React.Fragment>
//                     )
//                 })
//             }
//         </div>
//     );
// }
//
// export function MdAccordionItem({ header = null, content = null, isOpen = false, style = {} }: IMdAccordionSectionProps) {
//
//     const [isOpened, setIsOpened] = useState<boolean>(isOpen);
//
//     const onHeaderClick = () => {
//         setIsOpened(!isOpened);
//     }
//
//     useEffect(() => { }, []);
//
//
//     return (
//         <div className="md-accordion-section">
//             <div className="md-accordion-section-header">
//                 <button className="md-accordion-section-header-button"
//                     onClick={() => onHeaderClick()}
//                 >
//                     {header}
//                 </button>
//             </div>
//             <div className={("md-accordion-section-content" + (isOpened ? " --open" : ""))}>
//                 {content}
//             </div>
//         </div>
//     );
// }