import { ICustomerAirCompany } from "../../../../interfaces/reportInterfaces/customerInterfaces";
import { DataConvertService } from "../../../../services/DataConvertService";
import "../../../../scss/tables.scss";
import {useTranslation} from "react-i18next";

type CustomerAirCompaniesProp = {
  customerAirCompanies: ICustomerAirCompany[];
};
export function CustomerAirCompaniesComponent({ customerAirCompanies }: CustomerAirCompaniesProp) {
  const { useMoneyRubFormat } = DataConvertService();
  const { t } = useTranslation();
  return (
    <>
      <table className="table common-document-stripped-table table-bordered">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{`${t("statisticPage.airCompanyName")}`}</th>
            <th scope="col">{`${t("statisticPage.airCompanyCode")}`}</th>
            <th scope="col">{`${t("statisticPage.ticketClass")}`}</th>
            <th scope="col">{`${t("statisticPage.charge")}`}</th>
            <th scope="col">{`${t("statisticPage.count")}`}</th>
          </tr>
        </thead>
        <tbody>
          {customerAirCompanies && customerAirCompanies.map((item, i) => {
            return (
              <tr key={CustomerAirCompaniesComponent.name + "_" + i}>
                <td>{i}</td>
                <td>{item.airCompany}</td>
                <td>{item.airCompCode}</td>
                <td>{item.ticketClass}</td>
                <td>{useMoneyRubFormat.format(item.charge)}</td>
                <td>{item.count}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
