import React, {CSSProperties, SetStateAction, useEffect, useState} from "react";
import './style.scss'


interface IMdEmailAreaProps {
    style?: CSSProperties;
    placeholder?: string;
    valuesList: string[];
    setValuesList: React.Dispatch<SetStateAction<string[]>>;
    disabled?: boolean;
    onChange?: (event: any) => void;
}


export function MdEmailArea(props: IMdEmailAreaProps) {
    let root: HTMLElement | null = null;

    const onClick = (event: React.MouseEvent) => {
        let target = event.target as HTMLElement;

        if (target.classList.contains('emails-input')) {
            let input = target.querySelector('input') as HTMLInputElement;
            input.focus();
        }

        if (target.classList.contains('remove')) {
            let node = (event.target as HTMLElement).parentNode as HTMLElement;
            if (node != null && node.dataset.value != null) {
                removeChip(node.dataset.value);
            }
        }
    }

    const onKeyPress = (event: React.KeyboardEvent) => {
        let target = event.target as HTMLInputElement;
        if (event.key === "Enter") {
            event.preventDefault();
            let target = event.target as HTMLInputElement;
            if (isValidEmail(target.value)) {
                addChip(target.value);
                target.value = '';
            }
        } else if (event.key === "Backspace" && !target.value) {
            const chips = getChips();
            if (!chips.length) {
                return;
            }
            const lastChip = chips[chips.length - 1];

            // TODO check remove on backspace
            if (lastChip != null && lastChip.dataset.value != null) {
                removeChip(lastChip.dataset.value);
            }
        }
    }

    const onLostFocus = (event: React.FocusEvent) => {
        let target = event.target as HTMLInputElement;
        if (isValidEmail(target.value)) {
            addChip(target.value);
            target.value = '';
        }
    }

    const onPaste = (event: React.ClipboardEvent) => {
        let target = event.target as HTMLInputElement;

        if (!target.matches('input')) {
            return;
        }

        event.preventDefault();

        const chunks = event.clipboardData.getData('Text').split(/(\s+)/);

        if (chunks.length > 0) {
            chunks.forEach((chunk) => {
                if (isValidEmail(chunk)) {
                    addChip(chunk);
                }
            });
        }

        target.value = '';
    }


    const addChip = (email: string) => {
        const trimmedEmail = email && email.trim();

        if (!trimmedEmail) {
            return;
        }

        const chips = getChips();

        if(chips.some(x => x.dataset.value == trimmedEmail)) {
            return;
        }

        const chip = document.createElement('span');
        chip.setAttribute('role', 'email-chip');
        chip.classList.add('email-chip');
        chip.dataset.value = trimmedEmail;

        //if (!isValidEmail(trimmedEmail)) {
        //chip.classList.add('invalid')
        //}

        let input = root?.querySelector('input') as HTMLInputElement;

        chip.innerHTML = '<span class="content">' + trimmedEmail + '</span><a class="remove">×</a>'

        root?.insertBefore(chip, input);

        if(!props.valuesList.includes(trimmedEmail)) {
            props.setValuesList(prev => prev.concat(trimmedEmail));
        }
    }


    const removeChip = (email: string) => {
        if(email == null) {
            return;
        }

        const chips = getChips();

        let chipToRemove = chips.find(x => x.dataset.value == email);

        if(chipToRemove != null) {
            root?.removeChild(chipToRemove);
        }

        if(props.valuesList.includes(email)) {
            props.setValuesList(prev => prev.filter(x => x != email));
        }
    }


    const getChips = () => {
        return Array.prototype.slice
            .call(root?.querySelectorAll('.emails-input .email-chip') || [])
    }

    const isValidEmail = (email: string) => {
        //return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        //return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
        return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    }





    useEffect(() => {
        if(props.valuesList != null) {
            props.valuesList.forEach(chunk => {
                if (isValidEmail(chunk)) {
                    addChip(chunk);
                }
            });
        }
    }, []);


    return (
        <div spellCheck="false"
             style={props.style}
             className="md-email-area emails-input"
             onClick={onClick}
             ref={ref => root = ref}
        >
            <input
                type="text"
                role="emails-input"
                placeholder={props.placeholder}
                onBlur={onLostFocus}
                onPaste={onPaste}
                onKeyDown={onKeyPress}
            />
        </div>
    );
}