import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ITrainTariffInfo, ITrainVariant} from "../../../../../../interfaces/ITrainVariant";
import {IPassenger} from "../../../../../../interfaces/IPassenger";
import {MdButton} from "../../../../../../components/md-button";
import {MdToggleButton} from "../../../../../../components/md-toggle-button";
import {UserIconFilled} from "../../../../../../icons/UserIconFilled";
import {TrainIcon3} from "../../../../../../icons/TrainIcon3";
import {TrainTicketComponent} from "./trainTicketComponent";
import {CalendarIcon} from "../../../../../../icons/CalendarIcon";
import {Guid, Utils} from "../../../../../../utils/utils";
import "./style.scss";
import {FavoriteIcon} from "../../../../../../icons/FavoriteIcon";


export interface ITrainComponent {
    trainInfo: { id: string, train: ITrainVariant, tariff?: ITrainTariffInfo }
    changeTripItemTariff?: (variantId: string, tariff: ITrainTariffInfo) => void;
    passengers?: { passenger: IPassenger, passport: any }[],
    selectTrain?:  (ticket: ITrainVariant, tariff: ITrainTariffInfo) => void;
}


export function TrainComponent({ trainInfo, changeTripItemTariff, passengers, selectTrain }: ITrainComponent) {
    const {t} = useTranslation();

    const { train, tariff } = trainInfo;

    const tickets = [train];
    const tarifs = tickets.map(x => x.categoryPrice).flat();

    const minPrice = Math.min(...tickets.map(r => r.categoryPrice.map(t => t.price)).flat());

    const routeStart = tickets[0];
    //const routeStartDate = new Date(); // new Date(routeStart.dateDep.replace('Z', ''));

    const routeEnd = tickets[tickets.length - 1];
    //const routeEndDate = new Date(); // new Date(routeEnd.dateArr.replace('Z', ''));


    const [selectedTariff, setSelectedTariff] = useState<ITrainTariffInfo>(trainInfo.tariff ?? tarifs[0]);
    const [selectedTariffIndex, setSelectedTariffIndex] = useState<number>(Math.max(0, tarifs.findIndex(x => x == selectedTariff)));


    return (
        <details className="train-component-2">
            <summary className="train-component-2-header" style={{width: "100%"}}>
                <div className="train-component-2-header-first-line">
                    <div>
                        <span className="travel-time-info">
                            {
                                routeStart.travelTime
                            }
                        </span>
                    </div>
                    <div>

                    </div>
                    <div>
                        <span className="price-info">
                            {
                                (selectedTariff.price + selectedTariff.sborTts).toLocaleString() + " ₽"
                            }
                        </span>
                    </div>
                </div>
                <div className="train-component-2-header-second-line">
                    <div className="department-info">
                        <div className="department-airport-code">
                            {
                                routeEnd.stationFrom
                            }
                        </div>
                        <div className="department-airport-city">
                            {
                                routeEnd.cityFrom
                                    .toLowerCase()
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')
                                    .split('-')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join('-')
                            }
                        </div>
                        <div className="department-time">
                            {
                                // routeEndDate.getHours().toString().padStart(2, "0") + ":" + routeEndDate.getMinutes().toString().padStart(2, "0")
                                // + ", "
                                // + routeEndDate.toLocaleString('default', { month: 'short' }).replace(".", "")
                                // + " "
                                // + routeEndDate.getDate().toString().padStart(2, "0")
                                routeEnd.departureShortOn
                                + ", "
                                + routeEnd.departureAt.split(' ').reverse().join(' ').replace('.', '')
                            }
                        </div>
                    </div>
                    <div className="train-path-info">
                        <div className="train-track">
                            <hr style={{marginTop: "16px "}}/>
                            <div className="train-track-icon">
                                <TrainIcon3
                                    width="18px"
                                    height="18px"
                                    style={{
                                        "path": {
                                            fill: "#3E5CB8"
                                        }
                                    }}
                                />
                            </div>
                            <hr style={{marginTop: "16px "}}/>
                        </div>
                        {
                            tickets.length > 1
                                ? (
                                    <div className="train-connections">
                                        пересадки: {tickets.length - 1}
                                    </div>
                                )
                                : (
                                    <div className="train-time">
                                        {routeStart.trainNum}
                                    </div>
                                )
                        }
                    </div>
                    <div className="arrival-info">
                        <div className="arrival-airport-code">
                            {
                                routeStart.stationTo
                            }
                        </div>
                        <div className="arrival-airport-city">
                            {
                                routeStart.cityTo
                                    .toLowerCase()
                                    .split(' ')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')
                                    .split('-')
                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join('-')
                            }
                        </div>
                        <div className="arrival-time">
                            {
                                // routeStartDate.getHours().toString().padStart(2, "0") + ":" + routeStartDate.getMinutes().toString().padStart(2, "0")
                                // + ", "
                                // + routeStartDate.toLocaleString('default', { month: 'short' }).replace(".", "")
                                // + " "
                                // + routeStartDate.getDate().toString().padStart(2, "0")
                                routeStart.arriveAtn
                                + ", "
                                + routeStart.arriveShortOn.split(' ').reverse().join(' ').replace('.', '')
                            }
                        </div>
                    </div>
                </div>
            </summary>

            <div className="train-component-2-details" style={{backgroundColor: "#fdfdfd"}}>

                <div className="train-component-2-details-section">
                    <div className="train-component-2-details-section-block">
                        <div className="train-info-section">
                            <div className="train-roadmap" style={{padding: 0}}>
                                {
                                    tickets.map((ticket, ticketIndex) => {
                                        //const dateDepartment = new Date(ticket.dateDep.replace('Z', ''));
                                        const townDepartment = ticket.cityFrom;

                                        //const dateArrival = new Date(ticket.dateArr.replace('Z', ''));
                                        const townArrival = ticket.cityTo;

                                        let trainWarning = null;

                                        let messages = [];

                                        // if (ticketIndex > 0) {
                                        //     const prevDirection = tickets[ticketIndex - 1];
                                        //     const prevCity = prevDirection.cityArr;
                                        //     const prevDateArrival = new Date(prevDirection.dateArr.replace('Z', ''));
                                        //
                                        //     let waitTime = TimeUtils.getTimeDetails(dateDepartment.getTime() - prevDateArrival.getTime());
                                        //
                                        //     messages.push("пересадка в аэропорту города " + prevCity + " с ожиданием " + Math.floor(waitTime.minutes / 60) + "ч " + (waitTime.minutes % 60) + "м");
                                        //
                                        //     // other checks...
                                        //
                                        //     trainWarning = (
                                        //         <div className="train-roadmap-item">
                                        //             <div className="train-roadmap-validation">
                                        //                 <div className="train-roadmap-validation-node">
                                        //                     <div
                                        //                         className="train-roadmap-validation-node-button">
                                        //                         <WarningRhombusIcon
                                        //                             width="18px"
                                        //                             height="18px"
                                        //                             style={{
                                        //                                 "path": {
                                        //                                     fill: "#F66000" //"#838383"//"#F66000"
                                        //                                 }
                                        //                             }}
                                        //                         />
                                        //                     </div>
                                        //                 </div>
                                        //                 <div className="train-roadmap-validation-message">
                                        //                     <div
                                        //                         className="train-roadmap-validation-message-title">
                                        //                         Предупреждение
                                        //                     </div>
                                        //                     <ul
                                        //                         className="train-roadmap-validation-message-content">
                                        //                         {
                                        //                             messages.map(message => {
                                        //                                 return (
                                        //                                     <li key={"train-warning-message" + Guid.newGuid()}>{message}</li>
                                        //                                 )
                                        //                             })
                                        //                         }
                                        //                     </ul>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     )
                                        // }

                                        return (
                                            <div key={"train-roadmap-item" + Guid.newGuid()}>

                                                {/*{*/}
                                                {/*    trainWarning != null && (*/}
                                                {/*        trainWarning*/}
                                                {/*    )*/}
                                                {/*}*/}

                                                <div className="train-roadmap-item">
                                                    <div className="train-roadmap-item-start-node">
                                                        <button
                                                            className={"train-roadmap-item-start-node-button" + (ticketIndex == 0 ? " --train-begin" : "")}>

                                                        </button>
                                                        <div className="train-roadmap-item-start-node-location">
                                                            {townDepartment} ({ticket.stationFrom})
                                                        </div>
                                                    </div>
                                                    <div className="train-roadmap-item-content">

                                                        <TrainTicketComponent
                                                            ticket={ticket}
                                                        />

                                                    </div>
                                                    <div className="train-roadmap-item-end-node">
                                                        <button
                                                            className={"train-roadmap-item-end-node-button" + (ticketIndex == tickets.length - 1 ? " --train-end" : "")}>

                                                        </button>
                                                        <div className="train-roadmap-item-end-node-location">
                                                            {townArrival} ({ticket.stationTo})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="train-component-2-details-section-block">
                        <div className="train-tariff-switcher">
                            {
                                tariff != null
                                    ? (
                                        <MdToggleButton
                                            key={"tariff" + Guid.newGuid()}
                                            buttonStyle={{
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "1px 10px 0 10px",
                                                borderRadius: "4px",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "11px",
                                                color: "#74B100",
                                                border: "1px solid #74B100",
                                            }}
                                            inkStyle={{
                                                backgroundColor: "#74B100",
                                                opacity: 0.1
                                            }}
                                            checked={true}
                                        >
                                            {tariff.categoryName != null && tariff.categoryName.length > 0 ? tariff.categoryName : ("Неизвестный тариф")}
                                        </MdToggleButton>
                                    )
                                    : (
                                        train.categoryPrice.map((tariff, tariffIndex) => {
                                            return (
                                                <React.Fragment key={"tariff" + Guid.newGuid()}>
                                                    <MdToggleButton
                                                        buttonStyle={{
                                                            minInlineSize: "fit-content",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "1px 10px 0 10px",
                                                            borderRadius: "4px",
                                                            minHeight: "24px",
                                                            maxHeight: "52px",
                                                            fontSize: "11px",
                                                            color: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                            border: (selectedTariffIndex == tariffIndex ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                        }}
                                                        inkStyle={{
                                                            backgroundColor: (selectedTariffIndex == tariffIndex ? "#74B100" : "#C1C1C1"),
                                                            opacity: 0.1
                                                        }}
                                                        checked={selectedTariffIndex == tariffIndex}
                                                        onClick={() => {
                                                            setSelectedTariffIndex(tariffIndex);
                                                            setSelectedTariff(tariff);
                                                            if (changeTripItemTariff != null) {
                                                                changeTripItemTariff(trainInfo.id, tariff);
                                                            }
                                                        }}
                                                    >
                                                        {tariff.categoryName != null && tariff.categoryName.length > 0 ? tariff.categoryName : ("Тариф " + (tariffIndex + 1))}
                                                    </MdToggleButton>
                                                </React.Fragment>
                                            )
                                        })
                                    )
                            }
                        </div>

                        <div className="train-tariff-info">
                            <div className="train-tariff-info-item">
                                <span>{t("bookingPage.Class/Car/Seat")}</span>
                                <span></span>
                                <span>
                            {
                                selectedTariff.foundInfo
                            }
                        </span>
                            </div>
                        </div>

                        {
                            selectTrain != null && (
                                <div className="train-info-section" style={{width: "100%", justifyContent: "end"}}>
                                    <MdButton
                                        type="button"
                                        style={{
                                            "button": {
                                                marginTop: "5px",
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "30px",
                                                padding: "5px 15px"
                                            },
                                            "text": {
                                                width: "auto",
                                                flex: 0,
                                                color: "#FFFFFF"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => selectTrain(train, selectedTariff)}
                                    >
                                        Выбрать
                                    </MdButton>
                                </div>
                            )
                        }
                    </div>
                </div>

                <div className="train-component-2-details-section">
                    {
                        passengers != null && passengers.length > 0 && (
                            <div className="train-info-section">
                                <div className="passengers-list">
                                    {
                                        passengers.map((passengerInfo, passengerInfoIndex) => {
                                            const isExpired =
                                                passengerInfo.passport.typeData?.trim().toLowerCase() != "rus"
                                                && (new Date(passengerInfo.passport.expiry).getTime() - new Date().getTime() < 0);
                                            return (
                                                <div className="passenger-item" key={passengerInfoIndex}>
                                                    <div className="passenger-item-icon">
                                                        <UserIconFilled
                                                            width="14px"
                                                            height="14px"
                                                            style={{
                                                                "path": {
                                                                    fill: "#777E90"
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div className="passenger-item-details">
                                                        <div className="passenger-item-details-row">
                                                        <span className="passenger-fio">
                                                            {
                                                                passengerInfo.passenger.fio
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            }
                                                        </span>
                                                            <div className="passenger-item-details-row">
                                                                <CalendarIcon
                                                                    width="10px"
                                                                    height="10px"
                                                                    style={{
                                                                        "svg": {
                                                                            marginTop: "2px"
                                                                        },
                                                                        "path": {
                                                                            fill: "#8E8E90"
                                                                        }
                                                                    }}
                                                                />
                                                                <span
                                                                    className="passenger-dob">{passengerInfo.passport.dob.split('T')[0]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="passenger-item-details-row">
                                                            <div
                                                                className={("passport-item" + (isExpired ? " --expired" : ""))}>
                                                                <div className="passport-item-type">
                                                                    {
                                                                        !Utils.isEmpty(passengerInfo.passport.typeData?.trim())
                                                                            ? (
                                                                                passengerInfo.passport.typeData.trim().toLowerCase() == "rus"
                                                                                    ? "Паспорт РФ"
                                                                                    : "Загран."
                                                                            )
                                                                            : ""
                                                                    }
                                                                </div>
                                                                <div className="passport-item-number">
                                                                    {
                                                                        passengerInfo.passport.number
                                                                    }
                                                                </div>
                                                                {
                                                                    passengerInfo.passport.typeData?.trim().toLowerCase() != "rus" && (
                                                                        <div
                                                                            className="passport-item-expiry">
                                                                            {
                                                                                new Date(passengerInfo.passport.expiry).toLocaleDateString()
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>

            </div>

        </details>
    );
}