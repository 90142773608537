import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ICustomerEmployee} from "../../../../interfaces/reportInterfaces/customerInterfaces";
import {MdButton} from "../../../../components/md-button";
import {MdPagination} from "../../../../components/md-pagination";
import {MdTable} from "../../../../components/md-table";
import {MdCheckbox} from "../../../../components/md-checkbox";
import {CheckMarkIcon} from "../../../../icons/CheckMarkIcon";
import {CrossIcon} from "../../../../icons/CrossIcon";
import {PencilIcon} from "../../../../icons/PencilIcon";
import "./style.scss";
import "../../../../scss/tables.scss";
import {IOrder} from "../../../../interfaces/IOrderInfo";
import {ScheduleIcon} from "../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {OrderModal} from "../../tripPage/orderComponent";
import {SearchIcon2} from "../../../../icons/SearchIcon2";


interface EmployeeTableProps {
    employees?: ICustomerEmployee[];
    loadEmployees: () => void;
    isLoading: boolean,
    isSearchResultNotFound: boolean,
    selectedEmployees: ICustomerEmployee[];
    setSelectedEmployees: React.Dispatch<React.SetStateAction<ICustomerEmployee[]>>;
    openEmployeeAddModal: () => void;
    requestEditEmployee: (employee: ICustomerEmployee) => void;
}


export function EmployeeTableComponent({
        employees,
        loadEmployees,
        isLoading,
        isSearchResultNotFound,
        selectedEmployees,
        setSelectedEmployees,
        openEmployeeAddModal,
        requestEditEmployee,
    }: EmployeeTableProps) {

    const {t} = useTranslation();

    let tableData = employees;

    const itemsPerPage = 10;

    const isTableLoading = isLoading;
    const isEmptySearchResult = isSearchResultNotFound;


    const [employeeList, setEmployeeList] = useState<ICustomerEmployee[]>([]);
    const [activePage, setActivePage] = useState(1);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);



    const onPageChange = (pageNumber: number) => {
        let skip = Math.max(0, (pageNumber - 1) * itemsPerPage);
        setActivePage(pageNumber);
        if (tableData != null) {
            setEmployeeList(tableData.slice(skip, skip + itemsPerPage));
        }
    }


    const onToggleRowTools = (rowId: string) => {
        if(!expandedRows.includes(rowId)) {
            setExpandedRows(prev => [...prev.filter(x => x != rowId), rowId]);
        }
        else {
            setExpandedRows(prev => prev.filter(x => x != rowId));
        }
    }


    const selectEmployee = (employee: ICustomerEmployee) => {
        setSelectedEmployees([...selectedEmployees, employee]);
    };

    const unselectEmployee = (employee: ICustomerEmployee) => {
        setSelectedEmployees((prev) =>
            prev.filter((employee) => employee.id !== employee.id)
        );
    };

    const selectAllEmployees = () => {
        setSelectedEmployees([...employeeList]);
    };

    const unselectAllEmployees = () => {
        setSelectedEmployees([]);
    };



    useEffect(() => {

    }, [expandedRows]);


    useEffect(() => {
        setExpandedRows([]);
    }, [employees]);


    useEffect(() => {
        if (tableData != null) {
            //let skip = Math.max(0, (activePage - 1) * itemsPerPage);
            //setEmployeeList(tableData.slice(skip, skip + itemsPerPage));
            setEmployeeList(tableData);
        } else {
            setEmployeeList([]);
        }
    }, [employees, selectedEmployees, activePage]);


    return (
        <>
            <div className="employees-results">
                <div className="employees-results-header">
                    <div className="employees-results-caption">
                        {`${t("employeePage.employees")}`}
                        <span style={{display: (isTableLoading ? "none" : "block")}}>{tableData?.length}</span>
                        <span className="employees-results-preloader"
                              style={{display: (isTableLoading ? "flex" : "none")}}>
                            <div className="spinner-border employees-results-preloader-icon"></div>
                            <span className="employees-results-preloader-text">
                                {`${t("employeePage.searching")}`}
                            </span>
                        </span>
                    </div>
                    <div className="employees-results-tools">
                        <MdButton
                            style={{
                                "button": {
                                    borderWidth: 0,
                                    backgroundColor: "rgb(233 233 233)",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "35px",
                                    padding: "5px 15px"
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            disabled={isTableLoading}
                            onClick={() => openEmployeeAddModal()}
                        >
                            {`${t("employeePage.create")}`}
                        </MdButton>
                    </div>
                </div>

                {
                    (!isTableLoading && !isEmptySearchResult && employeeList.length > 0) && (
                        <MdTable
                            columns={[
                                {
                                    width: 45,
                                    headerTemplate() {
                                        return (
                                            <MdCheckbox
                                                type="filled"
                                                checked={(selectedEmployees?.length == employeeList.length && employeeList.length > 0)}
                                                style={{
                                                    "input": {
                                                        width: "16px",
                                                        height: "16px",
                                                    },
                                                }}
                                                onChecked={selectAllEmployees}
                                                onUnchecked={unselectAllEmployees}
                                            />
                                        )
                                    },
                                    cellTemplate(data) {
                                        return (
                                            <MdCheckbox
                                                type="filled"
                                                checked={
                                                    selectedEmployees?.some((e) => e.id === data.id)
                                                }
                                                style={{
                                                    "input": {
                                                        width: "16px",
                                                        height: "16px",
                                                    },
                                                }}
                                                onChanged={(checked: boolean) => {
                                                    if (checked) {
                                                        selectEmployee(data);
                                                    } else {
                                                        unselectEmployee(data);
                                                    }
                                                }
                                            }
                                            />
                                        )
                                    }
                                },
                                {
                                    header: t("employeePage.name"),
                                    cellTemplate(data) {
                                        return <div>{[data.firstName, data.lastName, data.patronymic].join(' ')}</div>
                                    }
                                },
                                {
                                    width: 150,
                                    header: t("employeePage.gender"),
                                    cellTemplate(data) {
                                        return <div>{(data.gender ? "Мужчина" : "Женщина")}</div>
                                    }
                                },
                                {
                                    width: 100,
                                    header: t("employeePage.dateOfBurn"),
                                    cellTemplate(data) {
                                        return <div>{data.dateOfBurn}</div>
                                    }
                                },
                                {
                                    header: t("employeePage.email"),
                                    cellTemplate(data) {
                                        return <div>{data.email}</div>
                                    }
                                },
                                {
                                    width: 100,
                                    header: t("employeePage.isActive"),
                                    cellTemplate(data) {
                                        return <div>
                                            {
                                                (data.isActive)
                                                    ? (
                                                        <div className="doc-state-indicator --success">
                                                            <div style={{display: "flex", gap: "5px"}}>
                                                            <span>
                                                                <CheckMarkIcon
                                                                    width="10px"
                                                                    height="10px"
                                                                    style={{
                                                                        "path": {
                                                                            stroke: "#74B100"
                                                                        }
                                                                    }}
                                                                />
                                                            </span></div>
                                                        </div>
                                                    )
                                                    : (
                                                        <div className="doc-state-indicator">
                                                            <div style={{display: "flex", gap: "5px"}}>
                                                            <span>
                                                                <CrossIcon
                                                                    width="10px"
                                                                    height="10px"
                                                                    style={{
                                                                        "path": {
                                                                            stroke: "#999999"
                                                                        }
                                                                    }}
                                                                />
                                                            </span></div>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    }
                                },
                                {
                                    width: 40,
                                    header: "",
                                    cellTemplate(data: ICustomerEmployee) {
                                        return (
                                            <button
                                                className={"toggle-tools-button" + (expandedRows.includes(data.id) ? " open" : "")}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onToggleRowTools(data.id);
                                                }}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </button>
                                        )
                                    }
                                },
                                {
                                    width: 0,
                                    header: "",
                                    className: ("tools-cell"),
                                    cellTemplate(data: ICustomerEmployee) {
                                        return (
                                            <>
                                                <div
                                                    className={"tools" + (expandedRows.includes(data.id) ? " open" : "")}
                                                    style={{
                                                    //width: (expandedRows.includes(data.id) ? "70px" : 0),
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                    justifyContent: "start",
                                                    overflow: "hidden"
                                                }}>
                                                    <div style={{ paddingLeft: "20px"}}>
                                                        <MdButton
                                                            icon={
                                                                <PencilIcon
                                                                    width="16px"
                                                                    height="16px"
                                                                    style={{
                                                                        "path": {
                                                                            fill: "#888888"
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            style={{
                                                                "button": {
                                                                    margin: "2px",
                                                                    borderWidth: 0,
                                                                    backgroundColor: "rgb(255 255 255)",
                                                                    color: "rgb(0 0 0)",
                                                                    borderRadius: "6px",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                    padding: "0",
                                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                },
                                                                "ink": {
                                                                    backgroundColor: "rgba(220,220,220,.5)"
                                                                },
                                                                "icon": {
                                                                    marginLeft: 0,
                                                                    marginRight: 0,
                                                                    margin: "auto",
                                                                },
                                                            }}
                                                            onClick={() => requestEditEmployee(data)}
                                                        />
                                                    </div>
                                                    {/*<div style={{ position: "absolute", top: 0, left: 0, width: "20px", height: "100%", backgroundColor: "#FFFFFF", boxShadow: "0 0 15px rgba(0,0,0,0.25)", clipPath: "inset(1px -15px 1px 2px)"}}>*/}

                                                    {/*</div>*/}
                                                </div>
                                            </>
                                        )
                                    }
                                },
                            ]}
                            itemSource={employeeList}
                            onPreviewRowSelect={() => false}
                            style={{
                                "tbody": {
                                    maxHeight: "calc(100vh - 400px)"
                                }
                            }}
                        />
                    )
                }

                {/*{*/}
                {/*    isTableLoading && (*/}
                {/*        <div className="table-skeleton-preloader">*/}
                {/*            {*/}
                {/*                Array.from(Array(itemsPerPage + 1).keys()).map((item, index) => {*/}
                {/*                    return (*/}
                {/*                        <div key={index} className="table-skeleton-item"></div>*/}
                {/*                    )*/}
                {/*                })*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

                {(isEmptySearchResult) && (
                    <div className="no-orders-message" style={{ margin: "40px 0"}}>
                        <div className="no-orders-message-icon">
                            <SearchIcon2 width="50px" height="50px" style={{
                                "path": {
                                    fill: "rgb(100 100 100)"
                                }
                            }}/>
                        </div>
                        <div className="no-orders-message-caption">
                            Нет сотрудников для отображения
                        </div>
                        <div className="no-orders-message-content">
                            Создайте сотрудника или повторите поиск
                        </div>
                        <div className="no-orders-message-button">
                            {
                                !isTableLoading
                                    ? (
                                        <MdButton
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(233 233 233)",
                                                    color: "rgb(0 0 0)",
                                                    borderRadius: "6px",
                                                    minWidth: "92px",
                                                    height: "35px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            disabled={isTableLoading}
                                            onClick={() => loadEmployees()}
                                        >
                                            Повторить
                                        </MdButton>
                                    )
                                    : (
                                        <CircleSpinnerIcon
                                            width="18px"
                                            height="18px"
                                            style={{
                                                "circle": {
                                                    stroke: "rgb(50 50 50)"
                                                }
                                            }}
                                        />
                                    )
                            }
                        </div>
                    </div>
                )}

            </div>

        </>
    );
}
