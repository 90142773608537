import axios, {AxiosResponse, CancelToken} from "axios";
import {
    BEARER,
    ROUTE_GET_REFS_AVIA_CITIES,
    ROUTE_GET_REFS_TRAIN_STATION,
} from "../constants/routeConstants/ApiRouteConstants";
import {IAviaCityInfo} from "../interfaces/IAviaCityInfo";
import {ITrainCityInfo} from "../interfaces/ITrainCityInfo";


export function RefsApi() {

    /**
     * GET method for obtaining cities with airports by requested name
     * @param {string} nameLike - city name
     * @param {string} local - localization name
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IAviaCityInfo[], any>>} response with list of cities
     */
    const getAviaCities = async (nameLike: string, local: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IAviaCityInfo[], any>> => {
        return await axios.get<IAviaCityInfo[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_REFS_AVIA_CITIES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    local: local,
                    nameLike: nameLike,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining cities with rail stations by requested name
     * @param {string} nameLike - city name
     * @param {string} local - localization name
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ITrainCityInfo[], any>>} response with list of cities
     */
    const getTrainCities = async (nameLike: string, local: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ITrainCityInfo[], any>> => {
        return await axios.get<ITrainCityInfo[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_REFS_TRAIN_STATION,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    local: local,
                    nameLike: nameLike,
                },
                cancelToken: ct
            }
        );
    };


    return {
        getAviaCities,
        getTrainCities
    };
}