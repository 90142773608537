import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../constants/componentsContants";

export function PlaneLandIcon({iconSize}: IIconProps) {
    if (iconSize === undefined) {
        iconSize = DEFAULT_ICON_SIZE;
    }
    return (
        <>

            <svg fill="currentColor"
                 width={iconSize}
                 height={iconSize}
                 viewBox="0 0 24 24"
            >
                <path
                    d="M18.842 15.296a1.61 1.61 0 0 0 1.892-1.189v-.001a1.609 1.609 0 0 0-1.177-1.949l-4.576-1.133L9.825 4.21l-2.224-.225 2.931 6.589-4.449-.449-2.312-3.829-1.38.31 1.24 5.52 15.211 3.17zM3 18h18v2H3z"/>
            </svg>
        </>
    )
}