import { IIconProps } from "../interfaces/componentInterfaces/IIconProps";
import { DEFAULT_ICON_SIZE } from "../constants/componentsContants";

export function DocumentIcon({ iconSize }: IIconProps) {
  if (iconSize === undefined) {
    iconSize = DEFAULT_ICON_SIZE;
  }
  return (
      <>
        <svg width={iconSize} height={iconSize} viewBox="0 0 64 64" id="fill" xmlns="http://www.w3.org/2000/svg">
          <polygon points="49 61 5 61 5 10 12 3 49 3 49 61" style={{fill:"#d9dae2"}}/>
          <path d="M5,61H5V10l7-7H49V17A44,44,0,0,1,5,61Z" style={{fill:"#e5e6eb"}}/>
          <rect height="5.657" style={{fill:"#0468ad"}} transform="translate(-8.276 44.021) rotate(-45)" width="31.113"
                x="33.444" y="29.172"/>
          <path d="M58,19l3,3L44,39a4.242,4.242,0,0,1-6,0h0Z" style={{fill:"#027de5"}}/>
          <path
              d="M20.548,51.3A6.053,6.053,0,0,0,22,47c0-2.851-1.29-5-3-5s-3,2.149-3,5a6.2,6.2,0,0,0,1.362,4.225l-.095.095A5.706,5.706,0,0,1,13.209,53H13v2h.209a7.692,7.692,0,0,0,5.472-2.266l.231-.231A7.719,7.719,0,0,0,24.605,55H26V53H24.605A5.711,5.711,0,0,1,20.548,51.3ZM19,44c.249,0,1,1.064,1,3s-.751,3-1,3-1-1.064-1-3S18.751,44,19,44Z"
              style={{fill:"#444"}}/>
          <rect height="2" style={{fill:"#444"}} width="24" x="14" y="25"/>
          <rect height="2" style={{fill:"#444"}} width="24" x="14" y="29"/>
          <rect height="2" style={{fill:"#444"}} width="24" x="14" y="33"/>
          <polygon points="29 12 29 21 26 19 23 21 23 12 29 12" style={{fill:"#94c7f2"}}/>
          <polygon points="5 10 12 10 12 3 5 10" style={{fill:"#cfcfd9"}}/>
          <circle cx="26" cy="11" r="4" style={{fill:"#cceaff"}}/>
          <polygon points="41 44 37 40 31 41 28.471 51.115 29.885 52.529 40 50 41 44" style={{fill:"#cfcfd9"}}/>
          <path d="M35,44a1.976,1.976,0,0,0-1.926,2.512l-4.6,4.6,1.414,1.414,4.6-4.6A2,2,0,1,0,35,44Z"
                style={{fill:"#fff"}}/>
          <path
              d="M13.209,53H13v2h.209a7.692,7.692,0,0,0,5.472-2.266l.231-.231A7.719,7.719,0,0,0,24.605,55H26V53H24.605a5.711,5.711,0,0,1-4.057-1.7A6.053,6.053,0,0,0,22,47c0-2.851-1.29-5-3-5s-3,2.149-3,5a6.2,6.2,0,0,0,1.362,4.225l-.095.095A5.706,5.706,0,0,1,13.209,53ZM19,44c.249,0,1,1.064,1,3s-.751,3-1,3-1-1.064-1-3S18.751,44,19,44Z"/>
          <path
              d="M26,6a5.006,5.006,0,0,0-5,5,4.948,4.948,0,0,0,1.026,3H22v7a1,1,0,0,0,1.555.832L26,20.2l2.445,1.63A1,1,0,0,0,30,21V14h-.026A4.948,4.948,0,0,0,31,11,5.006,5.006,0,0,0,26,6Zm0,2a3,3,0,1,1-3,3A3,3,0,0,1,26,8Zm.555,10.168a1,1,0,0,0-1.11,0L24,19.131V15.576a4.929,4.929,0,0,0,4,0v3.555Z"/>
          <rect height="2" width="24" x="14" y="25"/>
          <rect height="2" width="24" x="14" y="29"/>
          <rect height="2" width="24" x="14" y="33"/>
          <path
              d="M50,27V3a1,1,0,0,0-1-1H12a1,1,0,0,0-.707.293l-7,7A1,1,0,0,0,4,10V61a1,1,0,0,0,1,1H49a1,1,0,0,0,1-1V35l-2,2V60H6V11h6a1,1,0,0,0,1-1V4H48V29ZM11,9H7.414L11,5.414Z"/>
        </svg>


        {/* old picture updated at 04/09/2024 remove after month*/}
        {/*<svg viewBox="0 0 40 40" width={iconSize} height={iconSize}>*/}
        {/*  <path fill="whitesmoke" d="M6.5 37.5L6.5 2.5 24.793 2.5 33.5 11.207 33.5 37.5z" />*/}
        {/*  <path*/}
        {/*    fill="currentColor"*/}
        {/*    d="M24.586,3L33,11.414V37H7V3H24.586 M25,2H6v36h28V11L25,2L25,2z"*/}
        {/*  />*/}
        {/*  <path fill="currentColor" d="M24.5 11.5L24.5 2.5 24.793 2.5 33.5 11.207 33.5 11.5z" />*/}
        {/*  <path*/}
        {/*    fill="currentColor"*/}
        {/*    d="M25 3.414L32.586 11H25V3.414M25 2h-1v10h10v-1L25 2 25 2zM12 16H28V17H12zM12 19H24V20H12zM12 22H28V23H12zM12 25H24V26H12zM12 28H28V29H12z"*/}
        {/*  />*/}
        {/*</svg>*/}
      </>
  );
}
