interface ErrorMessageProps {
    isServiceMsg?: boolean
    error: string;
}

export function ErrorMessage({error,isServiceMsg}: ErrorMessageProps) {

    isServiceMsg=isServiceMsg??false;

    const style = "text-center align-self-center " + (isServiceMsg?"":"text-danger ") ;

    return (
        <>
            <p className={style}>{error}</p>
        </>
    );
}
