import axios, {CancelToken} from "axios";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    DATE_FORMAT_YYYYMMDD,
    PERIOD_CURRENT_MONTH,
    PERIOD_CURRENT_QUARTER,
    PERIOD_LAST_MONTH,
    PERIOD_LAST_QUARTER,
    PERIOD_LAST_TWELVE_MONTH
} from '../constants/ServiceCostants';
import {CompanyApi} from "../api/CompanyApi"
import {
    getClientDeptThunk,
    getClientStructureExchangeThunk,
    getClientStructureRefundThunk,
    getClientStructureSalesThunk,
    getClientSalesThunk,
    getClientDetailsThunk,
    getAllowedPersonsThunk,
    setGkKode,
    setFullName,
    setCode,
    setGkName,
    setInn,
    setAgreementNo,
    setAgreementFrom,
    setAgreementTill,
    setAddress,
    setPostalAddress,
    setAgreements,
    clearCompanyInfo
} from "../redux/CompanyInfoSlice";
import {
    setCurrentClientCode,
    setCurrentCompany,
} from "../redux/authSlice";
import {
    IClientStructureRequest,
} from '../interfaces/requestsInterfaces/reportRequests';
import {DateService} from "./DateService";
import {resetReports} from "../redux/reportsSlice";
import {Utils} from "../utils/utils";
import {ICompany} from "../interfaces/companyInfoInterfaces/ICompany";
import {store} from "../redux/store";
import {BEARER, ROUTE_ADD_DEFAULT_COMPANY_ROLES} from "../constants/routeConstants/ApiRouteConstants";
import {IAllowedPerson} from "../interfaces/companyInfoInterfaces/IAllowedPerson";


export function CompanyService() {

    const companyState = useAppSelector((state) => state.companyInfo);

    const dispatch = useAppDispatch();

    const dateService = DateService();

    const companyApi = CompanyApi();


    /**
     * Method for checking the existence of a company
     * @param {string} code - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<boolean>} result of the checking
     */
    const isCompanyExists = async (code: string, ct: CancelToken = axios.CancelToken.source().token): Promise<boolean> => {
        try {
            const state = store.getState();
            const response = await companyApi.isCompanyExists(code, state.auth.accessToken, ct);
            return response.data == true;
        }
        catch (ex: any) {
            return false;
        }
    };

    /**
     * Method for obtaining company data
     * @param {string} code - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompany | null>} response with company data. in case of errors returns null
     */
    const getCompany = async (code: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompany | null> => {
        try {
            const state = store.getState();
            const response = await companyApi.getCompany(code, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining information about a company
     * @param {string} code - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompany | null>} response with information about a company. in case of errors returns null
     */
    const getCompanyInfo = async (code: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompany | null> => {
        try {
            const state = store.getState();
            const response = await companyApi.getCompanyInfo(code, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        }
        catch (ex: any) {
            return null;
        }
    };

    /**
     * Method for obtaining a list of companies by name
     * @param {string} name - company name
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompany[]>} response with a list of companies
     */
    const searchCompaniesByName = async (name: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompany[]> => {
        try {
            const state = store.getState();
            const response = await companyApi.searchCompaniesByName(name, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    };

    /**
     * Method for obtaining a list of companies
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompany[]>} response with a list of companies
     */
    const getAllCompanies = async (ct: CancelToken = axios.CancelToken.source().token): Promise<ICompany[]> => {
        try {
            const state = store.getState();
            const response = await companyApi.getAllCompanies(state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    /**
     * Method for add or update company
     * @param {ICompany} company - company data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompany | null>} response with the company data. in case of errors returns null
     */
    const addOrUpdateCompany = async (company: ICompany, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompany | null> => {
        try {
            const state = store.getState();
            const request = {
                code: company.code,
                agreements: company.agreements ?? [],
                fullName: company.fullName,
                address: company.address,
                postalAddress: company.postalAddress,
                gkCode: company.gkCode,
                gkName: company.gkName,
                inn: company.inn,
                kpp: company.kpp,
                ogrn: company.ogrn,
                vatUse: company.vatUse,
                isActive: company.isActive
            };
            const response = await companyApi.addOrUpdateCompany(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        } catch (ex) {
            return null;
        }
    }

    /**
     * Method for add or update company
     * @param {ICompany} company - company data
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<ICompany>} response with the company data. in case of errors returns null
     */
    const importCompany = async (company: ICompany, ct: CancelToken = axios.CancelToken.source().token): Promise<ICompany | null> => {
        try {
            const state = store.getState();
            const request = {
                code: company.code,
                agreements: company.agreements ?? [],
                fullName: company.fullName,
                address: company.address,
                postalAddress: company.postalAddress,
                gkCode: company.gkCode,
                gkName: company.gkName,
                inn: company.inn,
                kpp: company.kpp,
                ogrn: company.ogrn,
                vatUse: company.vatUse,
                isActive: company.isActive
            };
            const response = await companyApi.importCompany(request, state.auth.accessToken, ct);
            return !Utils.isEmpty(response.data) ? response.data : null;
        } catch (ex) {
            return null;
        }
    };

    /**
     * Method for obtaining the allowed persons of a company
     * @param {string} code - company code
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<IAllowedPerson[]>} list of allowed persons of a company
     */
    const getAllowedPersons = async (code: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IAllowedPerson[]> => {
        try {
            const state = store.getState();
            const response = await companyApi.getAllowedPersons(code, state.auth.accessToken, ct);
            return Array.isArray(response.data) ? response.data : [];
        }
        catch (ex: any) {
            return [];
        }
    }

    const addDefaultCompanyRoles = async (clientCode: string, token: string) => {
        try {
            const result = await axios.post(
                process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_DEFAULT_COMPANY_ROLES,
                JSON.stringify(clientCode),
                {
                    headers: {
                        'Content-Type': 'text/plain',
                        Auth: 123,
                        Authorization: BEARER + token,
                    },
                }
            );
            return result;
        }
        catch (ex) {
            console.error(ex);
        }
        return null;
    };

    const getClientInfoInBackground = async (currentClientCode: string, period?: string) => {
        const datePeriod = getDatePeriodClientInfo(period);

        let clientStructureRequest: IClientStructureRequest = {
            clientCode: currentClientCode,
            status: 1,
            monthNum: 0,
            quarter: 0,
            year: 0,
            dateStart: dateService.dateToCustomFormat(datePeriod[0], DATE_FORMAT_YYYYMMDD),
            dateEnd: dateService.dateToCustomFormat(datePeriod[1], DATE_FORMAT_YYYYMMDD)
        };

        if (currentClientCode !== undefined) {
            dispatch(getClientDetailsThunk(currentClientCode));
            dispatch(getClientDeptThunk({
                clientCode: currentClientCode,
                dateStart: dateService.dateToCustomFormat(datePeriod[0], DATE_FORMAT_YYYYMMDD)
            }));

            dispatch(getAllowedPersonsThunk(currentClientCode));
            //status 1 Sales
            dispatch(getClientStructureSalesThunk(clientStructureRequest));
            //status 2 exchanges
            clientStructureRequest.status = 2;
            dispatch(getClientStructureExchangeThunk(clientStructureRequest));
            //status 33 refounds
            clientStructureRequest.status = 3;
            dispatch(getClientStructureRefundThunk(clientStructureRequest));

            dispatch(getClientSalesThunk(currentClientCode));
        }
    }

    const getDatePeriodClientInfo = (period?: string): [Date, Date] => {
        period = period ?? companyState.companyStatisticPeriod
        const currentDate = new Date();
        const quarterNum = Math.trunc(currentDate.getMonth() / 3);
        let startDate: Date = new Date()
        let endDate: Date = new Date()

        switch (period) {
            case PERIOD_LAST_MONTH:
                if (currentDate.getMonth() === 0) {
                    startDate = new Date(currentDate.getFullYear() - 1, 12, 1);
                } else {

                    startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
                }
                endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                break;

            case PERIOD_CURRENT_MONTH:
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                endDate = currentDate;
                break;

            case PERIOD_CURRENT_QUARTER:
                startDate = new Date(currentDate.getFullYear(),
                    quarterNum * 3, 1);
                endDate = currentDate;
                break;

            case PERIOD_LAST_QUARTER:
                startDate = new Date(quarterNum === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear(),
                    quarterNum === 0 ? 9 : (quarterNum - 1) * 3, 1);
                endDate = new Date(currentDate.getFullYear(),
                    quarterNum * 3, 1);
                break;

            case PERIOD_LAST_TWELVE_MONTH:
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
                endDate = currentDate;
                break;
        }

        return [startDate, endDate];
    };

    const makeActiveCompany = async (clientCode: string) => {
        // get information about a given company
        const companyInfo = await getCompanyInfo(clientCode);
        // check the received company info
        if(!Utils.isEmpty(companyInfo) && companyInfo != null) {
            // make the company active
            dispatch(setCurrentClientCode(clientCode));
            dispatch(setCurrentCompany(companyInfo));
            // reset information for a previously selected company
            dispatch(clearCompanyInfo());
            // update information about the selected company
            dispatch(setCode(companyInfo.code ?? ""));
            dispatch(setFullName(companyInfo.fullName ?? ""));
            dispatch(setGkKode(companyInfo.gkCode ?? ""));
            dispatch(setGkName(companyInfo.gkName ?? ""));
            dispatch(setInn(companyInfo.inn ?? ""));
            dispatch(setAddress(companyInfo.address ?? ""));
            dispatch(setPostalAddress(companyInfo.postalAddress ?? ""));
            dispatch(setAgreements(Array.isArray(companyInfo.agreements) ? companyInfo.agreements : []));
            dispatch(setAgreementNo(Array.isArray(companyInfo.agreements) ? (companyInfo.agreements[0]?.code ?? "") : ""));
            dispatch(setAgreementFrom(Array.isArray(companyInfo.agreements) ? (companyInfo.agreements[0]?.dateFrom ?? "") : ""));
            dispatch(setAgreementTill(Array.isArray(companyInfo.agreements) ? (companyInfo.agreements[0]?.dateTill ?? "") : ""));
            // reset reports for previously selected company
            dispatch(resetReports());
        }
    }


    return {
        isCompanyExists,
        getCompany,
        getCompanyInfo,
        searchCompaniesByName,
        getAllCompanies,
        addOrUpdateCompany,
        importCompany,
        getAllowedPersons,
        addDefaultCompanyRoles,
        getClientInfoInBackground,
        getDatePeriodClientInfo,
        makeActiveCompany,
    }
}