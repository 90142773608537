import {useState} from "react";
import "./style.scss";
import {CustomCheckBox} from "../../../commonComponents/checkBoxComponents/customCheckBox";
import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {TicketType} from "../../../../enums/TicketType";
import {SortIcon} from "../../../../icons/SortIcon";
import {OrderService} from "../../../../services/OrderServices";
import {OrderSort} from "../../../../enums/OrderSort";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {setFilteredVariants} from "../../../../redux/orderSlice";
import {IDirectionRequest} from "../../../../interfaces/requestsInterfaces/IDirectionRequest";
import {FilterIcon} from "../../../../icons/FilterIcon";
import {DataConvertService} from "../../../../services/DataConvertService";
import {IFilterOptions} from "../../../../interfaces/orderInterfaces/IFilterOptions";

type FilterResultProps = {
    isDefaultState: boolean;
    directions: IDirectionRequest[];
    // setSortedTravelList: () => void;
    travelVariants: ITravelVariant[];
    filteredTravelVariants: ITravelVariant[];
    setFilteredTravelVariants?: (filteredTravelVariants: ITravelVariant[]) => void;
    filterOptions?: IFilterOptions
}

export function FilterResultComponent(props: FilterResultProps) {

    const dispatch = useAppDispatch();
    const orderState = useAppSelector(state => state.order)

    //accordion hooks
    const [showSelectVariant, setShowSelectVariant] = useState(false);
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [showAirlines, setShowAirlines] = useState(false)
    const [showTransfers, setShowTransfers] = useState(false);
    const [showDirection, setShowDirection] = useState(false);
    const [showTrains,setShowTrains] = useState(false);

    //! new functionality filters
    //filtersHooks
    const [variantFilter, setVariantFilter] = useState<TicketType[]>(props.filterOptions?.ticketTypes ?? []);
    const [directionFilter, setDirectonFilter] = useState<string[]>(props.filterOptions?.directions ?? []);
    const [airlinesFilter, setAirlinesFilter] = useState<string[]>(props.filterOptions?.airlines ?? []);
    const [transfersFilter, setTransfersFilter] = useState<number[]>(props.filterOptions?.transfers ?? []);
    const [trainFilter,setTrainFilter] = useState<string[]>(props.filterOptions?.trains??[]);

    //sortOptions
    const [currentSortOption, setCurrentSortOption] = useState(0);

    //services
    const {stringIsNullOrEmpty} = DataConvertService();
    const {sortByParameter, applyAllFilters} = OrderService();

    //!Move to orderService
    // new func
    const updateStateWithAllFilters = (opt: IFilterOptions) => {

        // let filteredTrips = orderState.travelVariants;
        let filterOptions: IFilterOptions = {
            airlines: [...airlinesFilter, ...opt.airlines],
            directions: [...directionFilter, ...opt.directions],
            ticketTypes: [...variantFilter, ...opt.ticketTypes],
            trains: [...trainFilter,...opt.trains],
            transfers: [...transfersFilter, ...opt.transfers]
        }
        filterOptions.ticketTypes.forEach(tt => console.log(`UpdateStateWithAllFilters  ticketTypes ${tt} `))

        let filteredTrips = applyAllFilters(orderState.travelVariants, filterOptions)
        filteredTrips = sortByParameter(currentSortOption, filteredTrips);
        dispatch(setFilteredVariants(filteredTrips))
    }

    //  Handler for update variants filters - Ok
    const ticketTypeHandler = (filterValue: TicketType) => {
        console.log(`FilterComponent inputvalue ${filterValue}`)
        const isActiveFilter = variantFilter.some(v => v === filterValue);
        console.log(`FilterComponent isActiveFiler ${isActiveFilter}`)
        if (isActiveFilter) {
            let filteredTrips = orderState.filteredVariants.filter(trip => trip.ticketType !== filterValue)
            setVariantFilter([...variantFilter.filter(f => f !== filterValue)])
            dispatch(setFilteredVariants(filteredTrips))
        } else {
            let opt = getEmptyFilterOptions();
            opt.ticketTypes.push(filterValue)
            setVariantFilter(variantFilter => [...variantFilter, filterValue])
            updateStateWithAllFilters(opt);
        }
    }

    //  Handler for update directions filters - Ok
    const directionFilterHandler = (filterValue: string) => {
        const isActiveFilter = directionFilter.some(v => v === filterValue);
        if (isActiveFilter) {
            let filteredTrips = orderState.filteredVariants.filter(trip =>
                trip.direction?.townFrom + "-" + trip.direction?.townTo !== filterValue)
            setDirectonFilter(directionFilter.filter(dir => dir !== filterValue))
            dispatch(setFilteredVariants(filteredTrips));
        } else {
            let opt = getEmptyFilterOptions();
            opt.directions.push(filterValue)
            updateStateWithAllFilters(opt);
            setDirectonFilter([...directionFilter, filterValue])
        }
    }

    // Handler for update Airline filters - Ok
    const airlinesFilterHandler = (filterValue: string) => {
        console.log(filterValue);
        const isActiveFilter = airlinesFilter.some(v => v === filterValue);
        console.log(isActiveFilter);
        if (isActiveFilter) {
            let newFilterState = airlinesFilter.filter(air => air !== filterValue);
            let filteredTrips = orderState.filteredVariants.filter(trip => {
                    let result = false;
                    trip.travelCompanyOrVehicle.forEach(company => {
                        console.log("In forEach company:", company, "currentfilter some", newFilterState.some(f => f === company))
                        if (newFilterState.some(f => f === company)) {
                            result = true;
                            return;
                        }
                    })
                    return result;
                }
            )
            setAirlinesFilter(airlinesFilter.filter(dir => dir !== filterValue))
            dispatch(setFilteredVariants(filteredTrips))
        } else {
            let opt = getEmptyFilterOptions();
            opt.airlines.push(filterValue)
            updateStateWithAllFilters(opt)
            setAirlinesFilter([...airlinesFilter, filterValue])
        }
    }

    const trainFilterHandler = (filterValue:string) =>{
        const isActiveFilter = trainFilter.some(v => v === filterValue);

        if (isActiveFilter) {
            //let newFilterState = trainFilter.filter(train => train !== filterValue);
            let filteredTrips = orderState.filteredVariants.filter(trip => {
                if(trip.ticketType!==TicketType.TrainTicket) return true;
                return trip.travelCompanyOrVehicle[0] !== filterValue;
                }
            )
            setTrainFilter(trainFilter.filter(train => train !== filterValue))
            dispatch(setFilteredVariants(filteredTrips))
        } else {
            let opt = getEmptyFilterOptions();
            opt.trains.push(filterValue)
            updateStateWithAllFilters(opt)
            setTrainFilter([...trainFilter, filterValue])
        }
    }

    // Handler for update Transfer filters-Ok
    const transfersFilterHandler = (filterValue: number) => {
        const isActiveFilter = transfersFilter.some(v => v === filterValue);
        if (isActiveFilter) {
            let newFilterState = transfersFilter.filter(t => t !== filterValue);
            let filteredTrips = orderState.travelVariants.filter(trip => trip.transfersCount !== filterValue)
            setTransfersFilter(newFilterState);
            dispatch(setFilteredVariants(filteredTrips))
        } else {
            let opt = getEmptyFilterOptions();
            opt.transfers.push(filterValue)
            updateStateWithAllFilters(opt);
            setTransfersFilter([...transfersFilter, filterValue])
        }
    }

//! need to move service
    const getEmptyFilterOptions = (): IFilterOptions => {
        return {airlines: [], directions: [], ticketTypes: [], trains: [], transfers: []}
    }

    const sortByCurrentOpt = async (sortOpt: number) => {

        console.log("input sort opt: ", sortOpt);
        if (sortOpt === currentSortOption) {
            console.log("in if, check currentState: ", currentSortOption);
            setCurrentSortOption(0);
            return;
        }
        const res = [...orderState.filteredVariants];
        let result = sortByParameter(sortOpt, res);

        dispatch(setFilteredVariants(result));
        setCurrentSortOption(sortOpt);
        return;
    }

    const showAccBodyStyle = (value: boolean) => value ? "order-acc-body " : "order-acc-body hidden";

    const checkBoxStyle = {"label": {color: "gray", fontSize: "0.8rem", height: "1.3rem"}}
    return (
        <>

            <div className="">

                {/* Variants filter*/}
                <div className="">
                    <div className="document-accordion-header"
                         onClick={() => setShowSelectVariant(!showSelectVariant)}>
                        <div>Варианты</div>
                        <div typeof="push"><FilterIcon
                            iconSize="18px"/>{showSelectVariant ? <>&mdash;</> : <>&#10011;</>}</div>
                    </div>


                    <div className={showAccBodyStyle(showSelectVariant)}
                    >
                        {props.filterOptions?.ticketTypes.some(v => v === TicketType.AviaTicket) && <dd>
                            <CustomCheckBox
                                title={"Авиа варианты " + variantFilter.some(v => v === TicketType.AviaTicket)}
                                isChecked={variantFilter.some(v => v === TicketType.AviaTicket)}
                                icon={undefined}
                                style={checkBoxStyle}
                                onChange={() => {
                                    ticketTypeHandler(TicketType.AviaTicket)
                                }}
                            />
                        </dd>}

                        {props.filterOptions?.ticketTypes.some(v => v === TicketType.TrainTicket) && <dd>
                            <CustomCheckBox title={"ЖД варианты"}
                                            isChecked={variantFilter.some(v => v === TicketType.TrainTicket)}
                                            icon={undefined}
                                            style={checkBoxStyle}
                                            onChange={() => ticketTypeHandler(TicketType.TrainTicket)}/>
                        </dd>}

                    </div>

                </div>

                {/* Routes filter*/}
                <div className="document-accordion-item">
                    <div className="document-accordion-header" onClick={() => {
                        setShowDirection(!showDirection)
                    }}>
                        Routes
                        <div typeof="push"><FilterIcon
                            iconSize="18px"/>{showDirection ? <>&mdash;</> : <>&#10011;</>}</div>
                    </div>

                    <div className={showAccBodyStyle(showDirection)}>

                        {/*  All routes /*}
                        {/*{props.filterOptions?.directions !== undefined && props.filterOptions?.directions.length > 1 &&*/}
                        {/*    <dd><CustomCheckBox title={"Show all"}*/}
                        {/*                        isChecked={directionFilter.some(d => d === "all")}*/}
                        {/*                        icon={undefined}*/}
                        {/*                        style={checkBoxStyle}*/}
                        {/*                        onChange={() => {*/}
                        {/*                            directionFilterHandler("all")*/}
                        {/*                        }}*/}
                        {/*    /></dd>*/}
                        {/*}*/}

                        {props.directions.map(((dir, index) => {
                            if (stringIsNullOrEmpty(dir.townFrom) && stringIsNullOrEmpty(dir.townTo) && stringIsNullOrEmpty(dir.departDate)) {
                                return (
                                    <dd key={dir.townFrom + "-" + dir.townTo + "_" + index}>
                                        <CustomCheckBox title={dir.townFrom + "-" + dir.townTo}
                                                        isChecked={directionFilter.some(df => df === dir.townFrom + "-" + dir.townTo)}
                                                        icon={undefined}
                                                        style={checkBoxStyle}
                                                        onChange={() => directionFilterHandler(dir.townFrom + "-" + dir.townTo)}/>
                                    </dd>


                                )
                            } else return (<></>)
                        }))}

                    </div>
                </div>

                {/*Sort by params*/}
                <div className="document-accordion-item">
                    <div className="document-accordion-header"
                         onClick={() => setShowSortOptions(!showSortOptions)}>
                        <div> Сортировать по ...</div>
                        <div typeof="push"><SortIcon/> {showSortOptions ? <>&mdash;</> : <>&#10011;</>}</div>
                    </div>
                    <div>
                        <div className={showAccBodyStyle(showSortOptions)}>

                            {/*Sort by price*/}
                            <dd key={"OrderSort-" + (OrderSort.MinPrice | OrderSort.SortByAsc)}><CustomCheckBox
                                isChecked={currentSortOption === (OrderSort.MinPrice | OrderSort.SortByAsc)}
                                title={"Мин. цена возрастание"}
                                icon={<>&uarr;</>}
                                style={checkBoxStyle}
                                onChange={async () => {
                                    await sortByCurrentOpt((OrderSort.MinPrice | OrderSort.SortByAsc))
                                }}
                            /></dd>
                            <dd key={"OrderSort-" + (OrderSort.MinPrice | OrderSort.SortByDesc)}><CustomCheckBox
                                isChecked={currentSortOption === (OrderSort.MinPrice | OrderSort.SortByDesc)}
                                title={"Мин. цена убывание"}
                                style={checkBoxStyle}
                                icon={<>&darr;</>}

                                onChange={async () => {
                                    await sortByCurrentOpt(OrderSort.MinPrice | OrderSort.SortByDesc)
                                }}

                            /></dd>
                            <dd key={"OrderSort-" + (OrderSort.MaxPrice | OrderSort.SortByAsc)}><CustomCheckBox
                                isChecked={currentSortOption === (OrderSort.MaxPrice | OrderSort.SortByAsc)}
                                title={"Макс цена возрастание"}
                                icon={<>&uarr;</>}
                                style={checkBoxStyle}
                                onChange={async () => {
                                    await sortByCurrentOpt(OrderSort.MaxPrice | OrderSort.SortByAsc)
                                }}

                            /></dd>
                            <dd key={"OrderSort-" + (OrderSort.MaxPrice | OrderSort.SortByDesc)}><CustomCheckBox
                                isChecked={currentSortOption === (OrderSort.MaxPrice | OrderSort.SortByDesc)}
                                title={"Макс цена убывание"}
                                icon={<>&darr;</>}
                                style={checkBoxStyle}
                                onChange={async () => {
                                    await sortByCurrentOpt(OrderSort.MaxPrice | OrderSort.SortByDesc)
                                }}
                                // onChange={function (checkBoxState: boolean): void {
                                //     console.log("Макс цена возрастание",OrderSort.MaxPrice|OrderSort.SortByDesc)
                                // }}
                            /></dd>

                            {/* Sort by date */}
                            <dd key={"OrderSort-" + (OrderSort.SortByDepartureDate | OrderSort.SortByAsc)}>
                                <CustomCheckBox
                                    isChecked={currentSortOption === (OrderSort.SortByDepartureDate | OrderSort.SortByAsc)}
                                    title={"Время отправления"}
                                    icon={<>&uarr;</>}
                                    style={checkBoxStyle}
                                    onChange={async () => {
                                        console.log(`sort number: ${(OrderSort.SortByDepartureDate | OrderSort.SortByAsc)}`)
                                        await sortByCurrentOpt((OrderSort.SortByDepartureDate | OrderSort.SortByAsc))
                                    }}
                                /></dd>
                            <dd key={"OrderSort-" + (OrderSort.SortByDepartureDate | OrderSort.SortByDesc)}>
                                <CustomCheckBox
                                    isChecked={currentSortOption === (OrderSort.SortByDepartureDate | OrderSort.SortByDesc)}
                                    title={"Время отправления"}
                                    icon={<>&darr;</>}
                                    style={checkBoxStyle}
                                    onChange={async () => {
                                        console.log(`sort number: ${(OrderSort.SortByDepartureDate | OrderSort.SortByDesc)}`)
                                        await sortByCurrentOpt(OrderSort.SortByDepartureDate | OrderSort.SortByDesc)
                                    }}
                                /></dd>
                            <dd key={"OrderSort-" + (OrderSort.SortByArrivedDate | OrderSort.SortByAsc)}><CustomCheckBox
                                isChecked={currentSortOption === (OrderSort.SortByArrivedDate | OrderSort.SortByAsc)}
                                title={"Время прибытия"}
                                icon={<>&uarr;</>}
                                style={checkBoxStyle}
                                onChange={async () => {
                                    console.log(`sort number: ${(OrderSort.SortByArrivedDate | OrderSort.SortByAsc)}`)
                                    await sortByCurrentOpt(OrderSort.SortByArrivedDate | OrderSort.SortByAsc)
                                }}
                            /></dd>
                            <dd key={"OrderSort-" + (OrderSort.SortByArrivedDate | OrderSort.SortByDesc)}>
                                <CustomCheckBox
                                    isChecked={currentSortOption === (OrderSort.SortByArrivedDate | OrderSort.SortByDesc)}
                                    title={"Время прибытия"}
                                    icon={<>&darr;</>}
                                    style={checkBoxStyle}
                                    onChange={async () => {
                                        console.log(`sort number: ${(OrderSort.SortByArrivedDate | OrderSort.SortByDesc)}`)
                                        await sortByCurrentOpt(OrderSort.SortByArrivedDate | OrderSort.SortByDesc)
                                    }}
                                /></dd>
                        </div>
                    </div>
                </div>

                {/*transfers filter*/}
                {props.filterOptions?.transfers &&
                    <div className="document-accordion-item">
                        <div className="document-accordion-header"
                             onClick={() => setShowTransfers(!showTransfers)}>
                            <div>Transfers</div>
                            <div typeof="push"><FilterIcon
                                iconSize="18px"/>{showTransfers ? <>&mdash;</> : <>&#10011;</>}
                            </div>
                        </div>

                        <div className={showAccBodyStyle(showTransfers)}>

                            {props.filterOptions.transfers.map((transfer,index) => (
                                <dd key={"transfer-count-filter-"+index} ><CustomCheckBox
                                    title={transfer === 0 ? "No transfers" : transfer.toString()}
                                    isChecked={transfersFilter.some(tf => tf === transfer)}
                                    icon={undefined}
                                    style={checkBoxStyle}
                                    onChange={() => {
                                        transfersFilterHandler(transfer);
                                    }
                                    }/></dd>))}


                        </div>
                    </div>
                }

                {/* Filter by Airlines*/}
                {props.filterOptions?.airlines &&
                    <div className="document-accordion-item">
                        <div className="document-accordion-header"
                             onClick={() => setShowAirlines(!showAirlines)}>
                            <div>Авиакомпании</div>
                            <div typeof="push"><FilterIcon
                                iconSize="18px"/>{showAirlines ? <>&mdash;</> : <>&#10011;</>}
                            </div>
                        </div>
                        <div className={showAccBodyStyle(showAirlines)}>
                            {props.filterOptions.airlines.map((airline, index) => (
                                <dd key={"airline-filter-" + index}>

                                    <CustomCheckBox
                                        title={airline}
                                        isChecked={airlinesFilter.some(al => al === airline)}
                                        icon={undefined}
                                        style={checkBoxStyle}
                                        onChange={() => airlinesFilterHandler(airline)}/>
                                </dd>
                            ))}
                        </div>
                    </div>
                }

                {/* Filter by Train number or name*/}
                {props.filterOptions?.trains &&
                    <div className="document-accordion-item">
                        <div className="document-accordion-header"
                             onClick={() => setShowTrains(!showTrains)}>
                            <div>Поезда</div>
                            <div typeof="push"><FilterIcon
                                iconSize="18px"/>{showTrains ? <>&mdash;</> : <>&#10011;</>}
                            </div>
                        </div>
                        <div className={showAccBodyStyle(showTrains)}>
                            {props.filterOptions.trains.map((train, index) => (
                                <dd key={"train-name-filter-" + index}>
                                    <CustomCheckBox
                                        title={train}
                                        isChecked={trainFilter.some(al => al === train)}
                                        icon={undefined}
                                        style={checkBoxStyle}
                                        onChange={() => trainFilterHandler(train)}/>
                                </dd>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
