import {AXIOS_INSTANCE_GATEWAY, AXIOS_INSTANCE_INTEROP, axiosContext, axiosInstance} from './AxiosInstance';
import {
    BASE_ROUTE_GATEWAY_SERVICE,
    ROUTE_BOOK_FLIGHT, ROUTE_BOOK_TRAIN_INTEROP,
    ROUTE_FIND_TRAIN_INTEROP,
    ROUTE_GET_PAXES
} from "../constants/routeConstants/ApiRouteConstants";
import {IDirectionRequest} from "../interfaces/requestsInterfaces/IDirectionRequest";
import axios from 'axios';
import {CityElement} from '../interfaces/orderInterfaces/orderResponses';
import {IPax} from "../interfaces/orderInterfaces/IPax";
import {IBookFlightRequest} from "./request/IBookFlightRequest";
import {IPassenger} from "../interfaces/IPassenger";
import {IBookFlight} from "../interfaces/orderInterfaces/bookInterfaces";
import {IBookTrainRequest} from "../interfaces/orderInterfaces/IBookTrainRequest";
import {ISearchTrainsResponse} from "../responces/ISearchTrainsResponse";
import {ISearchFlightsResponse} from "../responces/ISearchFlightsResponse";
import {ICountryCitiesResponse} from "../interfaces/orderInterfaces/ICounryCitiesResponse";

export function OrderTicketApi() {

    //falcon
    // const searchAviaFlights = async (request:IDirectionRequest>) => {
    //     return await axiosInstance.post<IFalconResponse<IAviaVariant>>(ROUTE_FIND_AVIA_VARIANTS, request);
    // };
    //interop directly
    const searchAviaFlightsExtended = async (request: IDirectionRequest) => {
        //ToDo Update interface
        // const result = await axiosInstance.post<{variants:IAviaVariant[]}>(ROUTE_FIND_AVIA_VARIANTS, {
        //     SearchRequestDo: request,
        //     clientCode: "asd"
        // });


        //console.log("OrderApi response status: ", result.status);
        // console.log("REsult Data length", result.data)
        // axiosContext.getAxiosInstance(AXIOS_INSTANCE_INTEROP).post()
        const axiosInstance = axiosContext.getAxiosInstance(AXIOS_INSTANCE_INTEROP);
        const result = await axiosInstance.get<ISearchFlightsResponse>("/api/v1/flights/search", {
            params: {
                departDate: request.departDate,
                fromCity: request.townFrom,
                toCity: request.townTo,
                //clientCode: clientCode,
                clientCode: "Ф0013808",
                notes: request.notes
            }
        });

        // console.log(`OrderTicketApi: after gettin responce searchAvia status${result.status}`)
        //console.log(JSON.stringify(result.data));

        return result;
    }
    const searchTrainsInterop = async (departDate: string, fromCity: string, toCity: string, clientCode: string, notes?: string) => {
        const axiosInstance = axiosContext.getAxiosInstance(AXIOS_INSTANCE_INTEROP);
        const result = await axiosInstance.get<ISearchTrainsResponse>(ROUTE_FIND_TRAIN_INTEROP, {
            params: {
                departDate: departDate,
                fromCity: fromCity,
                toCity: toCity,
                //clientCode: clientCode,
                clientCode: "Ф0013808",
                notes: notes
            }
        });
        console.log(`OrderTicketApi: after gettin responce searchTrain status${result.status}`)
        console.log(JSON.stringify(result.data));

        return result;
    };

    const bookFlightApi = async (request: IBookFlight, clientCode: string,) => {

        console.log("request as string")
        console.log(JSON.stringify(request));
        const response = await axiosInstance.post(ROUTE_BOOK_FLIGHT, request, {params: {clientCode: clientCode}})
        //const response = await axios.post( "http://localhost:49301/api/v1/flights/book", request,{params:{clientCode:clientCode}})


        //issue for testing work

        //let data:string =request.toString()
        // console.log("request as string")
        // console.log(JSON.stringify(request));
        // const response=axios({
        //   url: 'http://localhost:49301/api/v1/flights/issue?clientCode=asdfasdf',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   method: 'post',
        //   data: data
        // })

        return response;
    }

    const bookTrainApi = async (request: IBookTrainRequest[]) => {

        console.log("before Send Book train request")
        const result = await axiosContext.getAxiosInstance(AXIOS_INSTANCE_INTEROP).post(ROUTE_BOOK_TRAIN_INTEROP, request);
        console.log("tryBook Train, response status: ", result.status, "; data:", result.data);
        return true;
    }

    const bookFlight2 = async (clientCode: string, request: IBookFlightRequest) => {
    }

    const getPaxes = async (clientCode: string) => {
        const response = await axiosContext.getAxiosInstance(AXIOS_INSTANCE_INTEROP).get<IPax[]>("/api/v1/pax/getpaxes", {params: {clientCode: clientCode}})

        if (response.status === 200) {
            console.log(response.data)
            return response.data;
        }
        return new Array<IPax>();
    }

    const getPassengers = async (clientCode: string) => {
        const response = await axios.get<IPassenger[]>(ROUTE_GET_PAXES, {params: {clientCode: clientCode}})

        if (response.status === 200) {
            console.log(response.data)
            return response.data;
        }
        return new Array<IPax>();
    }

//falcon
//     const searchTrains = async (request: ISearchTripVariants<IDirectionRequest>) => {
//         const result = await axiosInstance.post<IFalconResponse<ITrainVariant>>(ROUTE_FIND_TRAIN_VARIANTS, request);
//         return result;
//     };

    //interop

    const searchCities = async (partNameString: string) => {
        const result =
            //await axios.get<ICountryCitiesResponse[]>("http://localhost:49501/api/v1/webrefs/autocomlete/searchcities")
            await axiosContext.getAxiosInstance(AXIOS_INSTANCE_GATEWAY).get<ICountryCitiesResponse[]>("/gateway/api/v1/webrefs/autocomplete/searchcities",
                {
                    headers: {
                        Auth: 123,
                        //Authorization: authState.accessToken
                    },
                    params: {
                        text: partNameString,
                    }
                })
        // "/gateway/api/v1/interop/docs/ /api/v1/webrefs/autocomplete/searchcities
        //await axiosContext.getAxiosInstance().get<ICountryCitiesResponse>("/gateway/api/v1/interop/docs/searchcities")

        //await axiosInstance.get<ICountryCitiesResponse[]>("/api/autocomplete/searchcities", {params: {text: partNameString}});
        return result.data;
    }

    return {
        searchCities,
        searchAviaFlightsExtended,
        bookFlightApi,
        getPaxes,
        searchTrainsInterop,
        bookTrainApi
    };
}

