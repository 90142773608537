import { DataConvertService } from "../../../../services/DataConvertService";
import { customerBuhDocsTestData } from "./CustomerBuhDocsTestData";

export function CustomerBuhDocsComponent() {
  const testData = customerBuhDocsTestData;
  const { useMoneyRubFormat } = DataConvertService();
  return (
    <>
      <table className="table table-success table-striped table-bordered border-green">
        <thead>
          <tr>
            <th scope="col">No</th>
            <th scope="col">Document No</th>
            <th scope="col">Date</th>
            <th scope="col">Customer</th>
            <th scope="col">Payer</th>
            <th scope="col">VAT</th>
            <th scope="col">Amount</th>
            <th scope="col">Quantity</th>
            <th scope="col">Consolidated</th>
          </tr>
        </thead>
        <tbody>
          {testData.media.map((item, i) => {
            return (
              <tr>
                <th scope="row">{i}</th>
                <td>{item.accountingDocNumber}</td>
                <td>{item.date}</td>

                <td>{item.customer}</td>
                <td>{item.payer}</td>
                <td>{item.vatAmount}</td>
                {/* <td>{item.amount}</td> */}
                <td>{useMoneyRubFormat.format(Number(item.amount))}</td>
                <td>{item.quantity}</td>
                <td>{Number(item.consolidated) === 1 ? "Yes" : "no"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
