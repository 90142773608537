import {useEffect} from "react";
import "./style.scss";


export function ServicesPage() {

    useEffect(() => {

    }, []);

    return (
        <>

        </>
    );
}