import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const CircleSpinnerIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            className="circle-spinner"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 50 50"
            style={style["svg"]}
        >
            <circle
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
                style={style["circle"]}
            />
        </svg>
    )
}