import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../redux/hooks";
import {IOrder, IOrderBookingDetails, IOrderIssueDetails, IOrderTicketPdf} from "../../../../interfaces/IOrderInfo";
import {TripService} from "../../../../services/TripService";
import {ModalContext, ORDER_DETAILS_MODAL_ID} from "../../../commonComponents/modal/ModalContext";
import {MdScrollViewer} from "../../../../components/md-scroll-viewer";
import {MdModal} from "../../../../components/md-modal";
import {MdButton} from "../../../../components/md-button";
import {MdToggleButton} from "../../../../components/md-toggle-button";
import {AirplaneIcon} from "../../../../icons/AirplaneIcon";
import {CircleSpinnerIcon} from "../../../../icons/CircleSpinnerIcon";
import {PdfIcon2} from "../../../../icons/PdfIcon2";
import {TrainIcon3} from "../../../../icons/TrainIcon3";
import "./style.scss";


interface IOrderModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    orderNo: string,
}


export function OrderModal(props: IOrderModal) {

    const {
        opened,
        setOpened,
        orderNo,
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const tripService = TripService();

    const {isOpen, open, close} = useContext(ModalContext);


    const [order, setOrder] = useState<IOrder | null>(null);
    const [dataPending, setDataPending] = useState<boolean>(false);
    const [isOrderIssued, setIsOrderIssued] = useState<boolean>(false);
    const [orderBookingDetails, setOrderBookingDetails] = useState<IOrderBookingDetails[]>([]);
    const [orderIssueDetails, setOrderIssueDetails] = useState<IOrderIssueDetails | null>(null);
    const [pfdTicketNames, setPdfTicketNames] = useState<{ bso: string, tickets: IOrderTicketPdf[] }[]>([]);
    const [issuePending, setIssuePending] = useState<boolean>(false);


    const aviaBooking = orderBookingDetails[0]?.aviaBooking[0];
    const trainBooking = orderBookingDetails[0]?.trainBooking;


    const onModalOpen = () => {
        if (!opened) {
            setOpened(true);
        }
        getOrderDetails();
    }

    const onModalClose = () => {
        if (opened) {
            setOpened(false);
        }
    }


    const getOrderDetails = async () => {
        setOrder(null);
        setOrderBookingDetails([]);
        setOrderIssueDetails(null);

        setDataPending(true);

        const isOrderIssued = await tripService.isOrderIssued(authState.currentClientCode, orderNo);
        const orderData = await tripService.getOrder(authState.currentClientCode, orderNo);
        const orderBookingDetails = await tripService.getOrderBookingDetails(authState.currentClientCode, orderNo);
        const orderIssueDetails = await tripService.getOrderIssueDetails(authState.currentClientCode, orderNo);

        if(orderIssueDetails != null) {
            for(let i = 0; i < orderIssueDetails.bsoList.length; i++) {
                const bsoNo = orderIssueDetails.bsoList[i].bsoNo;
                const tickets = await tripService.getPdfTickets(authState.currentClientCode, bsoNo);
                setPdfTicketNames(prev => [
                    ...prev.filter(x => x.bso != bsoNo),
                    {
                        bso: bsoNo,
                        tickets: tickets
                    }
                ]);
            }
        }

        // const isOrderIssued = true;
        // const orderIssueDetails = {
        //     orderNo: "5247295",
        //     bsoList: [
        //         {
        //             bsoNo: "А002133795",
        //             tickets: [
        //                 {
        //                     ticketNo: "2120290543",
        //                     paxFullName: "KURYLAU IVAN",
        //                     pnr: "MS0W5C",
        //                     sumTotal: 18422,
        //                     airCompanyCode: "S7",
        //                     baggageInfo: "1PC",
        //                     flightNo: "1212",
        //                     tarifMask: "SPLOW",
        //                     classOfService: "Эконом",
        //                     departDate: "2024-11-27T13:15:00",
        //                     arriveDate: "2024-11-27T17:05:00",
        //                     departCode: "KGD",
        //                     arriveCode: "DME"
        //                 }
        //             ]
        //         }
        //     ]
        // }

        setDataPending(false);

        setOrder(orderData);
        setIsOrderIssued(isOrderIssued);
        setOrderBookingDetails(orderBookingDetails);
        setOrderIssueDetails(orderIssueDetails);
    }

    const issueOrder = async (order: IOrder, pnrs: string[]) => {
        setIssuePending(true);
        if(aviaBooking != null) {
            const response = tripService.issueFlights(authState.currentClientCode, order.orderNo, pnrs);
            const isOrderIssued = await tripService.isOrderIssued(authState.currentClientCode, orderNo);
            setIsOrderIssued(isOrderIssued);
            setIssuePending(false);
        }
        else if(trainBooking != null) {
            const response = tripService.issueTrains(authState.currentClientCode, order.orderNo, pnrs);
            const isOrderIssued = await tripService.isOrderIssued(authState.currentClientCode, orderNo);
            setIsOrderIssued(isOrderIssued);
            setIssuePending(false);
        }
    }

    // const getPdfTicketNames = async (bsoNo: string) => {
    //     const tickets = await tripService.getPdfTicketNames(authState.currentClientCode, bsoNo);
    //     setPdfTicketNames(prev => [
    //         ...prev.filter(x => x.bso != bsoNo),
    //         {
    //             bso: bsoNo,
    //             tickets: tickets
    //         }
    //     ]);
    // }

    const downloadPDFTicket = async (ticket: IOrderTicketPdf) => {
        const response = await tripService.getPdfTicketFile(authState.currentClientCode, ticket.key);
        if(response != null) {
            const fileURL = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', ticket.fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
        }
    }


    const trips: any[] = order?.evaParse?.trips ?? [];
    const dirs = trips.map((trip: any) => trip.dirs).flat();


    useEffect(() => {
        if (isOpen(ORDER_DETAILS_MODAL_ID) && !opened) {
            close(ORDER_DETAILS_MODAL_ID);
        } else if (!isOpen(ORDER_DETAILS_MODAL_ID) && opened) {
            open(ORDER_DETAILS_MODAL_ID);
        }
    }, [opened]);


    let recordLocator: any[] = [];
    if(aviaBooking != null) {
        recordLocator = [aviaBooking.aviaBooking.bookingFile.reservations.reservation[0].recordLocator];
    }
    else if(trainBooking != null) {
        recordLocator = trainBooking.map(x => x.bookingResponse.bookingFile.reservations[0].bookingNumber).flat();
    }


    let passengers = trips.map((trip: any) => trip.passengers).flat();
    // if(aviaBooking != null) {
    //     passengers = trips.map((trip: any) => trip.passengers).flat();
    // }
    // else if(trainBooking != null) {
    //     passengers = trainBooking.bookingResponse.bookingFile.reservations[0].products.railwayTicket.map(x => x.passengers).fl;
    // }

    let reservations: any[] = [];

    if(trainBooking != null)
    {
        reservations = trainBooking.map(x => x.bookingResponse.bookingFile.reservations[0]).flat();
    }


    return (
        <MdModal
            id={ORDER_DETAILS_MODAL_ID}
            title={"Заказ №" + orderNo}
            openHandler={onModalOpen}
            closeHandler={onModalClose}>
            <MdScrollViewer
                style={{
                    "root": {
                        maxHeight: "500px",
                        padding: "10px 0"
                    }
                }}
            >

                <div className="order-details">

                    {
                        dataPending && (
                            <div className="order-details-pending">
                                <CircleSpinnerIcon
                                    width="18px"
                                    height="18px"
                                    style={{
                                        "circle": {
                                            stroke: "#000000"
                                        }
                                    }}
                                />
                                <span>{t("bookingPage.orderDetailsPending")}</span>
                            </div>
                        )
                    }

                    {
                        !isOrderIssued && (
                            <>
                                <div className="order-header">
                                    <div className="order-header-title">

                                    </div>
                                </div>

                                <div className="order-booking-details">
                                    {
                                        aviaBooking != null && (
                                            passengers.map((passenger: any, passengerIndex: number) => {
                                                return (
                                                    dirs.map((dir: any, dirIndex: number) => {
                                                        const departCode = dir.townFromInfo.ita;
                                                        const departCity = dir.townFrom;
                                                        const departDate = new Date(dir.departDate);
                                                        const arriveCode = dir.townToInfo.ita;
                                                        const arriveCity = dir.townTo;
                                                        const arriveDate = new Date(dir.arriveDate);
                                                        const carrier = orderBookingDetails[0]?.aviaBooking[0]?.flightGroup?.carrier?.code ?? "";

                                                        return (
                                                            <React.Fragment key={passengerIndex}>
                                                                <div className="order-booking-details-item">
                                                                    <>
                                                                        <div className="avia-ticket-info">
                                                                            <div className="avia-ticket-info-header">
                                                                                <MdToggleButton
                                                                                    buttonStyle={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        padding: "1px 10px 0 10px",
                                                                                        borderRadius: "4px",
                                                                                        minHeight: "24px",
                                                                                        maxHeight: "52px",
                                                                                        fontSize: "11px",
                                                                                        color: "rgb(0 0 0)",
                                                                                        border: "1px solid rgb(200 200 200)",
                                                                                        cursor: 'default'
                                                                                    }}
                                                                                    inkStyle={{
                                                                                        backgroundColor: "rgb(200 200 200)",
                                                                                        opacity: 0.1
                                                                                    }}
                                                                                    checked={true}
                                                                                >
                                                                                    <>
                                                                                        <span>
                                                                                            Код бронирования:
                                                                                        </span>
                                                                                        <span style={{marginLeft: "5px", fontWeight: 500}}>
                                                                                            {
                                                                                                recordLocator[0] ?? "Не определен"
                                                                                            }
                                                                                        </span>
                                                                                    </>
                                                                                </MdToggleButton>
                                                                            </div>

                                                                            <div className="avia-ticket-info-content">
                                                                                <div className="depart-info">
                                                                                    <div className="city-code">
                                                                                        {departCode}
                                                                                    </div>
                                                                                    <div className="city-name">
                                                                                        {departCity}
                                                                                    </div>
                                                                                    <div className="datetime">
                                                                                        <span>
                                                                                            {departDate.getHours().toString().padStart(2, "0") + ":" + departDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                        </span>
                                                                                        <span>
                                                                                            {departDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                            {" "}
                                                                                            {departDate.getDate().toString().padStart(2, "0")}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight-info">
                                                                                    <div style={{fontSize: "12px"}}>
                                                                                        {carrier}
                                                                                    </div>
                                                                                    <div className="icon">
                                                                                    <span
                                                                                        style={{opacity: 0.25}}></span>
                                                                                        <span style={{opacity: 0.5}}></span>
                                                                                        <span></span>
                                                                                        <div style={{margin: "0 2px"}}>
                                                                                            <AirplaneIcon
                                                                                                width="16px"
                                                                                                height="16px"
                                                                                                style={{
                                                                                                    "path": {
                                                                                                        fill: "#475AEF"
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <span></span>
                                                                                        <span style={{opacity: 0.5}}></span>
                                                                                        <span style={{opacity: 0.25}}></span>
                                                                                    </div>
                                                                                    <div className="number">
                                                                                        {dir.cruiseNumber}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="arrive-info">
                                                                                    <div className="city-code">
                                                                                        {arriveCode}
                                                                                    </div>
                                                                                    <div className="city-name">
                                                                                        {arriveCity}
                                                                                    </div>
                                                                                    <div className="datetime">
                                                                                        <span>
                                                                                            {arriveDate.getHours().toString().padStart(2, "0") + ":" + arriveDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                        </span>
                                                                                        <span>
                                                                                            {arriveDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                            {" "}
                                                                                            {arriveDate.getDate().toString().padStart(2, "0")}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="avia-ticket-info-footer">
                                                                                <div
                                                                                    className="avia-ticket-info-item">
                                                                                    <span>Пассажир</span>
                                                                                    <span></span>
                                                                                    <span>{passenger.last + " " + passenger.first}</span>
                                                                                </div>
                                                                                <MdToggleButton
                                                                                    buttonStyle={{
                                                                                        margin: "20px 10px 5px 10px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        padding: "1px 10px 0 10px",
                                                                                        borderRadius: "4px",
                                                                                        minHeight: "24px",
                                                                                        maxHeight: "52px",
                                                                                        fontSize: "11px",
                                                                                        color: "rgb(200 200 200)",
                                                                                        border: "1px solid rgb(200 200 200)",
                                                                                    }}
                                                                                    inkStyle={{
                                                                                        backgroundColor: "rgb(200 200 200)",
                                                                                        opacity: 0.1
                                                                                    }}
                                                                                    checked={true}
                                                                                >
                                                                                    Ожидает выписки
                                                                                </MdToggleButton>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                )
                                            })
                                        )
                                    }

                                    {
                                        trainBooking != null && (
                                            reservations.map((reservation: any, reservationIndex: number) => {
                                                //const recordLocator = reservation.recordLocator;// ?? reservation.bookingNumber;
                                                const passenger0 = reservation.products.railwayTicket[0].passengers[0]; // ???
                                                const ticket0 = reservation.products.railwayTicket[0].segments[0]; // ???

                                                const departCode = ticket0.departureStation.station.code;
                                                const departCity = ticket0.departureStation.station.name;
                                                const departDate = new Date(ticket0.localDepartureDate);
                                                const arriveCode = ticket0.arriveStation.station.code;
                                                const arriveCity = ticket0.arriveStation.station.name;
                                                const arriveDate = new Date(ticket0.localArrivalDate);
                                                const cruiseNumber = ticket0.trainNumber;
                                                const carrier = "";

                                                return (
                                                    <React.Fragment key={reservationIndex}>
                                                        <div className="order-booking-details-item">
                                                            <>
                                                                <div className="avia-ticket-info">
                                                                    <div className="avia-ticket-info-header">
                                                                        <MdToggleButton
                                                                            buttonStyle={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                padding: "1px 10px 0 10px",
                                                                                borderRadius: "4px",
                                                                                minHeight: "24px",
                                                                                maxHeight: "52px",
                                                                                fontSize: "11px",
                                                                                color: "rgb(0 0 0)",
                                                                                border: "1px solid rgb(200 200 200)",
                                                                                cursor: 'default'
                                                                            }}
                                                                            inkStyle={{
                                                                                backgroundColor: "rgb(200 200 200)",
                                                                                opacity: 0.1
                                                                            }}
                                                                            checked={true}
                                                                        >
                                                                            <>
                                                                                <span>
                                                                                    Код бронирования:
                                                                                </span>
                                                                                <span style={{marginLeft: "5px", fontWeight: 500}}>
                                                                                    {
                                                                                        recordLocator[reservationIndex] ?? "Не определен"
                                                                                    }
                                                                                </span>
                                                                            </>
                                                                        </MdToggleButton>
                                                                    </div>

                                                                    <div className="avia-ticket-info-content">
                                                                        <div className="depart-info">
                                                                            <div className="city-code">
                                                                                {departCode}
                                                                            </div>
                                                                            <div className="city-name">
                                                                                {departCity}
                                                                            </div>
                                                                            <div className="datetime">
                                                                                <span>
                                                                                    {departDate.getHours().toString().padStart(2, "0") + ":" + departDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                </span>
                                                                                <span>
                                                                                    {departDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                    {" "}
                                                                                    {departDate.getDate().toString().padStart(2, "0")}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flight-info">
                                                                            <div style={{fontSize: "12px"}}>
                                                                                {carrier}
                                                                            </div>
                                                                            <div className="icon">
                                                                                <span style={{opacity: 0.25}}></span>
                                                                                <span style={{opacity: 0.5}}></span>
                                                                                <span></span>
                                                                                <div style={{margin: "0 2px"}}>
                                                                                    <TrainIcon3
                                                                                        width="18px"
                                                                                        height="18px"
                                                                                        style={{
                                                                                            "path": {
                                                                                                fill: "#475AEF"
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <span></span>
                                                                                <span style={{opacity: 0.5}}></span>
                                                                                <span style={{opacity: 0.25}}></span>
                                                                            </div>
                                                                            <div className="number">
                                                                                {cruiseNumber}
                                                                            </div>
                                                                        </div>
                                                                        <div className="arrive-info">
                                                                            <div className="city-code">
                                                                                {arriveCode}
                                                                            </div>
                                                                            <div className="city-name" style={{ textAlign: "right" }}>
                                                                                {arriveCity}
                                                                            </div>
                                                                            <div className="datetime">
                                                                                <span>
                                                                                    {arriveDate.getHours().toString().padStart(2, "0") + ":" + arriveDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                </span>
                                                                                <span>
                                                                                    {arriveDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                    {" "}
                                                                                    {arriveDate.getDate().toString().padStart(2, "0")}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="avia-ticket-info-footer">
                                                                        <div className="avia-ticket-info-item">
                                                                            <span>Пассажир</span>
                                                                            <span></span>
                                                                            <span>{passenger0.passport.lastName + " " + passenger0.passport.firstName}</span>
                                                                        </div>
                                                                        <MdToggleButton
                                                                            buttonStyle={{
                                                                                margin: "20px 10px 5px 10px",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                padding: "1px 10px 0 10px",
                                                                                borderRadius: "4px",
                                                                                minHeight: "24px",
                                                                                maxHeight: "52px",
                                                                                fontSize: "11px",
                                                                                color: "rgb(200 200 200)",
                                                                                border: "1px solid rgb(200 200 200)",
                                                                            }}
                                                                            inkStyle={{
                                                                                backgroundColor: "rgb(200 200 200)",
                                                                                opacity: 0.1
                                                                            }}
                                                                            checked={true}
                                                                        >
                                                                            Ожидает выписки
                                                                        </MdToggleButton>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        )
                                    }
                                </div>

                                <div className="order-footer">
                                    {
                                        (!issuePending && recordLocator.length > 0) && (
                                            <MdButton
                                                type="button"
                                                style={{
                                                    "button": {
                                                        borderWidth: 0,
                                                        backgroundColor: "#3E5CB8",
                                                        color: "#FFFFFF",
                                                        borderRadius: "6px",
                                                        height: "30px",
                                                        padding: "5px 15px"
                                                    },
                                                    "text": {
                                                        width: "auto",
                                                        flex: 0,
                                                        color: "#FFFFFF"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    if (order != null) {
                                                        issueOrder(order, recordLocator);
                                                    }
                                                }}
                                            >
                                                Выписать
                                            </MdButton>
                                        )
                                    }
                                    {
                                        issuePending && (
                                            <div className="issue-pending-message">
                                                <CircleSpinnerIcon
                                                    width="18px"
                                                    height="18px"
                                                    style={{
                                                        "circle": {
                                                            stroke: "#000000"
                                                        }
                                                    }}
                                                />
                                                <span>
                                                    Выписываем билеты
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }

                    {
                        isOrderIssued && (
                            <div className="order-issue-details">
                                {
                                    orderIssueDetails?.bsoList.map((bso, bsoIndex) => {
                                        return (
                                            <React.Fragment key={bsoIndex}>
                                                <div className="order-issue-details-item">
                                                    <a className="bso-link"
                                                       target="_blank"
                                                       href={("tts://objType=27145&docNum=" + bso.bsoNo)}>
                                                        Выписка: #{bso.bsoNo}
                                                    </a>
                                                    <div>
                                                        {
                                                            bso.tickets.map((ticket, ticketIndex) => {
                                                                const departCode = ticket.departCode;
                                                                const departCity = "???";
                                                                const departDate = new Date(ticket.departDate);
                                                                const arriveCode = ticket.arriveCode;
                                                                const arriveCity = "???";
                                                                const arriveDate = new Date(ticket.arriveDate);

                                                                return (
                                                                    <>
                                                                        <div className="avia-ticket-info">
                                                                            <div className="avia-ticket-info-header">
                                                                                <div className="depart-info">
                                                                                    <div className="city-code">
                                                                                        {departCode}
                                                                                    </div>
                                                                                    <div className="city-name">
                                                                                        {departCity}
                                                                                    </div>
                                                                                    <div className="datetime">
                                                                                        <span>
                                                                                            {departDate.getHours().toString().padStart(2, "0") + ":" + departDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                        </span>
                                                                                        <span>
                                                                                            {departDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                            {" "}
                                                                                            {departDate.getDate().toString().padStart(2, "0")}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flight-info">
                                                                                    <div style={{fontSize: "12px"}}>
                                                                                        {ticket.airCompanyCode}
                                                                                    </div>
                                                                                    <div className="icon">
                                                                                        <span style={{opacity: 0.25}}></span>
                                                                                        <span style={{opacity: 0.5}}></span>
                                                                                        <span></span>
                                                                                        <div style={{margin: "0 2px"}}>
                                                                                            {
                                                                                                aviaBooking != null
                                                                                                    ? (
                                                                                                        <AirplaneIcon
                                                                                                            width="16px"
                                                                                                            height="16px"
                                                                                                            style={{
                                                                                                                "path": {
                                                                                                                    fill: "#475AEF"
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                                    : (
                                                                                                        <TrainIcon3
                                                                                                            width="18px"
                                                                                                            height="18px"
                                                                                                            style={{
                                                                                                                "path": {
                                                                                                                    fill: "#475AEF"
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    )
                                                                                            }

                                                                                        </div>
                                                                                        <span></span>
                                                                                        <span style={{opacity: 0.5}}></span>
                                                                                        <span style={{opacity: 0.25}}></span>
                                                                                    </div>
                                                                                    <div className="number">
                                                                                        {ticket.flightNo}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="arrive-info">
                                                                                    <div className="city-code">
                                                                                        {ticket.arriveCode}
                                                                                    </div>
                                                                                    <div className="city-name">
                                                                                        {arriveCity}
                                                                                    </div>
                                                                                    <div className="datetime">
                                                                                        <span>
                                                                                            {arriveDate.getHours().toString().padStart(2, "0") + ":" + arriveDate.getMinutes().toString().padStart(2, "0") + ", "}
                                                                                        </span>
                                                                                        <span>
                                                                                            {arriveDate.toLocaleString('default', {month: 'short'}).replace(".", "")}
                                                                                            {" "}
                                                                                            {arriveDate.getDate().toString().padStart(2, "0")}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="avia-ticket-info-content">
                                                                                <div className="avia-ticket-info-item">
                                                                                    <span>Пассажир</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.paxFullName}</span>
                                                                                </div>
                                                                                <div className="avia-ticket-info-item">
                                                                                    <span>Номер билета</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.ticketNo}</span>
                                                                                </div>
                                                                                {/*<div className="avia-ticket-info-item">*/}
                                                                                {/*    <span>Багаж</span>*/}
                                                                                {/*    <span></span>*/}
                                                                                {/*    <span>{ticket.baggageInfo}</span>*/}
                                                                                {/*</div>*/}
                                                                                <div className="avia-ticket-info-item">
                                                                                    <span>Тариф</span>
                                                                                    <span></span>
                                                                                    <span>{ticket.tarifMask}</span>
                                                                                </div>
                                                                                {/*<div className="avia-ticket-info-item">*/}
                                                                                {/*    <span>Класс</span>*/}
                                                                                {/*    <span></span>*/}
                                                                                {/*    <span>{ticket.classOfService}</span>*/}
                                                                                {/*</div>*/}
                                                                                <div className="avia-ticket-info-item">
                                                                                    <span>Сумма</span>
                                                                                    <span></span>
                                                                                    <span>{(ticket.sumTotal).toLocaleString() + " ₽"}</span>
                                                                                </div>
                                                                                <MdToggleButton
                                                                                    buttonStyle={{
                                                                                        margin: "20px 10px 5px 10px",
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        padding: "1px 10px 0 10px",
                                                                                        borderRadius: "4px",
                                                                                        minHeight: "24px",
                                                                                        maxHeight: "52px",
                                                                                        fontSize: "11px",
                                                                                        color: "#74B100",
                                                                                        border: "1px solid #74B100",
                                                                                    }}
                                                                                    inkStyle={{
                                                                                        backgroundColor: "#74B100",
                                                                                        opacity: 0.1
                                                                                    }}
                                                                                    checked={true}
                                                                                >
                                                                                    Выписан
                                                                                </MdToggleButton>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="order-pdf-list">
                                                        {
                                                            pfdTicketNames
                                                                .filter(x => x.bso == bso.bsoNo)
                                                                .map(x => x.tickets)
                                                                .flat()
                                                                .map((ticket, ticketIndex) => {
                                                                    return (
                                                                        <React.Fragment key={ticketIndex}>
                                                                            <MdButton
                                                                                icon={
                                                                                    <PdfIcon2
                                                                                        width="12px"
                                                                                        height="12px"
                                                                                    />
                                                                                }
                                                                                style={{
                                                                                    "button": {
                                                                                        borderWidth: 0,
                                                                                        backgroundColor: "rgb(255 255 255)",
                                                                                        color: "#000000",
                                                                                        borderRadius: "6px",
                                                                                        maxWidth: "300px",
                                                                                        height: "auto",
                                                                                        padding: "5px",
                                                                                        boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.06), 0px 1px 10px 0px rgba(0, 0, 0, 0.04)"
                                                                                    },
                                                                                    "icon": {
                                                                                        marginLeft: "5px"
                                                                                    },
                                                                                    "ink": {
                                                                                        backgroundColor: "rgba(240,240,240,.5)"
                                                                                    }
                                                                                }}
                                                                                onClick={() => downloadPDFTicket(ticket)}
                                                                            >
                                                                                {
                                                                                    (ticket.fileName)
                                                                                }
                                                                            </MdButton>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                        )
                                    })
                                }
                            </div>
                        )
                    }

                </div>

            </MdScrollViewer>
        </MdModal>
    );
}