import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const WarningRhombusIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            fill="none"
            style={style["svg"]}>
            <path fillRule="evenodd"
                  d="M253.617407,12.4967773 L434.398258,193.277628 C451.060628,209.939998 451.060628,236.955037 434.398258,253.617407 L253.617407,434.398258 C236.955037,451.060628 209.939998,451.060628 193.277628,434.398258 L12.4967773,253.617407 C-4.16559245,236.955037 -4.16559245,209.939998 12.4967773,193.277628 L193.277628,12.4967773 C209.939998,-4.16559245 236.955037,-4.16559245 253.617407,12.4967773 Z M223.447518,282.114184 C208.209422,282.114184 196.780851,293.378184 196.780851,308.396851 C196.780851,324.098184 207.863102,335.362184 223.447518,335.362184 C238.685613,335.362184 250.114184,324.098184 250.114184,308.738184 C250.114184,293.378184 238.685613,282.114184 223.447518,282.114184 Z M244.780851,116.780851 L202.114184,116.780851 L202.114184,244.780851 L244.780851,244.780851 L244.780851,116.780851 Z"
                  transform="translate(32.552 32.552)"
                  style={style["path"]}/>
        </svg>
    )
}