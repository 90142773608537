import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const HotelIcon2 = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 380 380"
            style={style["svg"]}>
            <path
                d="M303.838,353.36V106.867h7.697V64.12H68.465v42.747h7.697V353.36H61.759V380h256.482v-26.64H303.838z M145.162,336.865
		           h-40.424v-40.424h40.424V336.865z M145.162,278.32h-40.424v-40.424h40.424V278.32z M145.162,219.775h-40.424v-40.424h40.424
		           V219.775z M145.162,161.23h-40.424v-40.424h40.424V161.23z M210.213,353.36h-40.426v-56.919h40.426V353.36z M210.213,278.32
		           h-40.426v-40.424h40.426V278.32z M210.213,219.775h-40.426v-40.424h40.426V219.775z M210.213,161.23h-40.426v-40.424h40.426V161.23
		           z M275.262,336.865h-40.424v-40.424h40.424V336.865z M275.262,278.32h-40.424v-40.424h40.424V278.32z M275.262,219.775h-40.424
		           v-40.424h40.424V219.775z M275.262,161.23h-40.424v-40.424h40.424V161.23z"
                style={style["path"]}
            />
            <polygon
                points="111.373,55.757 129.489,46.232 147.606,55.757 144.146,35.584 158.803,21.297 138.548,18.354 129.489,0 120.43,18.354 100.176,21.297 114.832,35.584"
                style={style["path"]}
            />
            <polygon
                points="171.883,55.757 190,46.232 208.117,55.757 204.656,35.584 219.313,21.297 199.059,18.354 190,0 180.941,18.354 160.687,21.297 175.344,35.584"
                style={style["path"]}
            />
            <polygon
                points="232.394,55.757 250.511,46.232 268.627,55.757 265.168,35.584 279.824,21.297 259.57,18.354 250.511,0  241.452,18.354 221.197,21.297 235.854,35.584"
                style={style["path"]}
            />
        </svg>
    )
}