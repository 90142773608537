import "./style.scss";
import {ReactNode} from "react";

type CustomCheckboxButtonProps = {
    isChecked: boolean;
    onChange: (checked: boolean) => void;
    label?: string;
    icon?:ReactNode;
    onClick?:()=>void;
}

export function CustomCheckboxButton({isChecked, onChange, label,icon}: CustomCheckboxButtonProps) {

    return (
            <div className={"custom-checkbox-button-item" + (isChecked ? "--enable" : "--disable")}
                 onClick={() => onChange(!isChecked)}>
                <div
                    typeof="check-button-checkbox">{isChecked ? <>&#x2714;</> : <> &#x2716;</>}</div>
                <div typeof="check-button-label">{label}</div>
                {icon && <div>{icon}</div>}
            </div>
    )
}