import {Validator} from "fluentvalidation-ts";
import {ISignupSupportUserRequest} from "../interfaces/support/ISignupSupportUserRequest";
import {ErrorText} from "../enums/ErrorText";

export class SignupSupportUserValidator extends Validator<ISignupSupportUserRequest>{
    constructor() {
        super();
        this.ruleFor("email")
            .notEmpty()
            .emailAddress()
            .withMessage("Email is incorrect.");
        this.ruleFor('code').notEmpty().withMessage(ErrorText.IsEmpty)
        this.ruleFor("login").minLength(1).withMessage(ErrorText.TooShort);
        this.ruleFor("firstName").minLength(1).withMessage(ErrorText.TooShort);
        this.ruleFor("lastName").minLength(1).withMessage(ErrorText.TooShort);
        //this.ruleFor("patronymic").minLength(1).withMessage("Name is short");
        this.ruleFor("position").notEmpty().withMessage("Please, input position!");
        this.ruleFor("gender").notNull().withMessage(ErrorText.NeedSelect)
    }
}