import React, { useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../redux/hooks";
import axios, {CancelTokenSource} from "axios";
import {ITrainTariffInfo, ITrainVariant} from "../../../../../interfaces/ITrainVariant";
import {DateService} from "../../../../../services/DateService";
import {TripService} from "../../../../../services/TripService";
import {MdSelect} from "../../../../../components/md-select";
import {MdButton} from "../../../../../components/md-button";
import {SwitchIcon} from "../../../../../icons/SwitchIcon";
import {RefsService} from "../../../../../services/RefsService";
import {ICompany} from "../../../../../interfaces/companyInfoInterfaces/ICompany";
import {IOrder} from "../../../../../interfaces/IOrderInfo";
import {MdMenu} from "../../../../../components/md-menu";
import {SearchTrainsResultsComponent} from "./searchTrainsResultsComponent";
import {MdToggleButton} from "../../../../../components/md-toggle-button";
import {MinusIcon} from "../../../../../icons/MinusIcon";
import {PlusIcon} from "../../../../../icons/PlusIcon";
import {SunriseIcon} from "../../../../../icons/SunriseIcon";
import {SunIcon} from "../../../../../icons/SunIcon";
import {MoonIcon} from "../../../../../icons/MoonIcon";
import {Guid, Utils} from "../../../../../utils/utils";
import "./style.scss";


export enum TimeOfDay {
    Morning   = 0,
    Afternoon = 1,
    Evening   = 2
}

export enum Railcar {
    None      = 0,
    Seat      = 1 << 0, // 0001
    Couchette = 1 << 1, // 0010
    Coupe     = 1 << 2, // 0100
    Sleeping  = 1 << 3, // 1000
    All = Seat | Couchette | Coupe | Sleeping
}

export enum CoupeType {
    None    = 0,
    Man     = 1 << 0, // 0001
    Woman   = 1 << 1, // 0010
    Family  = 1 << 2, // 0100
    Mixed   = 1 << 3, // 1000
    All = Man | Woman | Family | Mixed
}

export enum SeatType {
    None       = 0,          // 0
    Bottom     = 1 << 0,     // 0001
    SideBottom = 1 << 1,     // 0010
    Top        = 1 << 2,     // 0100
    SideTop    = 1 << 3,     // 1000
    ByTheWindow    = 1 << 4, // 10000
    All = Bottom | SideBottom | Top | SideTop | ByTheWindow
}


export interface ITrainRoute {
    id: string;
    isRoundTrip: boolean;
    passengers: number;
    railcar: Railcar;
    coupe: CoupeType;
    seat: SeatType;
    origin: IAutoCompleteItem;
    direction: IAutoCompleteItem;
    departDate?: string;
    departTimeOfDay?: TimeOfDay;
    departTime?: string;
    returnDate?: string;
    returnTimeOfDay?: TimeOfDay;
    returnTime?: string;
    notes: string;
}


interface IAutoCompleteItem {
    name: string;
    country: string;
    type: string;
    esr: string;
    express?: string;
}


interface ISearchTrainsComponent {
    order?: IOrder;
    selectTrainHandler: (train: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any) => void;
    selectedCompany: ICompany | undefined;
}


export function SearchTrainsComponent(props: ISearchTrainsComponent) {

    const {
        order,
        selectTrainHandler,
        selectedCompany
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const refsService = RefsService();

    const tripService = TripService();

    const dateService = DateService();


    const clearTimerRef = useRef();

    const days = [
        t("bookingPage.sundayShort"),
        t("bookingPage.mondayShort"),
        t("bookingPage.tuesdayShort"),
        t("bookingPage.wednesdayShort"),
        t("bookingPage.thursdayShort"),
        t("bookingPage.fridayShort"),
        t("bookingPage.saturdayShort"),
    ];

    const months = [
        t("bookingPage.januaryShort"),
        t("bookingPage.februaryShort"),
        t("bookingPage.marchShort"),
        t("bookingPage.aprilShort"),
        t("bookingPage.mayShort"),
        t("bookingPage.juneShort"),
        t("bookingPage.julyShort"),
        t("bookingPage.augustShort"),
        t("bookingPage.septemberShort"),
        t("bookingPage.octoberShort"),
        t("bookingPage.novemberShort"),
        t("bookingPage.decemberShort"),
    ];

    const minPassengersCount = 1;
    const maxPassengersCount = 99;


    const [trainRoute, setTrainRoute] =
        useState<ITrainRoute>(
            {
                id: Guid.newGuid(),
                isRoundTrip: false,
                passengers: minPassengersCount,
                railcar: Railcar.None,
                coupe: CoupeType.None,
                seat: SeatType.None,
                origin: {
                    name: "",
                    country: "",
                    esr: "",
                    type: "",
                },
                direction: {
                    name: "",
                    country: "",
                    esr: "",
                    type: "",
                },
                notes: ""
            }
        );

    const [railcarOptions] = useState<Railcar[]>([
        Railcar.Seat,
        Railcar.Couchette,
        Railcar.Coupe,
        Railcar.Sleeping
    ]);
    const [coupeTypeOptions] = useState<CoupeType[]>([
        CoupeType.Man,
        CoupeType.Woman,
        CoupeType.Family,
        CoupeType.Mixed
    ]);
    const [seatTypeOptions] = useState<SeatType[]>([
        SeatType.ByTheWindow,
        SeatType.Bottom,
        SeatType.Top,
        SeatType.SideBottom,
        SeatType.SideTop
    ]);


    const [originFocused, setOriginFocused] = useState<boolean>(false);
    const [directionFocused, setDirectionFocused] = useState<boolean>(false);
    const [departDateFocused, setDepartDateFocused] = useState<boolean>(false);
    const [returnDateFocused, setReturnDateFocused] = useState<boolean>(false);

    const [autoComplete, setAutoComplete] = useState<IAutoCompleteItem[]>([]);

    const [providers, setProviders] = useState<string[]>([]);
    const [selectedProviders, setSelectedProviders] = useState<string[]>([]);

    const [activeSlide, setActiveSlide] = useState<number>(0);

    const [errors, setErrors] = useState<string[]>([]);

    const [trains, setTrains] = useState<ITrainVariant[]>([]);
    const [searchPending, setSearchPending] = useState(false);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [searchTrainsCT, setSearchTrainsCT] = useState<CancelTokenSource|undefined>(undefined);

    const [searchResponse, setSearchResponse] = useState<any>(null);

    const [bookingTrains, setBookingTrains] = useState<{ variant: ITrainVariant, tariff: ITrainTariffInfo, searchResponse: any } | undefined>(undefined);
    const [bookTrainsModalOpened, setBookTrainsModalOpened] = useState<boolean>(false);



    const setIsRoundTrip = (isRoundTrip: boolean) => {
        setTrainRoute(route =>
            ({
                ...route,
                isRoundTrip: isRoundTrip,
                returnDate: (isRoundTrip ? route.returnDate: undefined),
                returnTime: (isRoundTrip ? route.returnTime: undefined),
            })
        );
    }

    const setPassengers = (passengers: number) => {
        setTrainRoute(route =>
            ({
                ...route,
                passengers: passengers
            })
        );
    }

    const setRailcar = (railcar: Railcar) => {
        setTrainRoute(route =>
            ({
                ...route,
                railcar: railcar
            })
        );
        if((trainRoute.railcar & Railcar.Seat) !== Railcar.Seat) {
            setSeat(trainRoute.seat &= ~SeatType.ByTheWindow);
        }
        if((trainRoute.railcar & Railcar.Couchette) !== Railcar.Couchette) {
            setSeat(trainRoute.seat &= ~SeatType.SideBottom);
            setSeat(trainRoute.seat &= ~SeatType.SideTop);
        }
        if((trainRoute.railcar & Railcar.Couchette) !== Railcar.Couchette && (trainRoute.railcar & Railcar.Coupe) !== Railcar.Coupe) {
            setSeat(trainRoute.seat &= ~SeatType.Bottom);
            setSeat(trainRoute.seat &= ~SeatType.Top);
        }
        if((trainRoute.railcar & Railcar.Coupe) !== Railcar.Coupe && (trainRoute.railcar & Railcar.Sleeping) !== Railcar.Sleeping) {
            setCoupe(CoupeType.None);
        }
    }

    const setCoupe = (coupe: CoupeType) => {
        setTrainRoute(route =>
            ({
                ...route,
                coupe: coupe
            })
        );
    }

    const setSeat = (seat: SeatType) => {
        setTrainRoute(route =>
            ({
                ...route,
                seat: seat
            })
        );
    }

    const setOrigin = (origin: IAutoCompleteItem) => {
        setTrainRoute(route =>
            ({
                ...route,
                origin: origin
            })
        );
    }

    const switchCities = () => {
        setTrainRoute(route =>
            ({
                ...route,
                origin: route.direction,
                direction: route.origin
            })
        );
    }

    const setDirection = (direction: IAutoCompleteItem) => {
        setTrainRoute(route =>
            ({
                ...route,
                direction: direction
            })
        );
    }

    const setDepartDate = (date: string | undefined) => {
        setTrainRoute(route =>
            ({
                ...route,
                departDate: date,
                returnDate: (route.returnDate != undefined && date != undefined && new Date(route.returnDate) < new Date(date) ? undefined : route.returnDate)
            })
        );
    }

    const setDepartTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setTrainRoute(route =>
            ({
                ...route,
                departTimeOfDay: timeOfDay
            })
        );
    }

    const setDepartTime = (time: string | undefined) => {
        setTrainRoute(route =>
            ({
                ...route,
                departTime: time
            })
        );
    }

    const setReturnDate = (date: string | undefined) => {
        setTrainRoute(route =>
            ({
                ...route,
                returnDate: date
            })
        );
    }

    const setReturnTimeOfDay = (timeOfDay: TimeOfDay | undefined) => {
        setTrainRoute(route =>
            ({
                ...route,
                returnTimeOfDay: timeOfDay
            })
        );
    }

    const setReturnTime = (time: string | undefined) => {
        setTrainRoute(route =>
            ({
                ...route,
                returnTime: time
            })
        );
    }


    const delay = (fn: Function, ms: number) => {
        clearTimeout(clearTimerRef.current);
        clearTimerRef.current = setTimeout(fn, ms) as any;
    }


    const originFocusHandler = async () => {
        setOriginFocused(true);
        setAutoComplete([]);
        const originText = trainRoute.origin.name;
        if(Utils.isEmpty(originText)) {
            return;
        }
        //delay(async () => {
            const autoCompleteItems = await refsService.getTrainCities(originText, "ru");
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }
    const originBlurHandler = async () => {
        setOriginFocused(false);
    }
    const originTextChangeHandler = async (text: string) => {
        //delay(async () => {
            if(Utils.isEmpty(text)) {
                setAutoComplete([]);
                return;
            }
            const autoCompleteItems = await refsService.getTrainCities(text, "ru");
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }

    const directionFocusHandler = async () => {
        setDirectionFocused(true);
        setAutoComplete([]);
        const directionText = trainRoute.direction.name;
        if(Utils.isEmpty(directionText)) {
            return;
        }
        //delay(async () => {
            const autoCompleteItems = await refsService.getTrainCities(directionText, "ru");
            if (autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            } else {
                setAutoComplete([]);
            }
        //}, 500);
    }
    const directionBlurHandler = async () => {
        setDirectionFocused(false);
    }
    const directionTextChangeHandler = async (text: string) => {
        //delay(async () => {
            if(Utils.isEmpty(text)) {
                setAutoComplete([]);
                return;
            }
            const autoCompleteItems = await refsService.getTrainCities(text, "ru");
            if(autoCompleteItems != null && !Utils.isEmpty(autoCompleteItems)) {
                setAutoComplete(autoCompleteItems);
            }
            else {
                setAutoComplete([]);
            }
        //}, 500);
    }

    const departDateFocusHandler = async () => {
        setDepartDateFocused(true);
    }
    const departDateBlurHandler = async () => {
        setDepartDateFocused(false);
    }

    const returnDateFocusHandler = async () => {
        setReturnDateFocused(true);
    }
    const returnDateBlurHandler = async () => {
        setReturnDateFocused(false);
    }


    const getProviders = async () => {
        //setProvidersReceivePending(true);
        //setIsProvidersNotFound(false);
        setProviders([]);
        const response = await tripService.getTrainProviders(selectedCompany?.code ?? "");
        const providers = response ?? [];
        if (providers.length > 0) {
            setProviders(providers);
        } else {
            //setIsProvidersNotFound(true);
        }
        //setProvidersReceivePending(false);
    };


    const searchTrains = async () => {
        setSearchPending(true);
        setSearchNotFound(false);
        setTrains([]);

        if (searchTrainsCT) {
            searchTrainsCT.cancel();
        }

        let cts = axios.CancelToken.source();
        setSearchTrainsCT(cts);

        const response = await tripService.getTrains(
            (
                trainRoute.isRoundTrip
                    ?
                        [
                            Object.assign({}, ({
                                ...trainRoute,
                            })),
                            Object.assign({}, ({
                                ...trainRoute,
                                origin: trainRoute.direction,
                                direction: trainRoute.origin,
                                departDate: trainRoute.returnDate,
                                departTimeOfDay: trainRoute.returnTimeOfDay,
                                departTime: trainRoute.returnTime
                            }))
                        ]
                    :
                        [
                            Object.assign({}, ({
                                ...trainRoute,
                            }))
                        ]
            ),
            selectedProviders,
            selectedCompany?.code ?? "",
            cts.token
        );

        setSearchTrainsCT(undefined);

        setSearchResponse(response);

        const variants = response?.variants ?? [];

        if (variants.length > 0) {
            // setTrains(variants.sort(function(a,b){
            //     return new Date(a.departureAt).getTime() - new Date(b.arriveAtn).getTime();
            // }));
            setTrains(variants);
        } else {
            setSearchNotFound(true);
        }

        setSearchPending(false);
    };
    const cancelSearchTrains = () => {
        if (searchTrainsCT) {
            searchTrainsCT.cancel();
        }
    }


    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.repeat) return;
        if (event.key === "Enter") {
            searchTrains();
        }
    }


    const selectTrain = (variant: ITrainVariant, tariff: ITrainTariffInfo) => {
        selectTrainHandler(variant, tariff, searchResponse);
        //setBookingTrains({ variant, tariff, searchResponse });
        //setBookTrainsModalOpened(true);
    }


    const sendSearchResultToTts = async () => {
        const response = await tripService.sendTrainsResultToTts(
            {
                trainSearchResponse: searchResponse,
                orderId: order?.orderNo.toString() ?? "",
                clientCode: selectedCompany?.code ?? "",
            }
        );
    }


    useEffect(() => {
        getProviders();
    }, [selectedCompany]);


    // TODO: check it
    // useEffect(() => {
    //     const errorMessages = [];
    //
    //     const wrongReturnDate =
    //         (flightRoute.returnDate != null && flightRoute.departDate != null && new Date(flightRoute.returnDate).getTime() < new Date(flightRoute.departDate).getTime())
    //         || (flightRoute.returnDate != null && flightRoute.departDate != null && new Date(flightRoute.returnDate + "T" + flightRoute.returnTime ?? "00:00:00").getTime() < new Date(flightRoute.departDate + "T" + flightRoute.departTime ?? "00:00:00").getTime());
    //
    //     if(wrongReturnDate != null) {
    //         errorMessages.push(t("errorMsg.inputDateError"));
    //     }
    //
    //     setErrors(errorMessages);
    // }, [flightRoute]);


    return (
        <React.Fragment>
            <div className="trip-page-search-form">

                <div className="trip-page-search-form-header">

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    trainRoute.isRoundTrip
                                        ? t("bookingPage.roundTrip")
                                        : t("bookingPage.oneWayTrip")
                                }

                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">

                            <MdToggleButton
                                buttonStyle={{
                                    flex: 1,
                                    minInlineSize: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "5px 10px",
                                    borderRadius: "4px",
                                    minWidth: "max-content",
                                    minHeight: "24px",
                                    maxHeight: "52px",
                                    fontSize: "12px",
                                    color: (!trainRoute.isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    border: (!trainRoute.isRoundTrip ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                }}
                                inkStyle={{
                                    backgroundColor: (!trainRoute.isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                    opacity: 0.1
                                }}
                                checked={!trainRoute.isRoundTrip}
                                onClick={() => {
                                    if (trainRoute.isRoundTrip) {
                                        setIsRoundTrip(false);
                                    }
                                }}
                            >
                                {
                                    t("bookingPage.oneWayTrip")
                                }
                            </MdToggleButton>

                            {/*<MdToggleButton*/}
                            {/*    buttonStyle={{*/}
                            {/*        flex: 1,*/}
                            {/*        minInlineSize: "fit-content",*/}
                            {/*        display: "flex",*/}
                            {/*        alignItems: "center",*/}
                            {/*        padding: "5px 10px",*/}
                            {/*        borderRadius: "4px",*/}
                            {/*        minWidth: "max-content",*/}
                            {/*        minHeight: "24px",*/}
                            {/*        maxHeight: "52px",*/}
                            {/*        fontSize: "12px",*/}
                            {/*        color: (isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                            {/*        border: (isRoundTrip ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),*/}
                            {/*    }}*/}
                            {/*    inkStyle={{*/}
                            {/*        backgroundColor: (isRoundTrip ? "rgb(62, 92, 184)" : "#C1C1C1"),*/}
                            {/*        opacity: 0.1*/}
                            {/*    }}*/}
                            {/*    checked={isRoundTrip}*/}
                            {/*    onClick={() => {*/}
                            {/*        if (!isRoundTrip) {*/}
                            {/*            setIsRoundTrip(true);*/}
                            {/*        }*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    {*/}
                            {/*        t("bookingPage.roundTrip")*/}
                            {/*    }*/}
                            {/*</MdToggleButton>*/}

                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {t("bookingPage.passengers")}: {trainRoute.passengers.toString().padStart(2, "0")}
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="passengers-input-control">
                            <MdButton
                                icon={
                                    <MinusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (trainRoute.passengers > minPassengersCount) {
                                        setPassengers(trainRoute.passengers - 1);
                                    }
                                }}
                            />

                            <input
                                type="number"
                                min={minPassengersCount}
                                max={maxPassengersCount}
                                value={trainRoute.passengers}
                                readOnly={true}
                            />

                            <MdButton
                                icon={
                                    <PlusIcon
                                        width="12px"
                                        height="12px"
                                        style={{
                                            "path": {
                                                stroke: "#878686"
                                            }
                                        }}
                                    />
                                }
                                style={{
                                    "button": {
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderWidth: 0,
                                        backgroundColor: "#FFFFFF",
                                        color: "rgb(0 0 0)",
                                        borderRadius: "6px",
                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                        marginRight: "0px",
                                        width: "22px",
                                        height: "22px",
                                        padding: 0
                                    },
                                    "ink": {
                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                    },
                                }}
                                onClick={() => {
                                    if (trainRoute.passengers < maxPassengersCount) {
                                        setPassengers(trainRoute.passengers + 1);
                                    }
                                }}
                            />
                        </div>
                    </MdMenu>

                    <MdMenu
                        behaviour={'none'}
                        header={(
                            <div className="filter-menu-header">
                                {
                                    (() => {
                                        let enabledOptions = 0;
                                        railcarOptions.forEach((variant) => {
                                            if(trainRoute.railcar === Railcar.None || (trainRoute.railcar & variant) === variant) {
                                                enabledOptions++;
                                            }
                                        });
                                        if(enabledOptions == 0 || enabledOptions == railcarOptions.length) {
                                            return "Любой вагон";
                                        }
                                        else {
                                            if((trainRoute.railcar & Railcar.Seat) === Railcar.Seat) {
                                                return "Сидячий вагон" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                            }
                                            else if((trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette) {
                                                return "Плацкартный вагон" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                            }
                                            else if((trainRoute.railcar & Railcar.Coupe) === Railcar.Coupe) {
                                                return "Купейный вагон" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                            }
                                            else if((trainRoute.railcar & Railcar.Sleeping) === Railcar.Sleeping) {
                                                return "Вагон люкс" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                            }
                                        }
                                    })()
                                }
                            </div>
                        )}
                        disabled={searchPending}
                    >
                        <div className="filter-menu-content">
                            {
                                railcarOptions.map((variant, variantIndex) => {
                                    return (
                                        <React.Fragment key={variantIndex}>
                                            <MdToggleButton
                                                buttonStyle={{
                                                    flex: 1,
                                                    minInlineSize: "fit-content",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "5px 10px",
                                                    borderRadius: "4px",
                                                    minWidth: "max-content",
                                                    minHeight: "24px",
                                                    maxHeight: "52px",
                                                    fontSize: "12px",
                                                    color: (((trainRoute.railcar & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    border: (((trainRoute.railcar & variant) === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                }}
                                                inkStyle={{
                                                    backgroundColor: (((trainRoute.railcar & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                    opacity: 0.1
                                                }}
                                                checked={((trainRoute.railcar & variant) === variant)}
                                                onClick={() => {
                                                    if (((trainRoute.railcar & variant) !== variant)) {
                                                        // add variant to railcar variants
                                                        setRailcar(trainRoute.railcar |= variant);
                                                    }
                                                    else {
                                                        // remove variant from railcar variants
                                                        setRailcar(trainRoute.railcar &= ~variant);
                                                    }
                                                }}
                                            >
                                                {
                                                    (() => {
                                                        switch (variant) {
                                                            case Railcar.Seat:
                                                                return "Сидячий вагон";
                                                            case Railcar.Couchette:
                                                                return "Плацкартный вагон";
                                                            case Railcar.Coupe:
                                                                return "Купейный вагон";
                                                            case Railcar.Sleeping:
                                                                return "Вагон люкс";
                                                        }
                                                    })()
                                                }
                                            </MdToggleButton>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </MdMenu>

                    {
                        (trainRoute.railcar === Railcar.None || (trainRoute.railcar & Railcar.Coupe) === Railcar.Coupe || (trainRoute.railcar & Railcar.Sleeping) == Railcar.Sleeping) && (
                            <MdMenu
                                behaviour={'none'}
                                header={(
                                    <div className="filter-menu-header">
                                        {
                                            (() => {
                                                let enabledOptions = 0;
                                                coupeTypeOptions.forEach((variant) => {
                                                    if(trainRoute.coupe === CoupeType.None || (trainRoute.coupe & variant) === variant) {
                                                        enabledOptions++;
                                                    }
                                                });
                                                if(enabledOptions == 0 || enabledOptions == coupeTypeOptions.length) {
                                                    return "Любое купе";
                                                }
                                                else {
                                                    if((trainRoute.coupe & CoupeType.Man) === CoupeType.Man) {
                                                        return "Мужское купе" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                    else if((trainRoute.coupe & CoupeType.Woman) === CoupeType.Woman) {
                                                        return "Женское купе" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                    else if((trainRoute.coupe & CoupeType.Family) === CoupeType.Family) {
                                                        return "Семейное купе" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                    else if((trainRoute.coupe & CoupeType.Mixed) === CoupeType.Mixed) {
                                                        return "Смешанное купе" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                }
                                            })()
                                        }
                                    </div>
                                )}
                                disabled={searchPending}
                            >
                                <div className="filter-menu-content">
                                    {
                                        coupeTypeOptions.map((variant, variantIndex) => {
                                            return (
                                                <React.Fragment key={variantIndex}>
                                                    <MdToggleButton
                                                        buttonStyle={{
                                                            flex: 1,
                                                            minInlineSize: "fit-content",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "5px 10px",
                                                            borderRadius: "4px",
                                                            minWidth: "max-content",
                                                            minHeight: "24px",
                                                            maxHeight: "52px",
                                                            fontSize: "12px",
                                                            color: (((trainRoute.coupe & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            border: (((trainRoute.coupe & variant) === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                        }}
                                                        inkStyle={{
                                                            backgroundColor: (((trainRoute.coupe & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            opacity: 0.1
                                                        }}
                                                        checked={((trainRoute.coupe & variant) === variant)}
                                                        onClick={() => {
                                                            if (((trainRoute.coupe & variant) !== variant)) {
                                                                // add variant to coupe variants
                                                                setCoupe(trainRoute.coupe |= variant);
                                                            }
                                                            else {
                                                                // remove variant from coupe variants
                                                                setCoupe(trainRoute.coupe &= ~variant);
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            (() => {
                                                                switch (variant) {
                                                                    case CoupeType.Man:
                                                                        return "Мужское купе";
                                                                    case CoupeType.Woman:
                                                                        return "Женское купе";
                                                                    case CoupeType.Family:
                                                                        return "Семейное купе";
                                                                    case CoupeType.Mixed:
                                                                        return "Смешанное купе";
                                                                }
                                                            })()
                                                        }
                                                    </MdToggleButton>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </MdMenu>
                        )
                    }

                    {
                        (trainRoute.railcar == Railcar.None || (trainRoute.railcar & Railcar.Couchette) == Railcar.Couchette || (trainRoute.railcar & Railcar.Coupe) == Railcar.Coupe || (trainRoute.railcar & Railcar.Seat) == Railcar.Seat) && (
                            <MdMenu
                                behaviour={'none'}
                                header={(
                                    <div className="filter-menu-header">
                                        {
                                            (() => {
                                                let availableOptions = seatTypeOptions.filter((variant) => {
                                                    if(variant === SeatType.ByTheWindow && (trainRoute.railcar === Railcar.None || (trainRoute.railcar & Railcar.Seat) === Railcar.Seat)) {
                                                        return true;
                                                    }
                                                    else if(variant === SeatType.Bottom && (trainRoute.railcar === Railcar.None || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette || (trainRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {
                                                        return true;
                                                    }
                                                    else if(variant === SeatType.Top && (trainRoute.railcar === Railcar.None || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette || (trainRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {
                                                        return true;
                                                    }
                                                    else if(variant === SeatType.SideBottom && (trainRoute.railcar === Railcar.None || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {
                                                        return true;
                                                    }
                                                    else if(variant === SeatType.SideTop && (trainRoute.railcar === Railcar.None || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {
                                                        return true;
                                                    }
                                                    return false;
                                                });
                                                let enabledOptions = 0;
                                                availableOptions.forEach((variant) => {
                                                    if(trainRoute.seat === SeatType.None || (trainRoute.seat & variant) === variant) {
                                                        enabledOptions++;
                                                    }
                                                });
                                                if(enabledOptions == 0 || enabledOptions == availableOptions.length) {
                                                    return "Любое место";
                                                }
                                                else {
                                                    if((trainRoute.seat & SeatType.ByTheWindow) === SeatType.ByTheWindow) {
                                                        return "У окна" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                    else if((trainRoute.seat & SeatType.Bottom) === SeatType.Bottom) {
                                                        return "Нижнее" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                    else if((trainRoute.seat & SeatType.Top) === SeatType.Top) {
                                                        return "Верхнее" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                    else if((trainRoute.seat & SeatType.SideBottom) === SeatType.SideBottom) {
                                                        return "Нижнее боковое" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                    else if((trainRoute.seat & SeatType.SideTop) === SeatType.SideTop) {
                                                        return "Верхнее боковое" + (enabledOptions > 1 ? (" + " + (enabledOptions - 1)) : "");
                                                    }
                                                }
                                            })()
                                        }
                                    </div>
                                )}
                                disabled={searchPending}
                            >
                                <div className="filter-menu-content">
                                    {
                                        seatTypeOptions.filter(variant => {
                                            if(variant === SeatType.ByTheWindow
                                                && (trainRoute.railcar === Railcar.None
                                                    || (trainRoute.railcar & Railcar.Seat) === Railcar.Seat)) {
                                                return true;
                                            }
                                            else if(variant === SeatType.Bottom
                                                && (trainRoute.railcar === Railcar.None
                                                    || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette
                                                    || (trainRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {
                                                return true;
                                            }
                                            else if(variant === SeatType.Top
                                                && (trainRoute.railcar === Railcar.None
                                                    || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette
                                                    || (trainRoute.railcar & Railcar.Coupe) === Railcar.Coupe)) {
                                                return true;
                                            }
                                            else if(variant === SeatType.SideBottom
                                                && (trainRoute.railcar === Railcar.None
                                                    || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {
                                                return true;
                                            }
                                            else if(variant === SeatType.SideTop
                                                && (trainRoute.railcar === Railcar.None
                                                    || (trainRoute.railcar & Railcar.Couchette) === Railcar.Couchette)) {
                                                return true;
                                            }
                                            return false;
                                        }).map((variant, variantIndex) => {
                                            return (
                                                <React.Fragment key={variantIndex}>
                                                    <MdToggleButton
                                                        buttonStyle={{
                                                            flex: 1,
                                                            minInlineSize: "fit-content",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            padding: "5px 10px",
                                                            borderRadius: "4px",
                                                            minWidth: "max-content",
                                                            minHeight: "24px",
                                                            maxHeight: "52px",
                                                            fontSize: "12px",
                                                            color: (((trainRoute.seat & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            border: (((trainRoute.seat & variant) === variant) ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                                        }}
                                                        inkStyle={{
                                                            backgroundColor: (((trainRoute.seat & variant) === variant) ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                            opacity: 0.1
                                                        }}
                                                        checked={((trainRoute.seat & variant) === variant)}
                                                        onClick={() => {
                                                            if (((trainRoute.seat & variant) !== variant)) {
                                                                // add variant to seat variants
                                                                setSeat(trainRoute.seat |= variant);
                                                            }
                                                            else {
                                                                // remove variant from seat variants
                                                                setSeat(trainRoute.seat &= ~variant);
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            (() => {
                                                                switch (variant) {
                                                                    case SeatType.ByTheWindow:
                                                                        return "У окна";
                                                                    case SeatType.Bottom:
                                                                        return "Нижнее";
                                                                    case SeatType.Top:
                                                                        return "Верхнее";
                                                                    case SeatType.SideBottom:
                                                                        return "Нижнее боковое";
                                                                    case SeatType.SideTop:
                                                                        return "Верхнее боковое";
                                                                }
                                                            })()
                                                        }
                                                    </MdToggleButton>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </MdMenu>
                        )
                    }

                </div>

                <div className="trip-page-search-form-content">

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((originFocused || trainRoute.origin.name.length > 0 ? "--displaced" : ""))}>
                            {t("commonText.from")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={trainRoute.origin.name}
                            setText={(value) => setOrigin(autoComplete.find(x => x.name == value) ?? {
                                name: value,
                                country: "",
                                type: "",
                                esr: ""
                            })}
                            selectedItem={trainRoute.origin}
                            setSelectedItem={(item) => setOrigin(item)}
                            onFocus={() => originFocusHandler()}
                            onBlur={() => originBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*            <span className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*                <span className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => originTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>

                    <div className="trip-page-search-form-switch-cities-control">
                        <MdButton
                            style={{
                                "button": {
                                    position: 'absolute',
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%) rotate(-90deg)",
                                    borderWidth: 0,
                                    backgroundColor: "transparent",
                                    color: "rgb(0 0 0)",
                                    borderRadius: "6px",
                                    height: "30px",
                                    padding: "5px",
                                    zIndex: 1
                                },
                                "icon": {
                                    marginRight: "5px",
                                },
                                "ink": {
                                    backgroundColor: "rgba(255,255,255,.5)"
                                }
                            }}
                            tabIndex={-1}
                            disabled={searchPending}
                            onClick={() => switchCities()}
                        >
                            <SwitchIcon
                                width="20px"
                                height="20px"
                                style={{
                                    "path": {
                                        stroke: "rgb(100, 100, 100)",
                                        fill: "rgb(100, 100, 100)"
                                    }
                                }}
                            />
                        </MdButton>
                    </div>

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}>
                        <label
                            className={((directionFocused || trainRoute.direction.name.length > 0 ? "--displaced" : ""))}>
                            {t("commonText.to")}
                        </label>
                        <MdSelect
                            isEditable={true}
                            text={trainRoute.direction.name}
                            setText={(value) => setDirection(autoComplete.find(x => x.name == value) ?? {
                                name: value,
                                country: "",
                                type: "",
                                esr: ""
                            })}
                            selectedItem={trainRoute.direction}
                            setSelectedItem={(item) => setDirection(item)}
                            onFocus={() => directionFocusHandler()}
                            onBlur={() => directionBlurHandler()}
                            itemSource={autoComplete}
                            displayMemberPath={"name"}
                            selectedItemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item"
                                         style={{padding: '26px 10px 6px 20px'}}>
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*                <span*/}
                                            {/*                    className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            itemTemplate={(item: IAutoCompleteItem) => {
                                const details = [(item.type ?? item.name), item.country].filter(x => x != null && x.length > 0);
                                return (
                                    <div className="trip-page-search-form-city-item">
                                        <div className="trip-page-search-form-city-item-text">
                                            {item.name}
                                            <div className="trip-page-search-form-city-item-code">
                                                {item.esr}
                                            </div>
                                        </div>
                                        <div className="trip-page-search-form-city-item-details">
                                            {/*{*/}
                                            {/*    item.country_code.length > 0 && (*/}
                                            {/*        <div className="trip-page-search-form-city-item-flag">*/}
                                            {/*                <span*/}
                                            {/*                    className={("flag-icon flag-icon-" + item.country_code.toLowerCase())}></span>*/}
                                            {/*        </div>*/}
                                            {/*    )*/}
                                            {/*}*/}
                                            {
                                                details.length > 0 ? details.join(', ') : ""
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                            onTextChange={(value) => directionTextChangeHandler(value)}
                            style={{
                                "root": {
                                    width: "100%",
                                    height: "100%",
                                    margin: 0,
                                    border: "none"
                                },
                                "text-field": {
                                    padding: "7px 10px 6px 20px",
                                    height: "100%",
                                    fontWeight: 500,
                                    fontSize: "13px"
                                },
                                "toggle-button-icon": {
                                    display: "none"
                                }
                            }}
                        />
                    </div>

                    <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))} style={{marginLeft: "6px", minWidth: "280px"}}>
                        <label className={(departDateFocused || trainRoute.departDate != null ? "--displaced" : "")}>
                            {t("bookingPage.departDate")}
                        </label>
                        <input
                            className={(departDateFocused ? "--focused" : "")}
                            type="date"
                            min={dateService.dateToStringForDatePicker(new Date()) + "T00:00:00"}
                            value={trainRoute.departDate ?? ""}
                            onFocus={() => departDateFocusHandler()}
                            onBlur={() => departDateBlurHandler()}
                            onChange={(e) => setDepartDate(e.target.value)}
                            disabled={searchPending}
                        />
                        {
                            (trainRoute.departDate != null && trainRoute.departTimeOfDay == null) && (
                                <input
                                    className={(departDateFocused ? "--focused" : "")}
                                    type="time"
                                    value={trainRoute.departTime ?? ""}
                                    onFocus={() => departDateFocusHandler()}
                                    onBlur={() => departDateBlurHandler()}
                                    onChange={(e) => setDepartTime(e.target.value)}
                                    disabled={searchPending}
                                />
                            )
                        }

                        {
                            trainRoute.departDate != null && (
                                <div className={("trip-page-search-form-datetime-item" + (departDateFocused ? " --hidden" : ""))}>
                                    <div className="trip-page-search-form-datetime-item-date">
                                        {
                                            days[new Date(trainRoute.departDate).getDay()]
                                            + ", " + new Date(trainRoute.departDate).getDate().toString().padStart(2, "0") + " "
                                            + months[new Date(trainRoute.departDate).getMonth()]
                                        }
                                    </div>
                                    <div className="trip-page-search-form-datetime-item-time">
                                        {
                                            trainRoute.departTimeOfDay == null
                                                ? (
                                                    <span style={{marginLeft: "15px"}}>
                                                        {
                                                            trainRoute.departTime != null ? trainRoute.departTime.toString() : "00:00"
                                                        }
                                                    </span>
                                                )
                                                : (
                                                    (() => {
                                                        switch (trainRoute.departTimeOfDay){
                                                            case TimeOfDay.Morning:
                                                                return (
                                                                    <>
                                                                        <SunriseIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            04:00 - 12:00
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Afternoon:
                                                                return (
                                                                    <>
                                                                        <SunIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            10:00 - 16:00
                                                                        </span>
                                                                    </>
                                                                )
                                                            case TimeOfDay.Evening:
                                                                return (
                                                                    <>
                                                                        <MoonIcon
                                                                            width="16px"
                                                                            height="16px"
                                                                        />
                                                                        <span>
                                                                            16:00 - 23:00
                                                                        </span>
                                                                    </>
                                                                )
                                                            default:
                                                                return ""
                                                        }
                                                    })()
                                                )
                                        }
                                    </div>
                                </div>
                            )
                        }

                        <MdMenu
                            behaviour={'none'}
                            header={(
                                <div className="train-departure-time-type">
                                    {
                                        trainRoute.departTimeOfDay == null
                                            ? (
                                                t("bookingPage.approximateTime")
                                            )
                                            : (
                                                (() => {
                                                    switch (trainRoute.departTimeOfDay){
                                                        case TimeOfDay.Morning:
                                                            return t("bookingPage.morning")
                                                        case TimeOfDay.Afternoon:
                                                            return t("bookingPage.afternoon")
                                                        case TimeOfDay.Evening:
                                                            return t("bookingPage.evening")
                                                        default:
                                                            return ""
                                                    }
                                                })()
                                            )
                                    }
                                </div>
                            )}
                            disabled={searchPending}
                            style={{
                                "root": {
                                    position: 'absolute',
                                    bottom: "2px",
                                    right: "3px",
                                }
                            }}
                        >
                            <div className="train-departure-time-type-variants">

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "12px",
                                        color: (trainRoute.departTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (trainRoute.departTimeOfDay == TimeOfDay.Morning ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (trainRoute.departTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={trainRoute.departTimeOfDay == TimeOfDay.Morning}
                                    onClick={() => {
                                        if (trainRoute.departTimeOfDay != TimeOfDay.Morning) {
                                            setDepartTimeOfDay(TimeOfDay.Morning);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.morning")
                                    }
                                </MdToggleButton>

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "12px",
                                        color: (trainRoute.departTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (trainRoute.departTimeOfDay == TimeOfDay.Afternoon ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (trainRoute.departTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={trainRoute.departTimeOfDay == TimeOfDay.Afternoon}
                                    onClick={() => {
                                        if (trainRoute.departTimeOfDay != TimeOfDay.Afternoon) {
                                            setDepartTimeOfDay(TimeOfDay.Afternoon);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.afternoon")
                                    }
                                </MdToggleButton>

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "12px",
                                        color: (trainRoute.departTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (trainRoute.departTimeOfDay == TimeOfDay.Evening ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (trainRoute.departTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={trainRoute.departTimeOfDay == TimeOfDay.Evening}
                                    onClick={() => {
                                        if (trainRoute.departTimeOfDay != TimeOfDay.Evening) {
                                            setDepartTimeOfDay(TimeOfDay.Evening);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.evening")
                                    }
                                </MdToggleButton>

                                <MdToggleButton
                                    buttonStyle={{
                                        flex: 1,
                                        minInlineSize: "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "5px 10px",
                                        borderRadius: "4px",
                                        minWidth: "max-content",
                                        minHeight: "24px",
                                        maxHeight: "52px",
                                        fontSize: "12px",
                                        color: (trainRoute.departTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        border: (trainRoute.departTimeOfDay == undefined ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                    }}
                                    inkStyle={{
                                        backgroundColor: (trainRoute.departTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                        opacity: 0.1
                                    }}
                                    checked={trainRoute.departTimeOfDay == undefined}
                                    onClick={() => {
                                        if (trainRoute.departTimeOfDay != undefined) {
                                            setDepartTimeOfDay(undefined);
                                            setDepartTime(undefined);
                                        }
                                    }}
                                >
                                    {
                                        t("bookingPage.approximateTime")
                                    }
                                </MdToggleButton>

                            </div>
                        </MdMenu>
                    </div>

                    {
                        trainRoute.isRoundTrip && (
                            <div className={("trip-page-search-form-field" + (searchPending ? " --disabled" : ""))}
                                 style={{marginLeft: "6px", minWidth: "280px"}}>
                                <label className={(returnDateFocused || trainRoute.returnDate != null ? "--displaced" : "")}>
                                    {t("bookingPage.returnDate")}
                                </label>
                                <input
                                    className={(returnDateFocused ? "--focused" : "")}
                                    type="date"
                                    min={dateService.dateToStringForDatePicker(new Date()) + "T00:00:00"}
                                    value={trainRoute.returnDate ?? ""}
                                    onFocus={() => returnDateFocusHandler()}
                                    onBlur={() => returnDateBlurHandler()}
                                    onChange={(e) => setReturnDate(e.target.value)}
                                    disabled={searchPending}
                                />
                                {
                                    (trainRoute.returnDate != null && trainRoute.returnTimeOfDay == null) && (
                                        <input
                                            className={(returnDateFocused ? "--focused" : "")}
                                            type="time"
                                            value={trainRoute.returnTime ?? ""}
                                            onFocus={() => returnDateFocusHandler()}
                                            onBlur={() => returnDateBlurHandler()}
                                            onChange={(e) => setReturnTime(e.target.value)}
                                            disabled={searchPending}
                                        />
                                    )
                                }

                                {
                                    trainRoute.returnDate != null && (
                                        <div
                                            className={("trip-page-search-form-datetime-item" + (returnDateFocused ? " --hidden" : ""))}>
                                            <div className="trip-page-search-form-datetime-item-date">
                                                {
                                                    days[new Date(trainRoute.returnDate).getDay()]
                                                    + ", " + new Date(trainRoute.returnDate).getDate().toString().padStart(2, "0") + " "
                                                    + months[new Date(trainRoute.returnDate).getMonth()]
                                                }
                                            </div>
                                            <div className="trip-page-search-form-datetime-item-time">
                                                {
                                                    trainRoute.returnTimeOfDay == null
                                                        ? (
                                                            <span style={{marginLeft: "20px"}}>
                                                        {
                                                            trainRoute.returnTime != null ? trainRoute.returnTime.toString() : "00:00"
                                                        }
                                                    </span>
                                                        )
                                                        : (
                                                            (() => {
                                                                switch (trainRoute.returnTimeOfDay) {
                                                                    case TimeOfDay.Morning:
                                                                        return (
                                                                            <>
                                                                                <SunriseIcon
                                                                                    width="16px"
                                                                                    height="16px"
                                                                                />
                                                                                <span>
                                                                            04:00 - 12:00
                                                                        </span>
                                                                            </>
                                                                        )
                                                                    case TimeOfDay.Afternoon:
                                                                        return (
                                                                            <>
                                                                                <SunIcon
                                                                                    width="16px"
                                                                                    height="16px"
                                                                                />
                                                                                <span>
                                                                            10:00 - 16:00
                                                                        </span>
                                                                            </>
                                                                        )
                                                                    case TimeOfDay.Evening:
                                                                        return (
                                                                            <>
                                                                                <MoonIcon
                                                                                    width="16px"
                                                                                    height="16px"
                                                                                />
                                                                                <span>
                                                                            16:00 - 23:00
                                                                        </span>
                                                                            </>
                                                                        )
                                                                    default:
                                                                        return ""
                                                                }
                                                            })()
                                                        )
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                                <MdMenu
                                    behaviour={'none'}
                                    header={(
                                        <div className="train-return-time-type">
                                            {
                                                trainRoute.returnTimeOfDay == null
                                                    ? (
                                                        t("bookingPage.approximateTime")
                                                    )
                                                    : (
                                                        (() => {
                                                            switch (trainRoute.returnTimeOfDay) {
                                                                case TimeOfDay.Morning:
                                                                    return t("bookingPage.morning")
                                                                case TimeOfDay.Afternoon:
                                                                    return t("bookingPage.afternoon")
                                                                case TimeOfDay.Evening:
                                                                    return t("bookingPage.evening")
                                                                default:
                                                                    return ""
                                                            }
                                                        })()
                                                    )
                                            }
                                        </div>
                                    )}
                                    disabled={searchPending}
                                    style={{
                                        "root": {
                                            position: 'absolute',
                                            top: "8px",
                                            right: "0",
                                        }
                                    }}
                                >
                                    <div className="train-return-time-type-variants">

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "5px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "12px",
                                                color: (trainRoute.returnTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (trainRoute.returnTimeOfDay == TimeOfDay.Morning ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (trainRoute.returnTimeOfDay == TimeOfDay.Morning ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={trainRoute.returnTimeOfDay == TimeOfDay.Morning}
                                            onClick={() => {
                                                if (trainRoute.returnTimeOfDay != TimeOfDay.Morning) {
                                                    setReturnTimeOfDay(TimeOfDay.Morning);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.morning")
                                            }
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "5px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "12px",
                                                color: (trainRoute.returnTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (trainRoute.returnTimeOfDay == TimeOfDay.Afternoon ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (trainRoute.returnTimeOfDay == TimeOfDay.Afternoon ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={trainRoute.returnTimeOfDay == TimeOfDay.Afternoon}
                                            onClick={() => {
                                                if (trainRoute.returnTimeOfDay != TimeOfDay.Afternoon) {
                                                    setReturnTimeOfDay(TimeOfDay.Afternoon);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.afternoon")
                                            }
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "5px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "12px",
                                                color: (trainRoute.returnTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (trainRoute.returnTimeOfDay == TimeOfDay.Evening ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (trainRoute.returnTimeOfDay == TimeOfDay.Evening ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={trainRoute.returnTimeOfDay == TimeOfDay.Evening}
                                            onClick={() => {
                                                if (trainRoute.returnTimeOfDay != TimeOfDay.Evening) {
                                                    setReturnTimeOfDay(TimeOfDay.Evening);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.evening")
                                            }
                                        </MdToggleButton>

                                        <MdToggleButton
                                            buttonStyle={{
                                                flex: 1,
                                                minInlineSize: "fit-content",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "5px 10px",
                                                borderRadius: "4px",
                                                minWidth: "max-content",
                                                minHeight: "24px",
                                                maxHeight: "52px",
                                                fontSize: "12px",
                                                color: (trainRoute.returnTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                border: (trainRoute.returnTimeOfDay == undefined ? "1px solid rgb(62, 92, 184)" : "1px solid #CCCCCC"),
                                            }}
                                            inkStyle={{
                                                backgroundColor: (trainRoute.returnTimeOfDay == undefined ? "rgb(62, 92, 184)" : "#C1C1C1"),
                                                opacity: 0.1
                                            }}
                                            checked={trainRoute.returnTimeOfDay == undefined}
                                            onClick={() => {
                                                if (trainRoute.returnTimeOfDay != undefined) {
                                                    setReturnTimeOfDay(undefined);
                                                    setReturnTime(undefined);
                                                }
                                            }}
                                        >
                                            {
                                                t("bookingPage.approximateTime")
                                            }
                                        </MdToggleButton>

                                    </div>
                                </MdMenu>
                            </div>
                        )
                    }

                </div>

                <MdButton
                    type="button"
                    style={{
                        "button": {
                            position: "absolute",
                            bottom: "-20px",
                            right: "30px",
                            borderWidth: 0,
                            backgroundColor: (searchPending ? "#B83E50" : "#3E5CB8"),
                            color: "#FFFFFF",
                            borderRadius: "8px",
                            minWidth: "160px",
                            height: "40px",
                            padding: "5px 15px",
                            fontWeight: 500,
                            fontSize: "12px",
                            textTransform: "capitalize",
                            alignItems: "center",
                            justifyContent: "center"
                        },
                        "text": {
                            width: "auto",
                            flex: 0,
                            color: "#FFFFFF"
                        },
                        "ink": {
                            backgroundColor: "rgba(255,255,255,.5)"
                        }
                    }}
                    //disabled={trainRoute.departDate == null}
                    onClick={() => {
                        if (!searchPending) {
                            searchTrains()
                        } else {
                            cancelSearchTrains();
                        }
                    }}
                >
                    {
                        searchPending
                            ? (
                                t("bookingPage.stop")
                            )
                            : (
                                "Поиск поездов"
                            )
                    }
                </MdButton>

            </div>

            <div className="trip-page-search-tools">
                {
                    authState.isSupportUser && (
                        <>
                            <div className="trip-page-search-providers">
                                <span className="provider-list-label">{t("bookingPage.providers")}:</span>
                                {
                                    providers.map((provider, providerIndex) => {
                                        const isSelected = selectedProviders.includes(provider);
                                        return (
                                            <React.Fragment key={"provider" + Guid.newGuid()}>
                                                <MdToggleButton
                                                    buttonStyle={{
                                                        minInlineSize: "fit-content",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "2px 8px",
                                                        borderRadius: "4px",
                                                        minWidth: "28px",
                                                        minHeight: "24px",
                                                        maxHeight: "52px",
                                                        fontSize: "10px",
                                                        color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                    }}
                                                    inkStyle={{
                                                        backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                        opacity: 0.1
                                                    }}
                                                    checked={isSelected}
                                                    onClick={() => {
                                                        if (isSelected) {
                                                            setSelectedProviders(prev => [...prev.filter(x => x != provider)]);
                                                        } else {
                                                            setSelectedProviders(prev => [...prev, provider]);
                                                        }
                                                    }}
                                                >
                                                    {
                                                        provider
                                                    }
                                                </MdToggleButton>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>

                            {
                                (order != null && trains.length > 0) && (
                                    <MdButton
                                        type="button"
                                        style={{
                                            "button": {
                                                marginTop: "7px",
                                                border: "1px dashed rgb(175, 175, 175)",
                                                backgroundColor: "transparent",
                                                color: "rgb(0, 0, 0)",
                                                borderRadius: "14px",
                                                padding: "5px 15px",
                                                fontWeight: 500,
                                                fontSize: "10px",
                                                textTransform: "capitalize",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "30px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        //disabled={trainRoute.departDate == null}
                                        onClick={() => {
                                            sendSearchResultToTts()
                                        }}
                                    >
                                        Сохранить ответ
                                    </MdButton>
                                )
                            }

                        </>
                    )
                }
            </div>

            <SearchTrainsResultsComponent
                searchPending={searchPending}
                searchNotFound={searchNotFound}
                trains={trains}
                selectTrainHandler={selectTrain}
            />

        </React.Fragment>
    );
}