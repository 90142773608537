import axios, {CancelToken} from "axios";
import {useAppSelector} from "../redux/hooks";
import {RefsApi} from "../api/RefsApi";
import {IAviaCityInfo} from "../interfaces/IAviaCityInfo";
import {ITrainCityInfo} from "../interfaces/ITrainCityInfo";
import {store} from "../redux/store";


export function RefsService() {

  const refsApi = RefsApi();

  /**
   * Method for obtaining cities with airports by requested name
   * @param {string} text - requested text
   * @param {string} local - localization name
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<IAviaCityInfo[]>} list of cities. in case of errors return empty list.
   */
  const getAviaCities = async (text: string, local: string, ct: CancelToken = axios.CancelToken.source().token): Promise<IAviaCityInfo[]> => {
    try {
      const state = store.getState();
      const response = await refsApi.getAviaCities(text, local, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }

  /**
   * Method for obtaining cities with rail stations by requested name
   * @param {string} text - requested text
   * @param {string} local - localization name
   * @param {CancelToken} ct - cancellation token
   * @return {Promise<ITrainCityInfo[]>} list of cities. in case of errors return empty list.
   */
  const getTrainCities = async (text: string, local: string, ct: CancelToken = axios.CancelToken.source().token): Promise<ITrainCityInfo[]> => {
    try {
      const state = store.getState();
      const response = await refsApi.getTrainCities(text, local, state.auth.accessToken, ct);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return [];
    }
  }


  return {
    getAviaCities,
    getTrainCities
  };
}