import { Validator } from "fluentvalidation-ts";
import { ITokenRequest } from "../Requests/ITokenRequest";

export class LoginRequestValidator extends Validator<ITokenRequest> {
  constructor(t: (key: string) => string) {
    super();
    this.ruleFor("email")
      .notEmpty()
      .withMessage(t("emailIsEmpty"))
      .emailAddress()
      .withMessage(t("incorrectEmail"));
    this.ruleFor("password")
        .notEmpty()
        //.minLength(8)
        .withMessage(t("passwordIsEmpty"));
  }
}
