import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const WaitIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            style={style["svg"]}>
            <path
                d="M256,0c-11.782,0-21.333,9.551-21.333,21.333v64c0,11.782,9.551,21.333,21.333,21.333     c11.782,0,21.333-9.551,21.333-21.333V43.72c107.802,10.704,192,101.662,192,212.28c0,117.818-95.515,213.333-213.333,213.333     S42.667,373.818,42.667,256c0-55.218,21.036-107.082,58.178-146.421c8.089-8.567,7.701-22.069-0.866-30.157     c-8.567-8.089-22.069-7.701-30.157,0.866C25.273,127.47,0,189.781,0,256c0,141.382,114.618,256,256,256s256-114.618,256-256     S397.382,0,256,0z"
                style={style["path"]}
            />
            <path
                d="M240.919,271.079c8.331,8.331,21.839,8.331,30.17,0s8.331-21.839,0-30.17l-85.333-85.333     c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17L240.919,271.079z"
                style={style["path"]}
            />
        </svg>
    )
}