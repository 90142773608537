import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import {ErrorMessage} from "../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {AuthService} from "../../../services/AuthService";
import {MdTextField} from "../../../components/md-text-field";
import {MdButton} from "../../../components/md-button";
import {CircleSpinnerIcon} from "../../../icons/CircleSpinnerIcon";
import "./style.scss";
import {useTranslation} from "react-i18next";


export function RecoveryPasswordPage() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [submitPending, setSubmitPending] = useState(false);
    const [recoveryError, setRecoveryError] = useState("");
    const [recoveryCompleted, setRecoveryCompleted] = useState(false);


    const authService = AuthService();


    const submitHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        setRecoveryError("");
        setSubmitPending(true);

        if (email === "") {
            setSubmitPending(false);
            setError("поле не заполнено");
            return;
        } else if (!isValidEmail(email)) {
            setSubmitPending(false);
            setError("не корректный почтовый адрес");
            return;
        }

        const result = await authService.recoveryPassword(email);

        if (result) {
            setRecoveryCompleted(true);
        } else {
            setSubmitPending(false);
            setRecoveryError('Не удалось выполнить сброс пароля');
        }
    }


    const isValidEmail = (email: string) => {
        //return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        //return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
        return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email);
    }


    useEffect(() => {
    }, []);


    return (
        <>
            {
                !recoveryCompleted
                    ? (
                        <form className="recovery-form">
                            <div className="recovery-form-header">{t("recoveryPage.resetPassword")}</div>

                            <div className="recovery-form-body">

                                <div className="recovery-form-error-message">
                                    {recoveryError && <ErrorMessage error={recoveryError}/>}
                                </div>

                                <MdTextField
                                    type="email"
                                    required={true}
                                    label={t("email")}
                                    value={email}
                                    invalid={error.length !== 0}
                                    errorMessage={error}
                                    disabled={submitPending}
                                    onChange={(event) => {
                                        setError("");
                                        setEmail(event.target.value);
                                    }}
                                />

                                <MdButton
                                    type="submit"
                                    style={{
                                        "button": {
                                            width: "100%",
                                            height: "40px",
                                            fontSize: "14px",
                                            backgroundColor: "#3E5CB8",
                                            color: "#FFFFFF",
                                        }
                                    }}
                                    disabled={submitPending}
                                    onClick={(event) => submitHandler(event)}
                                >

                                    {
                                        submitPending
                                            ? (
                                                <CircleSpinnerIcon
                                                    width="18px"
                                                    height="18px"
                                                    style={{
                                                        "circle": {
                                                            stroke: "#FFFFFF"
                                                        }
                                                    }}
                                                />
                                            )
                                            : (
                                                t("recoveryPage.resetPassword")
                                            )
                                    }
                                </MdButton>

                            </div>
                        </form>
                    )
                    : (
                        <div className="recovery-complete-message">
                            <div>
                                Сброс пароля успешно завершен. На вашу почту отправлено письмо с ссылкой для изменения пароля.
                            </div>
                            <MdButton
                                type="submit"
                                style={{
                                    "button": {
                                        margin: "auto",
                                        marginTop: "10px",
                                        padding: "0 20px",
                                        height: "40px",
                                        fontSize: "12px",
                                        backgroundColor: "#3E5CB8",
                                        color: "#FFFFFF",
                                    }
                                }}
                                onClick={(event) => navigate("/")}
                            >
                                {t("recoveryPage.toHomePage")}
                            </MdButton>
                        </div>
                    )
            }
        </>
    );
}
