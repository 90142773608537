import {axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_ADD_OR_UPDATE_COMPANY,
    ROUTE_GET_COMPANY_INFO,
    ROUTE_SEARCH_COMPANIES_BY_NAME,
    ROUTE_GET_COMPANY_BY_CODE,
    ROUTE_GET_ALL_COMPANIES,
    ROUTE_IMPORT_COMPANY,
    ROUTE_IS_COMPANY_EXIST,
    ROUTE_GET_USERS,
    ROUTE_GET_USER_ROLES,
    ROUTE_GET_COMPANY_ROLES,
    ROUTE_ADD_USER,
    ROUTE_UPDATE_USER,
    ROUTE_GET_ALLOWED_PERSONS,
    ROUTE_GET_USER_ROLES_IN_COMPANY,
} from "../constants/routeConstants/ApiRouteConstants";
import {SearchClientFees} from "../responces/SearchClientFees";
import {ICompany} from "../interfaces/companyInfoInterfaces/ICompany";
import {IAddCompanyRequest} from "./request/IAddCompanyRequest";
import {AxiosResponse, CancelToken} from "axios";
import {IImportCompanyRequest} from "./request/IImportCompanyRequest";
import {ICustomerEmployee} from "../interfaces/reportInterfaces/customerInterfaces";
import {
    IAddCustomerEmployeeRequest,
    ICompanyUserRoles,
} from "../interfaces/requestsInterfaces/reportRequests";
import {IAllowedPerson} from "../interfaces/companyInfoInterfaces/IAllowedPerson";


export function CompanyApi() {

    /**
     * GET method for checking the existence of a company
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<boolean>>} response with the result of the checking
     */
    const isCompanyExists = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<boolean>> => {
        return await axiosInstance.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IS_COMPANY_EXIST,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining company data
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompany>>} response with company data
     */
    const getCompany = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompany>> => {
        return  await axiosInstance.get<ICompany>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_BY_CODE,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code,
                },
            }
        );
    };

    /**
     * GET method for obtaining information about a company
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompany>>} response with information about a company
     */
    const getCompanyInfo = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompany>> => {
        return await axiosInstance.get<ICompany>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_INFO,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining a list of companies by name
     * @param {string} name - company name
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompany[]>>} response with a list of companies
     */
    const searchCompaniesByName = async (name: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompany[]>> => {
        return await axiosInstance.get<ICompany[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_SEARCH_COMPANIES_BY_NAME,
            {
                    headers: {
                        Authorization: BEARER + accessToken
                    },
                    params: {
                        name: name
                    }
            }
        );
    };

    /**
     * GET method for obtaining a list of companies
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompany[]>>} response with a list of companies
     */
    const getAllCompanies = async (accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompany[]>> => {
        return await axiosInstance.get<ICompany[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALL_COMPANIES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    }

    /**
     * PUT method for add or update company
     * @param {IAddCompanyRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompany>>} response with the company data
     */
    const addOrUpdateCompany = async (request: IAddCompanyRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompany>> => {
        return await axiosInstance.put<ICompany>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_OR_UPDATE_COMPANY,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method for add or update company
     * @param {IImportCompanyRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompany>>} response with the company data
     */
    const importCompany = async (request: IImportCompanyRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompany>> => {
        return await axiosInstance.put<ICompany>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_IMPORT_COMPANY,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining the allowed persons of a company
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<IAllowedPerson[]>>} response with a list of allowed persons of a company
     */
    const getAllowedPersons = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<IAllowedPerson[]>> => {
        return await axiosInstance.get<IAllowedPerson[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_ALLOWED_PERSONS, {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    clientCode: code
                },
                cancelToken: ct
            }
        );
    }

    /**
     * GET method for obtaining list of company roles
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyUserRoles[]>>} response with list of company roles
     */
    const getCompanyRoles = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyUserRoles[]>> => {
        return await axiosInstance.get<ICompanyUserRoles[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_COMPANY_ROLES,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code
                },
                cancelToken: ct
            }
        );
    }


    /**
     * PUT method for add company employee
     * @param {IAddCustomerEmployeeRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICustomerEmployee>>} response with the employee data
     */
    const addEmployee = async (request: IAddCustomerEmployeeRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICustomerEmployee>> => {
        return await axiosInstance.put<ICustomerEmployee>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_USER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * PUT method for update company employee
     * @param {IAddCustomerEmployeeRequest} request - request data
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICustomerEmployee>>} response with the employee data
     */
    const updateEmployee = async (request: IAddCustomerEmployeeRequest, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICustomerEmployee>> => {
        return await axiosInstance.put<ICustomerEmployee>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_UPDATE_USER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET Method for obtaining list of company employees
     * @param {string} code - company code
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<>ICustomerEmployee[]>} response with list of company employees
     */
    const getEmployees = async (code: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICustomerEmployee[]>> => {
        return await axiosInstance.get<ICustomerEmployee[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USERS,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                    Auth: 123
                },
                params: {
                    code: code,
                },
                cancelToken: ct
            }
        );
    }

    /**
     * GET method for obtaining list of employee roles
     * @param {string} email - user email
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyUserRoles[]>>} response with list of employee roles
     */
    const getEmployeeRoles = async (email: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyUserRoles[]>> => {
        return await axiosInstance.get<ICompanyUserRoles[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USER_ROLES,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    email: email,
                },
                cancelToken: ct
            }
        );
    };

    /**
     * GET method for obtaining list of employee roles
     * @param {string} companyCode - company code
     * @param {string} email - user email
     * @param {string} accessToken - access token
     * @param {CancelToken} ct - cancellation token
     * @return {Promise<AxiosResponse<ICompanyUserRoles[]>>} response with list of employee roles
     */
    const getEmployeeRolesInCompany = async (companyCode: string, email: string, accessToken: string, ct: CancelToken): Promise<AxiosResponse<ICompanyUserRoles[]>> => {
        return await axiosInstance.get<ICompanyUserRoles[]>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USER_ROLES_IN_COMPANY,
            {
                headers: {
                    Authorization: BEARER + accessToken
                },
                params: {
                    code: companyCode,
                    email: email,
                },
                cancelToken: ct
            }
        );
    };


    // TODO: check if necessary
    const addUserToCompany = async () => {
        const clientCode = "Ф0009867";
        const result = await axiosInstance.post<SearchClientFees>(
            "/api/company/addUser/" + clientCode,
            {
                email: "Some@email.com",
                firstName: "Mike",
                lastName: "Vasovski",
                patronymic: "Petrovich",
                position: "Sale Manageer",
                roleName: "sf",
            }
        );
        if (result.status === 200) {
            //return result.status;
        }
        return result.status;
    };


    return {
        isCompanyExists,
        getCompany,
        getCompanyInfo,
        searchCompaniesByName,
        getAllCompanies,
        addOrUpdateCompany,
        importCompany,
        getAllowedPersons,
        getCompanyRoles,

        addEmployee,
        updateEmployee,
        getEmployees,
        getEmployeeRoles,
        getEmployeeRolesInCompany,

        addUserToCompany, // TODO: check if necessary
    };
}