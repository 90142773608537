import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const ScheduleIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 100 100"
            fill="none"
            style={style["svg"]}>

            <path
                d="M42,23c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S39.8,23,42,23z M42,17c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2    S40.9,17,42,17z"
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M29,23c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S26.8,23,29,23z M29,17c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2    S27.9,17,29,17z"
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M16,15c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S18.2,15,16,15z M16,21c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S17.1,21,16,21z    "
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M68,23c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S65.8,23,68,23z M68,17c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2    S66.9,17,68,17z"
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M55,23c2.2,0,4-1.8,4-4s-1.8-4-4-4s-4,1.8-4,4S52.8,23,55,23z M55,17c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2    S53.9,17,55,17z"
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M80,39C80,39,80,39,80,39l0-13V16c0-2.8-2.2-5-5-5H9c-2.8,0-5,2.2-5,5v10v58c0,2.8,2.2,5,5,5h60c0,0,0.1,0,0.1,0    c0.3,0,0.6,0,0.9,0c14.3,0,26-11.7,26-26C96,52.2,89.4,42.9,80,39z M70,37c-2.8,0-5.5,0.4-8,1.3V27h16v11.3    C75.5,37.4,72.8,37,70,37z M24,72V58h17v14H24z M41,74v13H24V74H41z M41,27v13H24V27H41z M24,42h17v14H24V42z M22,56H6V42h16V56z     M22,40H6V27h16V40z M6,58h16v14H6V58z M43,58h1.5c-0.3,1.6-0.5,3.3-0.5,5c0,3.2,0.6,6.2,1.6,9H43V58z M45,56h-2V42h11.7    C50,45.4,46.6,50.3,45,56z M57.9,40H43V27h17v12c0,0,0,0,0,0C59.3,39.3,58.6,39.6,57.9,40z M6,16c0-1.7,1.3-3,3-3h66    c1.7,0,3,1.3,3,3v9H6V16z M6,84V74h16v13H9C7.3,87,6,85.7,6,84z M43,87V74h3.4C49.2,79.8,54,84.5,60,87H43z M70,87    c-13.2,0-24-10.8-24-24s10.8-24,24-24s24,10.8,24,24S83.2,87,70,87z"
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M70,43c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S81,43,70,43z M71,80.9V79c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.9    c-9.1-0.5-16.4-7.8-16.9-16.9H54c0.6,0,1-0.4,1-1s-0.4-1-1-1h-1.9c0.5-9.1,7.8-16.4,16.9-16.9V47c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.9    c9.1,0.5,16.4,7.8,16.9,16.9H86c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.9C87.4,73.1,80.1,80.4,71,80.9z"
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M38.7,44.3c-0.4-0.4-1-0.4-1.4,0l-7.1,7.1l-2.3-3.9c-0.3-0.5-0.9-0.6-1.4-0.3s-0.6,0.9-0.3,1.4l3,5    c0.2,0.3,0.4,0.4,0.7,0.5c0,0,0.1,0,0.1,0c0.3,0,0.5-0.1,0.7-0.3l8-8C39.1,45.3,39.1,44.7,38.7,44.3z"
                fill="#000000"
                style={style["path"]}
            />

            <path
                d="M83,62h-9.1c-0.4-1.4-1.5-2.5-2.9-2.9V53c0-0.6-0.4-1-1-1s-1,0.4-1,1v6.1c-1.7,0.4-3,2-3,3.9c0,2.2,1.8,4,4,4    c1.9,0,3.4-1.3,3.9-3H83c0.6,0,1-0.4,1-1S83.6,62,83,62z M70,65c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S71.1,65,70,65z"
                fill="#000000"
                style={style["path"]}
            />
        </svg>
    )
}