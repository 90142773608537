import "./style.scss";
import {useTranslation} from "react-i18next";
import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {TravelVariantComponent} from "../travelVariantComponent";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {setFavoriteVariantIds, setFavoriteVariants} from "../../../../redux/orderSlice";

type SearchResultProps = {
    selectedVariants: number[];
    filteredVariants: ITravelVariant[];
    openBookDialog?: (id: number) => void
    favoriteVariants?:number[]
};

export function SearchResultComponent(props: SearchResultProps) {
    const {t} = useTranslation();
    const orderState = useAppSelector(state => state.order);
    const dispatch = useAppDispatch();

    const addToFavoriteHandler = (trip:ITravelVariant) => {
        dispatch(setFavoriteVariants([...orderState.favoriteVariants, trip]))
        dispatch(setFavoriteVariantIds([...orderState.favoriteVariantIds, trip.id]))
    }
    const removeFromFavoriteHandler = (trip:ITravelVariant) => {
        dispatch(setFavoriteVariants(orderState.favoriteVariants.filter(fv => fv.id !== trip.id)));
        dispatch(setFavoriteVariantIds(orderState.favoriteVariantIds.filter(fi=>fi!==trip.id)))
    }

    const isFavoriteHandler = (id:number)=> {
        if(orderState.favoriteVariantIds===undefined)return false;
        return orderState.favoriteVariantIds.some(fi=>fi===id);
    }

    return (
        <>
            <div className=" ">
                <div className="search-result-container-form">
                    <div className=" d-flex flex-row flex-wrap gap-1 search-result-container-with-filters p-2">
                        <h5>Favorite variants {orderState.favoriteVariants && orderState.favoriteVariants.length}</h5>
                        <h5 className=" align-self-center">{t("searchPage.searchResult") + "(" + props.filteredVariants.length + ")"}:</h5>
                        {props.filteredVariants && props.filteredVariants.map((item, index) => (
                            <TravelVariantComponent key={index}
                                                    travelVariant={item}
                                                    openBookWindow={props.openBookDialog}
                                                    isFavorite={isFavoriteHandler(item.id)}
                                                    removeFromFavorite={removeFromFavoriteHandler}
                                                    addToFavorite={addToFavoriteHandler}

                            />))}
                    </div>
                </div>
            </div>
        </>
    );
}
