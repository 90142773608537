import {InputTextWithPopoverComponent} from "../../../../../components/md-input-text-popover";
import {useState} from "react";
import {MdInputDate} from "../../../../../components/md-input-date";
import {DownIcon} from "../../../../../icons/DownIcon";
import {IPax} from "../../../../../interfaces/orderInterfaces/IPax";
import "./style.scss";

interface IHotelRoom {
    guestCount?: number
    adultsCount?: number
    childrenAges?: number[];
    paxes?: IPax[]
}

export function SearchHotelComponent() {
    const [city, setCity] = useState<string>('');
    const [searchCityResult, setSearchCityResult] = useState<string[]>([]);
    const [checkInDate, setCheckInDate] = useState<string>('');
    const [checkOutDate, setCheckOutDate] = useState<string>('');
    const [roomCount, setRoomCount] = useState<number>(2);
    const [hotelRooms, setHotelRooms] = useState<IHotelRoom[]>([{adultsCount: 1}]);

    //testData
    const [cities, setCities] = useState<string[]>([
        "москва",
        "Санкт-Петербург",
        "Владивосток",
        "мосты",
        "можайск",
        "Самара",
        "Омск",
        "махачкала",
    ]);

    return (
        <div className="search-hotel-row-item">
            <InputTextWithPopoverComponent value={city} placeholder={"input place"} popoverItems={cities}
                                           setValue={setCity}
                                           runItemsSearch={() => {
                                               console.log("SearchHotelComponent")
                                           }} clearPopoverItemsResult={() => {
                setCity("")
            }}/>
            <MdInputDate setDateHandler={setCheckInDate} inputDateISO={checkInDate} dateLabel={"check-in"}/>
            <MdInputDate setDateHandler={setCheckOutDate} inputDateISO={checkOutDate} dateLabel={"check-out"}/>
            {/*<div>Количество номеров</div>*/}
            <div className="select-hotel-room-container">
                <label>{roomCount}</label>
                <div style={{fontSize: "1rem"}}>  {roomCount} Номера для 1
                    <DownIcon iconSize="25px"/>
                </div>
                <div className="select-room-action-container">
                    {hotelRooms.length > 0 && hotelRooms.map((item, index) => (
                        <div key={"hotelRoom_" + index}>
                            <div> Room {index + 1}</div>
                            <div className="d-flex flex-row">
                                <div>Adults {index + 1}</div>
                                <div>Children</div>
                            </div>

                        </div>
                    ))}
                    <div className="d-flex flex-row">
                        <div>Add Room</div>
                        <button onClick={() => {
                            console.log("clicked")
                        }}>Ready
                        </button>
                    </div>


                </div>
            </div>
        </div>)
}