import {IStorageKey} from "./IStorageKey";

export interface IOrderInfo {
    id: string;
    orderNo: string;
    orderTitle: string;
    comment: string;
    items: any[];
}


export interface IOrder {
    id: string;
    createdAt: string;
    orderNo: string;
    status: OrderStatus;
    evaParse: any;
}

export enum OrderStatus {
    PreOrder, //не определен
    Booked, //забронировано
    Issued, //выписано
    Canceled, //отменено/завойдировано
    Completed, //выполнено,
    Deleted
}

export interface IOrderBookingDetails {
    aviaBooking: Array<{
        aviaBooking: {
            bookingFile: {
                reservations: {
                    reservation: Array<{
                        recordLocator: string,
                        timeLimit: string,
                        products: {
                            airTicket: Array<{
                                token: string,
                                carrier: {
                                    code: string,
                                    name: string
                                },
                                eticket: number,
                                passenger: {
                                    passport: {
                                        firstName: string,
                                        lastName: string,
                                        middleName: string,
                                        citizenship: {
                                            code: string,
                                            name: string
                                        },
                                        issued: string,
                                        expired: string,
                                        number: string,
                                        type: string,
                                        birthday: string,
                                        gender: string
                                    },
                                    type: string,
                                    phoneType: string,
                                    phoneNumber: string,
                                    countryCode: string,
                                    areaCode: string,
                                    email: string,
                                    isEmailRefused: boolean,
                                    loyaltyCard: {
                                        id: string,
                                        carrier: {
                                            code: string,
                                            name: string
                                        }
                                    }
                                },
                                itineraries: {
                                    itinerary: Array<{
                                        flights: {
                                            flight: Array<{
                                                token: string,
                                                segments: {
                                                    segment: [
                                                        {
                                                            token: string,
                                                            serviceClass: string,
                                                            bookingClass: string,
                                                            fareBasis: string,
                                                            brandName: string,
                                                            carrier: {
                                                                code: string,
                                                                name: string
                                                            },
                                                            marketingCarrier: {
                                                                code: string,
                                                                name: string
                                                            },
                                                            operatingCarrier: {
                                                                code: string,
                                                                name: string
                                                            },
                                                            equipment: {
                                                                code: string,
                                                                name: string
                                                            },
                                                            methLocomotion: string,
                                                            dateBegin: string,
                                                            dateEnd: string,
                                                            flightNumber: string,
                                                            locationBegin: {
                                                                code: string,
                                                                name: string
                                                            },
                                                            locationEnd: {
                                                                code: string,
                                                                name: string
                                                            },
                                                            starting: boolean,
                                                            connected: boolean,
                                                            travelDuration: number,
                                                            baggage: {
                                                                type: string,
                                                                allow: string,
                                                                value: string,
                                                                descriptions: string[]
                                                            },
                                                            regLocator: string,
                                                            landings: []
                                                        }
                                                    ]
                                                }
                                            }>
                                        }
                                    }>
                                }
                            }>
                        }
                    }>
                }
            }
        },
        flightGroup: {
            carrier: {
                code: string,
                name: string
            }
        },
    }>,
    trainBooking: Array<{
        bookingResponse: {
            bookingFile: {
                reservations: Array<{
                    bookingNumber: string,
                    timeLimit: string,
                    products: {
                        railwayTicket: Array<{
                            issueDate: string,
                            passengers: Array<{
                                type: string,
                                passport: {
                                    number: string,
                                    issued: string,
                                    gender: string,
                                    birthday: string,
                                    firstName: string,
                                    lastName: string,
                                    middleName: string,
                                }
                            }>,
                            segments: Array<{
                                trainNumber: string,
                                carriageNumber: string,
                                carriageType: string,
                                compartmentType: string,
                                serviceClass: string,
                                operatorName: string,
                                vendorName: string,
                                departureDate: string,
                                localDepartureDate: string,
                                arriveDate: string,
                                localArrivalDate: string,
                                departureStation: {
                                    station: {
                                        code: string,
                                        name: string,
                                        description: string,
                                        utcTimeOffset: number
                                    }
                                },
                                arriveStation: {
                                    station: {
                                        code: string,
                                        name: string,
                                        description: string,
                                        utcTimeOffset: number
                                    }
                                },
                                equivalentFare: number,
                                carriageAtrs: string,
                                timeInfo: string,
                                eRegEnabled: boolean,
                                hasEReg: boolean,
                                ticketType: string,
                                trainCategory: string,
                                tariffCode: string,
                                places: string[],
                                placesType: string,
                                serviceClassName: string,
                            }>
                        }>
                    }
                }>
            }
        }
    }>
}

export interface IOrderIssueDetails {
    orderNo: string,
    bsoList: Array<{
        bsoNo: string,
        tickets: Array<{
            ticketNo: string,
            paxFullName: string,
            pnr: string,
            sumTotal: number,
            airCompanyCode: string,
            baggageInfo: string,
            flightNo: string,
            tarifMask: string,
            classOfService: string,
            departDate: string,
            arriveDate: string,
            departCode: string,
            arriveCode: string
        }>
    }>
}

export interface IOrderTicketPdf {
    key: IStorageKey;
    fileName: string;
}