import "./style.scss";
import {ITravelVariant} from "../../../../interfaces/orderInterfaces/ITravelVariant";
import {TravelVariantComponent} from "../travelVariantComponent";
import {MdButton} from "../../../../components/md-button";
import {IPax} from "../../../../interfaces/orderInterfaces/IPax";
import {SelectPaxesComponent} from "../selectPaxesComponent";
import {COMMON_BUTTON_DISABLE_STYLE, COMMON_BUTTON_STYLE} from "../../../../constants/componentsContants";
import {useEffect, useState} from "react";
import {BookSteps} from "../../../../enums/BookSteps";
import {OrderService} from "../../../../services/OrderServices";
import {BookSummaryComponent} from "../bookSummaryComponent";
import {ErrorMessage} from "../../../commonComponents/ErrorComponents/errorMessage/ErrorMessage";
import {TicketType} from "../../../../enums/TicketType";
import {useAppSelector} from "../../../../redux/hooks";

type BookProps = {
    id: number
    travelVariant: ITravelVariant
    paxes?: IPax[]
}

export function BookComponent(props: BookProps) {
    const {bookFlight,bookTrain,getAllPaxes} = OrderService();
    const paxes= useAppSelector(state=>state.order.paxes)
    const [currentAction, setCurrentAction] = useState(BookSteps.SelectTicket);
    const [activeTariff, setActiveTariff] = useState(-1);
    const [selectedPaxes, setSelectedPaxes] = useState<IPax[]>([])
    const [error, setError] = useState("")

    const bookHandler = async () => {
        const clientCode2 = "Ф0010326";
        let bookResult = false;
        if(props.travelVariant.ticketType === TicketType.AviaTicket) {
             bookResult = await bookFlight(props.travelVariant, activeTariff, selectedPaxes, clientCode2)
        }
        if(props.travelVariant.ticketType === TicketType.TrainTicket) {}
         bookResult = await bookTrain(props.travelVariant, activeTariff, selectedPaxes, clientCode2)
    }

    const isActiveStep = (stepName: number) => {
        if (currentAction === stepName) {
            return "arrow-button active-step"
        } else {
            return "arrow-button inactive-step"
        }
    }
    const changeStepHandler = (stepNumber: number) => {
        console.log(`changeStepHandler clear Error`)
        let errMsg = "";
        setError("");
        if (stepNumber === currentAction) {
            return
        }
        if (stepNumber > 2) {
            //check errors
            errMsg = activeTariff < 0 ? "Please select tariff." : "";
            errMsg += selectedPaxes.length===0?" / Please, select one or more passengers.":"";
        }

        if (errMsg === "") {
            setCurrentAction(stepNumber);
            return;
        } else {
            setError(errMsg)
            return;
        }
    }

    const updatePaxesListHandler = async ()=>{

        console.log("update Paxes list Handler");
        await getAllPaxes("Ф0013808")
    }

    const isVisibleStep = (stepName: number) => stepName === currentAction;
    useEffect(() => {

    }, [paxes]);

    return (
        <div className="order-book-container">
            <div className="order-book-step-container">
                <div className={isActiveStep(BookSteps.SelectTicket)}
                     onClick={() => changeStepHandler(BookSteps.SelectTicket)}>Выбор билета
                </div>
                <div className={isActiveStep(BookSteps.SelectPaxes)}
                     onClick={() => changeStepHandler(BookSteps.SelectPaxes)}>Выбор пассажиров
                </div>
                <div className={isActiveStep(BookSteps.BookTicket)}
                     onClick={() => changeStepHandler(BookSteps.BookTicket)}>Бронирование
                </div>
                <div className={isActiveStep(BookSteps.IssueTicket)}
                     onClick={() => changeStepHandler(BookSteps.IssueTicket)}>Выписка
                </div>
            </div>

            <div className="order-book-content-container">
                {error && <div className="order-book-step-container">
                    <ErrorMessage error={error}/>
                </div>}
                {isVisibleStep(BookSteps.SelectTicket) && <TravelVariantComponent //index={props.index}
                                                                                  travelVariant={props.travelVariant}
                                                                                  isBookingMode={true}
                                                                                  selectTariff={setActiveTariff}
                                                                                  selectedTariff={activeTariff}
                />}

                {isVisibleStep(BookSteps.SelectPaxes) &&
                    <SelectPaxesComponent
                        allPaxesList={paxes}
                        selectedPaxesList={selectedPaxes}
                        updateSelectedPaxes={(paxes:IPax[])=>setSelectedPaxes(paxes)}
                        getAllPaxes={updatePaxesListHandler}
                    />}

                {isVisibleStep(BookSteps.BookTicket) &&
                    <BookSummaryComponent
                        selectedVariant={props.travelVariant}
                        selectedTariff={activeTariff}
                        selectedPaxes={selectedPaxes}
                    />
                }
                {isVisibleStep(BookSteps.BookTicket) &&
                    <span onClick={() => bookHandler()}><MdButton style={COMMON_BUTTON_STYLE} children={<p
                        style={{color: "white"}}>Book</p>}></MdButton></span>}

            </div>
            <div className="d-flex flex-row gap-2">

                {currentAction > BookSteps.SelectTicket &&
                    <span onClick={() => {
                        changeStepHandler((currentAction - 1))
                    }}><MdButton type="button" children={<p style={{color: "white"}}>&#60;&#60; Назад</p>}
                                 style={currentAction > BookSteps.SelectTicket ? COMMON_BUTTON_STYLE : COMMON_BUTTON_DISABLE_STYLE}/></span>}
                {currentAction < BookSteps.BookTicket &&
                    <span onClick={() => {
                        changeStepHandler((currentAction + 1))
                    }}><MdButton type="button" children={<p style={{color: "white"}}>Дальше &#62;&#62;</p>}
                                 style={COMMON_BUTTON_STYLE}/></span>}

            </div>
        </div>
    )
}