import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../redux/hooks";
import {
    ModalContext,
    NEW_TRIP_MODAL_ID,
} from "../../../../commonComponents/modal/ModalContext";
import {ITrainTariffInfo, ITrainVariant} from "../../../../../interfaces/ITrainVariant";
import {IAviaVariant, IAviaTariffInfo} from "../../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {IPassenger} from "../../../../../interfaces/IPassenger";
import {ICompany} from "../../../../../interfaces/companyInfoInterfaces/ICompany";
import { IOrder } from "../../../../../interfaces/IOrderInfo";
import {CompanyService} from "../../../../../services/CompanyService";
import {MdButton} from "../../../../../components/md-button";
import {MdModal} from "../../../../../components/md-modal";
import {MdScrollViewer} from "../../../../../components/md-scroll-viewer";
import {MdSlider} from "../../../../../components/md-slider";
import {MdSelect} from "../../../../../components/md-select";
import {FlightComponent} from "../../flightComponent";
import {TrainComponent} from "../../trainComponent";
import {RecycleBinIcon} from "../../../../../icons/RecycleBin";
import {CircleSpinnerIcon} from "../../../../../icons/CircleSpinnerIcon";
import {CalendarIcon} from "../../../../../icons/CalendarIcon";
import {UserIconFilled} from "../../../../../icons/UserIconFilled";
import {CompanyIcon} from "../../../../../icons/CompanyIcon";
import {RefreshIcon} from "../../../../../icons/RefreshIcon";
import {Guid, Utils} from "../../../../../utils/utils";
import "./style.scss"


interface ITripBuilderModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,

    order?: IOrder;

    openCompanySelectionModal: () => void;
    selectedCompany: ICompany | undefined,
    setSelectedCompany: React.Dispatch<React.SetStateAction<ICompany | undefined>>,

    openTicketSearchModalHandler: () => void;
    // TODO: find a way to change tariff without re-rendering tickets list
    tripItemsInfo: {
        id: string,
        type: 'avia' | 'train',
        variant: IAviaVariant | ITrainVariant,
        tariff: IAviaTariffInfo | ITrainTariffInfo,
    }[],
    removeItemFromTrip: (variant: IAviaVariant | ITrainVariant) => void;

    changeTripItemTariff: (variantId: string, tariff: IAviaTariffInfo | ITrainTariffInfo) => void;

    openPassengersSelectionModal: () => void;
    passengers: { passenger: IPassenger, passport: any }[];
    setPassengers: React.Dispatch<React.SetStateAction<{ passenger: IPassenger, passport: any }[]>>,
    removePassengersFromOrder: (passengers: { passenger: IPassenger, passport: any }[]) => void;

    reset: () => void;
    submitHandler: () => Promise<boolean>;
}


export function TripBuilderModal(props: ITripBuilderModal) {

    const {
        opened,
        setOpened,

        order,

        openCompanySelectionModal,
        selectedCompany,
        setSelectedCompany,

        openTicketSearchModalHandler,
        tripItemsInfo,
        removeItemFromTrip,

        changeTripItemTariff,

        openPassengersSelectionModal,
        passengers,
        setPassengers,
        removePassengersFromOrder,

        reset,
        submitHandler
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);

    const {isOpen, open, close} = useContext(ModalContext);


    const companyService = CompanyService();


    const [companyChecking, setCompanyChecking] = useState<boolean>(false);
    const [companyExist, setCompanyExist] = useState<boolean>(false);
    const [companyCheckingError, setCompanyCheckingError] = useState<boolean>(false);

    const [activeSlide, setActiveSlide] = useState(0);

    const [bookingPending, setBookingPending] = useState(false);
    const [bookingComplete, setBookingComplete] = useState(false);
    const [bookingError, setBookingError] = useState(false);


    const onModalOpen = async () => {
        if (!opened) {
            setOpened(true);
        }
        reset();
        setBookingComplete(false);
        setActiveSlide(0);
        await checkCompany();
    }

    const onModalClose = () => {
        if (opened) {
            setOpened(false);
        }
        reset();
        setBookingComplete(false);
    }


    const checkCompany = async () => {
        setCompanyCheckingError(false);
        if (selectedCompany != null) {
            setCompanyChecking(true);
            let isCompanyExists = await companyService.isCompanyExists(selectedCompany.code);
            if (!isCompanyExists) {
                await companyService.importCompany(selectedCompany);
                isCompanyExists = await companyService.isCompanyExists(selectedCompany.code);
            }
            if (!isCompanyExists) {
                setCompanyCheckingError(true);
            }
            setCompanyExist(isCompanyExists);
            setCompanyChecking(false);
            return isCompanyExists;
        }
        return false;
    }


    const setPassengerPassport = (passenger: IPassenger, passport: any) => {
        console.warn(passenger, passport);
        setPassengers(prev => [
            ...prev.map(x => {
                if (x.passenger.paxCode == passenger.paxCode) {
                    console.log({
                        passenger: passenger,
                        passport: passport
                    });
                    return {
                        passenger: passenger,
                        passport: passport
                    };
                } else {
                    console.log(x);
                    return x;
                }
            })
        ])
    }


    const onSubmit = async () => {
        setBookingError(false)
        setBookingPending(true);
        setBookingComplete(false);

        const companyCheckResult = await checkCompany();

        if (companyCheckResult) {
            let result = await submitHandler();
            setBookingPending(false);
            setBookingError(!result);
            setBookingComplete(result);
        } else {
            setBookingPending(false);
            setBookingError(true);
        }
    }


    useEffect(() => {
        if (isOpen(NEW_TRIP_MODAL_ID) && !opened) {
            close(NEW_TRIP_MODAL_ID);
        } else if (!isOpen(NEW_TRIP_MODAL_ID) && opened) {
            open(NEW_TRIP_MODAL_ID);
        }
    }, [opened]);

    useEffect(() => {
        setPassengers([]);
        checkCompany();
    }, [selectedCompany]);

    useEffect(() => {

    }, []);


    return (
        <MdModal
            id={NEW_TRIP_MODAL_ID}
            title={
                (t("bookingPage.orderCreating") + (order != null ? " №" + order.orderNo : ""))
            }
            openHandler={onModalOpen}
            closeHandler={onModalClose}
            closeOnClickOutside={false}>

            <MdSlider
                header={
                    <div className="trip-builder-form-header">
                        {
                            companyChecking && (
                                <div className="trip-builder-form-company-check">
                                    <CircleSpinnerIcon
                                        width="18px"
                                        height="18px"
                                        style={{
                                            "circle": {
                                                stroke: "#000000"
                                            }
                                        }}
                                    />
                                    <span>
                                            {t("bookingPage.checkingCompany")}
                                        </span>
                                </div>
                            )
                        }

                        {
                            companyCheckingError && (
                                <div className="trip-builder-form-company-check-error-message">
                                    {t("bookingPage.checkCompanyErr")}
                                    <MdButton
                                        style={{
                                            "button": {
                                                position: "absolute",
                                                top: "50%",
                                                right: "5px",
                                                transform: "translate(0, -50%)",
                                                borderWidth: 0,
                                                backgroundColor: "transparent",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                margin: 0,
                                                width: "28px",
                                                height: "28px",
                                                padding: "2px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                                            }
                                        }}
                                        onClick={() => checkCompany()}
                                    >
                                        <RefreshIcon
                                            width="12px"
                                            height="12px"
                                            style={{
                                                "path": {
                                                    fill: "#856404"
                                                }
                                            }}
                                        />
                                    </MdButton>
                                </div>
                            )
                        }

                        {
                            !companyChecking && companyExist && (
                                <div className="selected-company-details">
                                    <div className="company-item">
                                        <div className="company-item-icon">
                                            <CompanyIcon
                                                width="18px"
                                                height="18px"
                                                style={{
                                                    "path": {
                                                        fill: "#777E90"
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="company-item-details">
                                            <div className="company-item-details-row">
                                                <div className="company-item-name">
                                                    {selectedCompany?.fullName}
                                                </div>
                                            </div>
                                            <div className="company-item-details-row">
                                                <div className="company-item-inn">
                                                    {selectedCompany?.inn}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="company-item-tools">

                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                }
                slides={[
                    {
                        content: (
                            <div className="trip-builder-form">

                                <span className="trip-builder-form-title">{t("bookingPage.routeBuilding")}</span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >
                                    <div className="ticket-list"
                                         style={{maxHeight: "inherit", overflow: "hidden", paddingBottom: "10px"}}>

                                        {
                                            tripItemsInfo.map((tripItem, tripItemIndex) => {
                                                switch (tripItem.type) {
                                                    case 'avia':
                                                        return (
                                                            <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                                                <div className="ticket-list-item">
                                                                    <FlightComponent
                                                                        flightInfo={{
                                                                            id: tripItem.id,
                                                                            flight: tripItem.variant as IAviaVariant,
                                                                            tariff: tripItem.tariff as IAviaTariffInfo
                                                                        }}
                                                                        changeTripItemTariff={changeTripItemTariff}
                                                                    />
                                                                    <MdButton
                                                                        icon={
                                                                            <RecycleBinIcon
                                                                                width="14px"
                                                                                height="14px"
                                                                                style={{
                                                                                    "path": {
                                                                                        fill: "#721c24"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        style={{
                                                                            "button": {
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                borderWidth: 0,
                                                                                backgroundColor: "#FFFFFF",
                                                                                color: "rgb(0 0 0)",
                                                                                borderRadius: "6px",
                                                                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                marginRight: "0px",
                                                                                width: "28px",
                                                                                height: "28px",
                                                                                padding: 0,
                                                                                alignSelf: "start",
                                                                                marginTop: "50px"
                                                                            },
                                                                            "ink": {
                                                                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                            },
                                                                            "icon": {
                                                                                marginLeft: 0,
                                                                                marginRight: 0,
                                                                            }
                                                                        }}
                                                                        onClick={() => removeItemFromTrip(tripItem.variant)}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    case 'train':
                                                        return (
                                                            <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                                                <div className="ticket-list-item">
                                                                    <TrainComponent
                                                                        trainInfo={{
                                                                            id: tripItem.id,
                                                                            train: tripItem.variant as ITrainVariant,
                                                                            tariff: tripItem.tariff as ITrainTariffInfo
                                                                        }}
                                                                        changeTripItemTariff={changeTripItemTariff}
                                                                    />
                                                                    <MdButton
                                                                        icon={
                                                                            <RecycleBinIcon
                                                                                width="14px"
                                                                                height="14px"
                                                                                style={{
                                                                                    "path": {
                                                                                        fill: "#721c24"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        style={{
                                                                            "button": {
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                borderWidth: 0,
                                                                                backgroundColor: "#FFFFFF",
                                                                                color: "rgb(0 0 0)",
                                                                                borderRadius: "6px",
                                                                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                marginRight: "0px",
                                                                                width: "28px",
                                                                                height: "28px",
                                                                                padding: 0
                                                                            },
                                                                            "ink": {
                                                                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                            },
                                                                            "icon": {
                                                                                marginLeft: 0,
                                                                                marginRight: 0,
                                                                            }
                                                                        }}
                                                                        onClick={() => removeItemFromTrip(tripItem.variant)}
                                                                    />
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    default:
                                                        return (
                                                            <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                                            </React.Fragment>
                                                        )
                                                }
                                            })
                                        }

                                        <div className="direction-add-button">
                                            <MdButton
                                                type="submit"
                                                style={{
                                                    "button": {
                                                        borderWidth: 0,
                                                        backgroundColor: "rgb(253 253 253)",
                                                        color: "rgb(175 175 175)",
                                                        border: "1px solid rgb(175 175 175)",
                                                        borderStyle: "dashed",
                                                        borderRadius: "6px",
                                                        height: "30px",
                                                        padding: "5px 15px"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                onClick={() => openTicketSearchModalHandler()}
                                            >
                                                <span>{t("bookingPage.add")}</span>
                                            </MdButton>
                                        </div>

                                    </div>
                                </MdScrollViewer>

                            </div>
                        )
                    },
                    {
                        content: (
                            <div className="passenger-selection-form">

                                <span className="passenger-selection-form-title">{t("bookingPage.choosingPax")}</span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >
                                    <div className="passengers-list">
                                        {
                                            passengers.map((passengerInfo, passengerInfoIndex) => {
                                                return (
                                                    <div className="passenger-item" key={"passenger" + Guid.newGuid()}>
                                                        <div className="passenger-item-icon">
                                                            <UserIconFilled
                                                                width="18px"
                                                                height="18px"
                                                                style={{
                                                                    "path": {
                                                                        fill: "#777E90"
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="passenger-item-details">
                                                            <div className="passenger-item-details-row">
                                                                <span className="passenger-fio">
                                                                    {
                                                                        passengerInfo.passenger.fio
                                                                            .toLowerCase()
                                                                            .split(' ')
                                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                            .join(' ')
                                                                    }
                                                                </span>
                                                                <div className="passenger-item-details-row">
                                                                    <CalendarIcon
                                                                        width="10px"
                                                                        height="10px"
                                                                        style={{
                                                                            "svg": {
                                                                                marginTop: "2px"
                                                                            },
                                                                            "path": {
                                                                                fill: "#8E8E90"
                                                                            }
                                                                        }}
                                                                    />
                                                                    <span
                                                                        className="passenger-dob">{passengerInfo.passport.dob.split('T')[0]}</span>
                                                                </div>
                                                            </div>
                                                            <div className="passenger-item-details-row">
                                                                <MdSelect
                                                                    displayMemberPath={"number"}
                                                                    itemTemplate={(item: any) => {
                                                                        const isExpired =
                                                                            item.typeData?.trim().toLowerCase() != "rus"
                                                                            && (new Date(item.expiry).getTime() - new Date().getTime() < 0);
                                                                        return (
                                                                            <div
                                                                                className={("passport-item" + (isExpired ? " --expired" : ""))}>
                                                                                <div className="passport-item-type">
                                                                                    {
                                                                                        !Utils.isEmpty(item.typeData?.trim())
                                                                                            ? (
                                                                                                item.typeData.trim().toLowerCase() == "rus"
                                                                                                    ? "Паспорт РФ"
                                                                                                    : "Загран."
                                                                                            )
                                                                                            : ""
                                                                                    }
                                                                                </div>
                                                                                <div className="passport-item-number">
                                                                                    {
                                                                                        item.number
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    item.typeData?.trim().toLowerCase() != "rus" && (
                                                                                        <div
                                                                                            className="passport-item-expiry">
                                                                                            {
                                                                                                new Date(item.expiry).toLocaleDateString()
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }}
                                                                    itemSource={passengerInfo.passenger.passports}
                                                                    selectedItem={passengerInfo.passport ?? passengerInfo.passenger.passports[0]}
                                                                    setSelectedItem={(passport: any) => setPassengerPassport(passengerInfo.passenger, passport)}
                                                                    style={{
                                                                        "root": {
                                                                            margin: "5px 0",
                                                                            minWidth: "290px"
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="passenger-item-tools">
                                                            <MdButton
                                                                icon={
                                                                    <RecycleBinIcon
                                                                        width="14px"
                                                                        height="14px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#721c24"
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                                style={{
                                                                    "button": {
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderWidth: 0,
                                                                        backgroundColor: "#FFFFFF",
                                                                        color: "rgb(0 0 0)",
                                                                        borderRadius: "6px",
                                                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                        marginRight: "0px",
                                                                        width: "28px",
                                                                        height: "28px",
                                                                        padding: 0
                                                                    },
                                                                    "ink": {
                                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                    },
                                                                    "icon": {
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                    }
                                                                }}
                                                                onClick={() => removePassengersFromOrder([passengerInfo])}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="passenger-add-button">
                                        <MdButton
                                            type="submit"
                                            style={{
                                                "button": {
                                                    borderWidth: 0,
                                                    backgroundColor: "rgb(253 253 253)",
                                                    color: "rgb(175 175 175)",
                                                    border: "1px solid rgb(175 175 175)",
                                                    borderStyle: "dashed",
                                                    borderRadius: "6px",
                                                    height: "30px",
                                                    padding: "5px 15px"
                                                },
                                                "ink": {
                                                    backgroundColor: "rgba(255,255,255,.5)"
                                                }
                                            }}
                                            onClick={() => openPassengersSelectionModal()}
                                        >
                                            <span>{t("bookingPage.add")}</span>
                                        </MdButton>
                                    </div>

                                </MdScrollViewer>

                            </div>
                        )
                    },
                    {
                        content: (
                            <div className="order-confirmation-form">
                                <span className="order-confirmation-form-title">{t("bookingPage.orderConfirmation")}</span>

                                <MdScrollViewer
                                    style={{
                                        "content": {
                                            padding: "20px 0"
                                        }
                                    }}
                                >

                                    <div className="ticket-list"
                                         style={{maxHeight: "inherit", overflow: "hidden", paddingBottom: "10px"}}>
                                        {
                                            tripItemsInfo.map((tripItem, tripItemIndex) => {
                                                switch (tripItem.type) {
                                                    case 'avia':
                                                        return (
                                                            <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                                                <FlightComponent
                                                                    flightInfo={{
                                                                        id: tripItem.id,
                                                                        flight: tripItem.variant as IAviaVariant,
                                                                        tariff: tripItem.tariff as IAviaTariffInfo
                                                                    }}
                                                                    changeTripItemTariff={changeTripItemTariff}
                                                                    passengers={passengers}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    case 'train':
                                                        return (
                                                            <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                                                <TrainComponent
                                                                    trainInfo={{
                                                                        id: tripItem.id,
                                                                        train: tripItem.variant as ITrainVariant,
                                                                        tariff: tripItem.tariff as ITrainTariffInfo
                                                                    }}
                                                                    changeTripItemTariff={changeTripItemTariff}
                                                                    passengers={passengers}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    default:
                                                        return (
                                                            <React.Fragment key={"trip-item" + Guid.newGuid()}>
                                                            </React.Fragment>
                                                        )
                                                }
                                            })
                                        }
                                    </div>

                                    <div className="order-summary-info">
                                        <div className="order-summary-info-item">
                                            <span>{t("bookingPage.numberOfPax")}</span>
                                            <span></span>
                                            <span>
                                                {
                                                    passengers.length
                                                }
                                            </span>
                                        </div>
                                        <div className="order-summary-info-item">
                                            <span>{t("bookingPage.orderTotalAmount")}</span>
                                            <span></span>
                                            <span>
                                                {
                                                    (
                                                        tripItemsInfo.reduce((a, b) => {
                                                            if(b.type == 'avia') {
                                                                return a + b.tariff.priceWithSborTts;
                                                            }
                                                            else {
                                                                return a + b.tariff.price + b.tariff.sborTts;
                                                            }
                                                        }, 0) * passengers.length
                                                    ).toLocaleString()
                                                    + " ₽"
                                                }
                                            </span>
                                        </div>
                                    </div>

                                </MdScrollViewer>

                                {
                                    bookingError && (
                                        <div className="no-results-message">
                                            {t("bookingPage.orderCreatingErr")}
                                        </div>
                                    )
                                }

                                {
                                    bookingComplete && (
                                        <div className="booking-complete-message">
                                            {(t("bookingPage.bookingComplete"))}
                                        </div>
                                    )
                                }

                            </div>
                        )
                    },
                ]}
                footer={
                    <div className="trip-builder-form-footer">
                        <div>
                            {
                                activeSlide > 0 && !bookingComplete && (
                                    <MdButton
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "rgb(233 233 233)",
                                                color: "rgb(0 0 0)",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "icon": {
                                                marginRight: "5px",
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}

                                        onClick={() => setActiveSlide(prev => Math.max(0, prev - 1))}
                                    >
                                        {t("commonText.back")}
                                    </MdButton>
                                )
                            }
                        </div>

                        <div>
                            {
                                activeSlide < 2 && (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        disabled={(!companyExist || (activeSlide == 0 && tripItemsInfo.length == 0) || (activeSlide == 1 && passengers.length == 0))}
                                        onClick={() => setActiveSlide(prev => Math.min(3, prev + 1))}
                                    >
                                        {t("commonText.next")}
                                    </MdButton>
                                )
                            }

                            {
                                activeSlide == 2 && !bookingComplete && (
                                    bookingPending
                                        ? (
                                            <div className="booking-pending-message">
                                                <CircleSpinnerIcon
                                                    width="18px"
                                                    height="18px"
                                                    style={{
                                                        "circle": {
                                                            stroke: "#000000"
                                                        }
                                                    }}
                                                />
                                                <span>
                                                    {t("bookingPage.bookingTickets")}
                                                </span>
                                            </div>
                                        )
                                        : (
                                            <MdButton
                                                type="submit"
                                                style={{
                                                    "button": {
                                                        alignSelf: "center",
                                                        width: "200px",
                                                        borderWidth: 0,
                                                        backgroundColor: "#3E5CB8",
                                                        color: "#FFFFFF",
                                                        borderRadius: "6px",
                                                        height: "35px",
                                                        padding: "5px 15px"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                disabled={(!companyExist || tripItemsInfo.length == 0 || passengers.length == 0)}
                                                onClick={onSubmit}
                                            >
                                                {t("bookingPage.book")}
                                            </MdButton>
                                        )
                                )
                            }

                            {
                                activeSlide == 2 && bookingComplete && (
                                    <MdButton
                                        type="submit"
                                        style={{
                                            "button": {
                                                alignSelf: "center",
                                                width: "200px",
                                                borderWidth: 0,
                                                backgroundColor: "#3E5CB8",
                                                color: "#FFFFFF",
                                                borderRadius: "6px",
                                                height: "35px",
                                                padding: "5px 15px"
                                            },
                                            "ink": {
                                                backgroundColor: "rgba(255,255,255,.5)"
                                            }
                                        }}
                                        onClick={() => setOpened(false)}
                                    >
                                        {t("bookingPage.close")}
                                    </MdButton>
                                )
                            }
                        </div>
                    </div>
                }
                activeSlide={activeSlide}
                style={{
                    "root": {
                        width: "580px",
                        height: "700px",
                        padding: "10px 20px 20px 20px"
                    },
                    "pagination": {
                        pointerEvents: "none"
                    }
                }}
            />

        </MdModal>
    );
}