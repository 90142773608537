import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../../redux/hooks";
import {ModalContext, BOOK_TRAIN_MODAL_ID} from "../../../../../commonComponents/modal/ModalContext";
import {ICompany} from "../../../../../../interfaces/companyInfoInterfaces/ICompany";
import {ITrainTariffInfo, ITrainVariant} from "../../../../../../interfaces/ITrainVariant";
import {IPassenger} from "../../../../../../interfaces/IPassenger";
import {IBookTrainRequestNew} from "../../../../../../api/request/IBookFlightRequest";
import {CompanyService} from "../../../../../../services/CompanyService";
import {TripService} from "../../../../../../services/TripService";
import {MdModal} from "../../../../../../components/md-modal";
import {MdSelect} from "../../../../../../components/md-select";
import {MdButton} from "../../../../../../components/md-button";
import {MdSlider} from "../../../../../../components/md-slider";
import {TrainTicketComponent} from "../trainComponent/trainTicketComponent";
import {PassengersSelectionModal} from "../../../../tripPage/passengersSelectionModal";
import {MdScrollViewer} from "../../../../../../components/md-scroll-viewer";
import {MdToggleButton} from "../../../../../../components/md-toggle-button";
import {CompanyIcon} from "../../../../../../icons/CompanyIcon";
import {CircleSpinnerIcon} from "../../../../../../icons/CircleSpinnerIcon";
import {UserIconFilled} from "../../../../../../icons/UserIconFilled";
import {CalendarIcon} from "../../../../../../icons/CalendarIcon";
import {RecycleBinIcon} from "../../../../../../icons/RecycleBin";
import {RefreshIcon} from "../../../../../../icons/RefreshIcon";
import {Guid, Utils} from "../../../../../../utils/utils";
import "./style.scss"


interface IBookTrainsModal {
    opened: boolean;
    setOpened: React.Dispatch<React.SetStateAction<boolean>>,
    selectedCompany: ICompany | undefined,
    tripItemInfo?: {
        variant: ITrainVariant,
        tariff: ITrainTariffInfo,
        searchResponse: any,
    }
}


export function BookTrainsModal(props: IBookTrainsModal) {

    const {
        opened,
        setOpened,
        selectedCompany,
        tripItemInfo,
        //submitHandler
    } = props;

    const {t} = useTranslation();

    const authState = useAppSelector((state) => state.auth);
    const companyState = useAppSelector((state) => state.companyInfo);

    const {isOpen, open, close} = useContext(ModalContext);


    const companyService = CompanyService();
    const tripService = TripService();

    const [activeSlide, setActiveSlide] = useState(0);

    const [companyChecking, setCompanyChecking] = useState<boolean>(false);
    const [companyExist, setCompanyExist] = useState<boolean>(false);
    const [companyCheckingError, setCompanyCheckingError] = useState<boolean>(false);

    const [passengersSelectionModalOpened, setPassengersSelectionModalOpened] = useState<boolean>(false);
    const [passengers, setPassengers] = useState<{ passenger: IPassenger, passport: any }[]>([]);

    const [selectedTariff, setSelectedTariff] = useState<ITrainTariffInfo | undefined>(undefined);

    const [orderNumber, setOrderNumber] = useState<number>(0);
    const [bookingComplete, setBookingComplete] = useState(false);
    const [bookingPending, setBookingPending] = useState(false);
    const [bookingError, setBookingError] = useState(false);


    const RefundAndChangeVariants: { [selector: number]: string } = {
        0: "запрещен",
        1: "бесплатно",
        2: "за доп. плату",
        3: "неопределён"
    }

    const BaggageVariants: { [selector: number]: string } = {
        0: "присутствует",
        1: "без багажа",
        2: "неопределён"
    }


    const onModalOpen = async () => {
        if (!opened) {
            setOpened(true);
        }
        setActiveSlide(0);
        setBookingComplete(false);
        await checkCompany();
    }
    const onModalClose = () => {
        if (opened) {
            setOpened(false);
        }
        setPassengers([]);
        setSelectedTariff(undefined);
    }


    const checkCompany = async () => {
        setCompanyCheckingError(false);
        setCompanyExist(false);
        if (selectedCompany != null) {
            setCompanyChecking(true);
            let isCompanyExists = await companyService.isCompanyExists(selectedCompany.code);
            if (!isCompanyExists) {
                await companyService.importCompany(selectedCompany);
                isCompanyExists = await companyService.isCompanyExists(selectedCompany.code);
            }
            if (!isCompanyExists) {
                setCompanyCheckingError(true);
            }
            setCompanyExist(isCompanyExists);
            setCompanyChecking(false);
            return isCompanyExists;
        }
        return false;
    }


    const openPassengersSelectionModal = () => {
        setPassengersSelectionModalOpened(true);
    }
    const choosePassengers = (passengers: { passenger: IPassenger, passport: any }[]) => {
        setPassengers(passengers);
    }
    const removePassenger = (passengerInfo: { passenger: IPassenger, passport: any }) => {
        setPassengers(prev => prev.filter(x => x.passenger.paxCode != passengerInfo.passenger.paxCode));
    }
    const setPassengerPassport = (passenger: IPassenger, passport: any) => {
        console.warn(passenger, passport);
        setPassengers(prev => [
            ...prev.map(x => {
                if (x.passenger.paxCode == passenger.paxCode) {
                    console.log({
                        passenger: passenger,
                        passport: passport
                    });
                    return {
                        passenger: passenger,
                        passport: passport
                    };
                } else {
                    console.log(x);
                    return x;
                }
            })
        ])
    }


    const onSubmit = async () => {
        setBookingComplete(false);
        setBookingError(false)
        setBookingPending(true);

        const companyCheckResult = await checkCompany();

        if (companyCheckResult) {
            let bookTrainRequest: IBookTrainRequestNew = {
                userId: authState.userId,
                supportUserCode: authState.supportUserCode,
                clientCode: selectedCompany?.code ?? "",
                parameters: [
                    {
                        fareBasises: [selectedTariff?.fareBasis ?? []].flat(),
                        paxes: passengers.map(p => {
                            return {
                                paxCode: p.passenger.paxCode,
                                selectedPassportNo: p.passport.number
                            }
                        }),
                        searchResponse: tripItemInfo?.searchResponse
                    }
                ]
            };

            const result = await tripService.bookTrains(bookTrainRequest);

            const complete = !Utils.isEmpty(result);

            if(complete) {
                setOrderNumber(Number(result));
            }

            setBookingPending(false);
            setBookingError(!complete);
            setBookingComplete(complete);
        } else {
            setBookingPending(false);
            setBookingError(true);
        }
    }


    useEffect(() => {
        // TODO: change modal id
        if (isOpen(BOOK_TRAIN_MODAL_ID) && !opened) {
            close(BOOK_TRAIN_MODAL_ID);
        } else if (!isOpen(BOOK_TRAIN_MODAL_ID) && opened) {
            open(BOOK_TRAIN_MODAL_ID);
        }
    }, [opened]);


    useEffect(() => {
        if(tripItemInfo != null) {
            setSelectedTariff(tripItemInfo.tariff);
        }
    }, [tripItemInfo]);


    useEffect(() => {
        setPassengers([]);
        checkCompany();
    }, [selectedCompany]);


    useEffect(() => {

    }, []);


    return (
        <React.Fragment>

            <MdModal
                id={BOOK_TRAIN_MODAL_ID}
                title={t("bookingPage.bookingTip")}
                openHandler={onModalOpen}
                closeHandler={onModalClose}
                closeOnClickOutside={false}>

                <div className="booking-form">
                    <MdSlider
                        header={
                            <div className="booking-form-header">
                                {
                                    companyChecking && (
                                        <div className="booking-form-company-check">
                                            <CircleSpinnerIcon
                                                width="18px"
                                                height="18px"
                                                style={{
                                                    "circle": {
                                                        stroke: "#000000"
                                                    }
                                                }}
                                            />
                                            <span>
                                            {t("bookingPage.checkingCompany")}
                                        </span>
                                        </div>
                                    )
                                }

                                {
                                    companyCheckingError && (
                                        <div className="booking-form-company-check-error-message">
                                            {t("bookingPage.checkCompanyErr")}
                                            <MdButton
                                                style={{
                                                    "button": {
                                                        position: "absolute",
                                                        top: "50%",
                                                        right: "5px",
                                                        transform: "translate(0, -50%)",
                                                        borderWidth: 0,
                                                        backgroundColor: "transparent",
                                                        color: "rgb(0 0 0)",
                                                        borderRadius: "6px",
                                                        margin: 0,
                                                        width: "28px",
                                                        height: "28px",
                                                        padding: "2px"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                    }
                                                }}
                                                onClick={() => checkCompany()}
                                            >
                                                <RefreshIcon
                                                    width="12px"
                                                    height="12px"
                                                    style={{
                                                        "path": {
                                                            fill: "#856404"
                                                        }
                                                    }}
                                                />
                                            </MdButton>
                                        </div>
                                    )
                                }

                                {
                                    !companyChecking && companyExist && (
                                        <div className="selected-company-details">
                                            <div className="company-item">
                                                <div className="company-item-icon">
                                                    <CompanyIcon
                                                        width="18px"
                                                        height="18px"
                                                        style={{
                                                            "path": {
                                                                fill: "#777E90"
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="company-item-details">
                                                    <div className="company-item-details-row">
                                                        <div className="company-item-name">
                                                            {selectedCompany?.fullName}
                                                        </div>
                                                    </div>
                                                    <div className="company-item-details-row">
                                                        <div className="company-item-inn">
                                                            {selectedCompany?.inn}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="company-item-tools">

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        }
                        slides={[
                            {
                                content: (
                                    <MdScrollViewer
                                        style={{
                                            "content": {
                                                padding: "10px 0"
                                            }
                                        }}
                                    >
                                        <div className="booking-form-content">
                                            <span className="booking-form-content-section-title">Билеты и тарифы</span>

                                            <div className="ticket-list">
                                                {
                                                    [tripItemInfo?.variant ?? []].flat().map((ticket, ticketIndex) => {
                                                        return (
                                                            <React.Fragment key={ticketIndex}>
                                                                <TrainTicketComponent
                                                                    ticket={ticket}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="tariff-switcher">
                                                {
                                                    tripItemInfo?.variant.categoryPrice.map((tariff, tariffIndex) => {
                                                        const isSelected = tariff == selectedTariff ?? tariff == tripItemInfo?.tariff;
                                                        return (
                                                            <React.Fragment key={"tariff" + Guid.newGuid()}>
                                                                <MdToggleButton
                                                                    buttonStyle={{
                                                                        minInlineSize: "fit-content",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        padding: "1px 10px 0 10px",
                                                                        borderRadius: "4px",
                                                                        minHeight: "24px",
                                                                        maxHeight: "52px",
                                                                        fontSize: "11px",
                                                                        color: (isSelected ? "#74B100" : "#C1C1C1"),
                                                                        border: (isSelected ? "1px solid #74B100" : "1px solid #CCCCCC"),
                                                                    }}
                                                                    inkStyle={{
                                                                        backgroundColor: (isSelected ? "#74B100" : "#C1C1C1"),
                                                                        opacity: 0.1
                                                                    }}
                                                                    checked={isSelected}
                                                                    onClick={() => {
                                                                        setSelectedTariff(tariff);
                                                                    }}
                                                                >
                                                                    {tariff.categoryName != null && tariff.categoryName.length > 0 ? tariff.categoryName : ("Тариф " + (tariffIndex + 1))}
                                                                </MdToggleButton>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="booking-summary-info">

                                                {
                                                    selectedTariff != null && (
                                                        <>
                                                            <div className="booking-summary-info-item">
                                                                <span>Стоимость билетов</span>
                                                                <span></span>
                                                                <span>
                                                                {
                                                                    (
                                                                        selectedTariff.price + selectedTariff.sborTts
                                                                    ).toLocaleString()
                                                                    + " ₽"
                                                                }
                                                            </span>
                                                            </div>

                                                        </>
                                                    )
                                                }

                                            </div>
                                        </div>

                                    </MdScrollViewer>
                                )
                            },
                            {
                                content: (
                                    <MdScrollViewer
                                        style={{
                                            "content": {
                                                padding: "10px 0"
                                            }
                                        }}
                                    >
                                        <div className="booking-form-content">
                                            <span className="booking-form-content-section-title">Пассажиры</span>

                                            <div className="passengers-list">
                                                {
                                                    passengers.map((passengerInfo, passengerInfoIndex) => {
                                                        return (
                                                            <div className="passenger-item"
                                                                 key={"passenger" + Guid.newGuid()}>
                                                                <div className="passenger-item-icon">
                                                                    <UserIconFilled
                                                                        width="18px"
                                                                        height="18px"
                                                                        style={{
                                                                            "path": {
                                                                                fill: "#777E90"
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="passenger-item-details">
                                                                    <div className="passenger-item-details-row">
                                                            <span className="passenger-fio">
                                                                {
                                                                    passengerInfo.passenger.fio
                                                                        .toLowerCase()
                                                                        .split(' ')
                                                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ')
                                                                }
                                                            </span>
                                                                        <div className="passenger-item-details-row">
                                                                            <CalendarIcon
                                                                                width="10px"
                                                                                height="10px"
                                                                                style={{
                                                                                    "svg": {
                                                                                        marginTop: "2px"
                                                                                    },
                                                                                    "path": {
                                                                                        fill: "#8E8E90"
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <span
                                                                                className="passenger-dob">{passengerInfo.passport.dob.split('T')[0]}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="passenger-item-details-row">
                                                                        <MdSelect
                                                                            displayMemberPath={"number"}
                                                                            itemTemplate={(item: any) => {
                                                                                const isExpired =
                                                                                    item.typeData?.trim().toLowerCase() != "rus"
                                                                                    && (new Date(item.expiry).getTime() - new Date().getTime() < 0);
                                                                                return (
                                                                                    <div
                                                                                        className={("passport-item" + (isExpired ? " --expired" : ""))}>
                                                                                        <div
                                                                                            className="passport-item-type">
                                                                                            {
                                                                                                !Utils.isEmpty(item.typeData?.trim())
                                                                                                    ? (
                                                                                                        item.typeData.trim().toLowerCase() == "rus"
                                                                                                            ? "Паспорт РФ"
                                                                                                            : "Загран."
                                                                                                    )
                                                                                                    : ""
                                                                                            }
                                                                                        </div>
                                                                                        <div
                                                                                            className="passport-item-number">
                                                                                            {
                                                                                                item.number
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            item.typeData?.trim().toLowerCase() != "rus" && (
                                                                                                <div
                                                                                                    className="passport-item-expiry">
                                                                                                    {
                                                                                                        new Date(item.expiry).toLocaleDateString()
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                            itemSource={passengerInfo.passenger.passports}
                                                                            selectedItem={passengerInfo.passport ?? passengerInfo.passenger.passports[0]}
                                                                            setSelectedItem={(passport: any) => setPassengerPassport(passengerInfo.passenger, passport)}
                                                                            style={{
                                                                                "root": {
                                                                                    margin: "5px 0",
                                                                                    minWidth: "290px"
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="passenger-item-tools">
                                                                    <MdButton
                                                                        icon={
                                                                            <RecycleBinIcon
                                                                                width="14px"
                                                                                height="14px"
                                                                                style={{
                                                                                    "path": {
                                                                                        fill: "#721c24"
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        style={{
                                                                            "button": {
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                borderWidth: 0,
                                                                                backgroundColor: "#FFFFFF",
                                                                                color: "rgb(0 0 0)",
                                                                                borderRadius: "6px",
                                                                                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.16), 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 1px 5px 0px rgba(0, 0, 0, 0.08)",
                                                                                marginRight: "0px",
                                                                                width: "28px",
                                                                                height: "28px",
                                                                                padding: 0
                                                                            },
                                                                            "ink": {
                                                                                backgroundColor: "rgba(230, 230, 230, 0.5)"
                                                                            },
                                                                            "icon": {
                                                                                marginLeft: 0,
                                                                                marginRight: 0,
                                                                            }
                                                                        }}
                                                                        onClick={() => removePassenger(passengerInfo)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className="passenger-add-button">
                                                <MdButton
                                                    type="submit"
                                                    style={{
                                                        "button": {
                                                            borderWidth: 0,
                                                            backgroundColor: "rgb(253 253 253)",
                                                            color: "rgb(175 175 175)",
                                                            border: "1px solid rgb(175 175 175)",
                                                            borderStyle: "dashed",
                                                            borderRadius: "6px",
                                                            height: "30px",
                                                            padding: "5px 15px"
                                                        },
                                                        "ink": {
                                                            backgroundColor: "rgba(255,255,255,.5)"
                                                        }
                                                    }}
                                                    onClick={() => openPassengersSelectionModal()}
                                                >
                                                    {t("bookingPage.add")}
                                                </MdButton>
                                            </div>
                                        </div>

                                    </MdScrollViewer>
                                )
                        },
                            {
                                content: (
                                    <MdScrollViewer
                                        style={{
                                            "content": {
                                                padding: "10px 0"
                                            }
                                        }}
                                    >
                                        <div className="booking-form-content">
                                            <span className="booking-form-content-section-title">
                                                {t("bookingPage.orderConfirmation")}
                                            </span>

                                            <div className="ticket-list">
                                                {
                                                    [tripItemInfo?.variant ?? []].flat().map((ticket, ticketIndex) => {
                                                        return (
                                                            <React.Fragment key={ticketIndex}>
                                                                <TrainTicketComponent
                                                                    ticket={ticket}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </div>

                                            {
                                                passengers.length > 0 && (
                                                    <div className="passengers-list" style={{ width: "100%" }}>
                                                        {
                                                            passengers.map((passengerInfo, passengerInfoIndex) => {
                                                                const isExpired =
                                                                    passengerInfo.passport.typeData?.trim().toLowerCase() != "rus"
                                                                    && (new Date(passengerInfo.passport.expiry).getTime() - new Date().getTime() < 0);
                                                                return (
                                                                    <div className="passenger-item"
                                                                         key={passengerInfoIndex}>
                                                                        <div className="passenger-item-icon">
                                                                            <UserIconFilled
                                                                                width="14px"
                                                                                height="14px"
                                                                                style={{
                                                                                    "path": {
                                                                                        fill: "#777E90"
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="passenger-item-details">
                                                                            <div className="passenger-item-details-row">
                                                                                <span className="passenger-fio">
                                                                                    {
                                                                                        passengerInfo.passenger.fio
                                                                                            .toLowerCase()
                                                                                            .split(' ')
                                                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                                            .join(' ')
                                                                                    }
                                                                                </span>
                                                                                <div
                                                                                    className="passenger-item-details-row">
                                                                                    <CalendarIcon
                                                                                        width="10px"
                                                                                        height="10px"
                                                                                        style={{
                                                                                            "svg": {
                                                                                                marginTop: "2px"
                                                                                            },
                                                                                            "path": {
                                                                                                fill: "#8E8E90"
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        className="passenger-dob">{passengerInfo.passport.dob.split('T')[0]}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="passenger-item-details-row">
                                                                                <div
                                                                                    className={("passport-item" + (isExpired ? " --expired" : ""))}>
                                                                                    <div className="passport-item-type" style={{ textAlign: "left" }}>
                                                                                        {
                                                                                            !Utils.isEmpty(passengerInfo.passport.typeData?.trim())
                                                                                                ? (
                                                                                                    passengerInfo.passport.typeData.trim().toLowerCase() == "rus"
                                                                                                        ? "Паспорт РФ"
                                                                                                        : "Загран."
                                                                                                )
                                                                                                : ""
                                                                                        }
                                                                                    </div>
                                                                                    <div className="passport-item-number"  style={{ fontSize: "12px" }}>
                                                                                        {
                                                                                            passengerInfo.passport.number
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        passengerInfo.passport.typeData?.trim().toLowerCase() != "rus" && (
                                                                                            <div
                                                                                                className="passport-item-expiry">
                                                                                                {
                                                                                                    new Date(passengerInfo.passport.expiry).toLocaleDateString()
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }

                                            <div className="booking-summary-info" style={{ marginTop: "10px" }}>

                                                {
                                                    selectedTariff != null && (
                                                        <>
                                                            <div className="booking-summary-info-item">
                                                                <span>Стоимость билетов</span>
                                                                <span></span>
                                                                <span>
                                                                    {
                                                                        (
                                                                            selectedTariff.price + selectedTariff.sborTts
                                                                        ).toLocaleString()
                                                                        + " ₽"
                                                                    }
                                                                </span>
                                                            </div>

                                                        </>
                                                    )
                                                }

                                                {
                                                    passengers.length > 0 && (
                                                        <>
                                                            <div className="booking-summary-info-item">
                                                                <span>{t("bookingPage.numberOfPax")}</span>
                                                                <span></span>
                                                                <span>
                                                                    {
                                                                        passengers.length
                                                                    }
                                                                </span>
                                                            </div>

                                                            <div className="booking-summary-info-item"
                                                                 style={{marginTop: "5px", fontWeight: 500}}>
                                                                <span>{t("bookingPage.orderTotalAmount")}</span>
                                                                <span></span>
                                                                <span>
                                                                    {
                                                                        (
                                                                            (
                                                                                ((selectedTariff?.price ?? 0) + (selectedTariff?.sborTts ?? 0)) * passengers.length
                                                                            ).toLocaleString()
                                                                            + " ₽"
                                                                        )
                                                                    }
                                                                </span>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                            </div>

                                            {
                                                bookingError && (
                                                    <div className="booking-error-message">
                                                        {t("bookingPage.bookingError")}
                                                    </div>
                                                )
                                            }

                                            {
                                                bookingComplete && (
                                                    <div className="booking-complete-message">
                                                        {(t("bookingPage.bookingComplete") + ". " + t("bookingPage.orderNumber") +  " " + orderNumber)}
                                                    </div>
                                                )
                                            }

                                        </div>

                                    </MdScrollViewer>

                                )
                            },
                        ]}
                        footer={
                            <div className="booking-form-footer">
                                <div>
                                    {
                                        activeSlide > 0 && !bookingComplete && (
                                            <MdButton
                                                style={{
                                                    "button": {
                                                        borderWidth: 0,
                                                        backgroundColor: "rgb(233 233 233)",
                                                        color: "rgb(0 0 0)",
                                                        borderRadius: "6px",
                                                        height: "35px",
                                                        padding: "5px 15px"
                                                    },
                                                    "icon": {
                                                        marginRight: "5px",
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                onClick={() => setActiveSlide(prev => Math.max(0, prev - 1))}
                                            >
                                                {t("commonText.back")}
                                            </MdButton>
                                        )
                                    }
                                </div>

                                <div>
                                    {
                                        activeSlide < 2 && (
                                            <MdButton
                                                type="submit"
                                                style={{
                                                    "button": {
                                                        borderWidth: 0,
                                                        backgroundColor: "#3E5CB8",
                                                        color: "#FFFFFF",
                                                        borderRadius: "6px",
                                                        height: "35px",
                                                        padding: "5px 15px"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                disabled={(!companyExist || (activeSlide == 0 && tripItemInfo == null) || (activeSlide == 1 && passengers.length == 0))}
                                                onClick={() => setActiveSlide(prev => Math.min(3, prev + 1))}
                                            >
                                                {t("commonText.next")}
                                            </MdButton>
                                        )
                                    }

                                    {
                                        activeSlide == 2 && !bookingComplete && (
                                            bookingPending
                                                ? (
                                                    <div className="booking-pending-message">
                                                        <CircleSpinnerIcon
                                                            width="18px"
                                                            height="18px"
                                                            style={{
                                                                "circle": {
                                                                    stroke: "#000000"
                                                                }
                                                            }}
                                                        />
                                                        <span>
                                                            {t("bookingPage.bookingTickets")}
                                                        </span>
                                                    </div>
                                                )
                                                : (
                                                    <MdButton
                                                        type="submit"
                                                        style={{
                                                            "button": {
                                                                alignSelf: "center",
                                                                width: "200px",
                                                                borderWidth: 0,
                                                                backgroundColor: "#3E5CB8",
                                                                color: "#FFFFFF",
                                                                borderRadius: "6px",
                                                                height: "35px",
                                                                padding: "5px 15px"
                                                            },
                                                            "ink": {
                                                                backgroundColor: "rgba(255,255,255,.5)"
                                                            }
                                                        }}
                                                        disabled={(!companyExist || tripItemInfo == null || passengers.length == 0)}
                                                        onClick={onSubmit}
                                                    >
                                                        {t("bookingPage.book")}
                                                    </MdButton>
                                                )
                                        )
                                    }

                                    {
                                        activeSlide == 2 && bookingComplete && (
                                            <MdButton
                                                type="submit"
                                                style={{
                                                    "button": {
                                                        alignSelf: "center",
                                                        width: "200px",
                                                        borderWidth: 0,
                                                        backgroundColor: "#3E5CB8",
                                                        color: "#FFFFFF",
                                                        borderRadius: "6px",
                                                        height: "35px",
                                                        padding: "5px 15px"
                                                    },
                                                    "ink": {
                                                        backgroundColor: "rgba(255,255,255,.5)"
                                                    }
                                                }}
                                                onClick={() => setOpened(false)}
                                            >
                                                {t("bookingPage.close")}
                                            </MdButton>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        activeSlide={activeSlide}
                        style={{
                            "root": {
                                width: "100%",
                                height: "100%",
                                padding: "0 20px 20px 20px"
                            },
                            "pagination": {
                                pointerEvents: "none"
                            }
                        }}
                    />
                </div>

            </MdModal>

            <PassengersSelectionModal
                opened={passengersSelectionModalOpened}
                setOpened={setPassengersSelectionModalOpened}
                company={selectedCompany}
                tripPassengers={passengers}
                submitHandler={choosePassengers}
            />

        </React.Fragment>

    );
}