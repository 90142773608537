import axios, { AxiosResponse} from "axios";
import { axiosInstance} from "./AxiosInstance";
import {
    BEARER,
    ROUTE_LOGIN,
    ROUTE_REFRESH_TOKEN,
    ROUTE_REVOKE_TOKEN,
    ROUTE_LOGOUT,
    ROUTE_RECOVERY_PASSWORD,
    ROUTE_CHANGE_PASSWORD,
    ROUTE_GET_USER_ACTIVE_STATE,
    ROUTE_ADD_USER
} from "../constants/routeConstants/ApiRouteConstants";
import {ILoginRequest} from "./request/ILoginRequest";
import {IChangePasswordRequest} from "./request/IChangePasswordRequest";
import {ISignupRequest} from "./request/ISignupRequest";
import {IAuthenticateInfo} from "../interfaces/IAuthenticateInfo";
import {IUserInfo} from "../interfaces/IUserInfo";


export function AuthApi() {

    /**
     * POST method for signup
     * @param {ISignupRequest} request - request data
     * @param {string} accessToken - access token
     * @return {Promise<AxiosResponse<IUserInfo>>} response with user info
     */
    const signup = async (request: ISignupRequest, accessToken: string): Promise<AxiosResponse<IUserInfo>> => {
        return await axiosInstance.post<IUserInfo>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_ADD_USER,
            request,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                }
            }
        );
    };

    /**
     * POST method for login
     * @param {ILoginRequest} request - request data
     * @return {Promise<AxiosResponse<IAuthenticateInfo, any>|null|undefined>} response with authenticate data. In case of errors returns null.
     */
    async function login(request: ILoginRequest): Promise<AxiosResponse<IAuthenticateInfo, any>|null|undefined> {
        return await axiosInstance.post<IAuthenticateInfo>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_LOGIN,
          request
        ).catch(
            function (error) {
                if (error.response) {
                    if (error.response.status < 500) return null;
                    return undefined;
                } else if (error.request) {
                    return undefined;
                }
                return undefined;
            }
        );
    }

    /**
     * PUT method for password recovery
     * @param {string} email - user email address
     * @return {Promise<AxiosResponse<boolean, any>} response with password recovery result
     */
    async function recoveryPassword(email: string): Promise<AxiosResponse<boolean, any>> {
        return await axios.put<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_RECOVERY_PASSWORD,
            email,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }

    /**
     * PUT method for change password
     * @param {IChangePasswordRequest} request - request data
     * @return {Promise<AxiosResponse<boolean>>} response with password change result
     */
    async function changePassword(request: IChangePasswordRequest): Promise<AxiosResponse<boolean>> {
        return await axiosInstance.put<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_CHANGE_PASSWORD,
            request
        );
    }

    /**
     * POST method for token refresh
     * @param {string} expiredJwtToken - expired JWT token
     * @return {Promise<AxiosResponse<IAuthenticateInfo>>} response with authenticate data
     */
    async function refreshToken(expiredJwtToken: string): Promise<AxiosResponse<IAuthenticateInfo>> {
        return await axiosInstance.post<IAuthenticateInfo>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REFRESH_TOKEN,
            {
                expiredJwtToken: expiredJwtToken
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
    }

    /**
     * POST method for revoke token
     * @param {string} accessToken - access token
     * @return {Promise<AxiosResponse<boolean>>} response with token revoke result
     */
    async function revokeToken(accessToken: string): Promise<AxiosResponse<boolean>> {
        return await axiosInstance.post(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_REVOKE_TOKEN,
            null,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                }
            }
        );
    }

    /**
     * GET method for obtaining user activity status
     * @param {string} email - user email
     * @param {string} token - change password token
     * @return {Promise<AxiosResponse<boolean>>} response with state of user activity
     */
    const isUserActive = async (email: string, token: string): Promise<AxiosResponse<boolean>> => {
        return await axiosInstance.get<boolean>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_GET_USER_ACTIVE_STATE,
            {
                params: {
                    email: email,
                    token: token
                }
            }
        );
    };

    /**
     * POST method for logout
     * @param {string} accessToken - access token
     */
    async function logout(accessToken: string): Promise<void> {
        await axiosInstance.post<any>(
            process.env.REACT_APP_GATEWAY_ROUTE + ROUTE_LOGOUT,
            null,
            {
                headers: {
                    Authorization: BEARER + accessToken,
                }
            }
        );
    }


    return {
        login,
        recoveryPassword,
        changePassword,
        refreshToken,
        revokeToken,
        logout,
        isUserActive,
        signup
    };
}