import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import { MAIN_BACKGROUND_COLOR } from "../../../../constants/PageConstants";

interface CompanySalesInfoBarProps {
  companySalesBarData: (string | number | object)[][];
}

export function CompanySalesInfoBarComponent(props: CompanySalesInfoBarProps) {
  const { t } = useTranslation();
  const options = {
    //backgroundColor: "#EEFFE3",
    animation: {
      startup: true,
      easing: "linear",
      duration: 2000,
    },

   color: "#2ba9dc",
    //enableInteractivity: false,
    title: t("accInfoPage.chartTitle"),
    tooltip: { textStyle: { color: "black" }, showColorCode: true },
    titleTextStyle: {
      color: "black",
      //fontName: <string>,
      fontSize: 18,
      bold: true,
      italic: false,
    },

    vAxis: {
      title: "RUB",
      titleTextStyle: {
        color: "black",
        //fontName: <string>,
        fontSize: 12,
        bold: true,
        italic: false,
      },

      //vizualization
      //viewWindowMode: "pretty",
      // viewWindowMode: "maximized",
      viewWindowMode: "explicit",
    },

    series: {
      0: { axis: "distance" }, // Bind series 0 to an axis named 'distance'.
      1: { axis: "brightness" }, // Bind series 1 to an axis named 'brightness'.
    },
    axes: {
      y: {
        distance: { label: "Rub" }, // Left y-axis.
        brightness: { side: "right", label: "Количестывыо" }, // Right y-axis.
      },
    },
  };
  return (
    <div className="col-11 m-2 "
        // style={{ backgroundColor: MAIN_BACKGROUND_COLOR }}
    >
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="250px"
        data={props.companySalesBarData}
        options={options}
      />
    </div>
  );
}
