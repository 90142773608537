import React from "react";
import "./style.scss";

interface ModalProps {
    children: React.ReactNode;
    title: string;
    onClose: () => void;
}

export function Modal({children, title, onClose}: ModalProps) {
    return (
        <div className="modal-overlay"
             onClick={() => {
                 //onClose();
             }}>
            {/*<div className="modal-window position-fixed bg-body-secondary opacity-100 py-3 px-3 w-75 h-75 rounded-4">*/}
                <div className="modal-window     rounded-4">
                <button
                    type="button"
                    className="btn-close  float-md-end"
                    onClick={() => {
                        onClose();
                    }}
                ></button>
                <div className="d-flex flex-column ">
                    <div style = {{width:"60wh", height:"20vh"}}>{children}</div></div>
            </div>
        </div>
    );
}
