import * as React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAppSelector} from "../redux/hooks";
import {
    CURRENT_REACT_ENVIROMENT,
    ENVIROMENT_DEVELOPMENT,
    ENVIROMENT_PRODUCTION,
    ENVIROMENT_STAGING
} from "../constants/ServiceCostants";
import {NotFoundPage} from "../pages/errorPages/notFoundPage";
import {Environments} from "../enums/Environments";
import {AccessDeniedPage} from "../pages/errorPages/accessDeniedPage";

export const ProtectedRoute = ({ env = Environments.All } : { env?: Environments } = {}) => {

    let location = useLocation();
    const authState = useAppSelector(state => state.auth);

    if (!authState.isActivatedAcc) return ( <AccessDeniedPage/>);

    if(location.pathname === "/cabinet/support" && (!authState.isSupportUser || (CURRENT_REACT_ENVIROMENT === ENVIROMENT_PRODUCTION && authState.email !== 'lk-dev@turne-trans.ru')) ) return ( <AccessDeniedPage/>);

    let currentEnv: Environments = (function() {
        switch (CURRENT_REACT_ENVIROMENT)
        {
            case ENVIROMENT_DEVELOPMENT:
                return Environments.Development;
            case ENVIROMENT_PRODUCTION:
                return Environments.Production;
            case ENVIROMENT_STAGING:
                return Environments.Staging
            default:
                return Environments.None
        }
    })();

    if ((currentEnv & env) !== currentEnv){
        return <NotFoundPage />;
    }

    if (!authState.isValidUser) {
        // redirect to sign in page
        return <Navigate to="/sign-in"/>;
    }

    // return requested page
    return <Outlet />;
};