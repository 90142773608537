export class TimeUtils {

    static getTimeDetails = function(time: number, callback?: (h: number, m: number, s: number) => any) {
        let milliseconds = (time);
        let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
        let minutes = Math.floor(milliseconds / 60000);
        let seconds = ((milliseconds % 60000) / 1000);

        if(callback != null) {
            callback(hours, minutes, seconds);
        }

        return {
            hours,
            minutes,
            seconds
        };
    }

}