import React from "react";
import {useTranslation} from "react-i18next";
import {IAviaTariffInfo, IAviaVariant} from "../../../../../../interfaces/orderInterfaces/AviaOrderInterfaces";
import {TrainComponent} from "../../../../tripPage/trainComponent";
import {ScheduleIcon} from "../../../../../../icons/ScheduleIcon";
import {CircleSpinnerIcon} from "../../../../../../icons/CircleSpinnerIcon";
import {Guid} from "../../../../../../utils/utils";
import "./style.scss";
import {ITrainVariant} from "../../../../../../interfaces/ITrainVariant";


interface IFavoriteTrainsListComponent {
    trains: ITrainVariant[],
    //selectTrainHandler: (train: ITrainVariant, tariff: ITrainTariffInfo) => void;
}


export function FavoriteTrainsListComponent(props: IFavoriteTrainsListComponent) {

    const {
        trains,
        //selectTrainHandler,
    } = props;

    const {t} = useTranslation();


    return (
        <div className="trip-page-search-results">
            {
                trains.length > 0 && (
                    <div className="trip-page-search-results-tickets">
                        {
                            trains.map((train, trainIndex) =>
                                <React.Fragment key={trainIndex}>
                                    <TrainComponent
                                        trainInfo={{id: Guid.newGuid(), train}}
                                    />
                                </React.Fragment>
                            )
                        }
                    </div>
                )
            }

            {
                trains.length == 0 && (
                    <div className="trip-page-no-results-message">
                        <div className="trip-page-no-results-message-icon">
                            <ScheduleIcon
                                width="50px"
                                height="50px"
                                style={{
                                    "path": {
                                        fill: "rgb(100 100 100)"
                                    }
                                }}
                            />
                        </div>
                        <div className="trip-page-no-results-message-caption">
                            Список пуст
                        </div>
                        <div className="trip-page-no-results-message-content" style={{minWidth: "240px"}}>
                            Добавьте интересующие Вас поезда в разделе "Результаты поиска".
                        </div>
                    </div>
                )
            }

        </div>
    );
}