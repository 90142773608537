import React from "react";
import {ISvgIcon} from "../interfaces/componentInterfaces/IIconProps";


export const CalendarIcon = ({width = "24px", height = "24px", style = {}}: ISvgIcon) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            style={style["svg"]}>
            <path
                d="M149.192,103.525c15.995,0,28.964-12.97,28.964-28.973V28.964C178.157,12.97,165.187,0,149.192,0 C133.19,0,120.22,12.97,120.22,28.964v45.588C120.22,90.556,133.19,103.525,149.192,103.525z"
                style={style["path"]}
            />
            <path
                d="M362.815,103.525c15.994,0,28.964-12.97,28.964-28.973V28.964C391.78,12.97,378.809,0,362.815,0 c-16.003,0-28.973,12.97-28.973,28.964v45.588C333.843,90.556,346.812,103.525,362.815,103.525z"
                style={style["path"]}
            />
            <path
                d="M435.164,41.287h-17.925v33.265c0,30.018-24.415,54.432-54.423,54.432c-30.017,0-54.432-24.414-54.432-54.432 V41.287H203.615v33.265c0,30.018-24.414,54.432-54.423,54.432c-30.017,0-54.431-24.414-54.431-54.432V41.287H76.836 c-38.528,0-69.763,31.235-69.763,69.763v331.187C7.073,480.765,38.308,512,76.836,512h358.328 c38.528,0,69.763-31.235,69.763-69.763V111.05C504.927,72.522,473.691,41.287,435.164,41.287z M450.023,429.988 c0,17.826-14.502,32.329-32.328,32.329H94.305c-17.825,0-32.328-14.503-32.328-32.329V170.876h388.046V429.988z"
                style={style["path"]}
            />
        </svg>
    )
}