import React from "react";
import { Outlet } from "react-router-dom";
import "./style.scss";


export function HomeLayout() {
    return (
        <>
            <Outlet/>
        </>
    );
}