import { use } from "i18next";
import { DataConvertService } from "../../../../services/DataConvertService";
import { SearchByDateComponent } from "../../../commonComponents/searchByDateComponent";
import { IStatementIncome } from "../../../../interfaces/reportInterfaces/IStatementIncome";
import "../../../../scss/tables.scss";
import {useTranslation} from "react-i18next";

type StatementIncomesProps = {
  statementIncomes: IStatementIncome[];
};
export function StatementIncomesComponent({ statementIncomes }: StatementIncomesProps) {
  const { useMoneyRubFormat } = DataConvertService();
  const { t } = useTranslation();
  //   let useRUB = new Intl.NumberFormat("de-DE", {
  //     style: "currency",
  //     currency: "RUB",
  //   });

  return (
    <>
      <table className="table common-document-stripped-table table-bordered">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">{`${t("accInfoPage.date")}`}</th>
            <th scope="col">{`${t("statisticPage.paymentKind")}`}</th>
            <th scope="col">{`${t("statisticPage.summaRub")}`}</th>
          </tr>
        </thead>
        <tbody>
          {statementIncomes &&  statementIncomes.map((item, i) => {
            return (
              <tr>
                <td>{item.nn}</td>
                <td>{item.dateOper}</td>
                <td>{item.paymentKind}</td>
                <td>{useMoneyRubFormat.format(item.sumOper)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
