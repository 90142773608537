import {DATE_FORMAT_YYYYMMDD, ENVIROMENT_DEVELOPMENT} from "../constants/ServiceCostants";
import {useTranslation} from "react-i18next";

export function DateService() {
    const {t} = useTranslation();

    const getCustomDate = (date: Date, separator: string = "/") => {
        return date.getDate() + separator + date.getMonth() + separator + date.getFullYear();
    };

    const getCustomTime = (date: Date, separator: string = ":") => {
        return (date.getHours() < 10 ? "0" + date.getHours() : date.getHours())
            + separator
            + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes());
    };

    const getDateFromTrainVariant = (date: string, time: string) => {

        //using next separators: " ", "-", "_", ":"
        const regExp = new RegExp("/\\s|-|_|:/")

        try {
            const dateArr = date.split(regExp);
            const timeArr = time.split(regExp)
            const currentDate = new Date();
            let monthNum: number= getTrainMonthNumber(dateArr[1]);
            let dayNum: number = parseInt(dateArr[0]);
            let hours = parseInt(timeArr[0]);
            let minutes  = parseInt(timeArr[1]);
            let year = currentDate.getFullYear();
            if (currentDate.getMonth()< monthNum) {year++}

            return new Date(year,monthNum,dayNum,hours,minutes)
        }
        catch (err) {
            if ( process.env.REACT_APP_ENV===ENVIROMENT_DEVELOPMENT) {console.warn(err)}
            return new Date();
        }

    }

    const getTravelTime = (departureTime: Date, arrivedTime: Date) => {
        let travelTime: string = "В пути: ";
        let travelPeriod = arrivedTime.getTime() - departureTime.getTime();

        //calculate days
        if (travelPeriod / (1000 * 3600 * 24) > 0) {
            let travelDays = Math.trunc(travelPeriod / (1000 * 3600 * 24));
            if (travelDays > 1) {
                travelTime += travelDays + t("orderPage.shortDay");
                travelPeriod -= 1000 * 3600 * 24 * travelDays;
            } else if (travelDays === 1) {
                travelTime += "1" + t("orderPage.shortDay");
                travelPeriod -= 1000 * 3600 * 24;
            }
        }

        //calculate hours
        if (travelPeriod / (1000 * 3600) > 0) {
            let travelHors = Math.trunc(travelPeriod / (1000 * 3600));
            if (travelHors > 1) {
                travelTime += " " + travelHors + t("orderPage.shortHour");
                travelPeriod -= 1000 * 3600 * travelHors;
            } else if (travelHors === 1) {
                travelTime += " 1" + t("orderPage.shortHour");
                travelPeriod -= 1000 * 3600;
            }
        }

        //calculate minutes
        if (travelPeriod / (1000 * 60) > 0) {
            let travelMinutes = Math.trunc(travelPeriod / (1000 * 60));
            if (travelMinutes > 1) {
                travelTime += " " + travelMinutes + t("orderPage.shortMinutes");

                //travelPeriod -= 1000 * 60 * travelMinutes;
            } else {
                travelTime += " 1" + t("orderPage.shortMinutes");
                //travelPeriod -= 1000;
            }
        }

        // travelTime += "; ";
        return travelTime;
    };

    //Convert Date to string for datePicker
    //!  month has indexes 00..11 (00 - Jan)
    const dateToStringForDatePicker = (date: Date): string => {
        let result: string = date.getFullYear().toString() + "-";
        if (date.getMonth() + 1 < 10) {
            result += "0" + (date.getMonth() + 1) + "-";
        } else {
            result += date.getMonth() + 1 + "-";
        }
        if (date.getDate() < 10) {
            result += "0" + date.getDate();
        } else {
            result += date.getDate();
        }
        return result;
    };

    //Convert string from datePicker to Date
    //!  month has indexes 00..11 (00 - Jan)
    const stringToDateFromDatePicker = (dateAsString: string): Date => {
        let array = dateAsString.split("-").map(Number);
        return new Date(array[0], array[1] - 1, array[2]);
    };

    const dateFromDatePickerToCustomFormat = (
        date: string,
        dateFormat: string
    ) => {

        switch (dateFormat) {
            case DATE_FORMAT_YYYYMMDD: {
                let array = date.split("-");
                return array[0] + array[1] + array[2];
            }

            default:
                return "";
        }
    };

    //Convert date to falcon string format,  and you can update for other string format
    const dateToCustomFormat = (
        date: Date,
        dateFormat: string
    ) => {

        switch (dateFormat) {
            case DATE_FORMAT_YYYYMMDD: {

                return date.getFullYear().toString()
                    + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1).toString())
                    + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
            }

            default:
                return "";
        }
    };

    //validate Date length from dataPicker (10) /input date or not
    // const isValidDateLengthFromDataPicker (date:string)=>{
    //  return date.
    // }
    //TODO need to implement
    //validate that arrived date more then departure
    const isTimeSpanCorrect = (
        dateStart: Date,
        dateEnd: Date,
        minDateSpanMilliSec?: number
    ) => {
        minDateSpanMilliSec = minDateSpanMilliSec ?? 0;
        return dateEnd.getTime() - dateStart.getTime() >= minDateSpanMilliSec
    };

    //internal func
    // convert russian month to number for date, return month from 0 to 11 or -1 if catch invalid name.
    function getTrainMonthNumber(monthName: string) {
        switch (monthName) {
            case "янв" : {
                return 0;
            }
            case "фев": {
                return 1;
            }
            case "мар": {
                return 2;
            }
            case "апр": {
                return 3;
            }
            case "май": {
                return 4;
            }
            case "июн": {
                return 5;
            }
            case "июл": {
                return 6;
            }
            case "авг": {
                return 7;
            }
            case "сен": {
                return 8;
            }
            case "окт": {
                return 9;
            }
            case "ноя": {
                return 10;
            }
            case "дек": {
                return 11;
                            }
            default: {
               return -1
            }
        }
    }

    return {
        getCustomDate,
        getCustomTime,
        getTravelTime,
        isTimeSpanCorrect,
        dateFromDatePickerToCustomFormat,
        dateToStringForDatePicker,
        stringToDateFromDatePicker,
        dateToCustomFormat,
        getDateFromTrainVariant
    };
}
