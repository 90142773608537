import {ILocalization} from "../interfaces/siteOptions/ILocalization";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface SiteOptionsState {
    siteLanguage: ILocalization,
    activeMainPage: string,
    activeCabinetPage: string,
}

const initialState: SiteOptionsState = {
    siteLanguage: {key: "Ru-ru", name: "Русский", languageCode: "ru"},
    activeMainPage: "home",
    activeCabinetPage: "MyProfile"
}
export const siteOptionsSlice = createSlice({
    name: "siteOptions", initialState, reducers: {
        setLanguage: (state, action: PayloadAction<ILocalization>) => {
            state.siteLanguage = action.payload;
        },
        setActiveMainPage: (state, action: PayloadAction<string>) => {
            state.activeMainPage = action.payload;
        },
        setActiveCabinetPage: (state, action: PayloadAction<string>) => {
            state.activeCabinetPage = action.payload;
        },
    }
})

export const {
    setLanguage,
    setActiveCabinetPage,
    setActiveMainPage
} = siteOptionsSlice.actions;


export default siteOptionsSlice.reducer;