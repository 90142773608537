import {useState} from "react";
import {IAllowedPerson} from "../../../../interfaces/companyInfoInterfaces/IAllowedPerson";
import {useTranslation} from "react-i18next";
import "./style.scss";
import {ArrowRightColorIcon} from "../../../../icons/ArrowRightColorIcon";

export interface AuthorizedUsersInfoProps {
    //userId: number;
    // userName: string;
    autorizedUsers: IAllowedPerson[];
}

export function AuthorizedUsersInfoComponent({autorizedUsers}: AuthorizedUsersInfoProps) {
    const {t} = useTranslation();
    const [selectedUser, setSelectedUser] = useState(-1);

    return (
        <>
            {/*<div className="authorized-users-row-container">*/}
            <div className="authorized-users-container">
                <div className="authorized-users-list">
                    <div className="title"><label>{t("accInfoPage.authorizedUsers")}({autorizedUsers.length})</label>
                    </div>
                    <dl>
                        {autorizedUsers.map((pax, index) => (<dd onClick={() =>
                            setSelectedUser(index)} key={'select-all-paxes-' + index}>{pax.email}</dd>))}
                    </dl>
                </div>
                {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}

                {/*<ArrowLeftIcon/>*/}
                <div className="authorized-users-arrow-icon"><ArrowRightColorIcon iconSize={'50px'}/></div>

                <div className="authorized-users-list">
                    <div className="title"><label>{t("accInfoPage.comments")}</label></div>
                    <dl>
                        <dd>Email: {selectedUser > -1 && autorizedUsers[selectedUser].email}</dd>
                        <dd>Прочее: {selectedUser > -1 && autorizedUsers[selectedUser].code}</dd>
                    </dl>
                </div>
                {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}
            </div>
        </>
    );
}
