import {ICLientSale} from '../interfaces/companyInfoInterfaces/IClientSale';
import {ErrorText} from "../enums/ErrorText";
import {useTranslation} from "react-i18next";

export function DataConvertService() {
    const {t} = useTranslation()
    const convertObjToTupleArray = (obj: Object): [string, string][] => {
        let result: [string, string][] = [];

        const keys = Object.keys(obj);
        const values = Object.values(obj);
        for (let index = 0; index < keys.length; index++) {
            result.push([keys[index], values[index]]);
        }

        return result;
    };

    const objHasAnyPropertyValue = (obj: Object): boolean => {
        return Object.values(obj).length !== 0;
    };

    const useMoneyRubFormat = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "RUB",
    });

    const convertDataToGoogleBar = (clientSales: ICLientSale[]) => {

        let resultarray: (string | number)[][] = clientSales.map((x) => {
            return [x.monthNum + "-" + x.year, x.totalSum, x.operCount]
        })
        return resultarray;
    }

    const convertAndGetLastYearClientSalesToGoogleBar = (clientSales: ICLientSale[]) => {
        let resultClientSales: (ICLientSale[]);

        // if (clientSales.length > 12) {
        //   return convertDataToGoogleBar(clientSales.slice(clientSales.length - 12, clientSales.length))
        // } else {
        //   return []
        // }

        return
    }

    const stringIsNullOrEmpty = (data?: string) => {
        if (data === null || data === undefined) return true;
        return data.length !== 0;
    }

    const getError = (paramName: string, errorType: string): string | undefined => {

        switch (errorType) {
            case ErrorText.TooShort :
                return t("errorMsg.tooShort", {param: paramName})
            case ErrorText.IsEmpty :
                return t("errorMsg.isEmpty", {param: paramName})
            case ErrorText.NeedSelect:
                return t("errorMsg.needSelect", {param: paramName})
            case ErrorText.InvalidEmail:
                return t("errorMsg.invalidEmail",{param: paramName})
            default:return ""
        }
    }

    const getErrorByStatusCode = (status:number): string | undefined => {

        if(status>=500) {
            return t("errorMsg.anyServerError")
        }else if(status>=400 && status<500){
            return t("errorMsg.anyClientError")
        }


        // switch (status) {
        //     case 200 :
        //         return t("errorMsg.tooShort", {param: paramName})
        //     case  :
        //         return t("errorMsg.isEmpty", {param: paramName})
        //     case ErrorText.NeedSelect:
        //         return t("errorMsg.needSelect", {param: paramName})
        //     case ErrorText.InvalidEmail:
        //         return t("errorMsg.invalidEmail",{param: paramName})
        //     default:return ""
        // }
    }



    return {convertObjToTupleArray, useMoneyRubFormat, objHasAnyPropertyValue, stringIsNullOrEmpty, getError,getErrorByStatusCode};
}
