import "./style.scss";
import {IPax} from "../../../../interfaces/orderInterfaces/IPax";
import {useTranslation} from "react-i18next";
import {ArrowRightColorIcon} from "../../../../icons/ArrowRightColorIcon";
import {useState} from "react";
import {MdButton} from "../../../../components/md-button";
import {COMMON_BUTTON_STYLE} from "../../../../constants/componentsContants";
import {MdInputText} from "../../../../components/md-input-text";

type SelectPaxFullProps = {
    allPaxes?: IPax[];
    selectedPax?: IPax[] | undefined;
    updateSelectedPax?: (pax: IPax) => void;
    updateSelectedPaxes?: (paxes:IPax[]) => void;
}

export function SelectPaxFullComponent(props: SelectPaxFullProps) {

    const {t} = useTranslation();
    const [selectedPaxes, setSelectedPaxes] = useState<IPax[]>([]);
    const [selectedPax, setSelectedPax] = useState<IPax>()

    const [filteredPaxes, setFilteredPaxes] = useState<IPax[]>(props.allPaxes !== undefined ? props.allPaxes : []);
    const [inputText, setInputText] = useState("");


    const selectPaxHandler = (pax: IPax) => {
        const isHasDuplicate = selectedPaxes!.find(p => p.passportNum === pax.passportNum && p.fio === pax.fio)
        if (isHasDuplicate) return;

        setSelectedPaxes([...selectedPaxes, pax]);
        setSelectedPax(pax);
    }

    const filterPaxHandler = (input: string) => {
        const inputLength = input.length;
        setInputText(input);

        if (props.allPaxes === undefined) return;

        if (inputLength === 0) {
            setFilteredPaxes(props.allPaxes)
            return;
        }

        const regex = new RegExp("(\w*)" + input + "(\w*)", "i",);

        if (inputLength > inputText.length) {
            setFilteredPaxes(filteredPaxes.filter(pax => regex.test(pax.fio)))
            return;
        }
        setFilteredPaxes(props.allPaxes.filter(pax => regex.test(pax.fio)))
        console.log("Case 3");
    }
    const unselectPaxHandler = (pax: IPax) => {
        setSelectedPaxes(selectedPaxes.filter(sp => sp.fio !== pax.fio))
    }

    return (
        <div className="select-paxes-full-container">

            <div className="select-paxes-action-container">
                <div style={{alignSelf: "center", padding: "8px 16px"}}><p>Поиск сотрудника:</p></div>
                <MdInputText value={inputText} setValue={filterPaxHandler}/>

                {props.updateSelectedPaxes &&
                    <MdButton style={COMMON_BUTTON_STYLE} children={<p style={{color: "white"}}>&#10227; Обновить </p>}
                              onClick={() => {
                                  if (props.updateSelectedPaxes !== undefined) props.updateSelectedPaxes(selectedPaxes);
                              }}/>}
                {/*<InputTextComponent/>*/}
            </div>
            <div className="select-pax-full-row-container">

                {/* All paxes*/}
                <div className="select-pax-list">
                    <div className="title"><label>All ({props.allPaxes?.length})</label>
                    </div>
                    <dl>
                        {filteredPaxes && filteredPaxes.map((pax: IPax, index) => (<dd onClick={() =>
                            selectPaxHandler(pax)}
                                                                                       key={'select-all-paxes-' + index}>{pax.fio}</dd>))}
                    </dl>
                </div>

                {/*<MdList itemSource={props.allPaxesList.map(pax => pax.fio+" / "+ pax.phoneNumber)}></MdList>*/}

                {/*<ArrowLeftIcon/>*/}
                <div className="select-pax-full-arrow-icon"><ArrowRightColorIcon iconSize={'50px'}/></div>

                {/* Selected paxes*/}
                <div className="select-pax-list">
                    <div className="title"><label>Selected paxes </label></div>
                    <dl>
                        {selectedPaxes && selectedPaxes.map((pax: IPax, index) => (
                            <dd key={"selected-paxes-" + index}
                                className={(selectedPax?.fio === pax.fio && selectedPax.passportNum === pax.passportNum) ? " selected-list-item" : ""}>
                                <span onClick={() => setSelectedPax(pax)}>{pax.fio}</span>
                                <p className="unselect-button" onClick={() => {
                                    unselectPaxHandler(pax)
                                }}>&#x2715;</p>
                            </dd>))}
                    </dl>
                </div>

                {/*<ArrowLeftIcon/>*/}
                <div className="select-pax-full-arrow-icon"><ArrowRightColorIcon iconSize={'50px'}/></div>

                {/* Selected One pax*/}
                <div className="select-pax-list">
                    <div className="title">
                        <label> {selectedPax === undefined ? "Selected user info:" : selectedPax.fio} </label>
                    </div>
                    <dl>
                        <dd><p>FIO:</p>{selectedPax?.fio}</dd>
                        <dd><p>Latin Name:</p>{selectedPax?.first}</dd>
                        <dd><p>Latin Patronymic:</p>{selectedPax?.middle}</dd>
                        <dd><p>Latin Family:</p>{selectedPax?.last}</dd>
                        <dd><p>Passport series:</p>{selectedPax?.passportInfo.series}</dd>
                        <dd><p>Passport Number:</p>{selectedPax?.passportInfo.number}</dd>
                        <dd><p>Passport Expiry:</p>{selectedPax?.passportInfo.expiry}</dd>
                    </dl>
                </div>


            </div>

        </div>)
}