import {IIconProps} from "../interfaces/componentInterfaces/IIconProps";
import {DEFAULT_ICON_SIZE} from "../constants/componentsContants";


export function InfoIcon ({iconSize,activeColor,disableColor,isChecked}:IIconProps) {

    iconSize = iconSize??DEFAULT_ICON_SIZE;
    activeColor = activeColor??"#FFFFFF";
    disableColor = disableColor??"#FFFFFF";
    return(<>

        <svg
            width={iconSize}
             height={iconSize}
             viewBox="0 0 1024 1024"
             version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M512 512m-448 0a448 448 0 1 0 896 0 448 448 0 1 0-896 0Z" fill="#2196F3" />
            <path d="M469.333333 469.333333h85.333334v234.666667h-85.333334z" fill={isChecked?activeColor:disableColor} />
            <path d="M512 352m-53.333333 0a53.333333 53.333333 0 1 0 106.666666 0 53.333333 53.333333 0 1 0-106.666666 0Z"
                  fill={isChecked?activeColor:disableColor} /></svg>

    </>)
}