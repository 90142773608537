import "./style.scss";
import React, {useState} from "react";

type ColorCardProps = {
  id: "blue" | "lightBlue" | "green" | "lightgreen";
  icon? :React.ReactNode;
  title?:string;
  data?:[subTitle:string, text:string][]
};
export function ColorCardComponent({ id,icon,title,data }: ColorCardProps) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollHandler = (e:React.UIEvent<HTMLDivElement>) => {
   const { scrollTop, scrollHeight, clientHeight } =  e.currentTarget
      const position = Math.ceil(
          (scrollTop / (scrollHeight - clientHeight)) * 100
      );
      setScrollPosition(position);
  }

  return (
    <>
      <div id={id} className="color-card-body ">
        <div id={id} className="shape-circle-1"></div>
        <div id={id} className="shape-circle-2"></div>
        <div className="color-card-icon">{icon}</div>
        {/*<div id={id} className="color-card-text">*/}
        {/*  <span>Информация по контракту:</span>*/}
        {/*</div>*/}
        <div id={id} className="color-card-header">
          <span>{title}</span>
        </div>
        <div id={id} className="color-card-text"
             //onScroll={(e)=>{scrollHandler(e)}}

       //onScroll={handleScroll}
        >
          {data && data.map((item,index)=><p key={index}>{item[0]!==undefined?item[0] : "" }{item[1]}</p>)}
        </div>
      </div>
    </>
  );
}
